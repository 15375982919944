import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import ListPagination from "../../../Components/Pagination";
import SavedJobsList from "../../../Components/SavedJobsList";

import EmployerJobsList from "../../../Components/EmployerJobsList";

import { getAllJobsList } from "../../../Services/AllJobsService";
import getSavedJobsList from "../../../Services/SavedJobsService";
import Search from "../../../Components/Search";
import { getJob, getJobs, getJobStatus } from "../../../Services/GetFields";
import { employerJobsPerPage, sortParamsForJob } from "../../../Utilities/Helper";
import SpinnerLoader from "../../../Components/SpinnerLoader";

export default function SavedLaterJobsList() {
  const [AllJobs, setAllJobsList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState();
  const [searchParams, setSearchParams] = useState("");
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [jobId, setJobId] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [statusId, setStatusId] = useState([]);

  useEffect(() => {

    const getAllJobsList = () => {
      const filter = "recordperpage=" + employerJobsPerPage + "&jobstatusid=2" +
        currentPage.filter +
        searchParams +
        sort.filter;

      getJobs(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobs(result.jobs);
          setTotalJobs(result.totalRecord);
          setShowSpinner(false);
          let jobs = result.jobs;
          // console.log(jobs);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getAllJobsList();
  }, [refreshTable]);


  const refreshTableHandler = () => {
    setRefreshTable((prev) => !prev);
    setShowSpinner(true);
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ ...prev, filter: `&page=${page}` }));
      refreshTableHandler();
    }
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">Saved Later Jobs</h1>
      <div className="dashboard-wrapper resumes-page">
        <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg hide-show-btns">
              <div className="search">{/* <Search/> */}</div>
              {showSpinner && <SpinnerLoader />}
              {jobs.length === 0 && !showSpinner && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              <EmployerJobsList jobslist={jobs} />
              <ListPagination
                recordPerPage={employerJobsPerPage}
                TotalRecord={totalJobs}
                className="mt-3"
                onPageChange={pageChangeHandler}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default Jobs;

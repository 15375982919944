import React, { useState, Component } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import FormInput from "./Shared/FormInput";
import Buttons from "./Shared/Button";
import { Validators } from "../Utilities/Validator";

class SearchSupportTeam extends Component {
  state = {
    name: "",
    email: "",
    position: "",
    city: "",
    company: "",
    groupcategoryid: "",
    ResponseRequestPercentage: "",
  };

  // validation check

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  groupCategoryChange = (e) => {
    this.setState({ groupcategoryid: e.target.value });
  };

  percentageChange = (e) => {
    this.setState({ ResponseRequestPercentage: e.target.value });
  };

  validate = (name, email) => {
    let result = true;
    if (Validators.isNull(name) || Validators.isUndefined(name)) {
      result = false;
    }

    if (Validators.isNull(email)) {
      result = false;
    }
    return result;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let searchParam = "";
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key)) {
        if (this.state[key] !== "" && this.state[key] !== null)
          searchParam += `&${key}=${this.state[key]}`;
      }
    }
    this.props.searchParam(searchParam);
    //const { name, email } = this.state;
    // if (this.validate(name, email)) {
    //   console.log("success");
    // } else {
    //   console.log("invalid");
    // }
  };

  handleReset = () => {
    this.setState({
      name: "",
      email: "",
      position: "",
      city: "",
      company: "",
      groupcategoryid: "",
      ResponseRequestPercentage: "",
    });
    this.props.searchParam("");
  };

  //export
  handleExport = () => {
    console.log("handleExport", this.props);
    this.props.exportHandle();
  };

  render() {
    // const { name, email, position, city, jobtitleCompany } = this.state;
    // const { history } = this.props;

    return (
      <div className="search mb-4">
        <Form noValidate>
          <Row>
            <Col md={3}>
              <FormInput
                value={this.state.name}
                type="text"
                placeholder="Name"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("name")}
              />
            </Col>
            <Col md={3}>
              <FormInput
                value={this.state.email}
                type="text"
                placeholder="Email"
                onChange={this.handleChange("email")}
              />
            </Col>
            <Col md={3}>
              <FormInput
                value={this.state.position}
                type="text"
                placeholder="Position"
                onChange={this.handleChange("position")}
              />
            </Col>
            <Col md={3}>
              <FormInput
                value={this.state.city}
                type="text"
                placeholder="City"
                onChange={this.handleChange("city")}
              />
            </Col>

            <Col md={3}>
              <FormInput
                value={this.state.company}
                type="text"
                placeholder="Company"
                onChange={this.handleChange("company")}
              />
            </Col>

            <Col md={4}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  value={this.state.ResponseRequestPercentage}
                  className="inputclass form-control"
                  onChange={this.percentageChange}
                >
                  <option value="">
                    Network Responses/Requests Percentage
                  </option>
                  <option value="0-25">0%-25%</option>
                  <option value="26-50">26%-50%</option>
                  <option value="51-75">51%-75%</option>
                  <option value="76-100">76%-100%</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  value={this.state.groupcategoryid}
                  className="inputclass form-control"
                  onChange={this.groupCategoryChange}
                >
                  <option value="">Sub Category</option>
                  {this.props.groupCategories.map((item) => (
                    <option
                      key={item.groupCategoryId}
                      value={item.groupCategoryId}
                    >
                      {item.groupCategoryName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Buttons
                variant="warning"
                title="Search"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleSubmit}
              ></Buttons>
              <span style={{ marginLeft: "5px" }}></span>
              <Buttons
                variant="warning"
                title="Reset"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleReset}
              ></Buttons>

              <button
                type="button"
                className="btn btn-primary btn-sm float-right ml-1"
                onClick={this.handleExport}
              >
                Export to Excel
              </button>

              <button
                onClick={this.props.exportToPdf}
                type="button"
                className="btn btn-primary btn-sm float-right"
              >
                Export to PDF
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export default withRouter(SearchSupportTeam);

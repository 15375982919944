import { useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import AdminGroupCategoriesListing from "../../../Components/AdminGroupCategoriesListing";
import { getAllGroupCategoryForAdmin } from "../../../Services/Organization";
import { allUserPerPage } from "../../../Utilities/Helper";
import ListPagination from "../../../Components/Pagination";
import AdminMangeGroupSearch from "../../../Components/AdminMangeGroupSearch";
const AdminGroupCategories = () => {
  const [groups, setGroups] = useState([]);
  const [totalGroup, setTotalGroup] = useState([]);
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [showPagination, setShowPagination] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [searchParams, setSearchParams] = useState("");

  useEffect(() => {
    setShowPagination(true);
    async function GroupCategories() {
      const filter =
        "recordperpage=" + allUserPerPage + currentPage.filter + searchParams;
      getAllGroupCategoryForAdmin(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroups(result.groupCategory);
          setTotalGroup(result.totalRecord);
          setShowSpinner(false);
        })
        .catch((err) => {
          console.log("Group Categories: ", err);
          setShowSpinner(false);
        });
    }
    GroupCategories();
  }, [refreshTable]);

  const refreshTableHandler = () => {
    setShowSpinner(true);
    setRefreshTable((prev) => !prev);
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
    }
  };

  const onSerchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage({ value: "", filter: "" });
    refreshTableHandler();
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Group Details{" "}
        <Badge
          pill
          bg="danger"
          className="bg-warning text-white px-3 ml-3 font-weight-normal"
        >
          {totalGroup}
        </Badge>
      </h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg sponsership-page manage-group-list">
              <div className="search">
                <AdminMangeGroupSearch searchParam={onSerchParamHandler} />
              </div>
              <div className="d-flex">
                <AdminGroupCategoriesListing
                  groups={groups}
                  showSpinner={showSpinner}
                  refreshTable={refreshTableHandler}
                />
              </div>
              <div className="d-flex">
                {groups.length !== 0 && showPagination && (
                  <ListPagination
                    recordPerPage={allUserPerPage}
                    TotalRecord={totalGroup}
                    className="mt-3"
                    onPageChange={pageChangeHandler}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminGroupCategories;

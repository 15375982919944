//import Pagination from "react-bootstrap/Pagination";
import ReactPaginate from "react-paginate";

const getPageCount = (TRecord, perPage) => {
  let totalPage = 0;
  if (TRecord > 0 && perPage > 0) {
    const pageCount = TRecord / perPage;
    const roundedPageCount = Math.floor(pageCount);
    totalPage =
      roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
  }
  return totalPage;
};

export default function ListPagination({
  recordPerPage,
  TotalRecord,
  onPageChange,
}) {
  const TotalPage = getPageCount(TotalRecord, recordPerPage);

  const handlePageClick = (e) => {
    const selected = e.selected;
    onPageChange(selected + 1);
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={TotalPage} //TotalPage
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
        />
      </div>
    </div>
  );
}

{
  /* <Pagination className="mb-0">
        <Pagination.First className="first" />
        <Pagination.Prev className="prev" />
        <Pagination.Item active>{1}</Pagination.Item>
        <Pagination.Item>{2}</Pagination.Item>
        <Pagination.Item>{3}</Pagination.Item>
        <Pagination.Item>{4}</Pagination.Item>
        <Pagination.Item>{5}</Pagination.Item>
        <Pagination.Next className="next" />
        <Pagination.Last className="last" />
      </Pagination>
    </div> */
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import profile from "../assests/img/profile.png";
import {
  Button,
  Modal,
  Form,
  Toast,
  Alert,
  Tooltip,
  OverlayTrigger,
  Col,
  Row,
} from "react-bootstrap";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import Buttons from "./Shared/Button";
import SpinnerLoader from "./SpinnerLoader";
import { getGroupCategoriesByGroup, getGroups } from "../Services/GetFields";
import { Validators } from "../Utilities/Validator";
import { updateGroupCategorySTMCount } from "../Services/Organization";
import { toast } from "react-toastify";

export default function AdminGroupCategoriesListing({
  groups = [],
  showSpinner,
  refreshTable,
}) {
  const [show, setShow] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [groupCategoryId, setGroupCategoryId] = useState(0);
  const [groupsList, setGroupsList] = useState([]);
  const [groupsCategory, setGroupsCategory] = useState([]);
  const [groupDetails, setGroupDetails] = useState({
    groupId: 0,
    groupCategoryId: 0,
    minimumSupportTeamMembers: 0,
    GroupCategoryName: "Default Name",
  });
  const [groupError, setGroupError] = useState({ error: null, message: "" });
  const [groupCategoryError, setGroupCategoryError] = useState({
    error: null,
    message: "",
  });
  const [minimumSupportTeamMembersError, setMinimumSupportTeamMembersError] =
    useState({ error: null, message: "" });

  useEffect(() => {
    async function allGroups() {
      getGroups()
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroupsList(result);
        })
        .catch((err) => {
          console.log("Group List: ", err);
        });
    }
    allGroups();
  }, []);

  const handleClose = () => setShow(false);

  const editGroupCategoryHandler = (
    groupid,
    groupcategoryid,
    minimumSupportTeamMembers
  ) => {
    setShow(true);
    setGroupDetails((prev) => ({
      ...prev,
      groupId: groupid,
      groupCategoryId: groupcategoryid,
      minimumSupportTeamMembers: minimumSupportTeamMembers,
    }));
    handleGroupChange(undefined, groupid);
    setGroupCategoryError({ error: false, message: "" });
    setMinimumSupportTeamMembersError({ error: false, message: "" });
    setGroupError({ error: false, message: "" });
  };

  const handleGroupChange = (e, id) => {
    let val;
    if (id > 0) {
      val = id;
      // console.log("Sub Category");
      setGroupCategoryError({
        error: false,
        message: "",
      });
    } else {
      val = e.target.value;
      setGroupDetails((prev) => ({ ...prev, groupCategoryId: "0" }));
      setGroupCategoryError({
        error: null,
        message: "",
      });
    }

    setGroupDetails((prev) => ({ ...prev, groupId: val }));
    if (val == "0") {
      setGroupError({ error: true, message: "Please select the group" });
      return;
    }
    setGroupError({ error: false, message: "" });
    if (val != "0") {
      getGroupCategoriesByGroup(val)
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroupsCategory(result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setGroupsCategory([]);
    }
  };

  const handelGroupCategorychange = (e) => {
    const val = e.target.value;

    setGroupDetails((prev) => ({
      ...prev,
      groupCategoryId: val,
    }));
    if (val == "0") {
      setGroupCategoryError({
        error: true,
        message: "Please select the sub category",
      });
      return;
    }
    setGroupCategoryError({ error: false, message: "" });
  };

  const handelMinimumSupportTeamMembers = (e) => {
    const val = e.target.value;
    setGroupDetails((prev) => ({
      ...prev,
      minimumSupportTeamMembers: e.target.value,
    }));
    if (!val || !val.toString().trim().length) {
      setMinimumSupportTeamMembersError({
        error: true,
        message: "Support Team Member count is required",
      });
      return;
    } else {
      const res = Validators.number(val);
      if (res.error) {
        setMinimumSupportTeamMembersError({
          error: true,
          message: "Please enter valid number",
        });
        return;
      } else {
        setMinimumSupportTeamMembersError({ error: false, message: "" });
      }
    }
  };

  const updateGroupCategoryHandler = () => {
    const data = {
      GroupCategoryId: groupDetails.groupCategoryId,
      MinimumSupportTeamMembers: groupDetails.minimumSupportTeamMembers,
      GroupCategoryName: groupDetails.GroupCategoryName,
    };

    if (groupCategoryError.error == true || groupCategoryError.error == null) {
      setGroupCategoryError({
        error: true,
        message: "Please select the sub category",
      });
      return;
    }
    if (
      groupError.error == true ||
      groupCategoryError.error == true ||
      minimumSupportTeamMembersError.error == true
    ) {
      return;
    }

    updateGroupCategorySTMCount(data)
      .then((res) => {
        toast.success(res.response);
        setShow(false);
        refreshTable();
      })
      .catch((err) => {
        console.log("Update count error: ", err);
      });
  };

  return (
    <React.Fragment>
      <ul className="job-list support-team-list mobile-list">
        <li className="headings">
          <div className="m-width1">Group Name </div>
          <div className="m-width2">Sub Category Name </div>
          <div className="m-width3">Active STM's</div>
          <div className="m-width3">Network Limit</div>
          <div className="m-width4 text-right">Action</div>
        </li>
        {showSpinner && <SpinnerLoader />}
        {groups.length === 0 && !showSpinner && (
          <h5 style={{ textAlign: "center" }}>No Data Found</h5>
        )}
        {groups.map((item, index) => (
          <li key={index} className="align-items-center">
            <div className="m-width1 mobile-coulmn">
              <span className="mobile-column-name">Group Name</span>
              <span className="mobile-column-content">{item.groupName}</span>
            </div>
            <div className="m-width2 mobile-coulmn">
              <span className="mobile-column-name">Group Category Name</span>
              <span className="mobile-column-content">
                {item.groupCategoryName}
              </span>
            </div>
            <div className="m-width3 mobile-coulmn">
              <span className="mobile-column-name">Active STM's</span>
              <span className="mobile-column-content">{item.activeSTM}</span>
            </div>
            <div className="m-width3 break-word  mobile-coulmn">
              <span className="mobile-column-name">Minimun Support User</span>
              <span className="mobile-column-content">
                {item.minimumSupportTeamMembers}
              </span>
            </div>
            <div className="m-width4 seeker-action-btns text-md-right status small-action-btn">
              {" "}
              <Button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() =>
                  editGroupCategoryHandler(
                    item.groupId,
                    item.groupCategoryId,
                    item.minimumSupportTeamMembers
                  )
                }
              >
                <span className="icon-edit mr-2"></span>Edit
              </Button>
            </div>
          </li>
        ))}
      </ul>

      {/* Add Group Category Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custome-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Group Details</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleClose}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            <form>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="dd">
                    <Form.Control
                      as="select"
                      value={groupDetails.groupId}
                      className="inputclass form-control"
                      onChange={handleGroupChange}
                    >
                      <option value="0">Select Group</option>
                      {groupsList.map((item) => (
                        <option key={item.groupId} value={item.groupId}>
                          {item.groupName}
                        </option>
                      ))}
                    </Form.Control>
                    {groupError.error && (
                      <span style={{ color: "red" }}>{groupError.message}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Control
                      as="select"
                      className="inputclass form-control"
                      value={groupDetails.groupCategoryId}
                      onChange={handelGroupCategorychange}
                    >
                      <option value="0">Select Sub Category</option>
                      {groupsCategory.map((item) => (
                        <option
                          key={item.groupCategoryId}
                          value={item.groupCategoryId}
                        >
                          {item.groupCategoryName}
                        </option>
                      ))}
                    </Form.Control>
                    {groupCategoryError.error && (
                      <span style={{ color: "red" }}>
                        {groupCategoryError.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Support Team Member Limit"
                      className="inputclass "
                      onChange={handelMinimumSupportTeamMembers}
                      value={groupDetails.minimumSupportTeamMembers}
                    />
                    {minimumSupportTeamMembersError.error && (
                      <span style={{ color: "red" }}>
                        {minimumSupportTeamMembersError.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </form>
          </div>
          <div
            className="message-footer text-right"
            style={{ display: "block" }}
          >
            <Button
              variant="warning"
              title="Update"
              type="button"
              size="xs"
              color="white"
              onClick={updateGroupCategoryHandler}
            >
              update
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

import moment from "moment";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DownloadByteArray,
  saveResume,
  saveUnsaveDocument,
  unsaveResume,
} from "../Services/ResumeService";
import { base64ToArrayBuffer, saveByteArray } from "../Utilities/DownloadFile";

export default function NewResumesList({
  groupName,
  resumes = [],
  saveResumeChange,
  title,
}) {
  // console.log(resumes);
  const saveUnSaveResumeHandler = (
    resumeId,
    resumeName,
    jinTemplateId,
    isSaved,
    isPrivate
  ) => {
    const toastId = "custom_toast";
    // console.log(resumeId, resumeName, jinTemplateId);
    if (resumeId === null) {
      return;
    }
    if (isSaved === true) {
      unsaveResume(resumeId)
        .then((res) => {
          // const result = JSON.parse(res.response);
          if (res.status === 200) {
            toast.success("Resume removed from saved list", {
              toastId: toastId,
            });
            saveResumeChange(isPrivate ? "private" : "public");
            document.querySelectorAll(".removefocus").forEach((item) => {
              item.blur();
            });
          }
        })
        .catch((err) => {
          console.log("UnSave Resume:", err);
        });
    } else {
      saveResume(resumeId, resumeName, jinTemplateId)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Resume saved successfully", { toastId: toastId });
            saveResumeChange(isPrivate ? "private" : "public");
          }
        })
        .catch((err) => {
          console.log("Save Resume:", err);
        });
    }
  };

  const saveDocument = (JobSeekerResumeId) => {
    const toastId = "custom_toast";
    saveUnsaveDocument(JobSeekerResumeId)
      .then((res) => {
        toast.success(res, { toastId: toastId });
        saveResumeChange("private");
      })
      .catch((err) => {
        console.log("Save Resume:", err);
      });
  };

  const downloadFile = (filename, resumeName) => {
    DownloadByteArray(filename)
      .then((res) => {
        const result = JSON.parse(res.response);
        var sampleArr = base64ToArrayBuffer(result.fileContentInBytes);
        saveByteArray(resumeName, result.fileName, sampleArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ul className="resume-list">
      {/* <p>resumes</p> */}
      {resumes &&
        resumes.map((resume, index) => (
          <li key={index + resume.jobTitle}>
            <div className="resume-details flex1">
              {resume.jinTemplateId !== null && (
                <Link
                  // to={`${process.env.PUBLIC_URL}/resumedetails`}>
                  to={`${process.env.PUBLIC_URL}/employer/resumedetails/${resume.jinTemplateId}`}
                >
                  <h4>
                    {resume.jinTemplateId === null
                      ? resume.resumeName
                      : resume.jobTitle}
                  </h4>
                </Link>
              )}

              {resume.jinTemplateId === null && (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    downloadFile(resume.resumePath, resume.resumePath);
                  }}
                >
                  <h4>{resume.resumeName}</h4>
                </span>
              )}

              <div className="date-location">
                Name :{" "}
                {!resume.isPrivate && (
                  <span className="text">
                    {resume.jobSeekerName.split(" ")[0]}{" "}
                    {resume.jobSeekerName.split(" ")[1][0] + "."}
                  </span>
                )}
                {resume.isPrivate && (
                  <span className="text">
                    {resume.jobSeekerName.split(" ")[0]}{" "}
                    {resume.jobSeekerName.split(" ")[1]}
                  </span>
                )}
              </div>
              <div className="date-location">
                Posted :{" "}
                {resume.jinTemplateId !== null && (
                  <span className="text">
                    {!resume.datePosted && "--"}
                    {resume.datePosted &&
                      moment(
                        new Date(resume.datePosted.toString() + "Z").toString()
                      ).format("MMM DD, yyyy")}
                  </span>
                )}
                {resume.jinTemplateId === null && (
                  <span className="text">
                    {!resume.createdDate && "--"}
                    {resume.createdDate &&
                      moment(
                        new Date(resume.createdDate.toString() + "Z").toString()
                      ).format("MMM DD, yyyy")}
                  </span>
                )}
              </div>
              {resume.jinTemplateId !== null && (
                <div className="date-location">
                  Industry :{" "}
                  <span
                    className="text"
                    title={
                      resume.jinTemplatesIndustries &&
                      resume.jinTemplatesIndustries.map(
                        (resumes, index) => resumes.industryName
                      )
                    }
                  >
                    {(resume.jinTemplatesIndustries == "" ||
                      !resume.jinTemplatesIndustries) &&
                      "--"}
                    {/* {resume.jinTemplatesIndustries &&
                    resume.jinTemplatesIndustries.map((resumes, index) => (
                      <span>
                        {resumes.industryName}
                        {resume.jinTemplatesIndustries &&
                          index < resume.jinTemplatesIndustries.length - 1
                          ? ", "
                          : " "}
                      </span>
                    ))} */}
                    {resume.jinTemplatesIndustries &&
                      resume.jinTemplatesIndustries.map((resumes, index) => (
                        <span key={index}>
                          {index > 2 && index < 4 && ",..."}
                          {index == 0 && resumes.industryName}
                          {index <= 2 &&
                            index > 0 &&
                            ", " + resumes.industryName}
                        </span>
                      ))}
                  </span>
                </div>
              )}
              {resume.isPrivate && (
                <div className="date-location">
                  Location :{" "}
                  <span className="text">
                    {resume.city ? " " + resume.city : "-"}
                    {resume.stateName ? ", " + resume.stateName : "-"}
                  </span>
                </div>
              )}
            </div>
            {/* <div className={`fav-icon ml-auto ${resume.icon}`}>

            </div> */}
            {resume.jinTemplateId !== null && (
              <Button
                variant="warning"
                size="sm"
                className="fav-icon ml-auto btn-like removefocus"
                title={
                  resume.isResumeSaved == true
                    ? "Remove from Saved Resumes"
                    : "Add to Saved Resumes"
                }
                onClick={() =>
                  saveUnSaveResumeHandler(
                    resume.jobSeekerJINTemplateId,
                    resume.jobTitle,
                    resume.jinTemplateId,
                    resume.isResumeSaved,
                    resume.isPrivate
                  )
                }
                style={
                  resume.isResumeSaved == true
                    ? { backgroundColor: "#fe7d25", color: "white" }
                    : {}
                }
              >
                <i className="far fa-heart"></i>
              </Button>
            )}

            {resume.jinTemplateId === null && (
              <Button
                variant="warning"
                size="sm"
                className="fav-icon ml-auto btn-like removefocus"
                title={
                  resume.isResumeSaved == true
                    ? "Remove from Saved Resumes"
                    : "Add to Saved Resumes"
                }
                onClick={() => saveDocument(resume.jobSeekerResumeId)}
                style={
                  resume.isResumeSaved == true
                    ? { backgroundColor: "#fe7d25", color: "white" }
                    : {}
                }
              >
                <i className="far fa-heart"></i>
              </Button>
            )}
          </li>
        ))}
    </ul>
  );
}
// export default NewResumesList;

import React, { useState, Component, Fragment } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import FormInput from "./Shared/FormInput";
import Buttons from "./Shared/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Validators } from "../Utilities/Validator";
import Dropdown from "./Shared/Dropdown";
import { Link } from "react-router-dom";
import Select from "react-select";
import { searchPublicResumes } from "../Services/GetFields";

class SearchPrivateResumes extends Component {
  state = {
    jobtitle: "",
    city: "",
    fromdate: null,
    todate: null,
    fromDate: null,
    toDate: null,
    state: "",
    name: "",
    industryid: null,
    jobtypeid: null,
    groupid: this.props.groupId,
    isjobapplied: null,
  };

  // DatePicker settings

  constructor(props) {
    super(props);
    this.minDate = new moment(props.minDate);
    this.maxDate = new moment(props.maxDate);
    this.state = {
      fromdate: props.minDate,
      todate: props.maxDate,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleDateChange(date) {
    this.setState({
      fromdate: date,
      // fromDate: date,
    });
  }

  handleEndDateChange(date) {
    this.setState({
      todate: date,
      // toDate: date,
    });
  }

  handelindustrychange = (key) => (e) => {
    // console.log(key, e.target.value);
    this.setState({ [key]: e.target.value });
  };

  handeljobtypchange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleGroupChange = (key) => (e) => {
    // console.log(key, e.target.value);
    this.setState({ [key]: e.target.value });
  };

  // validation check

  validate = (keyword, location) => {
    let result = true;
    if (Validators.isNull(keyword) || Validators.isUndefined(keyword)) {
      result = false;
    }

    if (Validators.isNull(location)) {
      result = false;
    }
    return result;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let searchParam = "";
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key)) {
        // console.log(key);
        if (
          this.state[key] !== "" &&
          this.state[key] !== null &&
          this.state[key] !== undefined
        ) {
          if (key === "todate" || key === "fromdate") {
            var selectedDate = new Date(this.state[key]);
            var newDate = moment(selectedDate).format("YYYY/MM/DD");
            searchParam += `&${key}=${newDate}`;
          } else {
            searchParam += `&${key}=${this.state[key]}`;
          }
        }
      }
    }
    this.props.searchParam(searchParam);
    // console.log(searchParam);
  };

  resetSearchFilter() {
    this.setState({
      jobtitle: "",
      city: "",
      fromdate: null,
      todate: null,
      fromDate: null,
      toDate: null,
      state: "",
      name: "",
      industryid: "",
      jobtypeid: "",
      groupid: "",
    });
    this.props.searchParam("");
  }

  render() {
    const {
      jobtitle,
      city,
      fromdate,
      fromDate,
      todate,
      toDate,
      state,
      name,
      industryid,
      jobtypeid,
      groupid = this.props.groupId,
    } = this.state;
    const { history } = this.props;
    return (
      <div className="search mb-4">
        <Form noValidate>
          <Row>
            {/* <Col>
             
             {this.state.categories.map(cat => (
                <Dropdown options={cat.options} title={cat.name} />
              ))}
            
             </Col> */}
            <Col md={3}>
              <FormInput
                value={jobtitle}
                type="text"
                placeholder="Job Title"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("jobtitle")}
              />
            </Col>

            <Col md={3}>
              <FormInput
                value={name}
                type="text"
                placeholder="Name"
                onChange={this.handleChange("name")}
              />
            </Col>
            <Col md={3}>
              <div className="form-group">
                <DatePicker
                  selected={fromdate}
                  onChange={this.handleDateChange}
                  // minDate={this.minDate}
                  value={this.props.fromdate}
                  placeholderText="Resume Posted From Date"
                  className="inputclass form-control"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <DatePicker
                  selected={todate}
                  onChange={this.handleEndDateChange}
                  // minDate={this.maxDate}
                  value={this.props.todate}
                  placeholderText="Resume Posted End Date"
                  className="inputclass form-control"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
              </div>
            </Col>
            <Col md={3}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  className="inputclass form-control"
                  value={industryid}
                  onChange={this.handelindustrychange("industryid")}
                >
                  <option value="">Select Industry</option>
                  {this.props.industriesList &&
                    this.props.industriesList.map((item) => (
                      <option key={item.industryId} value={item.industryId}>
                        {item.industryName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="3" className="jobPostingType">
              <Form.Group controlId="jobPostingType">
                <Form.Control
                  onChange={this.handeljobtypchange("jobtypeid")}
                  as="select"
                  className="inputclass form-control"
                  value={jobtypeid}
                >
                  <option value="">Select Job Type</option>
                  {this.props.jobTypesList &&
                    this.props.jobTypesList.map((item) => (
                      <option key={item.jobTypeId} value={item.jobTypeId}>
                        {item.jobTypeName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <FormInput
                value={city}
                type="text"
                placeholder="City"
                onChange={this.handleChange("city")}
              />
            </Col>
            <Col md={3}>
              <FormInput
                value={state}
                type="text"
                placeholder="State"
                onChange={this.handleChange("state")}
              />
            </Col>

            <Col md={3} className="dd">
              <Form.Group controlId="dd">
                <Form.Control
                  as="select"
                  defaultValue={this.props.groupId}
                  value={groupid}
                  className="inputclass form-control"
                  onChange={this.handleGroupChange("groupid")}
                >
                  <option value="">Select Group</option>
                  {this.props.groupsList &&
                    this.props.groupsList.map((item) => (
                      <option key={item.groupId} value={item.groupId}>
                        {item.groupName}
                      </option>
                    ))}
                  <option value="0">All Groups</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Buttons
                variant="warning"
                title="Search"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleSubmit}
              ></Buttons>
              <span style={{ marginLeft: 18 }}></span>
              <Buttons
                variant="warning"
                title="Reset"
                size="xs"
                color="white"
                onClick={() => {
                  this.resetSearchFilter();
                  // window.location.reload();
                }}
              ></Buttons>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(SearchPrivateResumes);

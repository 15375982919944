import moment from "moment";
import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { saveJob } from "../Services/AllJobsService";
import SpinnerLoader from "./SpinnerLoader";
export default function NewJobList({
  groupName,
  posts = [],
  showSpinner,
  refreshJobs,
}) {
  const saveJobClick = (jobId) => {
    saveJob(jobId)
      .then((res) => {
        toast.success(res.response);
        document.querySelectorAll(".removefocus").forEach((item) => {
          item.blur();
        });
        refreshJobs();
      })
      .catch((err) => {
        toast.error(JSON.stringify(err));
      });
  };

  return (
    <ul className="resume-list">
      {showSpinner && <SpinnerLoader />}
      {posts.length === 0 && !showSpinner && (
        <h5 style={{ textAlign: "center" }}>No Data Found</h5>
      )}
      {posts.map((item, index) => (
        <li key={item.jobId}>
          <div className="resume-details">
            <Link
              to={`${process.env.PUBLIC_URL}/jobseeker/jobdetails/${item.jobId}`}
            >
              <h4>{item.positionTitle}</h4>
            </Link>
            <h5>{item.companyName}</h5>
            <div className="date-location">
              Industry : <span className="text"> {item.industryName}</span>
            </div>
            <div className="date-location">
              Posted :{" "}
              <span className="text">
                {" "}
                {moment(item.jobStartDate).format("MMMM DD, Y")}
              </span>
            </div>
            <div className="date-location">
              {" "}
              Location :{" "}
              <span className="text">
                {item.city === null || item.city === ""
                  ? "-"
                  : item.city + ", " + item.stateName === null ||
                    item.stateName === ""
                  ? "-"
                  : item.stateName}
              </span>
              <p className="discription">
                {" "}
                {item.jobDescription !== null && (
                  <Fragment>
                    {item.jobDescription.length <= 135 && (
                      <span className="discription">{item.jobDescription}</span>
                    )}
                    {item.jobDescription.length > 135 && (
                      <span className="discription">
                        {item.jobDescription.substr(0, 134) + "..."}
                      </span>
                    )}
                  </Fragment>
                )}
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/jobseeker/jobdetails/${item.jobId}`}
                className="btn-link"
              >
                Read more.
              </Link>
            </div>
          </div>
          <Button
            onClick={() => saveJobClick(item.jobId)}
            variant="warning"
            size="sm"
            className="fav-icon ml-auto btn-like removefocus"
            style={
              item.isSaved == true
                ? { backgroundColor: "#fe7d25", color: "white" }
                : {}
            }
          >
            <i className="far fa-heart"></i>
          </Button>
        </li>
      ))}
    </ul>
  );
}

import React, { useState, Component, Fragment } from "react";
import { Col, Row, Form, Alert, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import {
  validateCharacterAndSpace,
  validateCharacterSpaceAndDigit,
  validateCompany,
  validatePosition,
  Validators,
  isImage,
  validateCharacterAndDigit,
  required,
  validateCity,
} from "../../../Utilities/Validator";
import Select from "react-select";
import { useDispatch, connect } from "react-redux";
import { signUp } from "../../../Redux/signUp/Action";
import {
  deleteAGLPicture,
  deleteEmployerPicture,
  deleteJSPicture,
  deleteSTMPicture,
  getCountry,
  getGroupCategoriesByGroup,
  getGroups,
  getIndustry,
  getPicture,
  getStateByCountry,
  getUserDetails,
  setAGLPicture,
  setEmployerPicture,
  setJSPicture,
  setSTMPicture,
  updateAccountInformation,
} from "../../../Services/GetFields";
import {
  employerRequestAPI,
  groupLeaderRequestAPI,
  jobseekerRequestAPI,
  Roles,
  supportTeamRequestAPI,
} from "../../../Utilities/Helper";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FaSearch, FaTrashAlt, FaTimes } from "react-icons/fa";
import { post } from "../../../Services/AxiosService";
import { getData } from "../../../Utilities/StorageHelper";

class AccountInformation extends Component {
  state = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    country: "",
    countryState: "",
    countryStateError: false,
    city: "",
    typeOfAccount: "",
    typeOfAccountError: false,
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    group: "",
    subcategory: "",
    groupError: false,
    subcategoryError: false,
    organizationName: "",
    organizationNameError: false,
    companyName: "",
    companyNameError: false,
    position: "",
    positionError: false,
    formattedPhoneNumber: "",
    countryList: [],
    countryStateList: [],
    showStateTextBox: false,
    industryList: [],
    groupList: [],
    subcategoryList: [],
    showModal: false,
    imageModal: false,
    id: null,
    userId: null,
    industry: "",
    initialIndustry: "",
    groupId: "",
    groupName: "",
    groupCategoryName: "",
    stateName: "",
    firstNameValidError: false,
    lastNameValidError: false,
    companyNameValidError: false,
    organizationNameValidError: false,
    emailValidError: false,
    phoneValidError: false,
    stateValidError: false,
    cityValidError: false,
    positionValidError: false,
    industryNameArray: "",
    selectedImage: "",
    imageArray: [],
    pictureMessage: "No Preview",
    upload: false,
    isSponsered: "",
    deletePicture: false,
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    zipValidError: false,
    admin: false,
    employer_message:
      "Receive emails of newly posted resumes in your selected industries",
    jobseeker_message:
      "Receive emailed Job Postings for your selected industries",
  };

  componentDidMount = () => {
    let role = getData("role");
    if (role === "Employer") {
      this.setState({ typeOfAccount: "employer" });
    } else if (role === "Support Team Member") {
      this.setState({ typeOfAccount: "supportTeam" });
    } else if (role === "Job Seeker") {
      this.setState({ typeOfAccount: "jobSeeker" });
    } else if (role === "Group Leader") {
      this.setState({ typeOfAccount: "groupLeader" });
    }

    if (role === Roles.admin) {
      this.setState({ admin: true });
    }

    getPicture()
      .then((res) => {
        if (res.response) {
          this.setState({ savedSrc: res.response, src: "" });
        }
      })
      .catch((err) => {
        this.setState({ savedSrc: "" });
        console.log(err);
      });

    getUserDetails()
      .then((res) => {
        // console.log(res.response);
        let response = res.response;
        let industryArray = res.response.industries;

        let industry = [];
        if (role === "Employer") {
          for (let i = 0; i < industryArray.length; i++) {
            industry.push({
              IndustryId: industryArray[i].industryId,
              EmployerId: response.id,
            });
          }
        } else if (role === "Support Team Member") {
          for (let i = 0; i < industryArray.length; i++) {
            industry.push({
              IndustryId: industryArray[i].industryId,
              SupportTeamMemberId: response.id,
            });
          }
        } else if (role === "Job Seeker") {
          for (let i = 0; i < industryArray.length; i++) {
            industry.push({
              IndustryId: industryArray[i].industryId,
              JobSeekerId: response.id,
            });
          }
          // Binding Address for JS
          this.setState({
            addressLine1: response.addressLine1,
            addressLine2: response.addressLine2,
            zipCode: response.zipCode,
          });
        } else if (role === "Group Leader") {
          for (let i = 0; i < industryArray.length; i++) {
            industry.push({
              IndustryId: industryArray[i].industryId,
              GroupLeaderId: response.id,
            });
          }
        }

        const countryText = response.countryId;
        if (countryText === 3) {
          this.setState({ showStateTextBox: true });
        }
        getStateByCountry(countryText)
          .then((res) => {
            const result = JSON.parse(res.response);
            this.setState({ countryStateList: result });
          })
          .catch((err) => {
            console.log(err);
          });

        this.setState({
          id: response.id,
          firstName: response.firstName,
          lastName: response.lastName,
          phone: response.phoneNumber,
          email: response.email,
          country: response.countryId,
          countryState:
            countryText !== 3 ? response.stateId : response.stateName,
          stateName: response.stateName,
          city: response.city,
          group: response.groupId,
          groupName: response.groupName,
          subcategory: response.groupCategoryId,
          groupCategoryName: response.groupCategoryName,
          organizationName: response.groupName,
          position: response.position,
          companyName: response.companyName,
          password: response.password,
          acceptence: response.acceptence,
          resumeEmail: response.isResumeEmail,
          IsJobMatchIndustry: response.isJobMatchIndustry,
          industry: industry,
          groupId: response.groupId,
          isSponsered: response.isSponsered,
          // industryNameArray: array
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getCountry()
      .then((res) => {
        const result = JSON.parse(res.response);
        this.setState({ countryList: result });
      })
      .catch((err) => {
        console.log(err);
      });

    getIndustry()
      .then((res) => {
        const result = JSON.parse(res.response);
        let industryArray = [];
        for (let i = 0; i < result.length; i++) {
          industryArray.push({
            label: result[i].industryName,
            value: result[i].industryId,
          });
        }
        this.setState({ industryList: industryArray });
      })
      .catch((err) => {
        console.log(err);
      });

    getGroups()
      .then((res) => {
        const result = JSON.parse(res.response);
        this.setState({ groupList: result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCountryChange = (e) => {
    this.setState({ countryStateList: [] });
    let countryValue = 0;
    countryValue = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    const countryText = e.nativeEvent.target[index].text;
    this.setState({ country: countryValue, countryState: "" });
    if (countryText === "Other")
      this.setState({ showStateTextBox: true, stateName: "" });
    else this.setState({ showStateTextBox: false });
    if (countryValue === "0") {
      this.setState({ countryError: true });
    } else {
      this.setState({ countryError: false, countryStateError: false });
      getStateByCountry(countryValue)
        .then((res) => {
          const result = JSON.parse(res.response);
          this.setState({ countryStateList: result });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleEventChange = (event) => {
    // console.log(event.target.value, "value", event.target.name);
    if (event.target.name === "countryState") {
      if (event.target.value === "" || event.target.value === "0") {
        this.setState({ countryStateError: true });
      } else {
        this.setState({ countryStateError: false });
      }
      if (this.state.showStateTextBox === true) {
        this.setState({ stateName: event.target.value });
      } else this.setState({ countryState: event.target.value });
    }
  };

  handleChange = (key) => (value) => {
    // console.log("handle change", key, value);
    if (key === "firstName") {
      if (Validators.isNull(value)) {
        this.setState({
          firstNameError: true,
        });
      } else {
        // let val = Validators.isName(value, "firstname");
        // if (val.error === true) {
        //   this.setState({ firstNameValidError: true });
        // } else {
        //   this.setState({ firstNameValidError: false });
        // }
        this.setState({
          firstNameError: false,
        });
      }
    }
    if (key === "lastName") {
      if (Validators.isNull(value)) {
        this.setState({
          lastNameError: true,
        });
      } else {
        // let val = Validators.isName(value, "lastname");
        // if (val.error === true) {
        //   this.setState({ lastNameValidError: true });
        // } else {
        //   this.setState({ lastNameValidError: false });
        // }
        this.setState({
          lastNameError: false,
        });
      }
    }
    if (key === "phone") {
      // let val = Validators.isPhone(value, "phone");
      // if (val.error === true) {
      //   this.setState({ phoneValidError: true });
      // } else {
      //   this.setState({ phoneValidError: false });
      // }
      if (value === "") {
        this.setState({ phoneValidError: false });
      }
    }
    if (key === "city") {
      if (value === "") {
        this.setState({ cityError: true });
      } else {
        this.setState({ cityError: false });
      }
    }
    if (key === "companyName") {
      if (value === "") {
        this.setState({ companyNameError: true });
      } else {
        this.setState({ companyNameError: false });
      }
    }
    if (key === "email") {
      if (value === "") {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });
      }
    }
    if (key === "organizationName") {
      if (value === "") {
        this.setState({ organizationNameError: true });
      } else {
        this.setState({ organizationNameError: false });
      }
    }
    if (key === "position") {
      if (value === "") {
        this.setState({ positionError: true });
      } else {
        this.setState({ positionError: false });
      }
    }
    this.setState({ [key]: value });
    // console.log('key, value');
    if (key === "stateName") {
      if (this.state.showStateTextBox === true) {
        if (value === "") {
          this.setState({ countryStateError: true });
        } else {
          // let val = validateCharacterAndSpace(value, "state");
          // if (val.error === true) {
          //   this.setState({ stateValidError: true });
          // } else {
          //   this.setState({ stateName: value, countryStateError: false, stateValidError: false });
          // }
          this.setState({
            stateName: value,
            countryStateError: false,
            stateValidError: false,
          });
        }
      }
    }
  };

  handleOnBlur = (key) => (value) => {
    // console.log("handle change", key, value);
    if (key === "firstName") {
      if (Validators.isNull(value)) {
        this.setState({
          firstNameError: true,
        });
      } else {
        let val = Validators.isName(value, "firstname");
        if (val.error === true) {
          this.setState({ firstNameValidError: true });
        } else {
          this.setState({ firstNameValidError: false });
        }
        this.setState({
          firstNameError: false,
        });
      }
    }
    if (key === "lastName") {
      if (Validators.isNull(value)) {
        this.setState({
          lastNameError: true,
        });
      } else {
        let val = Validators.isName(value, "lastname");
        if (val.error === true) {
          this.setState({ lastNameValidError: true });
        } else {
          this.setState({ lastNameValidError: false });
        }
        this.setState({
          lastNameError: false,
        });
      }
    }
    if (key === "phone") {
      let val = Validators.isPhone(value, "phone");
      if (val.error === true) {
        this.setState({ phoneValidError: true });
      } else {
        this.setState({ phoneValidError: false });
      }
      if (value === "") {
        this.setState({ phoneValidError: false });
      }
    }
    if (key === "city") {
      if (value === "") {
        this.setState({ cityError: true });
      } else {
        let val = validateCity(value, "city");
        if (val.error === true) {
          this.setState({ cityValidError: true });
        } else {
          this.setState({ cityValidError: false });
        }
        this.setState({ cityError: false });
      }
    }

    //FOr JOb Seeker
    if (key === "zipCode") {
      // if (value === "") {
      //   this.setState({ zipValidError: true });
      // } else {
      const reqError = required(value);
      let val = validateCharacterAndDigit(value, "zipCode");
      if (val.error === true && !reqError.error) {
        this.setState({ zipValidError: true });
      } else {
        this.setState({ zipValidError: false });
      }
    }

    if (key === "companyName") {
      if (value === "") {
        this.setState({ companyNameError: true });
      } else {
        let val = validateCompany(value, "company");
        if (val.error === true) {
          this.setState({ companyNameValidError: true });
        } else {
          this.setState({ companyNameValidError: false });
        }
        this.setState({ companyNameError: false });
      }
    }
    if (key === "position") {
      if (value === "") {
        this.setState({ positionError: true });
      } else {
        let val = validatePosition(value, "position");
        if (val.error === true) {
          this.setState({ positionValidError: true });
        } else {
          this.setState({ positionValidError: false });
        }
        this.setState({ positionError: false });
      }
    }
    if (key === "email") {
      if (value === "") {
        this.setState({ emailError: true });
      } else {
        let val = Validators.email(value, "email");
        if (val.error === true) {
          this.setState({ emailValidError: true });
        } else {
          this.setState({ emailValidError: false });
        }
        this.setState({ emailError: false });
      }
    }
    if (key === "organizationName") {
      if (value === "") {
        this.setState({ organizationNameError: true });
      } else {
        let val = validateCharacterSpaceAndDigit(value, "organizationName");
        if (val.error === true) {
          this.setState({ organizationNameValidError: true });
        } else {
          this.setState({ organizationNameValidError: false });
        }
        this.setState({ organizationNameError: false });
      }
    }
    this.setState({ [key]: value });
    if (key === "stateName") {
      if (this.state.showStateTextBox === true) {
        if (value === "") {
          this.setState({ countryStateError: true });
        } else {
          let val = validateCharacterAndSpace(value, "state");
          if (val.error === true) {
            this.setState({ stateValidError: true, countryStateError: false });
          } else {
            this.setState({
              stateName: value,
              countryStateError: false,
              stateValidError: false,
            });
          }
          // this.setState({ stateName: value, countryStateError: false });
        }
      }
    }
  };

  handleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleImageModal = () => {
    this.setState({
      imageModal: !this.state.imageModal,
      selectedImage: "",
      picture: "",
      // src: "",
      upload: false,
      pictureMessage: "No Preview",
    });
  };

  removeImage = () => {
    this.setState(
      () => {
        return { deletePicture: true, src: "", savedSrc: "" }
      },
      () => {
        this.handleSubmit();
    });
  };

  uploadImage = () => {
    this.setState(
      () => {
        return {
          imageModal: !this.state.imageModal,
        };
      },
      () => {
        this.handleSubmit();
      }
    );
    const { selectedImage, imageArray } = this.state;
    // console.log(selectedImage.type);
    // console.log(selectedImage.name);
  };

  handlePictureSelected = (event) => {
    var picture = event.target.files[0];
    // console.log(picture.size);
    let filesize = 0;
    if (picture.size > 0) {
      filesize = (picture.size / (1024 * 1024)).toFixed(2);
    }
    // console.log(filesize);

    var src = URL.createObjectURL(picture);
    if (isImage(picture.name)) {
      if (picture.size > 0 && filesize < 10) {
        this.setState({
          upload: true,
          deletePicture: false,
          picture: picture,
          src: src,
          selectedImage: picture,
          savedSrc: "",
        });
      } else {
        this.setState({
          upload: false,
          deletePicture: false,
          pictureMessage:
            "Please select a valid image with Size less than 10 MB",
        });
      }
    } else {
      this.setState({
        deletePicture: false,
        pictureMessage: "Please select a valid image",
        upload: false,
      });
    }
  };

  renderPreview = () => {
    if (this.state.src || this.state.savedSrc) {
      return (
        <div className="message-body">
          <img
            // src={this.state.src}
            src={
              this.state.savedSrc === ""
                ? this.state.src
                : `https://data.jin-storage.us-sjo1.upcloudobjects.com/ProfilePhotos/${this.state.savedSrc}`
            }
            width="100"
            height="100"
          />
        </div>
      );
    } else {
      return (
        <div className="message-body" style={{ color: "red" }}>
          {this.state.pictureMessage}
        </div>
      );
    }
  };

  validate = (firstName, lastName, country, countryState, city, stateName) => {
    let result = true;
    if (Validators.isNull(firstName)) {
      this.setState({
        firstNameError: true,
      });
      result = false;
    } else {
      let value = Validators.isName(firstName, "firstname");
      if (value.error === true) {
        result = false;
        this.setState({ firstNameValidError: true });
      } else {
        result = true;
        this.setState({ firstNameValidError: false });
      }
      this.setState({
        firstNameError: false,
      });
      // result = true;
    }
    // if (result === false) {
    //   return false;
    // }
    if (Validators.isNull(lastName)) {
      result = false;
      this.setState({
        lastNameError: true,
      });
      result = false;
    } else {
      let value = Validators.isName(lastName, "lastname");
      if (value.error === true) {
        result = false;
        this.setState({ lastNameValidError: true });
      } else {
        result = true;
        this.setState({ lastNameValidError: false });
      }
      this.setState({
        lastNameError: false,
      });
      // result = true;
    }

    // if (result === false) {
    //   return false;
    // }
    if (Validators.isUndefined(country) || country === "0") {
      result = false;
      this.setState({
        countryError: true,
      });
      result = false;
    } else {
      this.setState({
        countryError: false,
      });
      result = true;
    }

    // if (result === false) {
    //   return false;
    // }

    if (this.state.showStateTextBox === false) {
      if (Validators.isNull(countryState) || countryState === "0") {
        this.setState({
          countryStateError: true,
        });
        result = false;
      } else {
        let value = validateCharacterSpaceAndDigit(countryState, "state");
        if (value.error === true) {
          result = false;
          this.setState({ stateValidError: true });
        } else {
          result = true;
          this.setState({ stateValidError: false });
        }
        this.setState({
          countryStateError: false,
        });
        // result = true;
      }
    } else {
      if (Validators.isNull(stateName) || stateName === "") {
        this.setState({
          countryStateError: true,
        });
        result = false;
      } else {
        let value = validateCharacterAndSpace(stateName, "state");
        if (value.error === true) {
          result = false;
          this.setState({ stateValidError: true, countryStateError: false });
        } else {
          result = true;
          this.setState({ stateValidError: false });
        }
        // this.setState({
        //   countryStateError: false,
        // });
        // result = true;
      }
    }
    // if (result === false) {
    //   return false;
    // }
    if (Validators.isNull(city) || city === "") {
      result = false;
      this.setState({
        cityError: true,
      });
      result = false;
    } else {
      let value = validateCity(city, "city");
      if (value.error === true) {
        result = false;
        this.setState({ cityValidError: true });
      } else {
        result = true;
        this.setState({ cityValidError: false });
      }
      this.setState({
        cityError: false,
      });
      //
      // result = true;
    }
    if (this.state.showStateTextBox === false) {
      if (
        firstName === "" ||
        lastName === "" ||
        country === undefined ||
        country === "0" ||
        countryState === "" ||
        countryState === "0" ||
        city === ""
      ) {
        return (result = false);
      } else return result;
    }
    if (!this.state.showStateTextBox === false) {
      if (
        firstName === "" ||
        lastName === "" ||
        country === undefined ||
        country === "0" ||
        city === "" ||
        stateName === "" ||
        this.state.stateValidError === true
      ) {
        return (result = false);
      } else return result;
    }
    return result;
  };

  validateCompany = (companyName) => {
    let result = true;
    if (Validators.isNull(companyName)) {
      result = false;
      this.setState({
        companyNameError: true,
      });
    } else {
      let value = validateCompany(companyName, "company");
      if (value.error === true) {
        result = false;
        this.setState({ companyNameValidError: true });
      } else {
        result = true;
        this.setState({ companyNameValidError: false });
      }
      this.setState({
        companyNameError: false,
      });
    }
    return result;
  };

  checkboxChange = (event) => {
    // setResumeEmail((resumeEmail) => !resumeEmail);
    if (event === "other")
      this.setState({ IsJobMatchIndustry: !this.state.IsJobMatchIndustry });
    else this.setState({ resumeEmail: !this.state.resumeEmail });
  };

  handleSubmit = async (event) => {
    if (event != null) {
      event.preventDefault();
    }
    const toastId = "custom_toast";

    let payload;
    let userType;
    const {
      id,
      firstName,
      lastName,
      typeOfAccount,
      organizationName,
      companyName,
      industry,
      position,
      country,
      countryState,
      city,
      group,
      subcategory,
      email,
      phone,
      companyNameError,
      groupError,
      subcategoryError,
      positionError,
      organizationNameError,
      showStateTextBox,
      groupId,
      password,
      resumeEmail,
      acceptence,
      companyNameValidError,
      positionValidError,
      phoneValidError,
      stateName,
      selectedImage,
      isSponsered,
      addressLine1,
      addressLine2,
      zipCode,
      zipValidError,
      IsJobMatchIndustry,
    } = this.state;

    if (
      this.validate(firstName, lastName, country, countryState, city, stateName)
    ) {
      if (typeOfAccount === "employer") {
        if (
          companyNameError == false &&
          companyNameValidError == false &&
          this.validateCompany(companyName)
        ) {
          userType = "Employer";
          payload = {
            EmployerId: id,
            FirstName: firstName.trim(),
            LastName: lastName.trim(),
            Roles: [userType],
            Phone: phone,
            Email: email,
            CompanyName: companyName.trim(),
            City: city.trim(),
            StateId: showStateTextBox ? null : countryState,
            StateName: showStateTextBox ? stateName.trim() : null,
            CountryId: country,
            TermsOfUse: true,
            IsResumeEmail: resumeEmail,
            EmployerIndustries: industry,
          };
        } else {
        }
      } else if (typeOfAccount === "jobSeeker") {
        if (zipValidError) {
          return;
        }
        userType = "Job Seeker";
        payload = {
          JobSeekerId: id,
          FirstName: firstName.trim(),
          LastName: lastName.trim(),
          Phone: phone,
          Email: email,
          City: city.trim(),
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          zipCode: zipCode,
          StateId: showStateTextBox ? null : countryState,
          State: showStateTextBox ? stateName.trim() : null,
          CountryId: country,
          TermsOfUse: true,
          IsResumeEmail: resumeEmail,
          JobSeekerIndustries: industry,
          isSponsered: isSponsered,
          IsJobMatchIndustry: IsJobMatchIndustry,
        };
      } else if (typeOfAccount === "supportTeam") {
        if (Validators.isNull(group)) {
          this.setState({
            groupError: true,
          });
        } else {
          this.setState({
            groupError: false,
          });
        }
        if (Validators.isNull(subcategory)) {
          this.setState({
            subcategoryError: true,
          });
        } else {
          this.setState({
            subcategoryError: false,
          });
        }
        if (Validators.isNull(position)) {
          this.setState({
            positionError: true,
          });
        } else {
          let value = validatePosition(position, "position");
          if (value.error === true) {
            this.setState({ positionValidError: true });
          } else {
            this.setState({ positionValidError: false });
          }
          this.setState({
            positionError: false,
          });
        }
        if (Validators.isNull(companyName)) {
          this.setState({
            companyNameError: true,
          });
        } else {
          let value = validateCompany(companyName, "company");
          if (value.error === true) {
            this.setState({ companyNameValidError: true });
          } else {
            this.setState({ companyNameValidError: false });
          }
          this.setState({
            companyNameError: false,
          });
        }
        if (
          groupError == false &&
          subcategoryError == false &&
          positionError == false &&
          positionValidError == false &&
          companyNameError == false &&
          companyNameValidError == false &&
          this.validateCompany(companyName)
        ) {
          userType = "Support Team Member";

          payload = {
            SupportTeamMemberId: id,
            FirstName: firstName.trim(),
            LastName: lastName.trim(),
            Phone: phone,
            Email: email,
            Roles: [userType],
            CompanyName: companyName.trim(),
            City: city.trim(),
            StateId: showStateTextBox ? null : countryState,
            State: showStateTextBox ? stateName.trim() : null,
            CountryId: country,
            TermsOfUse: true,
            SupportTeamMembersIndustries: industry,
            GroupId: groupId,
            Position: position.trim(),
            GroupCatgoryId: subcategory,
            IsJobMatchIndustry: IsJobMatchIndustry,
          };
        } else {
        }
      } else if (typeOfAccount === "groupLeader") {
        if (Validators.isNull(organizationName)) {
          this.setState({
            organizationNameError: true,
          });
        } else {
          this.setState({
            organizationNameError: false,
          });
        }
        if (organizationNameError == false) {
          userType = "Group Leader";
          payload = {
            AffiliateGroupLeaderId: id,
            FirstName: firstName.trim(),
            LastName: lastName.trim(),
            Email: email,
            Phone: phone,
            Roles: [userType],
            // CompanyName: companyName,
            GroupId: groupId,
            OrganizationName: organizationName,
            City: city.trim(),
            StateId: showStateTextBox ? null : countryState,
            State: showStateTextBox ? stateName.trim() : null,
            CountryId: country,
            TermsOfUse: true,
            GroupLeadersIndustries: industry,
          };
        }
      }

      // console.log(payload, "payload");
      let requestAPI;
      if (userType === "Employer") {
        requestAPI = employerRequestAPI;
      } else if (userType === "Job Seeker") {
        requestAPI = jobseekerRequestAPI;
      } else if (userType === "Support Team Member") {
        requestAPI = supportTeamRequestAPI;
      } else {
        requestAPI = groupLeaderRequestAPI;
      }

      if (phoneValidError == false) {
        if (payload != undefined) {
          const headers = {
            Authorization: "Bearer " + getData("token"),
            "content-type": "application/json",
          };
          // console.log(payload, "success");
          // axios
          //   .post(requestAPI, payload, { headers: headers })
          post(requestAPI, payload)
            .then((response) => {
              // console.log(response, "res");

              if (selectedImage) {
                const formData = new FormData();
                formData.append("FileName", selectedImage.name);
                formData.append("FormFile", selectedImage);

                if (userType === Roles.employer) {
                  setEmployerPicture(formData)
                    .then((res) => {
                      // console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                      // toast.warning(err);
                    });
                } else if (userType === Roles.jobSeeker) {
                  setJSPicture(formData)
                    .then((res) => {
                      // console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                      // toast.warning(err);
                    });
                } else if (userType === Roles.stm) {
                  setSTMPicture(formData)
                    .then((res) => {
                      // console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                      // toast.warning(err);
                    });
                } else if (userType === Roles.groupLeader) {
                  setAGLPicture(formData)
                    .then((res) => {
                      // console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                      // toast.warning(err);
                    });
                }
              }
              if (this.state.deletePicture) {
                if (userType === Roles.employer) {
                  deleteEmployerPicture()
                    .then((res) => {
                      console.log(res, "res");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else if (userType === Roles.jobSeeker) {
                  deleteJSPicture()
                    .then((res) => {
                      console.log(res, "res");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else if (userType === Roles.stm) {
                  deleteSTMPicture()
                    .then((res) => {
                      console.log(res, "res");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else if (userType === Roles.groupLeader) {
                  deleteAGLPicture()
                    .then((res) => {
                      console.log(res, "res");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
              if (response.status === 200) {
                toast.success("Account Information updated successfully.", {
                  toastId: toastId,
                });
                setTimeout(this.reload, 3000);
                // window.location.reload();
              }
            })
            .catch(function (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                toast.error(error.response.data.message);
                // toast.error(error.response.data.errors);
                console.log(error.response.data.errors);
              } else if (error.request) {
                console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
              //  console.log(error.config);
            });
        }
      }
    } else {
      console.log("Invalid Details entered");
      return;
    }
  };

  reload = () => {
    window.location.reload();
  };

  buttonChange = (event) => {
    // console.log(event.target.name, event.target.value, "radio button");
    if (event.target.value === "") {
      this.setState({
        typeOfAccountError: true,
      });
    } else {
      this.setState({
        typeOfAccount: event.target.value,
        typeOfAccountError: false,
      });
    }
  };

  handleIndustryChange = (event) => {
    // console.log(event);
    const { typeOfAccount, id } = this.state;
    let array = [];
    if (typeOfAccount === "employer") {
      for (let i = 0; i < event.length; i++) {
        array.push({ IndustryId: event[i].value, EmployerId: id });
      }
    } else if (typeOfAccount === "supportTeam") {
      for (let i = 0; i < event.length; i++) {
        array.push({ IndustryId: event[i].value, SupportTeamMemberId: id });
      }
    } else if (typeOfAccount === "jobSeeker") {
      for (let i = 0; i < event.length; i++) {
        array.push({ IndustryId: event[i].value, JobSeekerId: id });
      }
    } else if (typeOfAccount === "groupLeader") {
      for (let i = 0; i < event.length; i++) {
        array.push({ IndustryId: event[i].value, GroupLeaderId: id });
      }
    }
    this.setState({ industry: array });
  };

  handleGroupChange = (e) => {
    const groupValue = e.target.value;
    this.setState({ group: groupValue });
    if (groupValue === "0") {
      this.setState({ groupError: true });
    } else {
      this.setState({ groupError: false });
      getGroupCategoriesByGroup(groupValue)
        .then((res) => {
          const result = JSON.parse(res.response);
          this.setState({ subcategoryList: result });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const {
      firstName,
      lastName,
      organizationName,
      companyName,
      email,
      groupList,
      subcategoryList,
      position,
      typeOfAccount,
      firstNameError,
      lastNameError,
      emailError,
      organizationNameError,
      companyNameError,
      positionError,
      countryError,
      countryStateError,
      cityError,
      country,
      countryList,
      countryState,
      city,
      formattedPhoneNumber,
      phone,
      groupError,
      subcategoryError,
      showStateTextBox,
      countryStateList,
      industry,
      industryList,
      stateName,
      firstNameValidError,
      lastNameValidError,
      companyNameValidError,
      organizationNameValidError,
      emailValidError,
      phoneValidError,
      stateValidError,
      cityValidError,
      positionValidError,
      industryNameArray,
      addressLine1,
      addressLine2,
      zipCode,
      zipValidError,
      employer_message,
      jobseeker_message,
      resumeEmail,
      IsJobMatchIndustry,
    } = this.state;

    let checkval = [];
    for (let i = 0; i < this.state.industry.length; i++) {
      // checkval.push(industryNameArray[i].IndustryName);
      checkval.push(industry[i].IndustryId);
    }
    // console.log(checkval, 'loop');

    return (
      <div className="container p-0 post-job-container outline-form">
        <h1 className="page-title d-flex align-items-center">
          Account Information
        </h1>
        <div className="dashboard-wrapper resumes-page">
          <Form noValidate>
            <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg account-information-form">
                  <Row>
                    <Col md={9} className="mx-auto">
                      <h5 className="sub-form-title mb-4 mt-4 d-flex">
                        Type of account
                        {/* <span className="icon icon-edit ml-auto grey cursor-pointer"></span> */}
                      </h5>
                      <div className="mb-3 d-flex radio-buttons-new flex-wrap justify-content-between">
                        <div className="radio-new">
                          <input
                            id="radio-1"
                            name="radio"
                            type="radio"
                            checked
                            // checked={typeOfAccount === "jobSeeker"}
                            // value="employer"
                            onChange={this.buttonChange}
                          />
                          <label htmlFor="radio-1" className="radio-label">
                            {getData("role")}
                          </label>
                        </div>
                      </div>

                      <h5 className="sub-form-title mb-4  mt-4 d-flex">
                        Contact details
                        {/* <span className="icon icon-edit ml-auto grey cursor-pointer"></span> */}
                      </h5>

                      <Row>
                        <Col md={6}>
                          <FormInput
                            value={firstName}
                            type="text"
                            placeholder="First Name *"
                            maxLength={30}
                            required="required"
                            onChange={this.handleChange("firstName")}
                            onBlur={this.handleOnBlur("firstName")}
                          />
                          {firstNameError && (
                            <p style={{ color: "red" }}>
                              First name is required
                            </p>
                          )}
                          {firstNameValidError && !firstNameError && (
                            <p style={{ color: "red" }}>
                              Enter valid first name
                            </p>
                          )}
                        </Col>
                        <Col md={6}>
                          <FormInput
                            value={lastName}
                            type="text"
                            placeholder="Last Name *"
                            maxLength={30}
                            required="required"
                            onChange={this.handleChange("lastName")}
                            onBlur={this.handleOnBlur("lastName")}
                          />
                          {lastNameError && (
                            <p style={{ color: "red" }}>
                              Last name is required
                            </p>
                          )}
                          {lastNameValidError && !lastNameError && (
                            <p style={{ color: "red" }}>
                              Enter valid last name
                            </p>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <FormInput
                            value={phone}
                            minLength="10"
                            maxLength="11"
                            type="text"
                            placeholder="Phone"
                            required="required"
                            onChange={this.handleChange("phone")}
                            onBlur={this.handleOnBlur("phone")}
                          />
                          {phoneValidError && (
                            <p style={{ color: "red" }}>
                              Enter valid phone number
                            </p>
                          )}
                        </Col>

                        <Col md={6}>
                          <div className="form-group">
                            <FormInput
                              // style={{ backgroundColor: "#CCC" }}
                              // disabled
                              type="text"
                              placeholder="Email *"
                              maxLength={100}
                              value={email.trim()}
                              className="inputclass form-control"
                              onChange={this.handleChange("email")}
                              onBlur={this.handleOnBlur("email")}
                            />
                          </div>
                          {emailError && (
                            <p style={{ color: "red" }}>Email is required</p>
                          )}
                          {emailValidError && !emailError && (
                            <p style={{ color: "red" }}>Enter valid email</p>
                          )}
                        </Col>

                        {typeOfAccount == "groupLeader" && (
                          <Col md={6}>
                            <div className="form-group">
                              <FormInput
                                // style={{ backgroundColor: "#CCC" }}
                                // disabled
                                type="text"
                                placeholder="Group Name *"
                                maxLength={100}
                                value={this.state.organizationName}
                                className="inputclass form-control"
                                onChange={this.handleChange("organizationName")}
                                onBlur={this.handleOnBlur("organizationName")}
                              />
                            </div>
                            {organizationNameError && (
                              <p style={{ color: "red" }}>
                                Group name is required
                              </p>
                            )}

                            {organizationNameValidError &&
                              !organizationNameError && (
                                <p style={{ color: "red" }}>
                                  Enter valid group name
                                </p>
                              )}
                          </Col>
                        )}
                        {(typeOfAccount == "employer" ||
                          typeOfAccount == "supportTeam") && (
                          <Col md={6}>
                            <FormInput
                              value={companyName}
                              maxLength={30}
                              type="text"
                              placeholder="Company name *"
                              required="required"
                              onChange={this.handleChange("companyName")}
                              onBlur={this.handleOnBlur("companyName")}
                            />
                            {companyNameError && (
                              <p style={{ color: "red" }}>
                                Company name is required
                              </p>
                            )}
                            {companyNameValidError && !companyNameError && (
                              <p style={{ color: "red" }}>
                                Enter valid company name
                              </p>
                            )}
                          </Col>
                        )}
                        {typeOfAccount == "supportTeam" && (
                          <Col md={6}>
                            <FormInput
                              value={position}
                              type="text"
                              placeholder="Position *"
                              maxLength={50}
                              required="required"
                              onChange={this.handleChange("position")}
                              onBlur={this.handleOnBlur("position")}
                            />
                            {positionError && (
                              <p style={{ color: "red" }}>
                                Position is required
                              </p>
                            )}
                            {positionValidError && !positionError && (
                              <p style={{ color: "red" }}>
                                Enter valid position
                              </p>
                            )}
                          </Col>
                        )}

                        <Col className="dd">
                          <Form.Group controlId="dd">
                            <Form.Control
                              as="select"
                              name="country"
                              value={country}
                              onChange={
                                (this.handleChange("country"),
                                this.handleCountryChange)
                              }
                              className="inputclass form-control"
                            >
                              <option value="0">Select Country *</option>
                              {countryList.map((item) => (
                                <option value={item.countryId}>
                                  {item.countryName}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <div>
                            {countryError && (
                              <p style={{ color: "red" }}>
                                Country is required
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        {!showStateTextBox && (
                          <Col className="dd">
                            <Form.Group controlId="dd">
                              <Form.Control
                                as="select"
                                name="countryState"
                                value={countryState}
                                onChange={this.handleEventChange}
                                className="inputclass form-control"
                              >
                                <option value="0">
                                  {country == 2
                                    ? "Select Province *"
                                    : "Select State *"}
                                </option>
                                {countryStateList.map((item) => (
                                  <option value={item.stateId}>
                                    {item.stateName}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <div>
                              {countryStateError && (
                                <p style={{ color: "red" }}>
                                  {country == 2
                                    ? "Province is required"
                                    : "State is required"}
                                </p>
                              )}
                            </div>
                          </Col>
                        )}
                        {showStateTextBox && (
                          <Col md={6}>
                            <div className="form-group">
                              <FormInput
                                value={stateName}
                                maxLength={30}
                                type="text"
                                placeholder="State *"
                                required="required"
                                onChange={this.handleChange("stateName")}
                                onBlur={this.handleOnBlur("stateName")}
                              />
                            </div>
                            {countryStateError && (
                              <p style={{ color: "red" }}>State is required</p>
                            )}
                            {stateValidError && !countryStateError && (
                              <p style={{ color: "red" }}>Enter valid State</p>
                            )}
                          </Col>
                        )}
                        <Col md={6}>
                          <FormInput
                            value={city}
                            type="text"
                            placeholder="City *"
                            maxLength={30}
                            required="required"
                            onChange={this.handleChange("city")}
                            onBlur={this.handleOnBlur("city")}
                          />
                          {cityError && (
                            <p style={{ color: "red" }}>City is required</p>
                          )}
                          {cityValidError && !cityError && (
                            <p style={{ color: "red" }}>Enter valid City</p>
                          )}
                        </Col>
                        {/* {typeOfAccount == "jobSeeker" && (
                          <Fragment>
                            <Col md={6}>
                              <FormInput
                                value={addressLine1}
                                rows="3"
                                type="textarea"
                                placeholder="Address Line 1"
                                maxLength={100}
                                required="required"
                                onChange={this.handleChange("addressLine1")}
                                onBlur={this.handleOnBlur("addressLine1")}
                                style={{ resize: "none", height: 130 }}
                              />                              
                            </Col>
                            <Col md={6}>
                              <FormInput
                                value={addressLine2}
                                rows="3"
                                type="textarea"
                                placeholder="Address Line 2"
                                maxLength={100}
                                required="required"
                                onChange={this.handleChange("addressLine2")}
                                onBlur={this.handleOnBlur("addressLine2")}
                                style={{ resize: "none", height: 130 }}
                              />                              
                            </Col>
                            <Col md={6}>
                              <FormInput
                                value={zipCode}
                                type="text"
                                placeholder="Zip Code"
                                maxLength={6}
                                required="required"
                                onChange={this.handleChange("zipCode")}
                                onBlur={this.handleOnBlur("zipCode")}
                              />
                              {zipValidError && (
                                <p style={{ color: "red" }}>
                                  Zipcode is invalid
                                </p>
                              )}
                            </Col>
                          </Fragment>
                        )} */}
                      </Row>
                      <Row>
                        {typeOfAccount == "supportTeam" && (
                          <Col md={6}>
                            <div className="form-group">
                              <input
                                style={{ backgroundColor: "#CCC" }}
                                disabled
                                type="text"
                                placeholder="Group *"
                                value={this.state.groupName}
                                className="inputclass form-control"
                              />
                            </div>
                            <div>
                              {groupError && (
                                <p style={{ color: "red" }}>
                                  Group is required
                                </p>
                              )}
                            </div>
                          </Col>
                        )}
                        {typeOfAccount == "supportTeam" && (
                          <Col md={6}>
                            <input
                              style={{ backgroundColor: "#CCC" }}
                              disabled
                              type="text"
                              placeholder="Subcategory *"
                              value={this.state.groupCategoryName}
                              className="inputclass form-control"
                            />
                            <div>
                              {subcategoryError && (
                                <p style={{ color: "red" }}>
                                  Subcategory is required
                                </p>
                              )}
                            </div>
                          </Col>
                        )}
                      </Row>
                      <h5 className="sub-form-title mb-4 mt-4 d-flex">
                        Industry
                        {/* <span className="icon icon-edit ml-auto grey cursor-pointer"></span> */}
                      </h5>

                      <Row>
                        <Col md={6}>
                          <Select
                            placeholder="Select Industry"
                            options={industryList}
                            // value={industryList.filter((obj) =>
                            //   checkval.includes(obj.label)
                            // )}
                            value={industryList.filter((obj) =>
                              checkval.includes(obj.value)
                            )}
                            onChange={this.handleIndustryChange}
                            isMulti
                            className="selectd"
                          />
                        </Col>
                        <Col md={12}>
                          <div className="custom-control custom-checkbox my-4 py-3">
                            {(typeOfAccount == "jobSeeker" ||
                              "supportTeam") && (
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                checked={IsJobMatchIndustry}
                                name="checkbox2"
                                onChange={() => this.checkboxChange("other")}
                              />
                            )}

                            {typeOfAccount == "employer" && (
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                checked={resumeEmail}
                                name="checkbox2"
                                onChange={() => this.checkboxChange("employer")}
                              />
                            )}

                            {typeOfAccount == "employer" && (
                              <label className="custom-control-label">
                                {employer_message}
                              </label>
                            )}

                            {typeOfAccount == "jobSeeker" && (
                              <label className="custom-control-label">
                                {jobseeker_message}
                              </label>
                            )}
                            {typeOfAccount == "supportTeam" && (
                              <label className="custom-control-label">
                                {jobseeker_message}
                              </label>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Modal
                          show={this.state.imageModal}
                          onHide={this.handleImageModal}
                          centered
                          className="custome-modal"
                        >
                          <div className="message-container custome-msg-container">
                            <div className="message-header-box">
                              <h4>Manage Profile Photo</h4>
                              <div className="messages-modal-actions">
                                <Button
                                  className="btn"
                                  onClick={this.handleImageModal}
                                >
                                  <FaTimes />
                                </Button>
                              </div>
                            </div>
                            <div className="message-body">
                              {/* <form>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control type="file" />
                                </Form.Group>
                              </form> */}
                              <input
                                type="file"
                                name="myImage"
                                onChange={this.handlePictureSelected}
                              />
                            </div>
                            <div>{this.renderPreview()}</div>
                            <div className="users-box right mt-2 mb-2">
                              {!this.state.upload && (
                                <Buttons
                                  disabled
                                  variant="warning"
                                  title="Upload"
                                  size="xs"
                                  color="white"
                                  marginLeft="ml-3 mt-3 mb-3"
                                  onClick={this.uploadImage}
                                />
                              )}
                              {this.state.upload && (
                                <Buttons
                                  variant="warning"
                                  title="Upload"
                                  size="xs"
                                  marginLeft="ml-3"
                                  color="white"
                                  onClick={this.uploadImage}
                                />
                              )}
                              {this.state.selectedImage && (
                                <Buttons
                                  variant="warning"
                                  title="Replace"
                                  marginLeft="ml-3"
                                  size="xs"
                                  color="white"
                                  onClick={this.uploadImage}
                                />
                              )}
                              {!this.state.selectedImage && (
                                <Buttons
                                  disabled
                                  variant="warning"
                                  title="Replace"
                                  marginLeft="ml-3"
                                  size="xs"
                                  color="white"
                                  onClick={this.uploadImage}
                                />
                              )}
                              {(this.state.src !== "" ||
                                this.state.savedSrc !== "") && (
                                <Buttons
                                  variant="warning"
                                  marginLeft="ml-3"
                                  title="Remove"
                                  size="xs"
                                  color="white"
                                  onClick={() => {
                                    this.removeImage();
                                    localStorage.removeItem("profileImage");
                                    this.setState({
                                      imageModal: !this.state.imageModal,
                                    });
                                  }}
                                />
                              )}
                              {this.state.src === "" &&
                                this.state.savedSrc === "" && (
                                  <Buttons
                                    disabled
                                    variant="warning"
                                    marginLeft="ml-3"
                                    title="Remove"
                                    size="xs"
                                    color="white"
                                    onClick={() => {
                                      this.removeImage();
                                      localStorage.removeItem("profileImage");
                                      this.setState({
                                        imageModal: !this.state.imageModal,
                                      });
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                        </Modal>
                        {!this.state.admin && (
                          <Col
                            md={8}
                            className="square-radius mt-5 btn-account"
                          >
                            <div className="message-footer">
                              <Buttons
                                variant="warning"
                                title="Manage Profile Photo"
                                // type="submit"
                                // size="xxs"
                                color="white"
                                onClick={this.handleImageModal}
                              />
                            </div>
                          </Col>
                        )}
                      </Row>

                      {!this.state.admin && (
                        <Row>
                          <Col
                            md={8}
                            className="square-radius mt-5 btn-account"
                          >
                            <Buttons
                              variant="warning"
                              title="Submit Changes"
                              type="submit"
                              size="xs"
                              color="white"
                              onClick={this.handleSubmit}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

export default connect()(AccountInformation);

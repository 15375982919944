import { toast } from "react-toastify";
import { getUserGroups } from "../../Services/GetFields";
import Logout from "../../Services/LogoutService";
import { getNotificationsList } from "../../Services/NotificationService";
import { getData } from "../../Utilities/StorageHelper";

// import loginData from '../../json/CredData.json'
export const SUCCESS = "SUCCESS";

export const timeout = () => {
  const toastId = "custom_toast";
  toast.success("Session expired. Please login again.", { toastId: toastId });
  Logout();
  sessionStorage.clear();
  window.location.href = `${process.env.PUBLIC_URL}/#/home`;
};

const LoginActions = (action) => {
  //console.log(action, "kkkkk");

  // const toastId = "custom_toast";
  let time = getData("expiry_time");

  const d = new Date(0);
  d.setUTCSeconds(time);
  //console.log(d, "expiry date");

  const dateNow = Date.now();
  const diffTime = Math.abs(dateNow - d);
  //console.log(diffTime + " milliseconds");
  // if (time) {
  //   setTimeout(timeout, diffTime);
  // }

  return {
    type: SUCCESS,
    action,
  };
};
export const getLoginAction = (login) => (dispatch) => {
  dispatch(LoginActions(login));
};

// export const Auth=(state)=>{
//     return (dispatch)=>{
//         let{username,password}=loginData
//              if(state.username==username&&state.password==password){
//                 dispatch(LoginActions("SUCCESS"))
//              }else{
//                 dispatch(LoginActions("FAIL"))
//              }
//     }

// }

import React, { useState, useEffect, Component } from "react";
import { Col, Row, Form, Alert, Badge } from "react-bootstrap";
import { FaRegListAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import JobSeekersList from "../../../Components/JobSeekersList";
import {
  getAllJobSeekers,
  updateJobSeekerStatus,
} from "../../../Services/LeaderJobSeeker";
import alphabates from "../../../Components/AlphabatesSorting";
import { Helper, groupLeaderJSPerPage } from "../../../Utilities/Helper";
import ListPagination from "../../../Components/Pagination";
import { toast } from "react-toastify";
import { SortingAlphabate } from "../../../Components/Shared/SortingAlphabate";

import { generatePdf } from "../../../Utilities/Helper";

export default function JobSeekers() {
  const [JobSeekers, setJobSeekers] = useState([]);
  const [totalJobSeekers, setTotalJobSeekers] = useState([]);
  const [activeJobSeekers, setActiveJobSeekers] = useState([]);

  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);

  const [showSpinnerForJS, setShowSpinnerForJS] = useState(true);

  const [showAlertSuccess, setShowAlertSuccess] = useState({
    show: false,
    message: "",
  });
  const [showAlertError, setShowAlertError] = useState({
    show: false,
    message: "",
  });
  const name = "JobSeekersList.xlsx";

  const hideAlerts = () => {
    setTimeout(() => {
      setShowAlertSuccess({ show: false, message: "" });
      setShowAlertError({ show: false, message: "" });
    }, 5000);
  };

  useEffect(() => {
    async function jobSeeksersListData() {
      const filter =
        "recordperpage=" +
        groupLeaderJSPerPage +
        currentPage.filter +
        sort.filter;
      getAllJobSeekers(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobSeekers(result.jobSeekers);
          setTotalJobSeekers(result.totalRecord);
          setActiveJobSeekers(result.activeJobSeekerCount);
          setShowSpinnerForJS(false);
        })
        .catch((err) => {
          console.log("JobSeeker: ", err);
          setShowSpinnerForJS(false);
        });
    }
    jobSeeksersListData();
  }, [refreshTable]);

  const refreshTableHandler = () => {
    setShowSpinnerForJS(true);
    setCurrentPage((prev) => ({ ...prev, filter: "" }));
    setRefreshTable((prev) => !prev);
  };

  const sortingChangeHandler = (value) => {
    if (sort.value !== value && value !== "clear") {
      setSort((prev) => ({ value: value, filter: `&sort=${value}` }));
      refreshTableHandler();
    }
    if (sort.value !== value && value === "clear") {
      setSort((prev) => ({ value: value, filter: "" }));
      refreshTableHandler();
    }
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ ...prev, filter: `&page=${page}` }));
      setRefreshTable((prev) => !prev);
      setShowSpinnerForJS(true);
    }
  };

  const changeStatusHandler = (data) => {
    const userdata = { JobSeekerId: data.id, IsActive: !data.status };
    updateJobSeekerStatus(userdata)
      .then((res) => {
        if (data.status == true) {
          setShowAlertError({ message: res.response, show: true });
        } else {
          setShowAlertSuccess({ message: res.response, show: true });
        }
        hideAlerts();
        setRefreshTable((prev) => !prev);
        setShowSpinnerForJS(true);
      })
      .catch((err) => {
        console.log("StatusUpdate: ", err);
      });
  };
  // on click of export below method get execute
  const exportDataHandler = async () => {
    setShowSpinnerForJS(true);
    const filter = "recordperpage=" + totalJobSeekers + sort.filter;

    await getAllJobSeekers(filter)
      .then((res) => {
        if (res) {
          console.log("res", res);
          const result = JSON.parse(res.response);

          const data = prepareData(result);
          //excel file genration
          Helper.excelGeneration(data, name);
        }
      })
      .catch((err) => {
        console.log("JobSeekerExport: ", err);
      });
    setShowSpinnerForJS(false);
  };

  const prepareData = (rawData) => {
    const jobSeekerData = rawData.jobSeekers;
    let data = [
      {
        Name: null,
        Email: null,
        Phone: null,
        Status: null,
      },
    ];
    if (jobSeekerData.length > 0) {
      data = jobSeekerData.map((item) => {
        const active = "Active";
        const inActive = "InActive";
        return {
          Name: item.firstName + " " + item.lastName,
          Email: item.email,
          Phone: item.phone,
          Status: item.isActive ? active : inActive,
        };
      });
    }

    return data;
  };

  const dataToExportAsPdf = (res) => {
    const userData = [];

    res.jobSeekers.forEach((element, index) => {
      let fullName = element.firstName + " " + element.lastName;
      userData.push([
        fullName,
        element.email,
        element.phone,
        element.isActive ? "Active" : "Inactive",
      ]);
    });

    const pdfHeaders = ["Name", "Email", "Phone", "Status"];

    generatePdf(pdfHeaders, userData, "SponsoredJobSeekersList.pdf", "Job Seekers List");
  };

  const handleExportToPdf = () => {
    const filter = "recordperpage=" + totalJobSeekers + sort.filter;
    setShowSpinnerForJS(true);

    getAllJobSeekers(filter)
      .then((res) => {
        const result = JSON.parse(res.response);
        dataToExportAsPdf(result);
        setShowSpinnerForJS(false);
      })
      .catch((err) => {
        console.log("JobSeeker: ", err);
        setShowSpinnerForJS(false);
      });
  };
  return (
    <React.Fragment>
      <Alert
        className="confirm-alert"
        variant="success"
        show={showAlertSuccess.show}
        onClose={() => setShowAlertSuccess({ show: false, message: "" })}
        dismissible
      >
        <p className="text-center mb-0 py-3">{showAlertSuccess.message}</p>
      </Alert>
      <Alert
        className="confirm-alert"
        variant="danger"
        show={showAlertError.show}
        onClose={() => setShowAlertError({ show: false, message: "" })}
        dismissible
      >
        <p className="text-center mb-0 py-3">{showAlertError.message}</p>
      </Alert>
      {/* excel genration  */}

      <div className="container p-0">
        <h1 className="page-title">
          Sponsored Job Seekers{" "}
          <Badge
            pill
            bg="danger"
            className="bg-warning text-white px-3 ml-3 font-weight-normal"
          >
            {activeJobSeekers}
          </Badge>
          <button
            type="button"
            className="btn btn-primary btn-sm float-right ml-1"
            onClick={exportDataHandler}
          >
            Export to Excel
          </button>
          <button
            onClick={handleExportToPdf}
            type="button"
            className="btn btn-primary btn-sm float-right"
          >
            Export to PDF
          </button>
        </h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={12}>
              <div className="dashboard-box dashboard-col-bg sponsership-page">
                <div className="sort d-flex justify-content-end">
                  {/* <Form.Group controlId="exampleForm.ControlSelect1" className="w-25">
                <Form.Control as="select" className="inputclass form-control">
                  <option>Sort By</option>
                  <option>Name</option>
                  <option> Email </option>
                  <option>Phone</option>
                  <option> Job Title & Company</option>
                </Form.Control>
              </Form.Group> */}
                </div>
                <div className="d-flex">
                  <JobSeekersList
                    jobSeekersList={JobSeekers}
                    onChangeStatus={changeStatusHandler}
                    showSpinner={showSpinnerForJS}
                    onClick={exportDataHandler}
                  />
                  <SortingAlphabate ChangeHandler={sortingChangeHandler} />
                </div>
                <div className="d-flex">
                  {JobSeekers.length !== 0 && (
                    <ListPagination
                      recordPerPage={groupLeaderJSPerPage}
                      TotalRecord={totalJobSeekers}
                      className="mt-3"
                      onPageChange={pageChangeHandler}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

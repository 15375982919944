import { API_URL } from "../Utilities/Helper";
import { get, post, _delete } from "./AxiosService";

const Url = API_URL;

// export function getAllGroupCategory(filter) {
//   return fetch(`${Url}user/groupcategoriesforgroupleader?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getAllGroupCategory(filter) {
  return get(`${Url}user/groupcategoriesforgroupleader?` + filter).then((res) => res.data);
}

// export function getGroupCategory(groupCategoryId) {
//   return fetch(`${Url}user/groupcategory/` + groupCategoryId, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getGroupCategory(groupCategoryId) {
  return get(`${Url}user/groupcategory/` + groupCategoryId).then((res) => res.data);
}

// export function addGroupCategory(data) {
//   return fetch(`${Url}user/addgroupcategory`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function addGroupCategory(data) {
  return post(`${Url}user/addgroupcategory`, data).then((res) => res.data);
}

// export function updateGroupCategory(data) {
//   return fetch(`${Url}user/updategroupcategory`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function updateGroupCategory(data) {
  return post(`${Url}user/updategroupcategory`, data).then((res) => res.data);
}

// export function deleteGroupCategory(groupCategoryId) {
//   return fetch(`${Url}user/deletegroupcategory/` + groupCategoryId, {
//     method: "Delete",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-type": "application/json",
//     },
//   }).then((data) => data.json());
// }

export function deleteGroupCategory(groupCategoryId) {
  return _delete(`${Url}user/deletegroupcategory/` + groupCategoryId).then((res) => res.data);
}

// export function getAllGroupCategoryForAdmin(filter) {
//   return fetch(`${Url}JinAdminMangeUser/GetGroupCategoriesForAdmin?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getAllGroupCategoryForAdmin(filter) {
  return get(`${Url}JinAdminMangeUser/GetGroupCategoriesForAdmin?` + filter).then((res) => res.data);
}

// export function updateGroupCategorySTMCount(data) {
//   return fetch(`${Url}JinAdminMangeUser/UpdateGroupCategorySTMCount`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function updateGroupCategorySTMCount(data) {
  return post(`${Url}JinAdminMangeUser/UpdateGroupCategorySTMCount`, data).then((res) => res.data);
}
import React, { useState, useEffect } from "react";
import { Col, Row, Form, Badge } from "react-bootstrap";

import ListPagination from "../../../Components/Pagination";
import SavedJobsList from "../../../Components/SavedJobsList";

import EmployerJobsList from "../../../Components/EmployerJobsList";

import getAllJobsList from "../../../Services/AllJobsService";
import getSavedJobsList from "../../../Services/SavedJobsService";
import SearchJob from "../../../Components/SearchJob";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroups,
  getIndustry,
  getJob,
  getJobs,
  getJobStatus,
} from "../../../Services/GetFields";
import {
  employerJobsPerPage,
  sortParamsForJob,
} from "../../../Utilities/Helper";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import { globalSearchAction } from "../../../Redux/GlobalSearch/Action";
// import { getjobsAction } from "../../../Redux/Actions/JobsAction";

export default function JobsList() {
  //Using Redux
  const store = useSelector((res) => res.GlobalSearchReducer.jobs);

  const [AllJobs, setAllJobsList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState();
  const [SavedJobs, setSavedJobs] = useState([]);
  //const dispatch = useDispatch();
  const [industries, setIndustries] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [searchParams, setSearchParams] = useState("");
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [currentPage, setCurrentPage] = useState({ value: "0", filter: "" });
  const [jobId, setJobId] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [statusId, setStatusId] = useState([]);
  const [pageTitle, setpageTitle] = useState("Published Jobs");
  const dispatch = useDispatch();
  const [showPagination, setShowPagination] = useState(true);
  // const history=useHistory();
  // const state = useSelector((state) => state);

  useEffect(() => {
    // getJob(22)
    //   .then((res) => {
    //     // console.log(res);
    //     let response = JSON.parse(res.response);
    //     // console.log(response);

    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setShowPagination(true);
    const getAllJobs = () => {
      const filter =
        "recordperpage=" +
        employerJobsPerPage +
        currentPage.filter +
        searchParams +
        sort.filter;
      getJobs(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobs(result.jobs);
          setTotalJobs(result.totalRecord);
          setShowSpinner(false);
          let jobs = result.jobs;
          // console.log(jobs);
          // const paragraph = filter;
          // const regex = "&jobstatusid=";
          // const found = paragraph.match(regex);
          // console.log(filter.at(28));
          if (filter) {
            if (filter.at(28) === "1" || filter.at(28) === undefined) {
              setpageTitle("Published Jobs");
            } else if (filter.at(28) === "2") {
              setpageTitle("Draft Jobs");
            } else if (filter.at(28) === "3") {
              setpageTitle("Filled/Closed Jobs");
            } else if (filter.at(28) === "4") {
              setpageTitle("Expired Jobs");
            } else if (filter.at(28) === "0") {
              setpageTitle("All Jobs");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setShowSpinner(false);
        });
    };
    if (store === null) {
      getAllJobs();
    } else {
      setShowSpinner(true);
      setJobs(store.items);
      setTotalJobs(store.totalRecord);
      setShowSpinner(false);
    }

    getGroups()
      .then((res) => {
        const result = JSON.parse(res.response);
        setGroupList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refreshTable, store]);

  useEffect(() => {
    getJobStatus()
      .then((res) => {
        const result = JSON.parse(res.response);
        setStatusId(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    async function allIndustries() {
      getIndustry()
        .then((res) => {
          const result = JSON.parse(res.response);
          setIndustries(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    allIndustries();
  }, []);

  const onSearchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  const refreshTableHandler = (page = 0) => {
    if (store !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store.recordperpage,
          page: page,
          type: store.type,
          SerachText: store.SerachText,
          GlobalSearchTypeId: store.GlobalSearchTypeId,
          searchTypeText: store.searchTypeText,
        })
      );
      setShowSpinner(true);
    } else {
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
    }
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      refreshTableHandler(page);
    }
  };

  const sortingChangeHandler = (e) => {
    var val = e.target.value;
    setSort((prev) => ({ ...prev, filter: `&sort=${val}` }));
    refreshTableHandler();
  };

  const refreshPage = () => {
    refreshTableHandler();
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">
        {pageTitle}
        {""}
        <Badge
          pill
          bg="danger"
          className="bg-warning text-white px-3 ml-3 font-weight-normal"
        >
          {totalJobs}
        </Badge>
      </h1>
      <div className="dashboard-wrapper resumes-page">
        {/* <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg mb-4 saved-resume  hide-btns hide-heart-icon">
              <h2 className="page-sub-title">Drafts</h2>
             
              <EmployerJobsList jobslist={AllJobs}/>
              <ListPagination/>
            </div>
          </Col>
          </Row>  */}
        <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg hide-show-btns">
              <div className="search">
                <SearchJob
                  industriesList={industries}
                  groupsList={groupList}
                  statusIdList={statusId}
                  searchParam={onSearchParamHandler}
                />
              </div>

              <div className="sort">
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="w-25"
                >
                  {" "}
                  <label>
                    <span>Sort By:</span>
                  </label>
                  <Form.Control
                    as="select"
                    className="inputclass form-control"
                    onChange={sortingChangeHandler}
                  >
                    {sortParamsForJob.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              {showSpinner && <SpinnerLoader />}
              {jobs.length === 0 && !showSpinner && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              <EmployerJobsList jobslist={jobs}
                refreshPage={refreshPage} />
              {jobs.length !== 0 && showPagination && (
                <ListPagination
                  recordPerPage={employerJobsPerPage}
                  TotalRecord={totalJobs}
                  className="mt-3"
                  onPageChange={pageChangeHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default Jobs;

import axios from "axios";
import { logoutAPI } from "../Utilities/Helper";
import { getData } from "../Utilities/StorageHelper";


export default function Logout() {

    const token = getData("token");

    // const options = {
    //     method: "GET",
    //     url: logoutAPI + "?id_token_hint=" + token,
    //     // + "&post_logout_redirect_uri=http%3A%2F%2Flocalhost%3A3002%2FjobInfonetwork%2Fprototype%2Fhome%2Fhome",
    //     headers: { "content-type": "application/json" },
    // };
    // // console.log(options, logoutAPI);
    // axios.request(options).then((response) => {
    //     console.log('data', response.data.headers);
    //     if (response.status === 200) {
    //         // console.log('data', response);
    //     }
    // })
    //     .catch(function (error) {
    //         console.error("error", error);
    //     });


    fetch(logoutAPI + "?id_token_hint=" + token, {
        method: "GET",
        redirect: "manual"
    }).then((data) => {
        // console.log(data);
    });
}
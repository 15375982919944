import React, { useState, useEffect } from "react";
import { Badge, Col, Row } from "react-bootstrap";

import AllActivities from "../../../Components/AllActivities";
import getAllActivities from "../../../Services/AllActivities";
import ListPagination from "../../../Components/Pagination";
import { activityListPerPage } from "../../../Utilities/Helper";

export default function ActivitiesList() {
  const [activities, setActivities] = useState([]);
  const [totalActivities, setTotalActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [showSpinnerForActivities, setShowSpineerForActivities] =
    useState(true);

  useEffect(() => {
    async function allActivities() {
      const filter = "recordperpage=" + activityListPerPage + currentPage;
      await getAllActivities(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setActivities(result.userActivity);
          setTotalActivities(result.totalRecord);
          setShowSpineerForActivities(false);
        })
        .catch((err) => {
          setShowSpineerForActivities(false);
          console.log(err);
        });
    }
    allActivities();
  }, [currentPage]);

  const pageChangeHandler = (page) => {
    setCurrentPage(`&page=${page}`);
    setShowSpineerForActivities(true);
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Activities{" "}
        <Badge
          pill
          bg="danger"
          className="bg-warning text-white px-3 ml-3 font-weight-normal"
        >
          {totalActivities}
        </Badge>
      </h1>
      <div className="dashboard-wrapper resumes-page">
        <Row>
          <Col md={12}>
            <div className="dashboard-box activities dashboard-col-bg mb-4">
              <AllActivities
                activities={activities}
                showSpinner={showSpinnerForActivities}
              />
              {activities.length !== 0 && (
                <ListPagination
                  recordPerPage={activityListPerPage}
                  TotalRecord={totalActivities}
                  className="mt-3"
                  onPageChange={pageChangeHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default ActivitiesList;

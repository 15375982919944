import React, { Component, Fragment } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Buttons from "./Shared/Button";
import moment from "moment";
import { Roles } from "../Utilities/Helper";

class AbuseReportSearch extends Component {
  state = {
    userTypeid: "",
    // name: "",
    // isActive: "",
  };

  // DatePicker settings

  constructor(props) {
    super(props);
    this.minDate = new moment(props.minDate);
    this.maxDate = new moment(props.maxDate);
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleUserTypeChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  // handleGroupChange = (key) => (e) => {
  //   this.setState({ [key]: e.target.value });
  // };

  // validation check

  handleSubmit = async (event) => {
    event.preventDefault();
    let searchParam = "";
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key)) {
        if (
          this.state[key] !== "" &&
          this.state[key] !== null &&
          this.state[key] !== undefined
        ) {
          searchParam += `&${key}=${this.state[key]}`;
        }
      }
    }
    this.props.searchParam(searchParam);
  };

  resetSearchFilter() {
    this.setState({
      userTypeid: "",
      // isActive: "",
      // name: "",
    });
    this.props.searchParam("");
  }

  render() {
    const {
      userTypeid,
      // name,
      // isActive,
    } = this.state;
    const { history } = this.props;
    return (
      <div className="search mb-4">
        <Form noValidate>
          <Row>
            {/* <Col md={4}>
              <FormInput
                value={name}
                type="text"
                placeholder="Name"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("name")}
              />
            </Col> */}
            <Col md={4}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  className="inputclass form-control"
                  value={userTypeid}
                  onChange={this.handleUserTypeChange("userTypeid")}
                >
                  <option value="">Select User Type</option>
                  {this.props.userTypeList &&
                    this.props.userTypeList.map((item) => (
                      <Fragment key={item.userTypeId}>
                        {item.userType !== Roles.admin &&
                          item.userType !== Roles.groupLeader && (
                            <option value={item.userTypeId}>
                              {item.userType}
                            </option>
                          )}
                      </Fragment>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            {/* <Col md={4}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  className="inputclass form-control"
                  value={isActive}
                  onChange={this.handleUserTypeChange("isActive")}
                >
                  <option value="">Select Status</option>
                  <option value="true">Active</option>
                  <option value="false">InActive</option>
                </Form.Control>
              </Form.Group>
            </Col> */}
            <Col className="col-auto">
              <Buttons
                variant="warning"
                title="Search"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleSubmit}
              ></Buttons>
              <span style={{ marginLeft: 18 }}></span>
              <Buttons
                variant="warning"
                title="Reset"
                size="xs"
                color="white"
                onClick={() => {
                  this.resetSearchFilter();
                }}
              ></Buttons>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(AbuseReportSearch);

import { getEmployerPicture } from "../../Services/GetFields";

const intial = {
  loginStatus: "",
};

export const login = (state = intial, action) => {
  // console.log(
  //     action
  // )
  switch (action.type) {
    case "SUCCESS":
      return {
        loginData: { ...state, action },
      };
    case "FAIL":
      return { ...state, loginStatus: "FAIL" };
    default:
      return state;
  }
};

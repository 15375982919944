import React, { useState, useEffect } from "react";
import { Col, Row, Toast } from "react-bootstrap";
import { FaRegListAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import RecentJobsList from "../../../Components/RecentJobsList";
import MessagesList from "../../../Components/MessagesList";

import NewJobList from "../../../Components/NewJobsList";
import { getRecentMessagesForJobSeeker } from "../../../Services/MessagesService";
//import getJobsList from "../../../Services/JobsServices";
import AllActivities from "../../../Components/AllActivities";
//import getJobsPostingList from "../../../Services/JobsPostingServices";
import getAllActivities from "../../../Services/AllActivities";
import {
  recentActivityPerPage,
  recentMessagesPerPage,
  recentJobsPerPage,
} from "../../../Utilities/Helper";
import { toast } from "react-toastify";
import { getAllJobs, getDashboardJobs } from "../../../Services/AllJobsService";
import { get } from "../../../Services/AxiosService";
import { getUserDetailsAPI } from "../../../Utilities/APIHelper";

export default function Dashboard() {
  const [RecentActivities, setActivities] = useState([]);
  const [Messages, setMessages] = useState([]);
  const [NewJobs, setNewJobs] = useState([]);

  //getting recent activity jobs list
  const [showSpinnerForActivities, setShowSpineerForActivities] =
    useState(true);
  const [showSpinnerForMessages, setShowSpineerForMessages] = useState(true);
  const [showSpinnerForJobs, setShowSpinnerForJobs] = useState(true);
  const [refreshJob, setRefreshJob] = useState(true);
  const [name, setName] = useState("");

  useEffect(() => {
    async function activitiesData() {
      getAllActivities("recordperpage=" + recentActivityPerPage)
        .then((res) => {
          const result = JSON.parse(res.response);
          const data = result.userActivity;
          setActivities(data);
          setShowSpineerForActivities(false);
        })
        .catch((err) => {
          console.log("Activity: ", err);
          setShowSpineerForActivities(false);
        });
    }
    activitiesData();
  }, []);

  // getting jobs posting list
  useEffect(() => {
    async function jobsData() {
      getDashboardJobs(`recordperpage=${recentJobsPerPage}`)
        .then((res) => {
          const result = JSON.parse(res.response);
          setNewJobs(result.jobs);
          setShowSpinnerForJobs(false);
        })
        .catch((err) => {
          setShowSpinnerForJobs(false);
          console.log("Jobs: ", err);
        });
    }
    jobsData();
  }, [refreshJob]);

  // getting Messages list
  useEffect(() => {
    async function messagesData() {
      getRecentMessagesForJobSeeker("?recordperpage=" + recentMessagesPerPage)
        .then((res) => {
          //console.log(res);
          const result = JSON.parse(res.response);
          var resultArray = [];
          result.forEach((element) => {
            var obj = {};
            obj.name = element.fullName;
            obj.text = element.message;
            obj.date = element.createdDate;
            obj.profilePhoto = element.profilePhoto;
            resultArray.push(obj);
          });
          setMessages(resultArray);
          setShowSpineerForMessages(false);
        })
        .catch((err) => {
          setShowSpineerForMessages(false);
          console.log("Message: ", err);
        });
    }
    messagesData();
  }, []);

  useEffect(() => {
    async function getName() {
      get(getUserDetailsAPI)
        .then((res) => {
          // console.log(res.data.response);
          let response = res.data.response;
          setName(response.firstName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getName();
  }, []);

  const refreshJobHandler = () => {
    setRefreshJob((prev) => !prev);
    setShowSpinnerForJobs(true);
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">Hello {name} </h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={7}>
            <div className="dashboard-box activity dashboard-col-bg">
              <h2 className="page-sub-title">Recent Activity</h2>
              <AllActivities
                activities={RecentActivities}
                showSpinner={showSpinnerForActivities}
              />
              <Link
                className="btn btn-warning btn-xs white mt-3"
                to={`${process.env.PUBLIC_URL}/jobseeker/activitieslist`}
              >
                <FaRegListAlt className="mr-2" /> View more
              </Link>
            </div>
            <div className="dashboard-box activity dashboard-col-bg mt-5">
              <h2 className="page-sub-title">Messages</h2>
              <MessagesList
                messages={Messages}
                showSpinner={showSpinnerForMessages}
              />
              <Link
                to={`${process.env.PUBLIC_URL}/jobseeker/messages`}
                className="btn btn-warning btn-xs white mt-3"
              >
                <FaRegListAlt className="mr-2" /> View all messages
              </Link>
            </div>
          </Col>
          <Col md={5}>
            <div className="dashboard-box resume dashboard-col-bg">
              <h2 className="page-sub-title">New Job Posting</h2>
              <NewJobList
                posts={NewJobs}
                showSpinner={showSpinnerForJobs}
                refreshJobs={refreshJobHandler}
              />
              <Link
                to={`${process.env.PUBLIC_URL}/jobseeker/jobs`}
                className="btn btn-warning btn-xs white mt-3"
              >
                <FaRegListAlt className="mr-2" /> View all jobs
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

// export default Dashboard;

import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Button, Modal, Form } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import Buttons from "../../../Components/Shared/Button";
import { Link } from "react-router-dom";
import {
  applyForJob,
  getJob,
  getJobSeekerResume,
  saveJob,
} from "../../../Services/AllJobsService";
import moment from "moment";
import { toast } from "react-toastify";
import { fileResumeTitle } from "../../../Utilities/Helper";

export default function SeekersJobDetails(
  props,
  { groupName, savedjobs = [] }
) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setDisableApplyButton(false);
  };

  const [job, setJob] = useState(null);
  const [jobResumes, setJobResumes] = useState([]);
  const [resumeId, setResumeId] = useState("0");
  const jobId = props.match.params.jobId;
  const [refresh, setResfresh] = useState(true);
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [resumeError, setResumeError] = useState(null);
  const [showApply, setShowApply] = useState(false);

  useEffect(() => {
    async function getJobDetails() {
      getJob(jobId)
        .then((res) => {
          const result = JSON.parse(res.response);
          setShowApply(true);
          setJob(result);
        })
        .catch((err) => {
          toast.error(err, { toastId: "custom_toast" });
        });
    }
    getJobDetails();
  }, [refresh]);

  const onSaveJobClick = (jobId) => {
    setDisableApplyButton(false);
    saveJob(jobId)
      .then((res) => {
        toast.success(res.response);
        setResfresh((prev) => !prev);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err));
      });
  };

  const onApplyClick = () => {
    getJobSeekerResume()
      .then((res) => {
        var result = JSON.parse(res.response);
        setJobResumes(result);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err));
      });
    setShow(true);
    setResumeError(false);
  };

  const onApplyModalHandler = () => {
    if (resumeId === "0") {
      setResumeError(true);
      return;
    }
    setResumeError(false);
    setDisableApplyButton(true);
    var resume = resumeId;
    var res = resume.split("-");
    var obj = {};
    obj[
      res[0] === "JIN" ? "JobSeekerPublicResumeId" : "JobSeekerPrivateResumeId"
    ] = res[1];
    obj.JobId = job.jobId;
    var data = { ...obj };

    applyForJob(data)
      .then((res) => {
        toast.success(res.response);
        setResfresh((prev) => !prev);
        setShow(false);
        setDisableApplyButton(false);
      })
      .catch((err) => {
        toast.error(JSON.stringify(err));
      });
  };

  const onResumeChange = (e) => {
    setResumeId(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="container p-0">
        <h1 className="page-title">Job Details</h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={12}>
              <div className="dashboard-box dashboard-col-bg  pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/jobseeker/jobs`}
                  className="page-sub-title-with-back back-link"
                >
                  <AiOutlineArrowLeft /> Back to jobs
                </Link>

                {job !== null && (
                  <Fragment>
                    <div className="users-wrap users-bg job-details-wrap btns-width d-block seekers-job-details-page-new">
                      <div className="users-box left w-100 d-flex">
                        <div className="seeker-job-name">
                          <h3 className="job-title">{job.positionTitle}</h3>
                          {/* jobPostingLink  Show job posting link   float-left*/}
                          <div className="company-name">
                            {job.companyName === null || job.companyName === ""
                              ? "-"
                              : job.companyName}
                          </div>
                        </div>
                        <div
                          className="users-box right"
                          style={{ textAlign: "right" }}
                        >
                          <Row>
                            <Col className="text-right job-acrions">
                              <Buttons
                                variant={
                                  job.isSaved === true ? "warning" : "secondary"
                                }
                                title={
                                  job.isSaved === true
                                    ? "Unsave job"
                                    : "Save job"
                                }
                                type="button"
                                size="xs"
                                color="white"
                                icon="icon-favorite-outline mr-3"
                                onClick={() => onSaveJobClick(job.jobId)}
                              ></Buttons>
                              {showApply &&
                                job.isApplied !== true &&
                                job.jobTypeName !== "Unaffiliated" && (
                                  <Buttons
                                    variant="warning"
                                    title="Apply"
                                    type="button"
                                    size="xs"
                                    color="white"
                                    marginLeft="ml-3"
                                    icon="icon-post mr-3"
                                    onClick={onApplyClick}
                                  ></Buttons>
                                )}
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="detail-view mt-3">
                        <Row>
                          <Col>
                            <div className="post-date">
                              <span>Start Date:</span>{" "}
                              {new Date(job.jobStartDate).toDateString()}
                            </div>
                          </Col>
                          <Col>
                            <div className="post-date">
                              <span>End Date:</span>{" "}
                              {new Date(job.jobEndDate).toDateString()}
                            </div>
                          </Col>
                          <Col>
                            <div className="post-date">
                              <span>Contact Name:</span>
                              {job.contactName === null ||
                              job.contactName === ""
                                ? "-"
                                : job.contactName}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="post-date">
                              <span>Contact Email:</span>
                              {job.contactEmail === null ||
                              job.contactEmail === ""
                                ? "-"
                                : job.contactEmail}
                            </div>
                          </Col>

                          <Col>
                            <div className="post-date">
                              <span>Telephone:</span>
                              {job.contactPhone === null ||
                              job.contactPhone === ""
                                ? "-"
                                : job.contactPhone}
                            </div>
                          </Col>
                          <Col>
                            <div className="location">
                              <span>Address:</span>{" "}
                              {`${job.addressLine1}, ${
                                job.addressLine2 !== null
                                  ? job.addressLine2 + ", "
                                  : ""
                              }                                                 
                            ${job.city !== null ? job.city + ", " : "-"} ${
                                job.stateName !== null
                                  ? job.stateName + ", "
                                  : "-"
                              }
                           ${job.countryName !== null ? job.countryName : "-"}`}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="post-date">
                              <span>Industry:</span>
                              {job.industryName === null ||
                              job.industryName === ""
                                ? "-"
                                : job.industryName}
                            </div>
                          </Col>
                          <Col>
                            <div className="post-date">
                              <span>Compensation:</span>
                              {job.payRate === null || job.payRate === ""
                                ? "-"
                                : job.payRate}
                            </div>
                          </Col>
                          <Col>
                            <div className="post-date">
                              <span>Job Length:</span>
                              {job.jobLength === null || job.jobLength === ""
                                ? "-"
                                : job.jobLength}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="post-date">
                              <span>Travel Required:</span>{" "}
                              {job.travelRequired === true && "Yes"}
                              {job.travelRequired === false && "No"}
                              {job.travelRequired === null && "-"}
                            </div>
                          </Col>
                          <Col>
                            <div className="post-date">
                              <span>Telecommute:</span>
                              {job.telecommute === true && "Yes"}
                              {job.telecommute === false && "No"}
                              {job.telecommute === null && "-"}
                            </div>
                          </Col>
                          <Col>
                            <div className="post-date">
                              <span>Position Responsibilites:</span>{" "}
                              {job.positionResponsibilities === null ||
                              job.positionResponsibilities === ""
                                ? "-"
                                : (
                                  <span style={{ wordBreak: "break-word", color: "#2660a5"}}>{job.positionResponsibilities}</span>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="post-date">
                              <span>Minimum Qualification:</span>{" "}
                              {job.minimumQualification === null ||
                              job.minimumQualification === ""
                                ? "-"
                                : (
                                  <span style={{ wordBreak: "break-word", color: "#2660a5"}}>{job.minimumQualification}</span>
                                )}
                            </div>
                          </Col>
                          <Col>
                            <div className="post-date">
                              <span>Preferred Qualification:</span>{" "}
                              {job.preferredQualification === null ||
                              job.preferredQualification === ""
                                ? "-"
                                : (
                                  <span style={{ wordBreak: "break-word", color: "#2660a5"}}>{job.preferredQualification}</span>
                                )}
                            </div>
                          </Col>
                          <Col>
                            <div className="post-date">
                              <span>Employment policy:</span>
                              {job.employmentPolicy === null ||
                              job.employmentPolicy === ""
                                ? "-"
                                : job.employmentPolicy}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {job.jobPostingLink !== null && (
                      <div className="job-description">
                        <h3>Job Link</h3>
                        <p>
                          {job.jobPostingLink === "" ? "-" : job.jobPostingLink}
                        </p>
                      </div>
                    )}
                    <div className="job-description">
                      <h3>Description</h3>
                      <p>
                        {job.jobDescription === null ||
                        job.jobDescription === ""
                          ? "-"
                          : job.jobDescription}
                      </p>
                    </div>
                  </Fragment>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custome-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Attach Resume</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleClose}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            <form>
              {/* <Form.Group controlId="formBasicEmail">
        <Form.Control
          type="text"
          placeholder="Resume title"
          className="inputclass "
        />
      </Form.Group> */}
              {/* <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Control type="file" />
      </Form.Group> */}

              <Form.Group
                controlId="exampleForm.ControlSelect1"
                className="w-100"
              >
                <Form.Control
                  as="select"
                  className="inputclass"
                  onChange={onResumeChange}
                >
                  <option value="0">Select Private Resume</option>
                  {jobResumes.map((item, index) => (
                    <option
                      key={index}
                      value={
                        item.templateId !== null
                          ? `JIN-${item.templateId}`
                          : `Private-${item.jobSeekerPrivateResumeId}`
                      }
                    >
                      {item.templateId !== null
                        ? `${item.templateName}`
                        : fileResumeTitle(item.resumePath, item.resumeName)}
                    </option>
                  ))}
                </Form.Control>
                {resumeError === true && (
                  <span style={{ color: "red" }}>Please select the resume</span>
                )}
              </Form.Group>
            </form>
          </div>
          <div className="message-footer">
            <Buttons
              variant="warning"
              title="Send Resume"
              type="submit"
              size="xs"
              disabled={disableApplyButton}
              color="white"
              onClick={onApplyModalHandler}
            ></Buttons>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
// export default SeekersJobDetails;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Col, Row, Form } from "react-bootstrap";
import FormInput from "../../../Components/Shared/FormInput";
import {
  getDemoDatabaseBackups,
  putResetDemoDatabase,
} from "../../../Services/DemoServices";
import { getCountry, getStateByCountry } from "../../../Services/GetFields";
import Logout from "../../../Services/LogoutService";
import { ConformationModal } from "../../../Components/ConformationModal";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ContentBox } from "./ContentBox";

export const AdminDemoCreate = () => {
  const history = useHistory();
  const { step } = useParams();

  const [showLoader, setShowLoader] = useState(false);
  const [savedDemos, setSavedDemos] = useState([]);
  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);

  const [baseSavedDemoId, setBaseSavedDemoId] = useState(0);
  const [resetDemoForm, setResetDemoForm] = useState({
    groupName: "",
    subCategoryName: "",
    country: 0,
    countryList: [],
    countryState: ["0", "0", "0", "0", "0"],
    stateName: [""],
    city: ["", "", "", "", ""],
    countryStateList: [],
    showStateTextBox: false,
    locationType: "multi_state",
    industries: ["", "", "", "", "", "", "", "", "", ""],
  });

  useEffect(() => {
    getCountry()
      .then((res) => {
        const result = JSON.parse(res.response);
        const countryList = result.filter((item) => item.countryId !== 3);
        setResetDemoForm({ ...resetDemoForm, countryList: countryList });
      })
      .catch((err) => {
        console.log(err);
      });

    getDemoDatabaseBackups()
      .then((res) => {
        var result = JSON.parse(res.response);
        result.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        setSavedDemos(result);
      })
      .catch((err) => {
        console.log(err);
      });

    // Only need to run on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetLocationChange = (key) => (value) => {
    setResetDemoForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleResetCityChange = (key) => (value) => {
    var updatedForm = { ...resetDemoForm };
    updatedForm.city[key] = value;
    setResetDemoForm(updatedForm);
  };

  const handleResetGroupChange = (e) => {
    const { name, value } = e.target;
    setResetDemoForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleStateChange = (key) => (e) => {
    var updatedForm = { ...resetDemoForm };
    updatedForm.countryState[key] = e.target.value;

    // CU-86ay8402f If the first state is selected, then update all the states to match if the city isn't already filled
    if (key === 0) {
      for (let i = 1; i < 5; i++) {
        if (updatedForm.city[i] === "") {
          updatedForm.countryState[i] = e.target.value;
        }
      }
    }

    setResetDemoForm(updatedForm);
  };

  const handleCountryChange = (e) => {
    let countryValue = 0;
    countryValue = e.target.value;
    var index = e.nativeEvent.target.selectedIndex;
    const countryText = e.nativeEvent.target[index].text;

    setResetDemoForm((prev) => ({
      ...prev,
      countryStateList: [],
      country: countryValue,
      countryState: ["0", "0", "0", "0", "0"],
      stateName: "",
    }));

    if (countryText === "Other")
      setResetDemoForm((prev) => ({ ...prev, showStateTextBox: true }));
    else setResetDemoForm((prev) => ({ ...prev, showStateTextBox: false }));

    if (countryValue !== "0") {
      getStateByCountry(countryValue)
        .then((res) => {
          const result = JSON.parse(res.response);
          setResetDemoForm((prev) => ({ ...prev, countryStateList: result }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleResetIndustryChange = (key) => (e) => {
    var updatedForm = { ...resetDemoForm };
    if (key < 0 || key > updatedForm.industries.length) return;
    updatedForm.industries[key] = e;
    setResetDemoForm(updatedForm);
  };

  const doLogout = () => {
    Logout();
    sessionStorage.clear();
    history.push(process.env.PUBLIC_URL + "/home");
  };

  const resetDemoSite = () => {
    toast.warning("Database reset started...", { autoClose: 2500 });
    // If city is not set then remove state selection as well
    const resetDemoFormApiParam = { ...resetDemoForm };
    for (let i = 0; i < 5; i++) {
      if (resetDemoFormApiParam.city[i].trim() === "") {
        resetDemoFormApiParam.countryState[i] = "0";
      }
    }

    putResetDemoDatabase({
      ...resetDemoFormApiParam,
      baseDemoId: baseSavedDemoId,
    })
      .then((res) => {
        toast.success("Database reset successfully. Logging out...");
        setTimeout(() => doLogout(), 3000);
      })
      .catch((err) => {
        toast.error("Unable to reset demo site.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const renderOneStateForm = () => {
    return (
      <>
        <Row>
          <Col md={4}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="country"
                value={resetDemoForm.country}
                onChange={handleCountryChange}
                className="inputclass form-control"
              >
                <option value="0">Select Country *</option>
                {resetDemoForm.countryList.map((item) => (
                  <option key={"cl-" + item.countryId} value={item.countryId}>
                    {item.countryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          {resetDemoForm.showStateTextBox ? (
            <Col md={4}>
              <div className="form-group">
                <FormInput
                  name="stateName"
                  value={resetDemoForm.stateName}
                  maxLength={30}
                  type="text"
                  placeholder="State *"
                  required="required"
                  onChange={handleResetLocationChange("stateName")}
                />
              </div>
            </Col>
          ) : (
            <Col md={4}>
              <Form.Group controlId="dd">
                <Form.Control
                  as="select"
                  className="inputclass form-control"
                  name="countryState"
                  value={resetDemoForm.countryState[0]}
                  onChange={handleStateChange(0)}
                >
                  <option value="0">
                    {resetDemoForm.country === 2
                      ? "Select Province *"
                      : "Select State *"}
                  </option>
                  {resetDemoForm.countryStateList.map((item) => (
                    <option key={"csl-" + item.stateId} value={item.stateId}>
                      {item.stateName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          <Col md={4}>
            <FormInput
              name="city"
              value={resetDemoForm.city[0]}
              type="text"
              placeholder="City 1 *"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(0)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormInput
              name="city"
              value={resetDemoForm.city[1]}
              type="text"
              placeholder="City 2"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(1)}
            />
          </Col>
          <Col md={4}>
            <FormInput
              name="city"
              value={resetDemoForm.city[2]}
              type="text"
              placeholder="City 3"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(2)}
            />
          </Col>
          <Col md={4}>
            <FormInput
              name="city"
              value={resetDemoForm.city[3]}
              type="text"
              placeholder="City 4"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(3)}
            />
          </Col>
          <Col md={12}>
            <FormInput
              name="city"
              value={resetDemoForm.city[4]}
              type="text"
              placeholder="City 5"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(4)}
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderMultiStateForm = () => {
    return (
      <>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="country"
                value={resetDemoForm.country}
                onChange={handleCountryChange}
                className="inputclass form-control"
              >
                <option value="0">Select Country *</option>
                {resetDemoForm.countryList.map((item) => (
                  <option key={"cl-" + item.countryId} value={item.countryId}>
                    {item.countryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={resetDemoForm.countryState[0]}
                onChange={handleStateChange(0)}
              >
                <option value="0">
                  {resetDemoForm.country === 2 ? "Province 1 *" : "State 1 *"}
                </option>
                {resetDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={resetDemoForm.city[0]}
              type="text"
              placeholder="City 1 *"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(0)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={resetDemoForm.countryState[1]}
                onChange={handleStateChange(1)}
              >
                <option value="0">
                  {resetDemoForm.country === 2 ? "Province 2" : "State 2"}
                </option>
                {resetDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={resetDemoForm.city[1]}
              type="text"
              placeholder="City 2"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(1)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={resetDemoForm.countryState[2]}
                onChange={handleStateChange(2)}
              >
                <option value="0">
                  {resetDemoForm.country === 2 ? "Province 3" : "State 3"}
                </option>
                {resetDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={resetDemoForm.city[2]}
              type="text"
              placeholder="City 3"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(2)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={resetDemoForm.countryState[3]}
                onChange={handleStateChange(3)}
              >
                <option value="0">
                  {resetDemoForm.country === 2 ? "Province 4" : "State 4"}
                </option>
                {resetDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={resetDemoForm.city[3]}
              type="text"
              placeholder="City 4"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(3)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="countryState"
                value={resetDemoForm.countryState[4]}
                onChange={handleStateChange(4)}
              >
                <option value="0">
                  {resetDemoForm.country === 2 ? "Province 5" : "State 5"}
                </option>
                {resetDemoForm.countryStateList.map((item) => (
                  <option key={"csl-" + item.stateId} value={item.stateId}>
                    {item.stateName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <FormInput
              name="city"
              value={resetDemoForm.city[4]}
              type="text"
              placeholder="City 5"
              maxLength={30}
              required="required"
              onChange={handleResetCityChange(4)}
            />
          </Col>
        </Row>
      </>
    );
  };

  const RenderIndustryRows = (count) => {
    const rows = [];

    for (let i = 0; i < count; i++) {
      rows.push(
        <Row key={i}>
          <Col md={6}>
            <FormInput
              name="industry"
              value={resetDemoForm.industries[i * 2]}
              type="text"
              placeholder={`Industry ${i * 2 + 1}`}
              maxLength={30}
              required="required"
              onChange={(e) => {
                handleResetIndustryChange(i * 2)(e);
              }}
            />
          </Col>
          <Col md={6}>
            <FormInput
              name="industry"
              value={resetDemoForm.industries[i * 2 + 1]}
              type="text"
              placeholder={`Industry ${i * 2 + 2}`}
              maxLength={30}
              required="required"
              onChange={(e) => {
                handleResetIndustryChange(i * 2 + 1)(e);
              }}
            />
          </Col>
        </Row>
      );
    }

    return rows;
  };

  //
  //  Top level components
  //
  const SelectBaseImage = () => {
    return (
      <ContentBox
        headerText="Select Saved Demo As A Starting Point (Optional)"
        nextText="Next"
        disableNext={false}
        onNext={() =>
          history.push(process.env.PUBLIC_URL + "/admin/demotools/create/1")
        }
      >
        <Row>
          <Col md={12}>
            <p className="">
              Using a saved demo as a starting point will allow you to overwrite
              group names, locations, and industries but all other information
              will be the same as the saved demo.
            </p>
            <p className="mt-1 mb-4">
              <b>This step is optional.</b> If you want to start from the
              default demo database click next without selecting a save to start
              with.
            </p>
            <p className="mt-1 mb-4">
              Use this option if you have a saved demo that is similar to the
              one you want to create.
            </p>
          </Col>
          <Col md={12}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                className="inputclass form-control"
                name="baseSavedDemoId"
                value={baseSavedDemoId}
                onChange={(e) => setBaseSavedDemoId(parseInt(e.target.value))}
              >
                <option value="0">Select A Save To Use</option>
                {savedDemos.map((item) => (
                  <option key={"sd-" + item.id} value={item.demoResetId}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </ContentBox>
    );
  };

  const renderInputGroupName = () => {
    return (
      <ContentBox
        headerText="Who is this demo for?"
        nextText="Next"
        disableNext={
          resetDemoForm.groupName.trim() === "" ||
          resetDemoForm.subCategoryName.trim() === ""
        }
        onNext={() =>
          history.push(process.env.PUBLIC_URL + "/admin/demotools/create/2")
        }
      >
        <Row>
          <Col md={12}>
            <p className="bp-4">
              Add a new Group name and Sub Category name that will be used in
              this demo.
            </p>
          </Col>

          <Col md={12}>
            <Form.Group>
              <Form.Control
                type="text"
                name="groupName"
                id="groupName"
                placeholder="Demo Group Name"
                className="inputclass"
                autoComplete="off"
                value={resetDemoForm.groupName}
                onChange={handleResetGroupChange}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Control
                name="subCategoryName"
                type="text"
                placeholder="Demo Sub Category Name"
                className="inputclass"
                autoComplete="off"
                value={resetDemoForm.subCategoryName}
                onChange={handleResetGroupChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </ContentBox>
    );
  };

  const renderLocationSelection = () => {
    const canReset = () =>
      resetDemoForm.city[0]?.trim() !== "" &&
      resetDemoForm.country !== 0 &&
      (resetDemoForm.countryState[0] !== "0" ||
        resetDemoForm.stateName?.trim() !== "") &&
      resetDemoForm.groupName.trim() !== "" &&
      resetDemoForm.subCategoryName.trim() !== "";

    return (
      <ContentBox
        headerText="Input Location Details"
        nextText="Next"
        disableNext={!canReset()}
        onNext={() =>
          history.push(process.env.PUBLIC_URL + "/admin/demotools/create/3")
        }
      >
        <Row>
          <Col md={12}>
            <p className="font-weight-bold">
              Where is {resetDemoForm.groupName} located?
            </p>
            <p className="bp-2">
              The users accounts will be updated to include the state and city
              names that you select here.
            </p>
            <p className="bp-2 font-italic">
              Only 1 location is required but you can add up to 5.
            </p>
            <p className="bp-2">
              If you add more than 1 location, the user accounts will be updated
              with a random location from this list.
            </p>
          </Col>
        </Row>
        {resetDemoForm.locationType === "multi_state"
          ? renderMultiStateForm()
          : renderOneStateForm()}
      </ContentBox>
    );
  };

  const renderIndustrySelection = () => {
    return (
      <ContentBox
        headerText="Select Industries (Optional)"
        nextText="Next"
        disableNext={false}
        onNext={() =>
          history.push(process.env.PUBLIC_URL + "/admin/demotools/create/4")
        }
      >
        <Row>
          <Col md={12}>
            <p className="font-weight-bold">
              Add extra industries to the demo?
            </p>
            <p className="font-italic">
              This step is optional. If you don't want to add extra industries
              click next to continue.
            </p>
            <p className="pb-2">
              The industries you add will be added to the industry lists.
            </p>
            <p className="pb-2">
              For support team members: The 1st industry will be added to
              support team members S1-10. The 2nd industry will be added to the
              S51-S60.
            </p>
            <p className="pb-4">
              For Job seekers. If 1 industry is provided David Thompson, Debbie
              Thompson, and Benjamin Thompson will be assigned the industry. If
              2 industry are provided David Thompson, Debbie Thompson get the
              1st industry and Benjamin Thompson will get the 2nd industry.
            </p>
          </Col>
          <Col md={12}>{RenderIndustryRows(5)}</Col>
        </Row>
      </ContentBox>
    );
  };

  const confirmDemoReset = () => {
    return (
      <ContentBox
        headerText="Create Demo With New Details?"
        nextText="Create"
        disableNext={true === showLoader}
        showLoader={showLoader}
        onNext={() => {
          setShowLoader(true);
          setShowOverwriteWarning(true);
        }}
      >
        <Row>
          <Col md={12}>
            <p className="">
              <b>Group Name:</b> {resetDemoForm.groupName}
            </p>
            <p className="">
              <b>Sub Category Name:</b> {resetDemoForm.subCategoryName}
            </p>
            <p className="pb-2">
              <b>Saved demo to use as a starting point: </b>
              {baseSavedDemoId === 0
                ? "None"
                : savedDemos.find((x) => x.demoResetId === baseSavedDemoId)
                    ?.name}
            </p>
            <p className="pb-2">
              <b>Locations: </b>
              <ls>
                {resetDemoForm.city.map((city, index) => {
                  if (city.trim() === "") return null;
                  const stateName = resetDemoForm.countryStateList.find(
                    (x) =>
                      x.stateId === parseInt(resetDemoForm.countryState[index])
                  )?.stateName;
                  return <li>{city + ", " + stateName}</li>;
                })}
              </ls>
            </p>
            {resetDemoForm.industries[0].trim() !== "" && (
              <p className="pb-2">
                <b>Industries: </b>
                <ls>
                  {resetDemoForm.industries.map((industry) => {
                    if (industry.trim() === "") return null;
                    return <li>{industry}</li>;
                  })}
                </ls>
              </p>
            )}
          </Col>
        </Row>
      </ContentBox>
    );
  };

  return (
    <>
      <Container>
        <h1 className="page-title">Create A New Demo</h1>
        <div className="dashboard-wrapper">
          {step === "0" && <SelectBaseImage />}
          {step === "1" && renderInputGroupName()}
          {step === "2" && renderLocationSelection()}
          {step === "3" && renderIndustrySelection()}
          {step === "4" && confirmDemoReset()}
        </div>
      </Container>

      <ConformationModal
        show={showOverwriteWarning}
        onHide={() => {
          setShowLoader(false);
          setShowOverwriteWarning(false);
        }}
        onPrimary={() => {
          setShowOverwriteWarning(false);
          resetDemoSite();
        }}
        onSecondary={() => {
          setShowOverwriteWarning(false);
          setShowLoader(false);
        }}
        showSecondary={true}
        primaryText="Continue"
        secondaryText="Cancel"
        title="Create The New Demo?"
        bodyText="The current Demo will be erased, and a new Demo will be set up. Do
        you want to continue?"
      />
    </>
  );
};

import React, { useState, Component, Fragment } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import FormInput from "./Shared/FormInput";
import Buttons from "./Shared/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Validators } from "../Utilities/Validator";
import Dropdown from "./Shared/Dropdown";
import { Link } from "react-router-dom";
import Select from "react-select";

// const options = [
//   { label: "Automobile", value: 1 },
//   { label: "Finance", value: 2 },
//   { label: "Healthcare", value: 3 },
// ];

class AdminMangeGroupSearch extends Component {
  state = {
    groupname: "",
    groupcategoryname: "",
  };

  constructor(props) {
    super(props);
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let searchParam = "";
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key)) {
        if (
          this.state[key] !== "" &&
          this.state[key] !== null &&
          this.state[key] !== undefined
        ) {
          if (key === "fromDate" || key === "toDate") {
            var selectedDate = new Date(this.state[key]);
            var newDate = new Date(
              selectedDate.setDate(selectedDate.getDate() + 1)
            ).toISOString();
            searchParam += `&${key}=${newDate}`;
          } else {
            searchParam += `&${key}=${this.state[key]}`;
          }
        }
      }
    }
    this.props.searchParam(searchParam);
  };

  resetSearchFilter() {
    this.setState({
      groupname: "",
      groupcategoryname: "",
    });
    this.props.searchParam("");
  }

  render() {
    const { groupname, groupcategoryname } = this.state;
    const { history } = this.props;
    return (
      <div className="search mb-4">
        <Form noValidate>
          <Row>
            {/* <Col>
             
             {this.state.categories.map(cat => (
                <Dropdown options={cat.options} title={cat.name} />
              ))}
            
             </Col> */}
            <Col md={3}>
              <FormInput
                value={groupname}
                type="text"
                placeholder="Group Name"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("groupname")}
              />
            </Col>

            <Col md={3}>
              <FormInput
                value={groupcategoryname}
                type="text"
                placeholder="Sub Category Name"
                onChange={this.handleChange("groupcategoryname")}
              />
            </Col>
            <Col className="col-auto">
              <Buttons
                variant="warning"
                title="Search"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleSubmit}
              ></Buttons>
              <span style={{ marginLeft: 18 }}></span>
              <Buttons
                variant="warning"
                title="Reset"
                size="xs"
                color="white"
                onClick={() => {
                  this.resetSearchFilter();
                }}
              ></Buttons>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(AdminMangeGroupSearch);

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BsList, BsX } from "react-icons/bs";
import querySearch from "stringquery";

import { Button, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getLoginAction } from "../../../Redux/Login/Action";
import axios from "axios";
import jwt from "jwt-decode";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  loginAPI,
  reportFailedLogginAPI,
  Roles,
} from "../../../Utilities/Helper";
import { ForgotPassword } from "../../../Services/GetFields";
import { getData, setData } from "../../../Utilities/StorageHelper";

export default function Home(props) {
  const history = useHistory();
  const location = useLocation();
  const toastId = "custom_toast";

  // const { password, confirmPassword } = this.state;
  const [dropdown, setDropdown] = useState("SignIn");
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isDemoSite, setIsDemoSite] = useState(false);

  React.useEffect(() => {
    if (
      window.location.hostname.indexOf("demo") > -1 ||
      window.location.hostname.indexOf("localhost") > -1
    ) {
      setIsDemoSite(true);
    }
  }, []);

  const handleInputChange = (event) => {
    // handleSubmit()
    setState((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleForgot = (e) => {
    e.preventDefault();
    if (state.email === "" || state.email === undefined) {
      setEmailError(true);
    } else {
      setEmailError(false);

      if (state.email !== "" && state.email !== undefined) {
        const data = JSON.stringify({ email: state.email });
        ForgotPassword(data).then((response) => {
          // console.log(response.value);
          if (response.value === "Password setup link sent successfully.") {
            setData("email", state.email);
            toast.success(
              "An Email with a link to reset your password has been sent to your registered email address. The link will be valid for 30 minutes.",
              { toastId: toastId }
            );
          } else {
            toast.error("The email entered is not registered with us.", {
              toastId: toastId,
            });
          }
        });
      } else if (state.email == "invalid") {
        toast.error("The link you are trying to access is expired.", {
          toastId: toastId,
        });
      } else {
        toast.error("The email entered is not registered with us.", {
          toastId: toastId,
        });
      }
    }
  };

  const reportFailedLogin = (username) => {
    const data = JSON.stringify({ username: username });
    axios
      .post(reportFailedLogginAPI, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    if (dropdown === "SignIn") {
      if (state.email == "" || state.email == undefined) {
        setEmailError(true);
        if (state.password == "" || state.password == undefined) {
          setPasswordError(true);
        } else {
          setPasswordError(false);
        }
      } else {
        setEmailError(false);
        if (state.password == "" || state.password == undefined) {
          setPasswordError(true);
        } else {
          setEmailError(false);
          setPasswordError(false);

          let params = new URLSearchParams();
          params.append("username", state.email);
          params.append("password", state.password);
          params.append("client_id", "JIN_spa");
          params.append("grant_type", "password");
          params.append("scope", "openid offline_access");

          var options = {
            method: "POST",
            url: loginAPI,
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: params,
          };

          axios
            .request(options)
            .then((response) => {
              if (response.status === 200) {
                toast.dismiss();
                const token = response.data.access_token;
                const refresh_token = response.data.refresh_token;
                const user = jwt(token);
                setData("token", token);
                setData("user_name", user.firstname);
                setData("expiry_time", response.data.expires_in);
                setData("role", user.roles);
                setData("refresh_token", refresh_token);
                redirectHandler();
              }
            })
            .catch(function (error) {
              console.error("error", error);
              toast.error("Entered details are Invalid!", { toastId: toastId });
              reportFailedLogin(state.email);
            });
        }
      }
    }
  };

  const dropdownToggle = (e) => {
    e.preventDefault();
    setState({ email: "", password: "" });
    setDropdown("ForgotPassword");
    setEmailError(false);
    setPasswordError(false);
  };

  const redirectHandler = () => {
    const { redirectTo } = querySearch(location.search);
    if (redirectTo) {
      history.push(`${process.env.PUBLIC_URL}${redirectTo}`);
      return;
    }

    let user = getData("role");
    if (user === "Employer") {
      dispatch(getLoginAction("first"));
      history.push(`${process.env.PUBLIC_URL}/dashboard`);
    } else if (user === "Support Team Member") {
      dispatch(getLoginAction("second"));
      history.push(`${process.env.PUBLIC_URL}/support/support-dashboard`);
    } else if (user === "Job Seeker") {
      dispatch(getLoginAction("third"));
      history.push(`${process.env.PUBLIC_URL}/seeker`);
    } else if (user === "Group Leader") {
      dispatch(getLoginAction("fourth"));
      history.push(
        `${process.env.PUBLIC_URL}/groupleader/groupleaderdashboard`
      );
    } else if (user === Roles.admin) {
      dispatch(getLoginAction("five"));
      history.push(`${process.env.PUBLIC_URL}/admin/dashboard`);
    }
  };

  useEffect(() => {
    document.body.classList.add("home-page");
    if (getData("token") !== null) {
      redirectHandler();
    }
    localStorage.removeItem("users");
    return function cleanup() {
      props.imagetoggle();
      document.body.classList.remove("home-page");
    };
  }, []);

  const mobileMenuShow = () => {
    setShowMobileMenu(!showMobileMenu);
    setShowSignIn(false);
  };

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="d-flex">
            {!!!isDemoSite && (
              <button
                className="navbar-toggler d-md-none"
                type="button"
                onClick={() => mobileMenuShow()}
              >
                {showMobileMenu ? <BsX /> : <BsList />}
              </button>
            )}
            <div className="brand">
              <a href={`${process.env.PUBLIC_URL}/`}>
                <img
                  src="https://php7.benchmarkit.solutions/jobInfonetwork/prototype/static/media/JIN_logo.48e5b9fa.svg"
                  alt="JobInfoNetwork"
                />
              </a>
            </div>
            <nav className="ml-auto">
              <ul>
                <li>
                  {!!!isDemoSite && (
                    <>
                      <a
                        className="nav-link sub-menu-parent d-none d-md-inline-block"
                        href="javascript:void(0);"
                        onClick={() => setShowSignIn(false)}
                        onMouseEnter={() => setShowSignIn(false)}
                      >
                        About
                      </a>

                      <div
                        //  className="sub-menu"
                        className={
                          showMobileMenu
                            ? "show-side-menu-mobile sub-menu"
                            : "sub-menu"
                        }
                      >
                        <ul>
                          <li>
                            <a href="https://jobinfonetwork.com/about/">
                              About JobInfoNetwork
                            </a>
                          </li>
                          <li>
                            <a href="https://jobinfonetwork.com/howitworks/">
                              Using JobInfoNetwork
                            </a>
                          </li>
                          <li>
                            <a href="https://jobinfonetwork.com/comparativeadvantages/">
                              Comparative Advantages
                            </a>
                          </li>
                          <li>
                            <a href="https://jobinfonetwork.com/ourteam/">
                              Our Team
                            </a>
                          </li>
                          <li>
                            <a href="https://jobinfonetwork.com/faq/">FAQs</a>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </li>
                <li className="login-doropdown outline-form ">
                  {
                    <Dropdown show={showSignIn}>
                      <a
                        className="nav-link login-popup"
                        href="javascript:void(0);"
                        onMouseEnter={() => setShowSignIn(true)}
                      >
                        <Dropdown.Toggle
                          className="profile"
                          id="dropdown-basic"
                          onClick={() => {
                            setShowSignIn(true);
                          }}
                        >
                          Sign in
                        </Dropdown.Toggle>
                      </a>
                      <Dropdown.Menu>
                        {isDemoSite ? (
                          <h1 className="mb-4 mt-2 pb-2">
                            {dropdown === "SignIn"
                              ? "Sign In (Demo)"
                              : "Forgot Password"}
                          </h1>
                        ) : (
                          <h1 className="mb-4 mt-2 pb-2">
                            {dropdown === "SignIn"
                              ? "Sign In"
                              : "Forgot Password"}
                          </h1>
                        )}

                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={state.email}
                            onChange={handleInputChange}
                            className="inputclass form-control"
                          />
                          {emailError && (
                            <p
                              style={{ color: "red", fontSize: 12, padding: 6 }}
                            >
                              Please provide email
                            </p>
                          )}
                        </div>
                        {dropdown === "SignIn" && (
                          <div className="form-group">
                            <input
                              type="password"
                              name="password"
                              placeholder="Password"
                              // value={state.password}
                              onChange={handleInputChange}
                              className="inputclass form-control"
                            />
                            {passwordError && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: 12,
                                  padding: 6,
                                }}
                              >
                                Please provide password
                              </p>
                            )}
                          </div>
                        )}

                        {dropdown === "SignIn" && (
                          <NavLink
                            activeClassName="is-active"
                            onClick={dropdownToggle}
                            to=""
                            className="text-decoration-none grey forgot-link"
                          >
                            Forgot password?
                          </NavLink>
                        )}

                        <Dropdown.Item
                          as="button"
                          className="profile-btns p-0 mt-4 square-radius"
                        >
                          <Button
                            onClick={
                              dropdown === "SignIn"
                                ? handleSubmit
                                : handleForgot
                            }
                            type="button"
                            style={{ marginRight: 8 }}
                          >
                            {dropdown === "SignIn" ? "Sign in" : "Send"}
                          </Button>

                          {dropdown === "ForgotPassword" && (
                            <Button
                              onClick={() => {
                                setDropdown("SignIn");
                                setState(
                                  { email: "", password: "" },
                                  setEmailError(false)
                                );
                              }}
                              type="button"
                            >
                              {"Cancel"}
                            </Button>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </li>
                <li className="btn-header">
                  <span>Not yet registered?</span>
                  <NavLink
                    className="btn-get-started"
                    to={`${process.env.PUBLIC_URL}/register`}
                  >
                    Get Started
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <div
        className="main-content"
        onClick={() => {
          setShowMobileMenu(false);
        }}
      >
        <div className="main-banner"></div>
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import { Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import SeekersConnectionsList from "../../../Components/SeekersConnectionsList";
import getSeekersNetworkList from "../../../Services/SeekersNetworkService";
import alphabates from "../../../Components/AlphabatesSorting";
import ListPagination from "../../../Components/Pagination";
import { FaCommentAlt, FaSearch } from "react-icons/fa";
import SearchSeekersConnection from "../../../Components/SearchSeekersConnection";
import { jobseekerConnectionsPerPage } from "../../../Utilities/Helper";
import { getJobSeekerConnections } from "../../../Services/SeekersConnectionService";
import { toast } from "react-toastify";
import { getIndustry } from "../../../Services/GetFields";
import { getJobSeekerGroups } from "../../../Services/JobSeekersService";
import { useDispatch, useSelector } from "react-redux";
import { globalSearchAction } from "../../../Redux/GlobalSearch/Action";

export default function SeekersConnections() {
  //Using Redux
  const store = useSelector((res) => res.GlobalSearchReducer.connections);

  const [connection, setConnection] = useState([]);
  const [totalConnections, setTotalConnections] = useState(0);
  const [industries, setIndustries] = useState([]);
  const [showConnectionSpinner, setShowConnectionSpinner] = useState(true);
  const [currentPage, setCurrentPage] = useState({ value: "0", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [searchParams, setSearchParams] = useState("");
  const [groups, setGroups] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const dispatch = useDispatch();

  // getting Network list
  useEffect(() => {
    function seekerConnection() {
      setShowPagination(true);
      const filter =
        "recordperpage=" +
        jobseekerConnectionsPerPage +
        currentPage.filter +
        searchParams;
      getJobSeekerConnections(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setConnection(result.connections);
          setTotalConnections(result.totalRecord);
          setShowConnectionSpinner(false);
        })
        .catch((err) => {
          setShowConnectionSpinner(false);
          console.log(err);
          // toast.error("something went wrong");
        });
    }
    if (store === null) {
      seekerConnection();
    } else {
      setShowConnectionSpinner(true);
      setConnection(store.items);
      setTotalConnections(store.totalRecord);
      setShowConnectionSpinner(false);
    }
  }, [refreshTable, store]);

  useEffect(() => {
    async function allIndustries() {
      getIndustry()
        .then((res) => {
          const result = JSON.parse(res.response);
          setIndustries(result);
        })
        .catch((err) => {
          console.log("Industry List: ", err);
        });
    }

    async function allJSGroups() {
      getJobSeekerGroups()
        .then((res) => {
          const result = res.response;
          setGroups(result);
        })
        .catch((err) => {
          console.log("Group List: ", err);
        });
    }
    allJSGroups();
    allIndustries();
  }, []);

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      refreshTableHandler(page);
    }
  };

  const refreshTableHandler = (page = 0) => {
    if (store !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store.recordperpage,
          page: currentPage.value,
          type: store.type,
          SerachText: store.SerachText,
          GlobalSearchTypeId: store.GlobalSearchTypeId,
          searchTypeText: store.searchTypeText,
        })
      );
      setShowConnectionSpinner(true);
    } else {
      setRefreshTable((prev) => !prev);
      setShowConnectionSpinner(true);
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const onSerchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  return (
    <div className="container p-0">
      <div className="header-with-search">
        <h1 className="page-title">Connections</h1>
        {/* <InputGroup className="messages-search">
          <InputGroup.Prepend>
            <InputGroup.Text id="search">
            <FaSearch />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Search"
            aria-label="search"
            aria-describedby="search"
          />
        </InputGroup> */}
      </div>

      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg sponsership-page network-page">
              <SearchSeekersConnection
                industriesList={industries}
                groupList={groups}
                searchParam={onSerchParamHandler}
              />
              <div className="d-flex mb-3">
                <SeekersConnectionsList
                  connections={connection}
                  showSpinner={showConnectionSpinner}
                />
                {/* <div className="alphabates">
                  {alphabates.map((alphabate, index) => (
                     <Button href="#"  key={index}>
                            {alphabate}
                     </Button>
                  ))}
                </div> */}
              </div>
              {connection.length !== 0 && showPagination && (
                <ListPagination
                  recordPerPage={jobseekerConnectionsPerPage}
                  TotalRecord={totalConnections}
                  className="mt-3"
                  onPageChange={pageChangeHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default SeekersConnections;

// signup
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  employerRequestAPI,
  jobseekerRequestAPI,
  supportTeamRequestAPI,
  groupLeaderRequestAPI,
  Roles,
} from "../../Utilities/Helper";

export const signUp = (userObj, userType) => {
  //console.log(userObj, "payload");
  //console.log(userType, "userType");

  let requestAPI;
  if (userType === Roles.employer) {
    requestAPI = employerRequestAPI;
  } else if (userType === Roles.jobSeeker) {
    requestAPI = jobseekerRequestAPI;
  } else if (userType === Roles.stm) {
    requestAPI = supportTeamRequestAPI;
  } else if (userType === Roles.groupLeader) {
    requestAPI = groupLeaderRequestAPI;
  }

  return (dispatch) => {
    // console.log("In Sign up dispatch");
    axios
      .post(requestAPI, userObj)
      .then((response) => {
        let email = userObj.Email;
        // toast.success(
        //   "THANK YOU FOR REGISTERING WITH JOB INFO NETWORK We have sent an email to " +
        //     email +
        //     " to confirm your email address & the link is valid for 30 minutes."
        // );
        toast.success("THANK YOU FOR REGISTERING WITH JOBINFONETWORK.");
        // console.log(response, "res");
        dispatch({
          type: "SIGN_UP",
          payload: response.data,
        });
        window.location.href = `${process.env.PUBLIC_URL}/#/home`;
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast.error(error.response.data.message);
          //toast.error(error.response.data.title);//data.error
          // console.log(error.response.data.title);//data.error
          console.log(error.response.data.message); //data.error
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          toast.error(error.request);
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
        //console.log(error.config);
      });
  };
};

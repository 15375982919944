import React, { useState, Component, useEffect, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import EmployerMenu from "../Components/EmployerMenus";
import SupportMenu from "../Components/SupportMenus";
import SeekerMenu from "../Components/SeekerMenu";
import GroupLeaderMenu from "../Components/GroupLeaderMenu";
import logoimage from "../assests/img/JIN_logo.svg";
import { useSelector } from "react-redux";
import { getUserDetails, getUserGroups } from "../Services/GetFields";
import AdminMenu from "./AdminMenu";
import { Roles } from "../Utilities/Helper";
import { getData } from "../Utilities/StorageHelper";

export default function Sidebar({
  isOpen,
  toggle,
  imageChange,
  imagetoggle,
  setToggleFalse,
}) {
  const [show, setShow] = useState("");

  useEffect(() => {
    let isLoggedIn = getData("token");
    if (getData("role") === Roles.jobSeeker) {
      if (isLoggedIn) {
        getUserDetails()
          .then((res) => {
            // console.log(res.response.isSponsered);
            let response = res.response.isSponsered;
            setShow(response);
          })
          .catch((err) => {
            console.log(err);
          });

        // let count = 0;
        // const res = getUserGroups()
        //   .then((res) => {
        //     for (let i = 0; i < res.response.length; i++) {
        //       count = count + res.response[i].supportTeamMemberCount;
        //     }
        //     // console.log(count, 'count');
        //     setStmCount(count);
        //     setData("count", count);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      } else {
        console.log("Signed out");
      }
    }
  }, [imageChange]);

  const todo = useSelector((state) => state.login.loginData);

  const role = todo;

  // console.log(todo, "todo");
  // const role = getData("users")
  return (
    <div className={isOpen ? "sidebar is-open" : "sidebar"}>
      <div className="sidebar-header">
        <img className="img-fluid" src={logoimage} alt="JIN Logo" />
        {/* <NavLink
          to={`${process.env.PUBLIC_URL}/dashboard`}
          className="JIN-brand"
        >
          <img className="img-fluid" src={logoimage} alt="JIN Logo" />
        </NavLink> */}
      </div>

      {role?.action?.action == "first" && (
        <>
          <h5>Employer Menu</h5>
          {<EmployerMenu setToggleFalse={setToggleFalse} />}
        </>
      )}
      {role?.action?.action == "second" && (
        <>
          <h5>Support Team Member</h5>
          {<SupportMenu setToggleFalse={setToggleFalse} />}
        </>
      )}
      {role?.action?.action == "third" && (
        <>
          <h5>Job Seeker Menu</h5>
          {<SeekerMenu show={show} setToggleFalse={setToggleFalse} />}
        </>
      )}
      {role?.action?.action == "fourth" && (
        <>
          <h5>Group Leader</h5>
          {/* {getData("users")} */}
          {<GroupLeaderMenu setToggleFalse={setToggleFalse} />}
        </>
      )}
      {role?.action?.action == "five" && (
        <>
          <h5>Administrator</h5>
          {/* {getData("users")} */}
          {<AdminMenu setToggleFalse={setToggleFalse} />}
        </>
      )}

      {/* <h5>Employer Menu</h5>
        { <EmployerMenu/> }
        <h5>Support Menu</h5>
        { <SupportMenu /> } 
        <h5>Seeker Menu</h5>
        { <SeekerMenu /> }
        <h5>Group Leader</h5>
        { <GroupLeaderMenu /> } */}
    </div>
  );
}

// export default Sidebar;

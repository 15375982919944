import React, { useState, useEffect, Component } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import FormInput from '../../../Components/Shared/FormInput';
import Buttons from '../../../Components/Shared/Button';

export default class PublicResumeDetails extends React.Component {
  render() {
    return (
      <div className="container p-0">
        <h1 className="page-title">Resume Details</h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={12}>
              <div className="dashboard-box dashboard-col-bg float-left pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/resumes`}
                  className="page-sub-title-with-back"
                >
                  <AiOutlineArrowLeft /> Back to resumes
                </Link>

                <div className="users-wrap users-bg job-details-wrap view-resume-details d-block pt-4">
                  <div className="text-right w-100">
                    <Link
                      to={`${process.env.PUBLIC_URL}/messages`}
                      className="text-decoration-none"
                    >
                      <Buttons
                        variant="primary"
                        title="Request to Contact"
                        type="submit"
                        size="xs"
                        color="white"
                        marginLeft="ml-3"
                        icon="icon fa fa-paper-plane mr-2"
                      ></Buttons>
                    </Link>

                    <Buttons
                      variant="warning"
                      title="Save Resume"
                      type="submit"
                      size="xs"
                      color="white"
                      marginLeft="ml-3"
                      icon="icon-favorite-outline mr-2"
                    ></Buttons>

                    {/* <Buttons
                        variant="secondary"
                        title="Remove"
                        type="button"
                        size="xs"
                        color="white"
                        marginLeft="ml-3"
                        icon="icon icon-trash mr-2"
                      ></Buttons> */}
                  </div>

                  <div className="detail-view mt-3 resume-content">
                    <h2>Stephen Jackson</h2>
                    {/* <p>
                      {' '}
                      Boston Common, Boston, MA, 02116, USA
                      <span className="dot">.</span>
                      <span className="r-title">Email: </span>
                      <span className="r-text">stephe.jack@gmail.com</span> 
                      <span className="dot">.</span>
                      <span className="r-title">Telephone: </span>
                      <span className="r-text">203.555.7813</span>
                    </p> */}
                    <hr />
                    <h3> Resume Title</h3>
                    <h6 className="mb-4">Project Manager</h6>
                    <hr />
                    <h3>Career Objective</h3>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.{' '}
                    </p>
                    <hr />
                    <h3>Core Competencies</h3>
                    <ul className="points-2">
                      <li>Customer Service</li>
                      <li>Deatsil & Orgnised</li>
                      <li>Cost Efficient</li>
                      <li>Supplier Relationship</li>
                    </ul>
                    <hr />
                    <div>
                      <h3>Professional Experience</h3>
                      <div className="company-text">
                        Google LLP, New York, NY.
                      </div>
                      <div className="exp-text">
                        Assistant Manager, August 2020 - Present
                      </div>
                      <ul className="d-block  points">
                        <li>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </li>
                        <li>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </li>
                        <li>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </li>
                        <li>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </li>
                      </ul>
                      <div className="company-text">
                        Microsoft, New York, NY.
                      </div>
                      <div className="exp-text">
                        Business Analyst, May 2018 - August 2020
                      </div>
                      <ul className="d-block  points">
                        <li>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </li>
                        <li>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </li>
                        <li>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </li>
                        <li>
                          {' '}
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </li>
                      </ul>
                    </div>
                    <hr />
                    <div>
                      <h3>Notice Period</h3>
                      <ul className="d-block points">
                        <li>60 days</li>
                      </ul>
                    </div>
                    <div>
                      <hr />
                      <h3>Education</h3>
                      <div className="company-text">
                        Florida State University, Orlands, FL.
                      </div>
                      <div className="exp-text">
                        Bachlor of Arts, August 2014.
                      </div>
                    </div>
                    <hr />
                    <div>
                      <h3>Additional Skills</h3>
                      <ul className="d-block points">
                        <li>Proficiant in Web Development</li>
                        <li>Certification in Agile</li>
                        <li>Language know Spanish, Germen</li>
                      </ul>
                    </div>

                    <hr />
                    <div>
                      <h3>Awards & Honors</h3>
                      <ul className="d-block points">
                        <li>Excellence Award 2020</li>
                        <li>Most Efficient Employee Award 2019</li>
                        <li>Best Employee Award 2020</li>
                      </ul>
                    </div>
                    {/*   <h3 className="job-title">Project Manager</h3>

                     <Row>
                      <Col>
                        <div className="post-date">
                          <span>Posted:</span> February 13, 2021
                        </div>
                      </Col>
                      <Col>
                        <div className="location">
                          <span>Location:</span> Seattle, WA,
                        </div>
                      </Col>
                      <Col>
                        <div className="post-date">
                          <span>Professional Experience:</span> 5 year's
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="post-date">
                          <span>Organization:</span> Joninfo Network
                        </div>
                      </Col>
                      <Col>
                        <div className="post-date">
                          <span>Notice Period:</span> 60 day's
                        </div>
                      </Col>
                      <Col>
                        <div className="post-date">
                          <span>Core Competencies:</span> HTML, CSS, Javascript,
                          Angular, React
                        </div>
                      </Col>
                    </Row> */}
                  </div>
                </div>
                {/* <div className="job-description">
                  <h3>Career Objective</h3>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.{" "}
                  </p>

                  <h3>Description</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.{" "}
                  </p>
                  <p className="mb-4">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem.
                  </p>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

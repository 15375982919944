import React, { useState, Component } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { toast } from "react-toastify";
import {
  required,
  validateCharacterAndSpace,
  Validators,
} from "../../../Utilities/Validator";
import { addAbuseReport } from "../../../Services/AbuseReport";
import { Roles } from "../../../Utilities/Helper";
import { getJobSeekerGroups } from "../../../Services/JobSeekersService";
import { getData } from "../../../Utilities/StorageHelper";

class ReportAbuse extends Component {
  state = {
    subject: "",
    message: "",
    errorSubject: "",
    errorMessage: "",
    errorGroup: "",
    groupid: "",
    grouplist: [],
  };

  componentDidMount() {
    getJobSeekerGroups()
      .then((res) => {
        const result = res.response;
        this.setState({ grouplist: result });
      })
      .catch((err) => {
        console.log("Group List: ", err);
      });
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  // validation check

  validate = (subject, message) => {
    var subjectrequired = required(subject, "This field");
    var IsErrorSubject = false;

    if (subjectrequired.error) {
      this.setState({ errorSubject: subjectrequired.message });
      IsErrorSubject = true;
    } else {
      this.setState({ errorSubject: "" });
      IsErrorSubject = false;
    }
    // else {
    //   var subjectInvalid = validateCharacterAndSpace(subject, "Subject");
    //   if (subjectInvalid.error) {
    //     this.setState({ errorSubject: subjectInvalid.message });
    //     IsErrorSubject = true;
    //   }
    // }

    var isErrorMessage = false;
    var messagerequired = required(message, "This field");
    if (messagerequired.error) {
      this.setState({ errorMessage: messagerequired.message });
      isErrorMessage = true;
    } else {
      this.setState({ errorMessage: "" });
      isErrorMessage = false;
    }

    var isErrorGroup = false;
    if (
      getData("role") === Roles.jobSeeker &&
      this.state.grouplist.length > 0
    ) {
      if (this.state.groupid === "0" || this.state.groupid === "") {
        this.setState({ errorGroup: "This field is required" });
        isErrorGroup = true;
      } else {
        isErrorGroup = false;
        this.setState({ errorGroup: "" });
      }
    }

    if (isErrorMessage || IsErrorSubject || isErrorGroup) return true;
    else return false;
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { subject, message, groupid } = this.state;
    if (!this.validate(subject, message)) {
      const data = { Subject: subject, Message: message, GroupId: groupid };
      addAbuseReport(data)
        .then((res) => {
          this.setState({ subject: "", message: "", groupid: "" });
          toast.success(res.response, { toastId: "custom_toast" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handelgroupchange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  render() {
    const { subject, message, groupid, grouplist = [] } = this.state;

    return (
      <div className="container p-0 post-job-container outline-form">
        <h1 className="page-title d-flex align-items-center">Report Abuse</h1>
        <div className="dashboard-wrapper resumes-page">
          <Form noValidate>
            <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg">
                  <Row>
                    <Col md={9} className="mx-auto">
                      {/* <h5 className="sub-form-title">
                        Password
                      </h5> */}
                      <Row>
                        {getData("role") === Roles.jobSeeker && (
                          <Col md={12} className="mb-3">
                            <Form.Control
                              value={groupid}
                              as="select"
                              className="inputclass form-control"
                              onChange={this.handelgroupchange("groupid")}
                            >
                              {" "}
                              {grouplist.length !== 0 && (
                                <option value="0">Select Group *</option>
                              )}
                              {grouplist.length === 0 && (
                                <option value="0">Select Group </option>
                              )}
                              {grouplist.map((item) => (
                                <option key={item.groupId} value={item.groupId}>
                                  {item.groupName}
                                </option>
                              ))}
                            </Form.Control>
                            <span
                              style={{ color: "#dc3545", fontSize: "13px" }}
                            >
                              {this.state.errorGroup}
                            </span>
                          </Col>
                        )}
                        <Col md={12}>
                          <FormInput
                            value={subject}
                            type="text"
                            placeholder="Subject *"
                            maxLength="100"
                            onChange={this.handleChange("subject")}
                            inputerror={this.state.errorSubject}
                          />
                        </Col>
                        <Col md={12}>
                          <FormInput
                            value={message}
                            type="textarea"
                            placeholder="Message *"
                            maxLength="500"
                            onChange={this.handleChange("message")}
                            inputerror={this.state.errorMessage}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8} className="square-radius mt-4">
                          <Buttons
                            variant="warning"
                            title="Submit"
                            type="button"
                            size="xs"
                            color="white"
                            onClick={this.handleSubmit}
                          ></Buttons>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}
export default ReportAbuse;

import React, { useState, Component } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import FormInput from "./Shared/FormInput";
import Buttons from "./Shared/Button";
import moment from "moment";

import { Validators } from "../Utilities/Validator";
import Dropdown from "./Shared/Dropdown";
import Select from "react-select";

const options = [
  { label: "Automobile", value: 1 },
  { label: "Finance", value: 2 },
  { label: "Healthcare", value: 3 },
];

class SearchSeekersConnection extends Component {
  state = {
    //keyword: "",
    position: "",
    company: "",
    city: "",
    state: "",
    groupid: "",
    // message: "",
    // location: "",
    // postDate: "",
    // selectedOption: "",
    industryid: "",
  };

  // DatePicker settings

  constructor(props) {
    super(props);
    //this.date = new moment(props.date);
    // this.state = {
    //   postDate: props.date,
    //   // category dropdown
    //   categories: [
    //     {
    //       options: [
    //         { label: "Sort", value: 1 },
    //         { label: "Location", value: 2 },
    //         { label: "Date", value: 3 },
    //       ],
    //     },
    //   ],
    //   // category dropdown
    //   industry: [
    //     {
    //       options: [
    //         { label: "Category", value: 1 },
    //         { label: "Category", value: 2 },
    //         { label: "Category", value: 3 },
    //       ],
    //     },
    //   ],
    // };
    // this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  // handleDateChange(date) {
  //   this.setState({
  //     postDate: date,
  //   });
  // }
  handelgroupchange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handelindustrychange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };
  // validation check

  // validate = (keyword, location) => {
  //   let result = true;
  //   if (Validators.isNull(keyword) || Validators.isUndefined(keyword)) {
  //     result = false;
  //   }

  //   if (Validators.isNull(location)) {
  //     result = false;
  //   }
  //   return result;
  // };

  handleSubmit = async (event) => {
    event.preventDefault();
    let searchParam = "";
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key)) {
        if (
          this.state[key] !== "" &&
          this.state[key] !== null &&
          this.state[key] !== undefined
        ) {
          if (key === "fromDate" || key === "toDate") {
            var selectedDate = new Date(this.state[key]);
            var newDate = new Date(
              selectedDate.setDate(selectedDate.getDate() + 1)
            ).toISOString();
            searchParam += `&${key}=${newDate}`;
          } else {
            searchParam += `&${key}=${this.state[key]}`;
          }
        }
      }
    }
    this.props.searchParam(searchParam);
  };

  resetSearchFilter() {
    this.setState({
      position: "",
      city: "",
      state: "",
      company: "",
      industryid: "",
      groupid: "",
    });
    this.props.searchParam("");
  }

  render() {
    const {
      // keyword,
      position,
      company,
      city,
      state,
      // postDate,
      industryid,
      groupid,
      // location,
    } = this.state;
    const { history } = this.props;

    return (
      <div className="search mb-4">
        <Form noValidate>
          <Row>
            <Col md={3}>
              <FormInput
                value={position}
                type="text"
                placeholder="Position"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("position")}
              />
            </Col>
            <Col md={3}>
              <FormInput
                value={company}
                type="text"
                placeholder="Company name"
                onChange={this.handleChange("company")}
              />
            </Col>
            <Col md={3}>
              {/* <Select
                placeholder="Industry"
                options={options}
                isMulti
                className="selectd"
              /> */}
            <div className="form-group">
              <Form.Control
                value={groupid}
                as="select"
                className="inputclass form-control"
                onChange={this.handelgroupchange("groupid")}
              >
                {" "}
                <option value="0">Select Group</option>
                {this.props.groupList.map((item) => (
                  <option key={item.groupId} value={item.groupId}>
                    {item.groupName}
                  </option>
                ))}
              </Form.Control>
              </div>
              {/* {this.state.industry.map((cat) => (
                <Dropdown options={cat.options} title={cat.name} />
              ))} */}
            </Col>
            <Col md={3}>
              {/* <Select
                placeholder="Industry"
                options={options}
                isMulti
                className="selectd"
              /> */}
 <div className="form-group">
              <Form.Control
                value={industryid}
                as="select"
                className="inputclass form-control"
                onChange={this.handelindustrychange("industryid")}
              >
                {" "}
                <option value="0">Select Industry</option>
                {this.props.industriesList.map((item) => (
                  <option key={item.industryId} value={item.industryId}>
                    {item.industryName}
                  </option>
                ))}
              </Form.Control>
              </div>
              {/* {this.state.industry.map((cat) => (
                <Dropdown options={cat.options} title={cat.name} />
              ))} */}
            </Col>
            <Col md={3}>
              <FormInput
                value={city}
                type="text"
                placeholder="City"
                onChange={this.handleChange("city")}
              />
            </Col>
            <Col md={3}>
              <FormInput
                value={state}
                type="text"
                placeholder="State"
                onChange={this.handleChange("state")}
              />
            </Col>
            <Col className="col-auto">
              <Buttons
                variant="warning"
                title="Search"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleSubmit}
              ></Buttons>
              <span style={{ marginLeft: 18 }}></span>
              <Buttons
                variant="warning"
                title="Reset"
                size="xs"
                color="white"
                onClick={(e) => this.resetSearchFilter(e)}
              ></Buttons>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export default withRouter(SearchSeekersConnection);

import React from "react";
import Button from "react-bootstrap/Button";
import classNames from "classnames";

export default class MessageLimit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <span
        style={{
          float: "right",
          color: `${this.props.currentLength.length >= 500 ? "red" : "black"}`,
        }}
      >
        {this.props.currentLength.length}/{this.props.totalLength}
      </span>
    );
  }
}

import React, { useState, useEffect, Component } from "react";
import { Col, Row, Form, Badge } from "react-bootstrap";
import { FaRegListAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import SponsershipList from "../../../Components/SponsershipList";
import { getSponsershipList } from "../../../Services/SponsershipService";
import ListPagination from "../../../Components/Pagination";
import { supportSponsorshipsPerPage } from "../../../Utilities/Helper";
import { SortingAlphabate } from "../../../Components/Shared/SortingAlphabate";

export default function Sponsership() {
  const [sponsership, setSponsership] = useState([]);
  const [totalSponsership, setTotalSponsership] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [showPagination, setShowPagination] = useState(true);

  // getting Sponsership list
  useEffect(() => {
    async function sponsershipData() {
      setShowPagination(true);
      const filter =
        "recordperpage=" +
        supportSponsorshipsPerPage +
        currentPage.filter +
        sort.filter;
      getSponsershipList(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setShowSpinner(false);
          setSponsership(result.jobSeekers);
          setTotalSponsership(result.totalRecord);
        })
        .catch((err) => {
          setShowSpinner(false);
          console.log(err);
        });
    }
    sponsershipData();
  }, [refreshTable]);

  const sortingChangeHandler = (value) => {
    if (sort.value !== value && value !== "clear") {
      setSort((prev) => ({ value: value, filter: `&sort=${value}` }));
      refreshTableHandler();
    }
    if (sort.value !== value && value === "clear") {
      setSort((prev) => ({ value: value, filter: "" }));
      refreshTableHandler();
    }
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
    }
  };

  const refreshTableHandler = () => {
    setCurrentPage({ value: "", filter: "" });
    setRefreshTable((prev) => !prev);
    setShowSpinner(true);
    setShowPagination(false);
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Sponsorships{" "}
        <Badge
          pill
          bg="danger"
          className="bg-warning text-white px-3 ml-3 font-weight-normal"
        >
          {totalSponsership}
        </Badge>
      </h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg sponsership-page">
              <div className="sort d-flex justify-content-end">
                {/* <Form.Group controlId="exampleForm.ControlSelect1" className="w-25">
                <Form.Control as="select" className="inputclass form-control">
                  <option>Sort By</option>
                  <option>Name</option>
                  <option> Email </option>
                  <option>Phone</option>
                  <option> Job Title & Company</option>
                </Form.Control>
              </Form.Group> */}
              </div>
              <div className="d-flex">
                <SponsershipList
                  sponsers={sponsership}
                  showSpinner={showSpinner}
                />
                <SortingAlphabate ChangeHandler={sortingChangeHandler} />
              </div>
              <div className="d-flex">
                {sponsership.length !== 0 && showPagination && (
                  <ListPagination
                    recordPerPage={supportSponsorshipsPerPage}
                    TotalRecord={totalSponsership}
                    className="mt-3"
                    onPageChange={pageChangeHandler}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

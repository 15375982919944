import moment from "moment";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Roles } from "../Utilities/Helper";
import { getData } from "../Utilities/StorageHelper";

export default function GroupLeaderJobsList({ groupName, jobList = [] }) {
  return (
    <ul className="resume-list all-job-list">
      {jobList.map((item, index) => (
        <li key={item.jobId}>
          <div className="resume-details">
            {getData("role") === Roles.groupLeader && (
              <Link
                to={`${process.env.PUBLIC_URL}/groupleader/groupleaderjobdetails/${item.jobId}`}
              >
                <h4>{item.positionTitle}</h4>
              </Link>
            )}
            {getData("role") === Roles.stm && (
              <Link
                to={`${process.env.PUBLIC_URL}/support/supportjobdetails/${item.jobId}`}
              >
                <h4>{item.positionTitle}</h4>
              </Link>
            )}
            <h5>{item.companyName}</h5>
            <div className="date-location">
              Industry : <span className="text">{" " + item.industryName}</span>
            </div>
            <div className="date-location">
              Posted :
              <span className="text">
                {" "}
                {/* {new Date(item.createdDate).toDateString()} */}
                {moment(item.jobStartDate).format("MMMM DD, Y")}
              </span>
            </div>
            <div className="date-location">
              Location :
              <span className="text">
                {" " + item.city + ", " + item.stateName}
              </span>
            </div>
          </div>
          {/* <Button
            variant="warning"
            size="sm"
            className="fav-icon ml-auto btn-like"
          >
            <i className="far fa-heart"></i>
          </Button> */}
        </li>
      ))}
    </ul>
  );
}

import React, { useState, useEffect } from "react";
import { Col, Row, Form, Modal, Button, Badge } from "react-bootstrap";
import {
  addSponsorshipRequest,
  getJobSeekerSponsorshipRequest,
} from "../../../Services/SponsershipService";
import alphabates from "../../../Components/AlphabatesSorting";
import ListPagination from "../../../Components/Pagination";
import JobSeekerSponsorshipRequestList from "../../../Components/JobSeekerSponsorshipRequestList";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { supportSponsorshipsPerPage } from "../../../Utilities/Helper";
import { SortingAlphabate } from "../../../Components/Shared/SortingAlphabate";
import { FaCircle } from "react-icons/fa";
// import { getUserDetails } from "../../../Services/GetFields";

export default function Sponsership() {
  const [sponsership, setSponsership] = useState([]);
  // const [userDetails, setUserDetails] = useState({ isSponsered: true });
  const [totalSponsership, setTotalSponsership] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [showModal, setShowModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showPagination, setShowPagination] = useState(true);

  const [formData, setFormData] = useState({
    Email: "",
    isJSNetworkWithSTM: true,
    sendJobPostingOfJS: true,
    isJSSubmitResume: true,
    isSTMRequestToContact: true,
  });

  const [emailError, setEmailError] = useState({ error: null, message: "" });
  //  getting Sponsership list

  // useEffect(() => {
  //   const getUserInfo = () => {
  //     getUserDetails()
  //       .then((res) => {
  //         const result = res.response;
  //         setUserDetails(result);
  //       })
  //       .catch((err) => console.log("UserDetails:", err));
  //   };
  //   getUserInfo();
  // }, []);

  useEffect(() => {
    async function getSponsorshipList() {
      setShowPagination(true);
      const filter =
        "recordperpage=" +
        supportSponsorshipsPerPage +
        currentPage.filter +
        sort.filter;
      getJobSeekerSponsorshipRequest(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setShowSpinner(false);
          setSponsership(result.jobSeekers);
          setTotalSponsership(result.totalRecord);
        })
        .catch((err) => {
          setShowSpinner(false);
          console.log("Request List: ", err);
        });
    }
    getSponsorshipList();
  }, [refreshTable]);

  const sortingChangeHandler = (value) => {
    if (sort.value !== value && value !== "clear") {
      setSort((prev) => ({ value: value, filter: `&sort=${value}` }));
      refreshTableHandler();
    }
    if (sort.value !== value && value === "clear") {
      setSort((prev) => ({ value: value, filter: "" }));
      refreshTableHandler();
    }
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ ...prev, filter: `&page=${page}` }));
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
    }
  };

  const refreshTableHandler = () => {
    setCurrentPage({ value: "", filter: "" });
    setRefreshTable((prev) => !prev);
    setFormData({ Email: "" });
    setShowSpinner(true);
    setShowSpinner(false);
  };

  const openModalHandler = () => {
    setFormData({
      Email: "",
      isJSNetworkWithSTM: true,
      sendJobPostingOfJS: true,
      isJSSubmitResume: true,
      isSTMRequestToContact: true,
    });
    setShowModal(true);
  };

  const onSendRequestHandler = () => {
    if (formData.Email === "" || formData.Email === undefined) {
      setEmailError({ error: true, message: "This field is required" });
      return;
    }
    setEmailError({ error: false, message: "" });
    setDisableButton(true);
    addSponsorshipRequest(formData)
      .then((res) => {
        const result = res;
        if (result.isSuccess) {
          toast.success(result.message);
          setShowModal(false);
          setDisableButton(false);
          refreshTableHandler();
        } else {
          toast.error(result.message);
          setDisableButton(false);
        }
      })
      .catch((err) => {
        setDisableButton(false);
        console.log("Add Request: ", err);
      });
  };

  const emailChangeHandler = (value) => {
    setFormData((prev) => ({ ...prev, Email: value.trim() }));
    if (value !== "") setEmailError({ error: false, message: "" });
  };

  // const onCheckBoxClick = (e, value) => {
  //   var data = {};
  //   data[value] = e.target.checked;
  //   setFormData((prev) => ({ ...prev, ...data }));
  // };

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Sponsorship Request
        <Badge
          pill
          bg="danger"
          className="bg-warning text-white px-3 ml-3 font-weight-normal"
        >
          {totalSponsership}
        </Badge>
      </h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg sponsership-page">
              <div className="sort d-flex justify-content-end"></div>
              {/* {userDetails !== null && ( */}
              <div className="d-flex">
                {/* {userDetails.isSponsered !== true && ( */}
                <Link
                  style={{ marginLeft: "auto", marginBottom: "10px" }}
                  className="btn btn-warning btn-xs white mt-3"
                  to={"#"}
                  onClick={openModalHandler}
                >
                  {" "}
                  Sponsorship Request
                </Link>
                {/* )} */}
              </div>
              {/* )} */}
              <div className="d-flex">
                <JobSeekerSponsorshipRequestList
                  sponsers={sponsership}
                  showSpinner={showSpinner}
                />

                <SortingAlphabate ChangeHandler={sortingChangeHandler} />
              </div>
              <div className="d-flex">
                {sponsership.length !== 0 && showSpinner && (
                  <ListPagination
                    recordPerPage={supportSponsorshipsPerPage}
                    TotalRecord={totalSponsership}
                    className="mt-3"
                    onPageChange={pageChangeHandler}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="custome-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Sponsorship Requests</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={() => setShowModal(false)}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            <FormInput
              value={formData.Email}
              type="text"
              placeholder="Email address of potential Sponsor *"
              required="required"
              onChange={(e) => emailChangeHandler(e)}
              inputerror={emailError.message}
            />
            <div>
              <p className="text-blue">
                At the option of the Sponsor, sponsor will receive the following
                information:
              </p>
            </div>
            <div className="my-2 d-flex">
              <span className="radi-icon">
                {" "}
                <FaCircle />
              </span>
              {/* <input
                id="check1"
                className="mt-2"
                type="radio"
                defaultChecked={true}
                disabled={true}
                //onClick={(e) => onCheckBoxClick(e, "isJSSubmitResume")}
              /> */}
              <label htmlFor="check1" className="ml-3 radio-note">
                Alert Sponsor when job seeker submits resume to a job.
              </label>
            </div>
            <div className=" my-2  d-flex">
              <span className="radi-icon">
                {" "}
                <FaCircle />
              </span>
              <label htmlFor="check2" className="ml-3 radio-note">
                Alert Sponsor when job seeker networks with support team
                members.
              </label>
            </div>
            <div className="my-2  d-flex">
              <span className="radi-icon">
                {" "}
                <FaCircle />
              </span>
              <label htmlFor="check3" className="ml-3 radio-note">
                Notify Sponsor when Support Team member requests contact.
              </label>
            </div>
            <div className="my-2  d-flex">
              <span className="radi-icon">
                {" "}
                <FaCircle />
              </span>
              <label htmlFor="check4" className="ml-3 radio-note">
                Send job postings in the job seeker's chosen job categories.
              </label>
            </div>
          </div>
          <div className="message-footer">
            <Buttons
              variant="warning"
              title="Send Sponsorship Request"
              size="xs"
              color="white"
              disabled={disableButton}
              onClick={onSendRequestHandler}
            ></Buttons>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import { API_URL } from "../Utilities/Helper";
import { get, post } from "./AxiosService";

export function getAllJobsList() {
  return fetch(
    "https://my-json-server.typicode.com/ashishbhaleraobits/jin/Jobs"
  ).then((data) => data.json());
}

// export function getAllJobs(filter) {
//   return fetch(`${API_URL}job/jobs?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getAllJobs(filter) {
  return get(`${API_URL}job/jobs?` + filter).then((res) => res.data);
}

// export function getDashboardJobs(filter) {
//   return fetch(`${API_URL}job/dasboardjobs?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getDashboardJobs(filter) {
  return get(`${API_URL}job/dasboardjobs?` + filter).then((res) => res.data);
}

// export function getAllSavedJobs(filter) {
//   return fetch(`${API_URL}job/savedjobs?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getAllSavedJobs(filter) {
  return get(`${API_URL}job/savedjobs?` + filter).then((res) => res.data);
}

// export function getJob(JobId) {
//   return fetch(`${API_URL}job/job/` + JobId, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJob(JobId) {
  return get(`${API_URL}job/job/` + JobId).then((res) => res.data);
}

// export function saveJob(JobId) {
//   return fetch(`${API_URL}job/savejob?JobId=` + JobId, {
//     method: "POST",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function saveJob(JobId) {
  return post(`${API_URL}job/savejob?JobId=` + JobId).then((res) => res.data);
}

//Get JobSeeker Group for filter
// export function getJobSeekerGroupId() {
//   return fetch(`${API_URL}user/groupdetails`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeekerGroupId() {
  return get(`${API_URL}user/groupdetails`).then((res) => res.data);
}

// export function getJobSeekerResume() {
//   return fetch(`${API_URL}job/jobseekerresumes`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeekerResume() {
  return get(`${API_URL}job/jobseekerresumes`).then((res) => res.data);
}

// export function applyForJob(data) {
//   return fetch(`${API_URL}job/applyforjob`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function applyForJob(data) {
  return post(`${API_URL}job/applyforjob`, data).then((res) => res.data);
}

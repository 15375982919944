import React, { useState, useEffect, Component } from "react";
import { Col, Row, Form, Badge, Tabs, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaRegEdit, FaRegHeart, FaRegListAlt } from "react-icons/fa";
import axios from "axios";
import NewResumesList from "../../../Components/ResumesList";
import ListPagination from "../../../Components/Pagination";
import {
  getResumesList,
  saveResume,
  unsaveResume,
} from "../../../Services/ResumeService";
import {
  getGroups,
  getIndustry,
  getPrivateResumes,
  getPrivateSavedResumes,
  getPublicResumes,
  searchPublicResumes,
} from "../../../Services/GetFields";
import {
  employerResumesPerPage,
  sortParamsForJob,
  sortParamsForPrivateResumes,
  sortParamsForResumes,
} from "../../../Utilities/Helper";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import SearchResumes from "../../../Components/SearchResumes";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { globalSearchAction } from "../../../Redux/GlobalSearch/Action";
import SearchPrivateResume from "../../../Components/SearchPrivateResume";

export default function Resumes() {
  document.body.classList.remove("applied-job-fliter");

  //Using Redux
  const store = useSelector((res) => res.GlobalSearchReducer.publicResumes);
  const store1 = useSelector((res) => res.GlobalSearchReducer.privateResumes);

  const [savedResumes, setSavedResumes] = useState([]);
  const [savedPrivateResumes, setSavedPrivateResumes] = useState([]);

  const [totalSavedResumes, setTotalSavedResumes] = useState(0);
  const [totalSavedPrivateResumes, setTotalSavedPrivateResumes] = useState(0);

  const [currentSavedResumesPage, setCurrentSavedResumesPage] = useState({
    value: "",
    filter: "&PageNumber=1",
  });
  const [refreshSavedTable, setRefreshSavedTable] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSavedJobSpinner, setShowSavedJobSpinner] = useState(true);
  const [newResumes, setNewResumes] = useState([]);
  const [newPrivateResumes, setNewPrivateResumes] = useState([]);

  const [totalResumes, setTotalResumes] = useState(0);
  const [totalPrivateResumes, setTotalPrivateResumes] = useState(0);

  const [currentResumesPage, setCurrentResumesPage] = useState({
    value: "0",
    filter: "",
  });
  const [refreshTable, setRefreshTable] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [searchParams, setSearchParams] = useState("");
  const [resumeRecords, setResumeRecords] = useState();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(store1 === null ? "first" : "second");

  const [refreshPrivateTable, setRefreshPrivateTable] = useState(true);
  const [showPrivateSpinner, setShowPrivateSpinner] = useState(true);
  const [currentPrivateResumesPage, setCurrentPrivateResumesPage] = useState({
    value: "0",
    filter: "",
  });
  const [sortPrivate, setSortPrivate] = useState({ value: "", filter: "" });
  const [searchPrivateParams, setSearchPrivateParams] = useState("");
  const [showPaginationPublic, setShowPaginationPublic] = useState(true);
  const [showPaginationPrivate, setShowPaginationPrivate] = useState(true);
  const [refreshPrivateSavedTable, setRefreshPrivateSavedTable] =
    useState(true);
  const [showPrivateSavedSpinner, setShowPrivateSavedSpinner] = useState(true);
  const [currentPrivateSavedResumesPage, setCurrentPrivateSavedResumesPage] =
    useState({
      value: "",
      filter: "",
    });

  useEffect(() => {
    async function allIndustries() {
      getIndustry()
        .then((res) => {
          const result = JSON.parse(res.response);
          setIndustries(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function allGroups() {
      getGroups()
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroups(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    allGroups();
    allIndustries();
  }, []);

  useEffect(() => {
    // if (!searchParams) {
    // console.log(searchParams, "without search");
    try {
      setShowPaginationPublic(true);
      const fetchData = async () => {
        const filter =
          "?PageSize=" +
          employerResumesPerPage +
          currentResumesPage.filter +
          "&IsSaved=unsaved" +
          sort.filter +
          searchParams;
        // console.log(filter, currentResumesPage.filter, "filter");
        const res = await getPublicResumes(filter);
        setShowSpinner(false);
        if (res) {
          const response = res;
          if (response) {
            // console.log(
            //   response,
            //   "unsaved",
            //   response.jinTemplatesModelList.length
            // );
            if (response.jinTemplatesModelList) {
              setNewResumes(response.jinTemplatesModelList);
              //  setResumeRecords(response.publicResumeCardsTotalCount);
              setTotalResumes(response.publicResumeCardsTotalCount);
            }
          }
        } else {
          setNewResumes([]);
        }
      };
      // fetchData();
      if (store === null) {
        fetchData();
      } else {
        document.getElementById("publicTab").click();
        setShowSpinner(true);
        setNewResumes(store.items);
        setTotalResumes(store.totalRecord);
        setShowSpinner(false);
      }
    } catch (err) {
      // toast.error("Something went wrong.");
      console.log(err);
      setShowSpinner(false);
    }
    // }
  }, [refreshTable, store]);

  useEffect(() => {
    if (tab == "first") {
      try {
        const fetchData = async () => {
          const filter =
            "?PageSize=" +
            employerResumesPerPage +
            currentSavedResumesPage.filter +
            "&IsSaved=saved";
          // "?PageNumber=1&PageSize=10&IsSaved=saved&SortBy=jobseekernameasc";
          // console.log(filter, "saved");
          const res = await getPublicResumes(filter);
          setShowSavedJobSpinner(false);
          const response = res;
          if (response) {
            // console.log(response, "saved");
            if (response.jinTemplatesModelList) {
              setSavedResumes(response.jinTemplatesModelList);
              setTotalSavedResumes(response.publicResumeCardsTotalCount);
            }
            // setShowSavedJobSpinner(false);
          }
        };
        fetchData();
      } catch (err) {
        // toast.error("Something went wrong.");
        console.log(err);
        setShowSavedJobSpinner(false);
      }
    }
  }, [refreshSavedTable]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const filter =
          "?recordperpage=" +
          employerResumesPerPage +
          currentPrivateSavedResumesPage.filter;
        const res = await getPrivateSavedResumes(filter);
        setShowPrivateSavedSpinner(false);
        const response = res;
        if (response) {
          if (response.jinTemplatesModelList) {
            // console.log(response.jinTemplatesModelList, 'saved private');
            setSavedPrivateResumes(response.jinTemplatesModelList);
            setTotalSavedPrivateResumes(response.publicResumeCardsTotalCount);
          }
        }
      };
      fetchData();
    } catch (err) {
      // toast.error("Something went wrong.");
      console.log(err);
      setShowPrivateSavedSpinner(false);
    }
    // }
  }, [refreshPrivateSavedTable]);

  // useEffect(() => {
  //   // console.log('called for unsaved private');
  //   // if (tab == "second") {
  //   try {
  //     setShowPaginationPrivate(true);
  //     const fetchData = async () => {
  //       const filter =
  //         "?recordperpage=" +
  //         employerResumesPerPage +
  //         currentPrivateResumesPage.filter +
  //         sortPrivate.filter +
  //         searchPrivateParams;
  //       // console.log(filter, '220 filter');
  //       const res = await getPrivateResumes(filter);
  //       // console.log(res, '221');
  //       setShowPrivateSpinner(false);
  //       const response = JSON.parse(res.response);
  //       if (response) {
  //         // console.log(response.jinTemplatesModelList, "unsaved private");
  //         if (response.jinTemplatesModelList) {
  //           setNewPrivateResumes(response.jinTemplatesModelList);
  //           setTotalPrivateResumes(response.publicResumeCardsTotalCount);
  //         }
  //       }
  //     };
  //     if (store1 === null) {
  //       fetchData();
  //     } else {
  //       document.getElementById("privateTab").click();
  //       setShowPrivateSpinner(true);
  //       setNewPrivateResumes(store1.items);
  //       setTotalPrivateResumes(store1.totalRecord);
  //       setShowPrivateSpinner(false);
  //     }
  //   } catch (err) {
  //     // toast.error("Something went wrong.");
  //     console.log(err);
  //     setShowPrivateSpinner(false);
  //   }
  //   // }
  // }, [refreshPrivateTable, store1]);

  const refreshSavedTableHandler = () => {
    setRefreshSavedTable((prev) => !prev);
    setShowSavedJobSpinner(true);
  };

  const savedPageChangeHandler = (page) => {
    if (currentSavedResumesPage.value !== page) {
      setCurrentSavedResumesPage((prev) => ({
        ...prev,
        filter: `&PageNumber=${page}`,
      }));
      refreshSavedTableHandler();
    }
  };

  const refreshTableHandler = (page = 0) => {
    if (store !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store.recordperpage,
          page: page,
          type: store.type,
          SerachText: store.SerachText,
          GlobalSearchTypeId: store.GlobalSearchTypeId,
          searchTypeText: store.searchTypeText,
        })
      );
      setShowSpinner(true);
    } else {
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const refreshPrivateTableHandler = (page = 0) => {
    if (store1 !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store1.recordperpage,
          page: page,
          type: store1.type,
          SerachText: store1.SerachText,
          GlobalSearchTypeId: store1.GlobalSearchTypeId,
          searchTypeText: store1.searchTypeText,
        })
      );
      setShowPrivateSpinner(true);
    } else {
      setRefreshPrivateTable((prev) => !prev);
      setShowPrivateSpinner(true);
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const refreshPrivateSavedTableHandler = () => {
    setRefreshPrivateSavedTable((prev) => !prev);
    setShowPrivateSavedSpinner(true);
  };

  const pageChangeHandler = (page) => {
    if (currentResumesPage.value !== page) {
      setCurrentResumesPage((prev) => ({
        value: page,
        filter: `&PageNumber=${page}`,
      }));
      refreshTableHandler(page);
    }
  };

  const privatePageChangeHandler = (page) => {
    if (currentPrivateResumesPage.value !== page) {
      setCurrentPrivateResumesPage((prev) => ({
        value: page,
        filter: `&page=${page}`,
      }));
      refreshPrivateTableHandler(page);
    }
  };

  const privateSavedPageChangeHandler = (page) => {
    if (currentPrivateSavedResumesPage.value !== page) {
      setCurrentPrivateSavedResumesPage((prev) => ({
        ...prev,
        filter: `&page=${page}`,
      }));
      refreshPrivateSavedTableHandler();
    }
  };

  const sortingChangeHandler = (e) => {
    var val = e.target.value;
    // console.log(val);
    if (val === "Newest First") {
      val = "newestfirst";
    } else if (val === "Oldest First") {
      val = "oldestfirst";
    }
    if (val === "Job title Ascending") {
      val = "jobtitleasc";
    } else if (val === "Job title Descending") {
      val = "jobtitledesc";
    } else if (val === "Name Ascending") {
      val = "jobseekernameasc";
    } else if (val === "Name Descending") {
      val = "jobseekernamedesc";
    }
    if (val === "State Ascending") {
      val = "stateasc";
    } else if (val === "State Descending") {
      val = "statedesc";
    }
    if (val === "City Ascending") {
      val = "cityasc";
    } else if (val === "City Descending") {
      val = "citydesc";
    }
    setSort((prev) => ({ ...prev, filter: `&SortBy=${val}` }));
    refreshTableHandler();
    dispatch(
      globalSearchAction({
        type: "clear",
      })
    );
  };

  const sortingChangePrivateHandler = (e) => {
    var val = e.target.value;
    setSortPrivate((prev) => ({ ...prev, filter: `&sort=${val}` }));
    dispatch(
      globalSearchAction({
        type: "clear",
      })
    );
    refreshPrivateTableHandler();
  };

  const onSearchParamHandler = (params) => {
    setShowPaginationPublic(false);
    setSearchParams(params);
    setCurrentResumesPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  const onSearchPrivateParamHandler = (params) => {
    setShowPaginationPrivate(false);
    setSearchPrivateParams(params);
    setCurrentPrivateResumesPage((prev) => ({ value: "", filter: "" }));
    refreshPrivateTableHandler();
  };

  const onSaveResumeClick = (type) => {
    // console.log(type, "type to save");
    if (type === "public") {
      setCurrentResumesPage((prev) => ({
        value: 1,
        filter: `&PageNumber=${1}`,
      }));
      refreshSavedTableHandler();
      refreshTableHandler(1);
    } else {
      setCurrentPrivateResumesPage((prev) => ({
        value: 1,
        filter: `&PageNumber=${1}`,
      }));
      refreshPrivateTableHandler(1);
      refreshPrivateSavedTableHandler();
    }
  };

  return (
    <div className="container p-0 resume-tabs">
      {/* <h1 className="page-title">Resumes</h1> */}
      <Tabs defaultActiveKey={tab} onSelect={(k) => setTab(k)}>
        <Tab eventKey="first" title="Public Resumes" id="publicTab">
          <div className="dashboard-wrapper resumes-page">
            <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg saved-resume border-grey-1">
                  <h2 className="page-sub-title">
                    Saved Public Resumes
                    <Badge
                      pill
                      bg="danger"
                      className="bg-warning text-white px-3 ml-3 font-weight-normal"
                    >
                      {totalSavedResumes}
                    </Badge>
                  </h2>

                  {/* <Tabs
                defaultActiveKey="public"
                activeKey={tab}
                onSelect={(k) => setTab(k)}
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="public" title="Public Resumes">
                  
                </Tab>
                <Tab eventKey="private" title="Private Resumes">
      
                </Tab>
              </Tabs> */}

                  {/* <div className="search"><Search/></div> */}
                  {showSavedJobSpinner && <SpinnerLoader />}
                  {savedResumes &&
                    savedResumes.length === 0 &&
                    !showSavedJobSpinner && (
                      <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                    )}
                  <NewResumesList
                    resumes={savedResumes && savedResumes.slice(0, 9)}
                    title={"Remove from Saved Resumes"}
                    // saveResumeChange={onUnsaveResumeClick}
                    saveResumeChange={onSaveResumeClick}
                  />
                  {savedResumes && savedResumes.length !== 0 && (
                    <ListPagination
                      recordPerPage={employerResumesPerPage}
                      TotalRecord={totalSavedResumes}
                      className="mt-3"
                      onPageChange={savedPageChangeHandler}
                    />
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg">
                  <div className="d-flex align-items-center resume-block-mobile">
                    <h2 className="page-sub-title">
                      Public Resumes
                      <Badge
                        pill
                        bg="danger"
                        className="bg-warning text-white px-3 ml-3 font-weight-normal"
                      >
                        {totalResumes}
                      </Badge>
                    </h2>

                    <div className="saved-resume text-right mb-4 font-weight-bold ml-auto">
                      {/* <span className="text-warning font-weight-bold mr-1">{totalSavedResumes}</span> */}
                      Saved Public Resumes
                      <span className="icon-d  ml-3">
                        <span className="text-warning font-weight-bold">
                          {totalSavedResumes}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="search">
                    <SearchResumes
                      industriesList={industries}
                      groupsList={groups}
                      searchParam={onSearchParamHandler}
                    />
                  </div>
                  <div className="sort">
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      className="w-25"
                      onChange={sortingChangeHandler}
                    >
                      <Form.Control
                        as="select"
                        className="inputclass form-control"
                      >
                        {sortParamsForResumes.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  {showSpinner && <SpinnerLoader />}
                  {newResumes && newResumes.length === 0 && !showSpinner && (
                    <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                  )}
                  <NewResumesList
                    resumes={
                      newResumes &&
                      newResumes[0] !== "" &&
                      newResumes.slice(0, 9)
                    }
                    title={"Add to Saved Resumes"}
                    saveResumeChange={onSaveResumeClick}
                  />
                  {newResumes &&
                    newResumes.length !== 0 &&
                    showPaginationPublic && (
                      <ListPagination
                        recordPerPage={employerResumesPerPage}
                        TotalRecord={totalResumes}
                        className="mt-3"
                        onPageChange={pageChangeHandler}
                      />
                    )}
                </div>
              </Col>
            </Row>
          </div>
        </Tab>
        <Tab
          eventKey="second"
          title="Private Resumes"
          onSelect={(k) => setTab(k)}
          id="privateTab"
        >
          <div className="dashboard-wrapper resumes-page">
            <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg saved-resume border-grey-1">
                  <h2
                    className="page-sub-title"
                    style={{ marginBottom: "-10px" }}
                  >
                    Saved Private Resumes
                    <Badge
                      pill
                      bg="danger"
                      className="bg-warning text-white px-3 ml-3 font-weight-normal"
                    >
                      {totalSavedPrivateResumes}
                    </Badge>
                  </h2>
                  <h3
                    className="page-sub-title"
                    style={{ fontSize: "14px", color: "rgb(100 100 100)" }}
                  >
                    JobInfoNetwork formatted resumes only
                  </h3>
                  {showPrivateSavedSpinner && <SpinnerLoader />}
                  {savedPrivateResumes &&
                    savedPrivateResumes.length === 0 &&
                    !showSavedJobSpinner && (
                      <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                    )}
                  <NewResumesList
                    resumes={
                      savedPrivateResumes && savedPrivateResumes.slice(0, 9)
                    }
                    title={"Remove from Saved Resumes"}
                    // saveResumeChange={onUnsaveResumeClick}
                    saveResumeChange={onSaveResumeClick}
                  />
                  {savedPrivateResumes && savedPrivateResumes.length !== 0 && (
                    <ListPagination
                      recordPerPage={employerResumesPerPage}
                      TotalRecord={totalSavedPrivateResumes}
                      className="mt-3"
                      onPageChange={privateSavedPageChangeHandler}
                    />
                  )}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg">
                  <div className="d-flex align-items-center">
                    <h2 className="page-sub-title">
                      Private Resumes
                      <Badge
                        pill
                        bg="danger"
                        className="bg-warning text-white px-3 ml-3 font-weight-normal"
                      >
                        {totalPrivateResumes}
                      </Badge>
                    </h2>

                    <div className="saved-resume text-right mb-4 font-weight-bold ml-auto">
                      Saved Private Resumes
                      <span className="icon-d  ml-3">
                        <span className="text-warning font-weight-bold">
                          {totalSavedPrivateResumes}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="search">
                    <SearchPrivateResume
                      industriesList={industries}
                      groupsList={groups}
                      searchParam={onSearchPrivateParamHandler}
                    />
                  </div>
                  <div className="sort">
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      className="w-25"
                      onChange={sortingChangePrivateHandler}
                    >
                      <Form.Control
                        as="select"
                        className="inputclass form-control"
                      >
                        {sortParamsForPrivateResumes.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  {showPrivateSpinner && <SpinnerLoader />}
                  {newPrivateResumes &&
                    newPrivateResumes.length === 0 &&
                    !showSpinner && (
                      <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                    )}
                  <NewResumesList
                    resumes={
                      newPrivateResumes &&
                      newPrivateResumes[0] !== "" &&
                      newPrivateResumes.slice(0, 9)
                    }
                    title={"Add to Saved Resumes"}
                    saveResumeChange={onSaveResumeClick}
                  />
                  {newPrivateResumes &&
                    newPrivateResumes.length !== 0 &&
                    showPaginationPrivate && (
                      <ListPagination
                        recordPerPage={employerResumesPerPage}
                        TotalRecord={totalPrivateResumes}
                        className="mt-3"
                        onPageChange={privatePageChangeHandler}
                      />
                    )}
                </div>
              </Col>
            </Row> */}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

// export default Resumes;

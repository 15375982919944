import React, { useState, Component } from "react";
import { NavLink } from "react-router-dom";

export default function EmployerMenu({ setToggleFalse }) {
  return (
    <ul>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/dashboard`}
        >
          <span className="icon icon-wysiwyg"></span>
          Dashboard
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/resumes`}
        >
          <span className="icon icon-file_present"></span> Resumes
        </NavLink>
      </li>

      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/jobslist`}
        >
          <span className="icon icon-design"></span>
          Jobs
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/postjob`}
        >
          <span className="icon icon-post"></span>
          Post Job
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/employer/messages`}
        >
          <span className="icon icon-chat_bubble_outline"></span> Messages
        </NavLink>
      </li>
    </ul>
  );
}

// export default Sidebar;

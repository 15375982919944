import { NavLink } from "react-router-dom";

export default function AdminMenu({ setToggleFalse }) {
  return (
    <ul>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/admin/dashboard`}
        >
          <span className="icon icon-wysiwyg"></span>
          Dashboard
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/admin/manageusers`}
        >
          <span className="icon icon-job-seeker"> </span> Manage Users
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/admin/reportabuse`}
        >
          <span className="icon icon-documents"></span> Report Abuse
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/admin/groupcategories`}
        >
          <span className="icon icon-people_outline"></span> Manage Group
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/admin/demotools`}
        >
          <span className="icon icon-edit"></span> Demo Tools
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/groupleader/supportjobs`}>
           <span className="icon icon-manage_search"></span> Jobs 
          </NavLink>
      </li> */}
    </ul>
  );
}

// export default SeekerMenu;

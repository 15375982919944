import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import Buttons from "./Shared/Button";
import SpinnerLoader from "./SpinnerLoader";
import FormInput from "./Shared/FormInput";
import { getReportAbuse } from "../Services/AbuseReport";
import { toast } from "react-toastify";

export default function ReportAbuseList({
  reports = [],
  showSpinner,
  addComment,
}) {
  // Active confirm modal
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const [showt, setShowt] = useState(false);
  // //const handleClose1 = () => setShowt(false);

  // // block confirm modal
  // const [showblock, setShowblock] = useState(false);
  // const handleCloseBlock = () => setShowblock(false);
  // const handleShowBlock = () => setShowblock(true);

  // const [showb, setShowb] = useState(false);
  // //const handleClose1 = () => setShowt(false);

  // setTimeout(() => {
  //   setShowt(false);
  //   setShowb(false);
  // }, 5000);

  // const renderActiveTooltip = (props) => <Tooltip {...props}>Active</Tooltip>;

  // const renderBlockTooltip = (props) => <Tooltip {...props}>Block</Tooltip>;

  const [showViewAbuseReport, setShowViewAbuseReport] = useState(false);
  const [currentLength, setCurrentLength] = useState(0);
  const [totalLength, setTotalLength] = useState(500);
  const [formData, setFormData] = useState({
    AbuseReportId: 0,
    Message: "",
    Comment: "",
  });
  const handleClose = () => setShowViewAbuseReport(false);

  const clearForm = () => {
    setFormData({ AbuseReportId: 0, Message: "", Comment: "" });
  };

  const viewAbuseReportHandler = (abuseReportId) => {
    clearForm();
    setShowViewAbuseReport(true);
    setCurrentLength(0);
    getReportAbuse(abuseReportId)
      .then((res) => {
        var result = JSON.parse(res.response);
        setFormData({
          AbuseReportId: result.abuseReportId,
          Message: result.message,
          Comment: result.comment,
        });
        setCurrentLength(result.comment !== null ? result.comment.length : 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCommentChange = (val) => {
    setCurrentLength(val.length);
    setFormData((prev) => {
      return { ...prev, Comment: val };
    });
  };

  const addCommentHandler = () => {
    handleClose();
    addComment(formData);
  };

  return (
    <React.Fragment>
      {/* <Alert
        className="confirm-alert"
        variant="success"
        show={showt}
        onClose={() => setShowt(false)}
        dismissible
      >
        <p className="text-center mb-0 py-3">
          Job seeker activate sucessfully.
        </p>
      </Alert>

      <Alert
        className="confirm-alert"
        variant="danger"
        show={showb}
        onClose={() => setShowb(false)}
        dismissible
      >
        <p className="text-center mb-0 py-3">Job seeker blocked sucessfully.</p>
      </Alert> */}

      <ul className="job-list support-team-list abuse-list mobile-list">
        {/* <p>Jobs</p> */}
        <li className="headings">
          <div className="width-t1">User Name </div>
          <div className="width-t2">User Type </div>
          <div className="width-t3">Subject </div>
          <div className="width-t4">Comment </div>
          <div className="width-t5">Action </div>
        </li>
        {showSpinner && <SpinnerLoader />}
        {reports.length === 0 && !showSpinner && (
          <h5 style={{ textAlign: "center" }}>No Data Found</h5>
        )}
        {reports.map((report, index) => (
          <li key={index} className="align-items-center">
            <div className="width-t1 mobile-coulmn">
            <span className="mobile-column-name">User Name</span>
            <span className="mobile-column-content">
              {report.userName}
              </span> 
              </div>
            <div className="width-t2 mobile-coulmn">
            <span className="mobile-column-name">User Type</span>
            <span className="mobile-column-content">
              {report.userType}
              </span>
              </div>
            <div className="width-t3 mobile-coulmn">
            <span className="mobile-column-name">Subject</span>
            <span className="mobile-column-content">
              {report.subject}
              </span>
              </div>
            {report.comment !== null && (
              <Fragment>
                {report.comment.length > 58 && (
                  <div className="width-t4 mobile-coulmn">
                     <span className="mobile-column-name">Comment</span>
            <span className="mobile-column-content">
                    {report.comment.substr(0, 57) + "..."}
                    </span>
                  </div>
                )}
                {report.comment.length < 58 && (
                  <div className="width-t4 mobile-coulmn">
                      <span className="mobile-column-name">Comment</span>
            <span className="mobile-column-content">
                    {report.comment}</span>
                    
                    </div>
                )}
              </Fragment>
            )}
            {report.comment === null && (
              <div className="width-t4 mobile-coulmn">
                  <span className="mobile-column-name">Comment</span>
            <span className="mobile-column-content">
                
                {report.comment}</span></div>
            )}
            <div
              className="width-t5 mobile-coulmn"
              style={{ cursor: "pointer" }}
              onClick={() => viewAbuseReportHandler(report.abuseReportId)}
            >
              <span className={"fa-icon fas fa-pen"}></span>
            </div>
          </li>
        ))}
      </ul>

      {/* Active confirm */}

      <Modal
        show={showViewAbuseReport}
        onHide={handleClose}
        centered
        className="confirm-modal"
        dialogClassName="modal-lg"
      >
        <div className="message-container  modal-lg custome-msg-container">
          <div className="message-header-box">
            <h4>Abuse Report Details</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleClose}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            <Row style={{ textAlign: "left" }}>
              <Col md={12}>
                <label>
                  <b>Message :</b>
                </label>
                <p>{formData.Message}</p>
              </Col>
              <Col md={12}>
                <label>
                  <b>Comment :</b>
                </label>
                <FormInput
                  value={formData.Comment}
                  type="textarea"
                  placeholder="Message"
                  maxLength={"500"}
                  onChange={onCommentChange}
                />
                <span
                  style={{
                    float: "right",
                    color: `${currentLength === 500 ? "red" : "black"}`,
                  }}
                >
                  {currentLength}/{totalLength}
                </span>
              </Col>
            </Row>
          </div>
          <div
            className="message-footer pt-0"
            style={{ display: "block", textAlign: "right" }}
          >
            <Buttons
              variant="secondary"
              title="Cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                handleClose();
              }}
            ></Buttons>
            <Buttons
              variant="primary"
              title="Add Comment"
              type="button"
              size="xs"
              color="white"
              onClick={addCommentHandler}
            ></Buttons>
          </div>
        </div>
      </Modal>

      {/* block confirm

      <Modal
        show={showblock}
        onHide={handleCloseBlock}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleCloseBlock}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            Are you sure you want to block this job seeker?
          </div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                setShowb(true);
                setShowblock(false);
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                setShowb(true);
                setShowblock(false);
              }}
            ></Buttons>
          </div>
        </div>
      </Modal> */}
    </React.Fragment>
  );
}

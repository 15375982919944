import { API_URL } from "../Utilities/Helper";
import { get } from "./AxiosService";

const Url = API_URL;

// export function getAllSupportTeamMembers(filter) {
//   return fetch(`${Url}user/supportteammemberforgroupleader?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getAllSupportTeamMembers(filter) {
  return get(`${Url}user/supportteammemberforgroupleader?` + filter).then((res) => res.data);
}

// export function getSupportTeamMember(supportTeamMemberId) {
//   return fetch(
//     `${Url}user/supportteammember?SupportTeamMemberId=` + supportTeamMemberId,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getSupportTeamMember(supportTeamMemberId) {
  return get(
    `${Url}user/supportteammember?SupportTeamMemberId=` + supportTeamMemberId
  ).then((res) => res.data);
}

// export function updateSupportTeamMemberStatus(data) {
//   return fetch(
//     `${Url}user/supportteammemberisactivestatus?SupportTeamMemberId=${data.SupportTeamMemberId}&IsActive=${data.IsActive}`,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   )
//     .then((data) => data.json())
//     .catch((err) => err.json());
// }

export function updateSupportTeamMemberStatus(data) {
  return get(
    `${Url}user/supportteammemberisactivestatus?SupportTeamMemberId=${data.SupportTeamMemberId}&IsActive=${data.IsActive}`
  )
    .then((res) => res.data);
}

// export function getGroupCategoriesByGroup() {
//   return fetch(`${Url}user/groupcategoriesbygroup`, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function getGroupCategoriesByGroup() {
  return get(`${Url}user/groupcategoriesbygroup`).then((res) => res.data);
}

import { useState } from "react";
import alphabates from "../AlphabatesSorting";
export const SortingAlphabate = ({ ChangeHandler }) => {
  const [addActive, setActive] = useState(null);

  const sortingChange = (value, index) => {
    if (value === "clear") {
      setActive(null);
    } else {
      setActive(index);
    }
    ChangeHandler(value);
  };

  return (
    <div className="alphabates">
      <span
        style={{ cursor: "pointer" }}
        className={"icon far fa-times-circle mr-1"}
        onClick={() => sortingChange("clear")}
      ></span>
      {alphabates.map((alphabate, index) => (
        <div
          className={`alphabate ${index === addActive ? "active" : ""}`}
          key={index}
          onClick={() => sortingChange(alphabate, index)}
        >
          <span>{alphabate}</span>
        </div>
      ))}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Col, Row, Button, InputGroup, FormControl } from "react-bootstrap";
import SeekersNetworkList from "../../../Components/SeekersNetworkList";
import getSeekersNetworkList from "../../../Services/SeekersNetworkService";
import alphabates from "../../../Components/AlphabatesSorting";
import ListPagination from "../../../Components/Pagination";
import { FaCommentAlt, FaSearch } from "react-icons/fa";
import SearchSeekersNetwork from "../../../Components/SearchSeekersNetwork";
import { getJobSeekerNetworkList } from "../../../Services/JobSeekersService";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import { jobseekerNetworkPerPage } from "../../../Utilities/Helper";
import { getIndustry, getUserGroups } from "../../../Services/GetFields";
import { globalSearchAction } from "../../../Redux/GlobalSearch/Action";
import { useDispatch, useSelector } from "react-redux";

export default function Network() {
  //Using Redux
  const store = useSelector((res) => res.GlobalSearchReducer.networks);
  const [SeekersNetwork, setSeekersNetwork] = useState([]);
  const [totalNetwork, setTotalNetwork] = useState([]);
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [searchParams, setSearchParams] = useState("");
  const [showPagination, setShowPagination] = useState(true);
  // const [groupID, setGroupID] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    async function allIndustries() {
      getIndustry()
        .then((res) => {
          const result = JSON.parse(res.response);
          setIndustries(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    allIndustries();
  }, []);

  // getting Network list
  useEffect(() => {
    async function allGroups() {
      getUserGroups()
        .then((res) => {
          // console.log(res.response);
          setGroups(res.response);
          // setGroupID(res.response.groupId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function seekersNetworkData() {
      setShowPagination(true);
      const filter =
        "PageSize=" +
        jobseekerNetworkPerPage +
        currentPage.filter +
        searchParams;
      // console.log(filter);
      getJobSeekerNetworkList(filter)
        .then((res) => {
          if (res.response === "" || res.response === null) {
            setShowSpinner(false);
            setSeekersNetwork([]);
            setTotalNetwork(0);
          } else {
            const response = JSON.parse(res.response);
            setShowSpinner(false);
            setSeekersNetwork(response.networkModels);
            setTotalNetwork(response.totalRecord);
          }
        })
        .catch((err) => {
          setShowSpinner(false);
          console.log(err);
        });
    }
    if (store === null) {
      seekersNetworkData();
    } else {
      setShowSpinner(true);
      setSeekersNetwork(store.items);
      setTotalNetwork(store.totalRecord);
      setShowSpinner(false);
    }
    allGroups();
  }, [refreshTable, store]);

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({
        value: page,
        filter: `&PageNumber=${page}`,
      }));
      refreshTableHandler(page);
    }
  };

  const refreshTableHandler = (page = 0) => {
    if (store !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store.recordperpage,
          page: currentPage.value,
          type: store.type,
          SerachText: store.SerachText,
          GlobalSearchTypeId: store.GlobalSearchTypeId,
          searchTypeText: store.searchTypeText,
        })
      );
      setShowSpinner(true);
    } else {
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const onSerchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  const setRefresh = (val) => {
    if (val) {
      // console.log(val);
      refreshTableHandler();
    }
  };

  return (
    <div className="container p-0">
      <div className="header-with-search">
        <h1 className="page-title">Network</h1>

      </div>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg sponsership-page network-page">
              <SearchSeekersNetwork
                industriesList={industries}
                groupsList={groups}
                searchParam={onSerchParamHandler}
              />

              <div className="d-flex mb-3">
                <SeekersNetworkList networks={SeekersNetwork}
                  setRefresh={setRefresh} />
              </div>
              {showSpinner && <SpinnerLoader />}
              {SeekersNetwork.length === 0 &&
                // SeekersNetwork.length === 0 &&
                !showSpinner && (
                  <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                )}
              {SeekersNetwork.length !== 0 && showPagination && (
                <ListPagination
                  recordPerPage={jobseekerNetworkPerPage}
                  TotalRecord={totalNetwork}
                  className="mt-3"
                  onPageChange={pageChangeHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default Network;

import React, { useState, Component, useEffect } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { Route, Switch, Redirect, Link, NavLink } from "react-router-dom";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { Validators } from "../../../Utilities/Validator";
import publiclogo from "../../../assests/img/logo-public.png";
import {
  Navbar,
  Button,
  Nav,
  ButtonDropdown,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  NavDropdown,
  Image,
} from "react-bootstrap";
export default function Registerd() {
  // const { password, confirmPassword } = this.state;

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [register, setRegister] = useState({
    firstName: "",
    lastName: "",
  });

  const handleInputChange = (event) => {
    setState((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChange = (event) => {
    setRegister((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    document.body.classList.add("home-page");

    return function cleanup() {
      document.body.classList.remove("home-page");
    };
  }, []);

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="d-flex">
            <div className="brand">
              <a href="index.html">
                <img
                  src="https://php7.benchmarkit.solutions/jobInfonetwork/prototype/static/media/JIN_logo.48e5b9fa.svg"
                  alt="JobInfoNetwork"
                />
              </a>
            </div>
            <nav className="ml-auto">
              <ul>
                <li>
                  <a
                    className="nav-link sub-menu-parent"
                    href="javascript:void(0);"
                  >
                    About
                  </a>
                  <div className="sub-menu">
                    <ul>
                      <li>
                        <a href="about-jobInfoNetwork.html">
                          About JobInfoNetwork
                        </a>
                      </li>
                      <li>
                        <a href="how-it-works.html">How It Works</a>
                      </li>
                      <li>
                        <a href="comparative-advantages.html">
                          Comparative Advantages
                        </a>
                      </li>
                      <li>
                        <a href="our-team.html">Our Team</a>
                      </li>
                      <li>
                        <a href="FAQs.html">FAQs</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="login-doropdown outline-form ">
                  <Dropdown>
                    <a
                      className="nav-link login-popup"
                      href="javascript:void(0);"
                    >
                      <Dropdown.Toggle className="profile" id="dropdown-basic">
                        Sign in
                      </Dropdown.Toggle>
                    </a>
                    <Dropdown.Menu>
                      <h1 className="mb-4 mt-2 pb-2">Sign in</h1>
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={state.email}
                          onChange={handleInputChange}
                          className="inputclass form-control"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={state.password}
                          onChange={handleInputChange}
                          className="inputclass form-control"
                        />
                      </div>
                      {/* <FormInput
                        value={state.email}
                        type="text"
                        placeholder="Email"
                        onChange={handleInputChange}
                      /> */}

                      {/* <FormInput
                        value={state.password}
                        type="text"
                        placeholder="Password"
                        required="required"
                        onChange={handleInputChange}
                      /> */}

                      <NavLink
                        activeClassName="is-active"
                        to=""
                        className="text-decoration-none grey forgot-link"
                      >
                        Forgot password?
                      </NavLink>

                      <Dropdown.Item
                        as="button"
                        className="profile-btns p-0 mt-4 square-radius"
                      >
                        <NavLink
                          activeClassName="is-active"
                          to={`${process.env.PUBLIC_URL}/dashboard`}
                          className="text-decoration-none btn btn-warning fill-signout"
                        >
                          Sign in
                        </NavLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="btn-header">
                  <span>Not yet registered?</span>
                  <NavLink className="btn-get-started" to={`${process.env.PUBLIC_URL}/register`}>
                    Get Started
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <div className="main-content">
        <div className="main-banner">
          <div className="dashboard-wrapper resumes-page">
            <div className="container">
              <div className="dashboard-box dashboard-col-bg public-wrapper outline-form">
                {/* <div className="text-right">
                  <span className="icon icon-close ml-auto black cursor-pointer"></span>
                </div> */}
                <Form noValidate>
                  <Row>
                    <Col md={9} className="mx-auto">
                      <h1>Congrats. You're all set.</h1>
                      <h1 className="mt-5 mb-4 pb-3 pt-3">Sign in</h1>
                      <Row>
                        <Col md={6}>
                          <div className="form-group">
                            <input
                              type="text"
                              name="email"
                              placeholder="Email"
                              value={state.email}
                              onChange={handleChange}
                              className="inputclass form-control"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <input
                              type="text"
                              name="password"
                              placeholder="Password"
                              value={state.passwordr}
                              onChange={handleChange}
                              className="inputclass form-control"
                            />
                          </div>
                        </Col>
                      </Row>
                      <NavLink to="" className="text-decoration-underline d-block mt-4 grey">Forgot password?</NavLink>
                      <Row>
                        <Col md={8} className="square-radius mt-5">
                          <Buttons
                            variant="warning"
                            title="Sign in"
                            type="submit"
                            size="xs"
                            color="white"
                          ></Buttons>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

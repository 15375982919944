import { API_URL } from "../Utilities/Helper";
import { get, post } from "./AxiosService";

// export function getConnectionsList(filter) {
//   return fetch(`${API_URL}JobSeeker/GetJobSeekerConnectionList?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getConnectionsList(filter) {
  return get(`${API_URL}JobSeeker/GetJobSeekerConnectionList?` + filter).then((res) => res.data);
}

// export function getConnectionsResponses() {
//   return fetch(`${API_URL}JobSeeker/JobSeekerConnectionResponses`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getConnectionsResponses() {
  return get(`${API_URL}JobSeeker/JobSeekerConnectionResponses`).then((res) => res.data);
}

// export function getResponsesMessage(jobSeekerConnectionId) {
//   return fetch(
//     `${API_URL}JobSeeker/RespondMessagesData?JobSeekerConnectionId=${jobSeekerConnectionId}`,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getResponsesMessage(jobSeekerConnectionId) {
  return get(
    `${API_URL}JobSeeker/RespondMessagesData?JobSeekerConnectionId=${jobSeekerConnectionId}`
  ).then((res) => res.data);
}

// export function sendResponse(data) {
//   return fetch(`${API_URL}JobSeeker/RespondConnectionList`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function sendResponse(data) {
  return post(`${API_URL}JobSeeker/RespondConnectionList`, data).then((res) => res.data);
}
import { API_URL } from "../Utilities/Helper";
import { get, put } from "./AxiosService";

// export function getNotificationsList(filter) {
//     return fetch(
//         `${API_URL}Notification/GetNotificationForAllUsers${filter}`,
//         {
//             method: "GET",
//             headers: { Authorization: "Bearer " + getData("token") },
//         }
//     ).then((data) => data.json());
// }

export function getNotificationsList(filter) {
    return get(`${API_URL}Notification/GetNotificationForAllUsers${filter}`).then((res) => res.data);
}

// export function readNotification(id) {
//     return fetch(
//         `${API_URL}Notification/InsertNotificationForAllUsers`,
//         {
//             method: "PUT",
//             headers: {
//                 Authorization: "Bearer " + getData("token"),
//                 "Content-type": "application/json",
//             },
//             body: id
//         }
//     ).then((data) => data.json());
// }

export function readNotification(id) {
    const body = { userId: id };
    return put(`${API_URL}Notification/InsertNotificationForAllUsers`, body)
        .then((data) => data);
}

// const handleErrors = (response) => {
//     if (!response.ok) {
//         throw Error(response.statusText);
//     }
//     console.log(response.status);
//     return response;
// };

// export function getNotificationsUnreadCount() {
//     return fetch(
//         `${API_URL}Notification/GetCountForAllUsers`,
//         {
//             method: "GET",
//             headers: { Authorization: "Bearer " + getData("token") },
//         }
//     )
//         // .then(handleErrors)
//         .then((data) => data.json());
// }

export function getNotificationsUnreadCount() {
    return get(`${API_URL}Notification/GetCountForAllUsers`).then((res) => res.data);
}
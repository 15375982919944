import { API_URL } from "../Utilities/Helper";
import { get } from "./AxiosService";

// export function getSearchTypes() {
//   return fetch(`${API_URL}GlobalSearch/GetGlobalSearchType`, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function getSearchTypes() {
  return get(`${API_URL}GlobalSearch/GetGlobalSearchType`).then((res) => res.data);
}

// export function getSearchResult(filter) {
//   return fetch(`${API_URL}GlobalSearch/GetResultSearch?${filter}`, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function getSearchResult(filter) {
  return get(`${API_URL}GlobalSearch/GetResultSearch?${filter}`).then((res) => res.data);
}
import { API_URL } from "../Utilities/Helper";
import { get, post } from "./AxiosService";

export function getResumesList() {
  return fetch(
    "https://my-json-server.typicode.com/bitsnitinpatil/demo/NewResumes"
  ).then((data) => data.json());
}

// export function getPublicResumes(filter) {
//   return fetch(`${API_URL}JINTemplate/GetPublicResumeCardsList${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getPublicResumes(filter) {
  return get(API_URL + "JINTemplate/GetPublicResumeCardsList" + filter).then(
    (res) => JSON.parse(res.data.response)
  );
}

export function getPublicResumeListForEmpDashboard(filter) {
  return get(
    API_URL + "JINTemplate/GetPublicResumeListForDashboard" + filter
  ).then((res) => JSON.parse(res.data.response));
}

// export function getJobSeekerPrivateResumeList(filter) {
//   return fetch(`${API_URL}Profile/GetJobSeekerPrivateResumesList${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeekerPrivateResumeList(filter) {
  return get(`${API_URL}Profile/GetJobSeekerPrivateResumesList${filter}`).then(
    (res) => res.data
  );
}

// export function getJobSeekerPublicResumeList(filter) {
//   return fetch(`${API_URL}JINTemplate/GetJobSeekerPublicResumesList${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeekerPublicResumeList(filter) {
  return get(
    `${API_URL}JINTemplate/GetJobSeekerPublicResumesList${filter}`
  ).then((res) => res.data);
}

// export function getJINTemplateDetails(jinTemplateId) {
//   return fetch(
//     `${API_URL}JINTemplate/GetPublicResumeDetailsById?publicResumeId=` +
//     jinTemplateId,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getJINTemplateDetails(jinTemplateId) {
  return get(
    `${API_URL}JINTemplate/GetPublicResumeDetailsById?publicResumeId=` +
      jinTemplateId
  ).then((res) => res.data);
}

// export function AddOrUpdateJINTemplate(data) {
//   return fetch(`${API_URL}JINTemplate/CreateUpdatePublicResume`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function AddOrUpdateJINTemplate(data) {
  return post(`${API_URL}JINTemplate/CreateUpdatePublicResume`, data).then(
    (res) => res.data
  );
}

// export function uploadPrivateResume(data) {
//   return fetch(`${API_URL}Profile/UploadPrivateResume`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//     body: data,
//   }).then((data) => data.json());
// }

export function uploadPrivateResume(data) {
  return post(`${API_URL}Profile/UploadPrivateResume`, data).then(
    (res) => res.data
  );
}

// export function DownloadByteArray(filename) {
//   return fetch(`${API_URL}Profile/DownloadFile?fileName=${filename}`, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function DownloadByteArray(filename) {
  return get(`${API_URL}Profile/DownloadFile?fileName=${filename}`).then(
    (res) => res.data
  );
}

// export function deletePrivateResume(data) {
//   return fetch(`${API_URL}Profile/DeletePrivateResume`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function deletePrivateResume(data) {
  return post(`${API_URL}Profile/DeletePrivateResume`, data).then(
    (res) => res.data
  );
}

// export function saveResume(JobSeekerJINTemplateId, ResumeName, JINTemplateId) {
//   return fetch(
//     `${API_URL}JINTemplate/SaveResume?JobSeekerJINTemplateId=` +
//     JobSeekerJINTemplateId +
//     "&ResumeName=" +
//     ResumeName +
//     "&JINTemplateId=" +
//     JINTemplateId,
//     {
//       method: "GET",
//       headers: {
//         Authorization: "Bearer " + getData("token"),
//         "Content-Type": "application/json",
//       },
//       // body: JSON.stringify(data),
//     }
//   ).then((data) => data.json());
// }

export function saveResume(JobSeekerJINTemplateId, ResumeName, JINTemplateId) {
  return get(
    `${API_URL}JINTemplate/SaveResume?JobSeekerJINTemplateId=` +
      JobSeekerJINTemplateId +
      "&ResumeName=" +
      ResumeName +
      "&JINTemplateId=" +
      JINTemplateId
  ).then((data) => data);
}

// export function unsaveResume(JobSeekerJINTemplateId) {
//   return fetch(
//     `${API_URL}JINTemplate/UnsavedPublicResume?JobSeekerJINTemplateId=` +
//     JobSeekerJINTemplateId,
//     {
//       method: "GET",
//       headers: {
//         Authorization: "Bearer " + getData("token"),
//         "Content-Type": "application/json",
//       },
//       // body: JSON.stringify(data),
//     }
//   ).then((data) => data.json());
// }

export function unsaveResume(JobSeekerJINTemplateId) {
  return get(
    `${API_URL}JINTemplate/UnsavedPublicResume?JobSeekerJINTemplateId=` +
      JobSeekerJINTemplateId
  ).then((data) => data);
}

// export function saveUnsaveDocument(JobSeekerResumeId) {
//   return fetch(
//     `${API_URL}Profile/SavePrivateResume?JobSeekerResumeId=` +
//     JobSeekerResumeId,
//     {
//       method: "POST",
//       headers: {
//         Authorization: "Bearer " + getData("token"),
//         "Content-Type": "application/json",
//       },
//     }
//   ).then((data) => data.json());
// }

export function saveUnsaveDocument(JobSeekerResumeId) {
  return post(
    `${API_URL}Profile/SavePrivateResume?JobSeekerResumeId=` + JobSeekerResumeId
  ).then((res) => res.data.response);
}

export function getProfanitiesWords() {
  return get(`${API_URL}Profile/GetProfanitiesWords`).then((data) => data);
}

// import React, { useState } from "react";

// function Button(props) {
//   const [size] = useState(props.size);
//   const [variant] = useState(props.variant);
//   const [color] = useState(props.color);
//   const [mtop] = useState(props.mtop);

//   return (
//     <button className={`btn btn-${variant} btn-${size} ${color} ${mtop}`}>{props.children}</button>
//   );
// }

// export default Button;


import React from 'react';
import Button from 'react-bootstrap/Button';
import classNames from "classnames";

export default class Buttons extends React.Component{
  constructor(props){
      super(props);

      this.state = {

      }
  }

  render(){
      return(
        <Button className={classNames(this.props.color, this.props.marginLeft)} size={ this.props.size} variant={this.props.variant} type={this.props.type} onClick={this.props.onClick} disabled={this.props.disabled} tooltip={this.props.tooltip}>
      <span className={classNames(this.props.icon)}></span>  {this.props.title}
    </Button>
      )
  }
}
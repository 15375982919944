import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import profile from "../assests/img/profile.png";
import {
  Button,
  Modal,
  Form,
  Toast,
  Alert,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import Buttons from "./Shared/Button";
import SpinnerLoader from "./SpinnerLoader";

export default function GroupListing({ groups = [], showSpinner }) {
  const [showConfirmModal, setshowConfirmModal] = useState({
    title: "",
    show: false,
  });

  const [userData, setUserData] = useState({ id: null, status: null });

  const renderActiveTooltip = (props) => <Tooltip {...props}>Active</Tooltip>;

  const renderBlockTooltip = (props) => <Tooltip {...props}>Block</Tooltip>;

  const onOpenModalHandler = (jobSeekerId, status) => {
    setUserData({ id: jobSeekerId, status: status });
    if (status === true) {
      setshowConfirmModal({
        title: "Are you sure you want to block this support team member?",
        show: true,
      });
    } else {
      setshowConfirmModal({
        title: "Are you sure you want to activate this support team member?",
        show: true,
      });
    }
  };

  const onCloseModalHandler = () => {
    setshowConfirmModal({ title: "", show: false });
    setUserData({ id: null, status: null });
  };

  const onChangeStatusHandler = () => {
    // onChangeStatus(userData);
    onCloseModalHandler();
  };

  return (
    <React.Fragment>
      <ul className="job-list support-team-list">
        {/* <p>Jobs</p> */}
        <li className="headings">
          <div className="company pr-3 g-width">Group Name </div>
          <div className="email pr-3 count-width">Count </div>
          {/* <div className="position">Position </div>
          <div className="company">Company </div>
          <div className="phone">City </div>
          <div className="subcat">Sub Category </div>
          <div className="statust">Status </div>
          <div className="text-right status">Action </div> */}
        </li>
        {showSpinner && <SpinnerLoader />}
        {groups.length === 0 && !showSpinner && (
          <h5 style={{ textAlign: "center" }}>No Data Found</h5>
        )}
        {groups.map((item, index) => {
          if (item.groupName === "To Remove") return null;
          return (
            <li key={item.supportTeamMemberId} className="align-items-center">
              <div className="name g-width">
                {/* <Link
                to={`${process.env.PUBLIC_URL}/groupleader/supportteammembersdetails/${item.supportTeamMemberId}`}
              > */}
                {item.groupName}
                {/* <span
                  className="job-post-date ml-auto hide-on-list pt-0"
                  style={{ color: "#7b7777" }}
                >
                  {item.email}
                </span> */}
                {/* </Link> */}
              </div>
              <div className="job-post-date ml-auto hide-on-list pt-0 pr-3 count-width">
                {item.supportTeamMemberCount != null
                  ? item.supportTeamMemberCount
                  : item.jobSeekerCount}
              </div>
              <div className="email pr-3 break-word count-width">
                {" "}
                {item.supportTeamMemberCount != null
                  ? item.supportTeamMemberCount
                  : item.jobSeekerCount}
              </div>
              {/*  <div className="position">{item.position}</div>
            <div className="company">{item.companyName}</div>
            <div className="phone">{item.city}</div>
            <div className="subcat">{item.groupCategoryName}</div>
            <div className="statust">
              {item.isActive ? "Active" : "InActive"}
            </div>
            <div className="seeker-action-btns text-right status">
              {item.isActive === false && (
                // {network.status}
                <OverlayTrigger placement="top" overlay={renderActiveTooltip}>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() =>
                      onOpenModalHandler(
                        item.supportTeamMemberId,
                        item.isActive
                      )
                    }
                  >
                    <span className="icon-check mr-1"></span> Active
                  </Button>
                </OverlayTrigger>
              )}

              {item.isActive === true && (
                <OverlayTrigger placement="top" overlay={renderBlockTooltip}>
                  <Button
                    variant="danger"
                    size="sm"
                    className="ml-0"
                    onClick={() =>
                      onOpenModalHandler(
                        item.supportTeamMemberId,
                        item.isActive
                      )
                    }
                  >
                    <span className="icon-block mr-1"></span>Deactive
                  </Button>
                </OverlayTrigger>
              )}
            </div> */}
            </li>
          );
        })}
      </ul>

      {/* Active confirm */}

      <Modal
        show={showConfirmModal.show}
        onHide={onCloseModalHandler}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={onCloseModalHandler}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">{showConfirmModal.title}</div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="Ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                onChangeStatusHandler();
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="Cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                onCloseModalHandler();
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>

      {/* block confirm */}

      {/* <Modal
        show={showblock}
        onHide={handleCloseBlock}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleCloseBlock}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            Are you sure you want to block this job seeker?
          </div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                setShowb(true);
                setShowblock(false);
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                setShowb(true);
                setShowblock(false);
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>
            */}
    </React.Fragment>
  );
}

const intial = {
  jobs: null,
  messages: null,
  networks: null,
  connections: null,
  publicResumes: null,
  privateResumes: null,
  supports: null,
};

export const GlobalSearchReducer = (state = intial, action) => {
  switch (action.type) {
    case "Job":
      return {
        messages: null,
        networks: null,
        connections: null,
        publicResumes: null,
        privateResumes: null,
        supports: null,
        jobs: {
          items: action.items,
          recordperpage: action.recordperpage,
          totalRecord: action.totalRecord,
          type: "Job",
          SerachText: action.SerachText,
          GlobalSearchTypeId: action.GlobalSearchTypeId,
          searchTypeText: action.searchTypeText,
        },
      };
    case "Message":
      return {
        messages: null,
        networks: null,
        connections: null,
        publicResumes: null,
        privateResumes: null,
        supports: null,
        jobs: null,
        messages: {
          items: action.items,
          recordperpage: action.recordperpage,
          totalRecord: action.totalRecord,
          type: "Message",
          SerachText: action.SerachText,
          GlobalSearchTypeId: action.GlobalSearchTypeId,
          searchTypeText: action.searchTypeText,
        },
      };
    case "Network":
      return {
        messages: null,
        connections: null,
        publicResumes: null,
        privateResumes: null,
        supports: null,
        jobs: null,
        networks: {
          items: action.items,
          recordperpage: action.recordperpage,
          totalRecord: action.totalRecord,
          type: "Network",
          SerachText: action.SerachText,
          GlobalSearchTypeId: action.GlobalSearchTypeId,
          searchTypeText: action.searchTypeText,
        },
      };
    case "Connection":
      return {
        networks: null,
        messages: null,
        publicResumes: null,
        privateResumes: null,
        supports: null,
        jobs: null,
        connections: {
          items: action.items,
          recordperpage: action.recordperpage,
          totalRecord: action.totalRecord,
          type: "Connection",
          SerachText: action.SerachText,
          GlobalSearchTypeId: action.GlobalSearchTypeId,
          searchTypeText: action.searchTypeText,
        },
      };
    case "Public Resume":
      return {
        networks: null,
        messages: null,
        supports: null,
        jobs: null,
        connections: null,
        privateResumes: null,
        publicResumes: {
          items: action.items,
          recordperpage: action.recordperpage,
          totalRecord: action.totalRecord,
          type: "Public Resume",
          SerachText: action.SerachText,
          GlobalSearchTypeId: action.GlobalSearchTypeId,
          searchTypeText: action.searchTypeText,
        },
      };
    case "Private Resume":
      return {
        networks: null,
        messages: null,
        supports: null,
        jobs: null,
        connections: null,
        publicResumes: null,
        privateResumes: {
          items: action.items,
          recordperpage: action.recordperpage,
          totalRecord: action.totalRecord,
          type: "Private Resume",
          SerachText: action.SerachText,
          GlobalSearchTypeId: action.GlobalSearchTypeId,
          searchTypeText: action.searchTypeText,
        },
      };
    case "Support":
      return {
        networks: null,
        messages: null,
        jobs: null,
        publicResumes: null,
        privateResumes: null,
        connections: null,
        supports: {
          items: action.items,
          recordperpage: action.recordperpage,
          totalRecord: action.totalRecord,
          type: "Support",
          SerachText: action.SerachText,
          GlobalSearchTypeId: action.GlobalSearchTypeId,
          searchTypeText: action.searchTypeText,
        },
      };
    case "clear":
      return {
        jobs: null,
        messages: null,
        networks: null,
        connections: null,
        publicResumes: null,
        privateResumes: null,
        supports: null,
      };
    default:
      return state;
  }
};

import axios from "axios";
import { toast } from "react-toastify";
import { loginAPI } from "../Utilities/Helper";
import { getData, setData } from "../Utilities/StorageHelper";
import Logout from "./LogoutService";

export default function RefreshTokenService(originalRequest, instance) {
    return new Promise((resolve, reject) => {
        let old_token = getData("refresh_token");
        let params = new URLSearchParams();
        params.append("client_id", "JIN_spa");
        params.append("grant_type", "refresh_token");
        params.append("scope", "openid offline_access");
        params.append("refresh_token", old_token);
        var options = {
            method: "POST",
            url: loginAPI,
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: params,
        };

        axios
            .request(options)
            .then(async (response) => {
                if (response.status === 200) {
                    const token = response.data.access_token;
                    const refresh_token = response.data.refresh_token;

                    localStorage.removeItem("token");
                    localStorage.removeItem("refresh_token");
                    localStorage.removeItem("expiry_time");

                    setData("token", token);
                    setData("expiry_time", response.data.expires_in);
                    setData("refresh_token", refresh_token);

                    originalRequest.headers['Authorization'] = 'Bearer ' + token;

                    resolve(instance(originalRequest));
                }
            })
            .catch(function (error) {
                console.error("error in catch", error);
                if (error.response.status === 400) {
                    const toastId = "custom_toast";
                    toast.error("Your active session has expired. Please login again.", { toastId: toastId });
                    setTimeout(logout, 4000);
                }
                reject(error);
            });
    });
}

const logout = () => {
    Logout();
    sessionStorage.clear();
    window.location.href = `${process.env.PUBLIC_URL}/#/home`;
};
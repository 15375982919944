import React, { useState, Component } from "react";
import { NavLink } from "react-router-dom";

export default function SupportMenu({ setToggleFalse }) {
  return (
    <ul>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/support/support-dashboard`}
        >
          <span className="icon icon-wysiwyg"></span>
          Dashboard
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/support/jobs`}
        >
          <span className="icon icon-manage_search"></span> Jobs
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/support/sponsorship`}
        >
          <span className="icon icon-people_outline"></span> Sponsorship
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/postjob`}>
          <span className="icon icon-post"></span>
           Post Job
            </NavLink>
      </li> */}

      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/supportConnections`}
        >
          <span className="icon icon-group-add"></span> Connections
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/support/messages`}
        >
          <span className="icon icon-chat_bubble_outline"></span> Messages
        </NavLink>
      </li>
    </ul>
  );
}

// export default Sidebar;

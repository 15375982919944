import React, { useState, useEffect, Component, Fragment } from "react";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { required, Validators } from "../../../Utilities/Validator";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import profile from "../../../assests/img/profile.png";
import {
  approveDeclineSponsershipRequest,
  getSponsershipDetailRecentMessages,
  getSponsershipDetails,
  removeSponsershipRequest,
  saveNotes,
  updateNotification,
} from "../../../Services/SponsershipService";
import { toast } from "react-toastify";
import { Roles } from "../../../Utilities/Helper";
import { FaTimes } from "react-icons/fa";
import { sendUserMessages } from "../../../Services/MessagesService";
import MessageLimit from "../../../Components/Shared/MessageLimit";
import moment from "moment";

export default class SponsershipDetails extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    jobCompany: "",
    // note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  commodo consequat. Duis aute irure dolor in  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    id: 0,
    btnApproveDisable: true,
    btnDeclineDisable: true,
    isJSNetworkWithSTM: null,
    isJSSubmitResume: null,
    isSTMRequestToContact: null,
    sendJobPostingOfJS: null,
    notes: "",
    notesError: null,
    jobSeekerSponsershipRequestId: 0,
    messages: [],
    showSpinnerForMsg: true,
    jinTemplateId: null,
    showConfirmModal: false,
    showSponshorshipCancel: false,
    isDeleted: false,
    textMessage: "",
    profilePhoto: null,
    statusName: null,
  };

  constructor(props) {
    super(props);
    this.state = { isHidden: true, textMessage: "" };
  }

  getSponsershipDetailsHandler(jobSeekerSponsershipRequestId) {
    getSponsershipDetails(jobSeekerSponsershipRequestId)
      .then((res) => {
        const result = JSON.parse(res.response);
        this.setState({
          id: result.jobSeekerId,
          name: result.fullName,
          email: result.email,
          phone: result.phone,
          isJSNetworkWithSTM: result.isJSNetworkWithSTM,
          isJSSubmitResume: result.isJSSubmitResume,
          isSTMRequestToContact: result.isSTMRequestToContact,
          sendJobPostingOfJS: result.sendJobPostingOfJS,
          notes: result.notes === null ? "" : result.notes,
          jobSeekerSponsershipRequestId: result.jobSeekerSponsershipRequestId,
          jinTemplateId: result.jinTemplateId,
          isDeleted: result.isDeleted,
          profilePhoto: result.profilePhoto,
          statusName: result.statusName,
        });
        if (result.statusName === "Pending") {
          this.setState({ btnApproveDisable: false, btnDeclineDisable: false });
        } else {
          if (result.statusName === "Approved") {
            this.setState({
              showSponshorshipCancel: true,
              btnDeclineDisable: true,
              btnApproveDisable: true,
            });
          }
          if (result.statusName === "Declined") {
            this.setState({
              btnApproveDisable: false,
              btnDeclineDisable: true,
              showSponshorshipCancel: false,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response, { toastId: "custom_toast" });
      });
  }

  getSponsershipRecentMsg(jobSeekerSponsershipRequestId) {
    const data = `?JobSeekerSponsershipRequestId=${jobSeekerSponsershipRequestId}&recordperpage=5`;
    getSponsershipDetailRecentMessages(data)
      .then((res) => {
        const result = JSON.parse(res.response);
        this.setState({ messages: result, showSpinnerForMsg: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ showSpinnerForMsg: false });
        toast.error(err.response, { toastId: "custom_toast" });
      });
  }

  componentDidMount() {
    const jobSeekerSponsershipRequestId =
      this.props.match.params.jobSeekerSponsershipRequestId;
    this.setState({
      jobSeekerSponsershipRequestId: jobSeekerSponsershipRequestId,
    });

    this.getSponsershipDetailsHandler(jobSeekerSponsershipRequestId);
    this.getSponsershipRecentMsg(jobSeekerSponsershipRequestId);
  }

  toggleHidden() {
    this.setState({ isHidden: !this.state.isHidden });
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleChangepersonal = (key) => (value) => { };

  onApproveDeclineHandler(status) {
    const data = {
      UserId: this.state.id,
      Approve: status,
      JobSeekerSponsershipRequestId: this.state.jobSeekerSponsershipRequestId,
      JobSeekerName: this.state.name,
    };
    approveDeclineSponsershipRequest(data)
      .then((res) => {
        this.getSponsershipDetailsHandler(
          this.state.jobSeekerSponsershipRequestId
        );
        if (status === true)
          toast.success(res.message, {
            toastId: "custom_toast",
          });
        else toast.error(res.message, { toastId: "custom_toast" });
      })
      .catch((err) => {
        toast.error(err.response, { toastId: "custom_toast" });
      });
  }

  onRemoveContactHandler() {
    const data = {
      JobSeekerSponsershipRequestId: this.state.jobSeekerSponsershipRequestId,
      UserId: this.state.id,
      JobSeekerName: this.state.name,
    };

    removeSponsershipRequest(data)
      .then((res) => {
        toast.error(res.message, {
          toastId: "custom_toast",
        });
        this.props.history.push("/support/sponsorship");
      })
      .catch((err) => {
        toast.error(err.response, { toastId: "custom_toast" });
      });
  }

  onNoteSaveHandler(status) {
    var error = required(this.state.notes);
    if (error.error) {
      this.setState({ notesError: "This field is required" });
      return;
    } else {
      this.setState({ notesError: "" });
      if (status === false) {
        this.setState({ notes: "" });
      }
    }

    const data = {
      JobSeekerSponsershipRequestId: this.state.jobSeekerSponsershipRequestId,
      Notes: status ? this.state.notes : "",
      JobSeekerId: this.state.id,
      Status: status,
    };
    saveNotes(data)
      .then((res) => {
        toast.success(res.message, {
          toastId: "custom_toast",
        });
      })
      .catch((err) => {
        toast.error(err.response, { toastId: "custom_toast" });
      });
  }

  onNotificationChangeHandler(event, value) {
    const data = {};
    data[value] = event.target.checked;
    data.JobSeekerSponsershipRequestId =
      this.state.jobSeekerSponsershipRequestId;
    data.UserId = this.state.id;
    updateNotification(data)
      .then((res) => {
        // toast.success(res.response, { toastId: "custom_toast" });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response, { toastId: "custom_toast" });
      });
  }

  onRemoveContactModalHandler() {
    this.setState({ showConfirmModal: true });
  }

  onCloseModalHandler() {
    this.setState({ showConfirmModal: false });
  }

  onTextMessageChange(e) {
    if (e.target.value.length > 500) {
      return;
    }
    this.setState({ textMessage: e.target.value });
  }

  SendMessage() {
    if (this.state.textMessage.trim() === "") {
      return;
    }
    var data = {
      SenderId: this.state.id,
      Message: this.state.textMessage,
      userType: Roles.jobSeeker,
    };
    sendUserMessages(data)
      .then((res) => {
        toast.success(res.response);
        this.setState({ textMessage: "" });
        this.getSponsershipRecentMsg(this.state.jobSeekerSponsershipRequestId);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {
      name,
      id,
      email,
      phone,
      jobCompany,
      notes = "",
      btnApproveDisable = true,
      btnDeclineDisable = true,
      isJSNetworkWithSTM,
      sendJobPostingOfJS,
      isJSSubmitResume,
      isSTMRequestToContact,
      messages = [],
      showSpinnerForMsg = true,
      jinTemplateId = null,
      notesError,
      showSponshorshipCancel = false,
      isDeleted = false,
      textMessage = "",
      profilePhoto = null,
      statusName,
    } = this.state;
    const { isHidden } = this.state;
    return (
      <div className="container p-0">
        <h1 className="page-title">Sponsorships</h1>
        <div className="dashboard-wrapper">
          {this.state.id && (
            <Fragment>
              {" "}
              <Row>
                <Col md={12}>
                  <div className="dashboard-box dashboard-col-bg pb-5 sponsership-user-detail">
                    {/* class--- float-left */}
                    <Link
                      to={`${process.env.PUBLIC_URL}/support/sponsorship`}
                      className="page-sub-title-with-back"
                    >
                      <AiOutlineArrowLeft />
                      Back to sponsorships
                    </Link>

                    <div className="user-details d-flex align-items-center">
                      <div className="user-avatar">
                        {profilePhoto !== null ? (
                          <img
                            src={`https://data.jin-storage.us-sjo1.upcloudobjects.com/ProfilePhotos/${profilePhoto}`}
                          />
                        ) : (
                          <img src={profile} />
                        )}
                      </div>
                      <div className="user-name">{name}</div>

                      <div className="actions-buttons ml-auto w-55 text-right">
                        <div>
                          {statusName == "Pending" && (
                            <span className="note-text">
                              {name} wishes to network within our organization.
                              Please “Approve or “Decline”. If you approve, please
                              select desired “Notifications”.
                            </span>
                          )}
                        </div>
                        {isDeleted === true && (
                          <span className="cancelled-text">
                            Cancelled sponsorship
                          </span>
                        )}
                        {showSponshorshipCancel && (
                          <Buttons
                            variant="light"
                            title="Cancel Sponsorship"
                            icon="icon icon-trash mr-2"
                            type="button"
                            size="xs"
                            color="white"
                            marginLeft="mr-3"
                            onClick={() => this.onRemoveContactModalHandler()}
                          ></Buttons>
                        )}
                        <Buttons
                          variant="success"
                          title="Approve"
                          icon="icon far fa-check-circle mr-2"
                          type="button"
                          size="xs"
                          color="white"
                          marginLeft="ml-0"
                          disabled={btnApproveDisable}
                          onClick={() => this.onApproveDeclineHandler(true)}
                        ></Buttons>
                        <Buttons
                          variant="danger"
                          title="Decline"
                          icon="icon far fa-times-circle mr-2"
                          type="button"
                          size="xs"
                          color="white"
                          marginLeft="ml-3"
                          disabled={btnDeclineDisable}
                          onClick={() => this.onApproveDeclineHandler(false)}
                        ></Buttons>
                      </div>
                    </div>
                    <div className="users-wrap users-bg">
                      <div className="users-box left">
                        <Form>
                          <FormInput
                            value={email}
                            type="text"
                            placeholder="Not available"
                            required="required"
                            label="Email"
                            disabled={true}
                            ic
                            onChange={this.handleChangepersonal("email")}
                          />
                          <FormInput
                            value={phone}
                            type="text"
                            disabled={true}
                            placeholder="Not available"
                            required="required"
                            label="Phone"
                            onChange={this.handleChangepersonal("phone")}
                          />
                        </Form>
                        {jinTemplateId !== null && (
                          <div className="mt-4">
                            <Link
                              to={`${process.env.PUBLIC_URL}/support/resumedetails/${jinTemplateId}`}
                            >
                              Resume
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="users-box right">
                        <div className="notification">
                          <h6 className="sub-title mb-4">Notification</h6>
                          <div className="custom-control custom-checkbox my-1">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              defaultChecked={isJSSubmitResume}
                              onClick={(e) =>
                                this.onNotificationChangeHandler(
                                  e,
                                  "isJSSubmitResume"
                                )
                              }
                            ></input>
                            <label className="custom-control-label">
                              Alert Sponsor when job seeker submits resume to a job.
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox my-1">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              defaultChecked={isJSNetworkWithSTM}
                              onClick={(e) =>
                                this.onNotificationChangeHandler(
                                  e,
                                  "isJSNetworkWithSTM"
                                )
                              }
                            ></input>
                            <label className="custom-control-label">
                              Alert Sponsor when job seeker networks with support
                              team members.
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox my-1">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              defaultChecked={isSTMRequestToContact}
                              onClick={(e) =>
                                this.onNotificationChangeHandler(
                                  e,
                                  "isSTMRequestToContact"
                                )
                              }
                            ></input>
                            <label className="custom-control-label">
                              Notify Sponsor when Support Team member requests
                              contact.
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox my-1">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              defaultChecked={sendJobPostingOfJS}
                              onClick={(e) =>
                                this.onNotificationChangeHandler(
                                  e,
                                  "sendJobPostingOfJS"
                                )
                              }
                            ></input>
                            <label className="custom-control-label">
                              Send job postings in the job seeker's chosen job
                              categories.
                            </label>
                          </div>
                        </div>
                        <div className="note note-bg mt-4">
                          <div className="note-heading">
                            <h4>
                              Notes <span className="fa fa-pen"></span>
                            </h4>
                          </div>
                          <div>
                            <FormInput
                              style={{
                                border: "1px solid #c1bebe",
                                borderRadius: "4px",
                              }}
                              value={notes}
                              type="textarea"
                              placeholder="you can add your note here"
                              required="required"
                              // icon="fas fa-pen"
                              //withIcon="with-icon"
                              inputerror={notesError}
                              className="px-0 mb-0"
                              onChange={this.handleChange("notes")}
                            />
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Buttons
                              variant="secondary mr-3"
                              title="Save"
                              //icon="icon far fa-times-circle mr-2"
                              type="button"
                              size="xs"
                              color="black"
                              border
                              onClick={() => this.onNoteSaveHandler(true)}
                            ></Buttons>
                            <Buttons
                              variant="secondary"
                              title="Reset"
                              //icon="icon far fa-times-circle mr-2"
                              type="button"
                              size="xs"
                              color="black"
                              border
                              onClick={() => this.onNoteSaveHandler(false)}
                            ></Buttons>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!isHidden && (
                      <div className="detail-view mt-3 resume-content">
                        <h2>Stephen Jackson</h2>
                        <p>
                          Boston Common, Boston, MA, 02116, USA
                          <span className="dot">.</span>
                          <span className="r-title">Email: </span>
                          <span className="r-text">stephe.jack@gmail.com</span>
                          {/* <span className="dot">.</span>
                      <span className="r-title">Telephone: </span>
                      <span className="r-text">203.555.7813</span> */}
                        </p>
                        <hr />
                        <h3>Career Objective</h3>
                        <p className="mb-4">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                          sed do eiusmod tempor incididunt ut labore et dolore magna
                          aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                          ullamco laboris nisi ut aliquip ex ea commodo consequat.
                          Duis aute irure dolor in reprehenderit in voluptate velit
                          esse cillum dolore eu fugiat nulla pariatur. Excepteur
                          sint occaecat cupidatat non proident, sunt in culpa qui
                          officia deserunt mollit anim id est laborum.{" "}
                        </p>
                        <hr />
                        <h3>Core Competencies</h3>
                        <ul className="points-2">
                          <li>Customer Service</li>
                          <li>Deatsil & Orgnised</li>
                          <li>Cost Efficient</li>
                          <li>Supplier Relationship</li>
                        </ul>
                        <hr />
                        <div>
                          <h3>Professional Experience</h3>
                          <div className="company-text">
                            Google LLP, New York, NY.
                          </div>
                          <div className="exp-text">
                            Assistant Manager, August 2020 - Present
                          </div>
                          <ul className="d-block  points">
                            <li>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore et
                              dolore magna aliqua. Ut enim ad minim veniam, quis
                              nostrud exercitation ullamco laboris nisi ut aliquip
                              ex ea commodo consequat.
                            </li>
                            <li>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore et
                              dolore magna aliqua. Ut enim ad minim veniam, quis
                              nostrud exercitation ullamco laboris nisi ut aliquip
                              ex ea commodo consequat.
                            </li>
                            <li>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore et
                              dolore magna aliqua. Ut enim ad minim veniam, quis
                              nostrud exercitation ullamco laboris nisi ut aliquip
                              ex ea commodo consequat.
                            </li>
                            <li>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore et
                              dolore magna aliqua. Ut enim ad minim veniam, quis
                              nostrud exercitation ullamco laboris nisi ut aliquip
                              ex ea commodo consequat.
                            </li>
                          </ul>

                          <div className="company-text">
                            Microsoft, New York, NY.
                          </div>
                          <div className="exp-text">
                            Business Analyst, May 2018 - August 2020
                          </div>
                          <ul className="d-block  points">
                            <li>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore et
                              dolore magna aliqua. Ut enim ad minim veniam, quis
                              nostrud exercitation ullamco laboris nisi ut aliquip
                              ex ea commodo consequat.
                            </li>
                            <li>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore et
                              dolore magna aliqua. Ut enim ad minim veniam, quis
                              nostrud exercitation ullamco laboris nisi ut aliquip
                              ex ea commodo consequat.
                            </li>
                            <li>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore et
                              dolore magna aliqua. Ut enim ad minim veniam, quis
                              nostrud exercitation ullamco laboris nisi ut aliquip
                              ex ea commodo consequat.
                            </li>
                            <li>
                              {" "}
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore et
                              dolore magna aliqua. Ut enim ad minim veniam, quis
                              nostrud exercitation ullamco laboris nisi ut aliquip
                              ex ea commodo consequat.
                            </li>
                          </ul>
                        </div>

                        <div>
                          <hr />
                          <h3>Education</h3>
                          <div className="company-text">
                            Florida State University, Orlands, FL.
                          </div>
                          <div className="exp-text">
                            Bachlor of Arts, August 2014.
                          </div>
                        </div>
                        <hr />
                        <div>
                          <h3>Additional Skills</h3>
                          <ul className="d-block points">
                            <li>Proficiant in Web Development</li>
                            <li>Certification in Agile</li>
                            <li>Language know Spanish, Germen</li>
                          </ul>
                        </div>

                        <hr />
                        <div>
                          <h3>Awards & Honors</h3>
                          <ul className="d-block points">
                            <li>Excellence Award 2020</li>
                            <li>Most Efficient Employee Award 2019</li>
                            <li>Best Employee Award 2020</li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {messages.length > 0 && (
                      <div className="recent-communication mt-5 clearfix">
                        <h3>Recent communication</h3>
                        <ul className="job-list">
                          {/* <li>
                      {showSpinnerForMsg && <SpinnerLoader />}
                      {messages.length === 0 && !showSpinnerForMsg && (
                        <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                      )}
                    </li> */}
                          {messages.map((item, index) => (
                            <Fragment key={index}>
                              {item.userType === Roles.jobSeeker && (
                                <li>
                                  <div className="message-box other">
                                    <div className="avatar">
                                      {item.profilePhoto !== null ? (
                                        <img
                                          src={`https://data.jin-storage.us-sjo1.upcloudobjects.com/ProfilePhotos/${item.profilePhoto}`}
                                        />
                                      ) : (
                                        <img src={profile} />
                                      )}
                                    </div>
                                    <div className="message-details">
                                      <h4>{item.fullName}
                                        <span className="communication-date ml-auto">
                                          {Date.parse(
                                            moment(item.createdDate)
                                              .format("Y-MM-DD")
                                              .toString()
                                          ) <
                                            Date.parse(
                                              moment(Date.now())
                                                .format("Y-MM-DD")
                                                .toString()
                                            )
                                            ? moment(
                                              new Date(
                                                item.createdDate.toString() + "Z"
                                              ).toString()
                                            ).format("llll")
                                            : moment(
                                              new Date(
                                                item.createdDate.toString() + "Z"
                                              ).toString()
                                            ).format("LT")}
                                        </span>
                                      </h4>
                                      {/* <h4>new job posting</h4> */}
                                      {/* {item.jobSeekerSponshirShipMsg.length >
                                    100 && ( */}
                                      <p className="mb-0">
                                        {item.jobSeekerSponshirShipMsg}

                                        {/* <Link
                                      to={`${process.env.PUBLIC_URL}/support/messages`}
                                    >
                                      READ MORE
                                    </Link> */}
                                      </p>
                                      {/* )} */}
                                      {/* {item.jobSeekerSponshirShipMsg.length <
                                    100 && (
                                    <p className="mb-0">
                                      {item.jobSeekerSponshirShipMsg}
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/support/messages`}
                                      >
                                        READ MORE
                                      </Link>
                                    </p>
                                  )} */}
                                    </div>
                                  </div>
                                </li>
                              )}
                              {item.userType === Roles.stm && (
                                <li>
                                  <div className="message-box me">
                                    <div className="avatar">
                                      <img src={profile} />
                                    </div>
                                    <div className="message-details">
                                      <h4>me
                                        <span className="communication-date ml-auto">
                                          {Date.parse(
                                            moment(item.createdDate)
                                              .format("Y-MM-DD")
                                              .toString()
                                          ) <
                                            Date.parse(
                                              moment(Date.now())
                                                .format("Y-MM-DD")
                                                .toString()
                                            )
                                            ? moment(
                                              new Date(
                                                item.createdDate.toString() + "Z"
                                              ).toString()
                                            ).format("llll")
                                            : moment(
                                              new Date(
                                                item.createdDate.toString() + "Z"
                                              ).toString()
                                            ).format("LT")}
                                        </span>
                                      </h4>
                                      {/* <h4>new job posting</h4> */}

                                      <p className="mb-0">
                                        {item.supportTeamMemberMsg}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Fragment>
                          ))}
                        </ul>
                      </div>
                    )}
                    {statusName == "Approved" && (
                      <Fragment>
                        {" "}
                        <div className="mb-5">
                          <textarea
                            rows={"3"}
                            value={textMessage}
                            maxLength={500}
                            className="form-control"
                            onChange={(e) => {
                              this.onTextMessageChange(e);
                            }}
                          />
                          <MessageLimit
                            currentLength={textMessage}
                            totalLength={500}
                          />
                        </div>
                        <div className="text-right">
                          {/* <Link
                    to={`${process.env.PUBLIC_URL}/support/messages`}
                    className="text-decoration-none"
                  > */}
                          <Buttons
                            variant="warning"
                            title="Send message"
                            icon="icon icon-chat_bubble_outline mr-2"
                            type="button"
                            size="xs"
                            color="white"
                            onClick={(e) => this.SendMessage(e)}
                          ></Buttons>
                          {/* </Link> */}
                        </div>
                      </Fragment>
                    )}
                    {/* {messages.length !== 0 && (
                  <div className="mt-3">
                    <Link
                      to={`${process.env.PUBLIC_URL}/support/messages`}
                      className="text-decoration-none"
                    >
                      <Buttons
                        variant="warning"
                        title="View more"
                        type="submit"
                        size="xs"
                        color="white"
                      ></Buttons>
                    </Link>
                  </div>
                )} */}
                  </div>
                </Col>
              </Row></Fragment>)}{" "}
        </div>

        <Modal
          show={this.state.showConfirmModal}
          onHide={(e) => this.onCloseModalHandler(e)}
          centered
          className="confirm-modal"
        >
          <div className="message-container custome-msg-container">
            <div className="message-header-box">
              <h4>Confirm</h4>
              <div className="messages-modal-actions">
                <Button
                  className="btn"
                  onClick={(e) => this.onCloseModalHandler(e)}
                >
                  <FaTimes />
                </Button>
              </div>
            </div>
            <div className="message-body">
              Are you sure you want to cancel the sponsorship?
            </div>
            <div className="message-footer pt-0">
              <Buttons
                variant="primary"
                title="Ok"
                type="submit"
                size="xs"
                color="white"
                onClick={(e) => {
                  this.onRemoveContactHandler(e);
                }}
              ></Buttons>
              <Buttons
                variant="secondary"
                title="Cancel"
                type="submit"
                size="xs"
                color="white"
                onClick={(e) => {
                  this.onCloseModalHandler(e);
                }}
              ></Buttons>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import FormInput from "../../../Components/Shared/FormInput";
import { Spinner } from "react-bootstrap";
import {
  getDemoDatabaseBackups,
  putBackupDemoDatabase,
} from "../../../Services/DemoServices";
import { ConformationModal } from "../../../Components/ConformationModal";
import { toast } from "react-toastify";

export const AdminDemoSave = () => {
  const history = useHistory();

  const [showLoader, setShowLoader] = useState(false);
  const [saveDemoAsName, setSaveDemoAsName] = useState("");
  const [savedDemos, setSavedDemos] = useState([]);
  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);

  useEffect(() => {
    getDemoDatabaseBackups()
      .then((res) => {
        var result = JSON.parse(res.response);
        result.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        setSavedDemos(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveDemo = () => {
    setShowOverwriteWarning(false);

    // Validate the name
    const badChars = ["<", ">", ":", "/", "\\", "|", "?", "*", "&", '"', "'"];
    var name = saveDemoAsName;
    for (let i = 0; i < badChars.length; i++) {
      name = name.replaceAll(badChars[i], "");
    }
    name = name.trim();
    if (name.length === 0) {
      toast.error("Demo site name cannot be empty.");
      setTimeout(() => setShowLoader(false), 3000);
      return;
    }

    putBackupDemoDatabase({ name: name.trim() })
      .then((res) => {
        toast.success("Demo site saved successfully.");
        setSaveDemoAsName("");
        // Redirect to the demo tools page
        setTimeout(() => {
          setShowLoader(false);
          history.push("/admin/demotools");
        }, 2000);
      })
      .catch((err) => {
        toast.error("Unable to save demo site.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const handleUserClickedSave = () => {
    setShowLoader(true);

    // Check if name is already in use
    if (
      savedDemos.some(
        (x) => x.name.toLocaleLowerCase() === saveDemoAsName.toLocaleLowerCase()
      )
    ) {
      setShowOverwriteWarning(true);
      return;
    }

    saveDemo();
  };

  return (
    <>
      <Container>
        <h1 className="page-title">Save The Current Demo</h1>
        <div className="dashboard-wrapper">
          <Row className="justify-content-md-center">
            <Col sm={12} md={6} className="mt-0">
              <div className="dashboard-box dashboard-col-bg mb-4">
                <h2 className="sub-form-title mb-6 d-flex">Save Demo</h2>
                <p className="font-weight-bold m-0">
                  Saving the current demo database will allow you to:
                </p>
                <ls>
                  <li>Restore the demo exactly as it currently is.</li>
                  <li>
                    Use it as a base when creating a new demo - where you can
                    overwrite select details such as Group Name and Locations
                    but use the rest of the demo as it currently is.
                  </li>
                </ls>

                <p className="mt-4 mb-0 font-weight-bold">
                  Enter a short but descriptive name to save the current demo
                  as:
                </p>

                <Row>
                  <Col md={12}>
                    <Form.Group controlId="dd">
                      <FormInput
                        name="saveDemoAsName"
                        value={saveDemoAsName}
                        type="text"
                        placeholder="Name"
                        maxLength={50}
                        required="required"
                        onChange={(e) => {
                          if (showLoader) return;

                          setSaveDemoAsName(e);
                        }}
                      />
                    </Form.Group>
                    <p className="m-0">
                      <span className="font-weight-bold">
                        Good Example Names:{" "}
                      </span>
                      'Demo For Salt Lake City April 8th 2024' or 'Main Industry
                      set as Legal'
                    </p>
                    <p className="font-italic mt-0">
                      Max length should be under 50 characters
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-4">
                    <Button
                      type="button"
                      className="btn btn-danger"
                      disabled={saveDemoAsName.trim().length <= 0 || showLoader}
                      onClick={() => {
                        handleUserClickedSave();
                      }}
                    >
                      {showLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <ConformationModal
        show={showOverwriteWarning}
        onHide={() => setShowOverwriteWarning(false)}
        onPrimary={() => saveDemo()}
        onSecondary={() => {
          setShowOverwriteWarning(false);
          setShowLoader(false);
        }}
        showSecondary={true}
        primaryText="Overwrite Old Save"
        secondaryText="Cancel"
        title="Warning"
        bodyText="A demo with this name already exists. Do you want to overwrite it?"
      />
    </>
  );
};

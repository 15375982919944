import { API_URL } from "../Utilities/Helper";
import { get, post } from "./AxiosService";

// export function addAbuseReport(data) {
//   return fetch(`${API_URL}user/createabusereport`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function addAbuseReport(data) {
  return post(`${API_URL}user/createabusereport`, data).then((res) => res.data);
}

// export function getReportAbuseList(filter) {
//   return fetch(`${API_URL}user/abusereports?` + filter, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function getReportAbuseList(filter) {
  return get(`${API_URL}user/abusereports?` + filter).then((res) => res.data);
}

// export function getReportAbuse(abuseReportId) {
//   return fetch(`${API_URL}user/abusereport/` + abuseReportId, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function getReportAbuse(abuseReportId) {
  return get(`${API_URL}user/abusereport/` + abuseReportId).then((res) => res.data);
}

// export function addReportAbuseComment(data) {
//   return fetch(`${API_URL}user/abusereportcomment`, {
//     method: "post",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function addReportAbuseComment(data) {
  return post(`${API_URL}user/abusereportcomment`, data).then((res) => res.data);
}
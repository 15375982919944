import React, { useState, useEffect } from "react";
import { Col, Row, Form, Badge } from "react-bootstrap";

import ListPagination from "../../../Components/Pagination";

import GroupLeaderJobsList from "../../../Components/GroupLeaderJobsList";
import { getAllJobs } from "../../../Services/AllJobsService";
import { getIndustry, getUserDetails } from "../../../Services/GetFields";
import {
  groupLeaderJobsPerPage,
  sortParamsForJobJS,
} from "../../../Utilities/Helper";
import SearchJob from "../../../Components/SearchJob";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import { toast } from "react-toastify";
import { getJobSeekerForSTM } from "../../../Services/JobSeekersService";

export default function SupportJobs() {
  const [jobs, setJobs] = useState([]);
  const [userIndustries, setUserIndustries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [jobSeekers, setJobSeekers] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [searchParams, setSearchParams] = useState("");
  const [totalJobs, setTotalJobs] = useState();
  const [refreshTable, setRefreshTable] = useState(true);
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [showPagination, setShowPagination] = useState(true);

  useEffect(() => {
    async function allJobs() {
      setShowPagination(true);
      const filter =
        "recordperpage=" +
        groupLeaderJobsPerPage +
        currentPage.filter +
        searchParams +
        sort.filter;
      getAllJobs(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobs(result.jobs);
          setTotalJobs(result.totalRecord);
          setShowSpinner(false);
        })
        .catch((err) => {
          console.log("Jobs: ", err);
          setShowSpinner(false);
        });
    }
    allJobs();
  }, [refreshTable]);

  useEffect(() => {
    async function allIndustries() {
      getIndustry()
        .then((res) => {
          const result = JSON.parse(res.response);
          setIndustries(result);
        })
        .catch((err) => {
          console.log("Industry: ", err);
        });
    }
    async function allJobSeekers() {
      getJobSeekerForSTM()
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobSeekers(result);
        })
        .catch((err) => {
          console.log("JobSeekers: ", err);
        });
    }
    allIndustries();
    allJobSeekers();
  }, []);

  useEffect(() => {
    getUserDetails()
      .then((res) => {
        const result = res.response;
        const industries = result.industries;
        if (industries.length > 0) {
          var value = "";
          industries.forEach((e) => {
            value += " " + e.industryName + ",";
          });
          setUserIndustries(
            "Default Filter is your selected industries: " +
            value.substr(0, value.length - 1)
          );
        } else {
          setUserIndustries("Default Filter is your selected industries: N/A");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ ...prev, filter: `&page=${page}` }));
      refreshTableHandler();
    }
  };

  const refreshTableHandler = () => {
    setRefreshTable((prev) => !prev);
    setShowSpinner(true);
  };

  const onSerchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  const sortingChangeHandler = (e) => {
    var val = e.target.value;
    setSort((prev) => ({ ...prev, filter: `&sort=${val}` }));
    refreshTableHandler();
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Jobs{" "}
        <Badge
          pill
          bg="danger"
          className="bg-warning text-white px-3 ml-3 font-weight-normal"
        >
          {totalJobs}
        </Badge>
      </h1>
      <div className="dashboard-wrapper resumes-page">
        {/* <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg mb-4 saved-resume  hide-btns hide-heart-icon">
              <h2 className="page-sub-title">Drafts</h2>
             
              <EmployerJobsList jobslist={AllJobs}/>
              <ListPagination/>
            </div>
          </Col>
          </Row>  */}
        <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg hide-draft">
              <div className="search">
                <SearchJob
                  industriesList={industries}
                  jobSeekerList={jobSeekers}
                  searchParam={onSerchParamHandler}
                  stmindustries={userIndustries}
                />
              </div>

              <div className="sort">
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="w-25"
                >
                  {" "}
                  <label>
                    <span>Sort By:</span>
                  </label>
                  <Form.Control
                    as="select"
                    className="inputclass form-control"
                    onChange={sortingChangeHandler}
                  >
                    {sortParamsForJobJS.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              {showSpinner && <SpinnerLoader />}
              {jobs.length === 0 && !showSpinner && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              <GroupLeaderJobsList jobList={jobs} />
              {jobs.length !== 0 && showPagination && (
                <ListPagination
                  recordPerPage={groupLeaderJobsPerPage}
                  TotalRecord={totalJobs}
                  className="mt-3"
                  onPageChange={pageChangeHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default Jobs;

import React, { useState, useEffect, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllJobSeekers } from "../../../Services/LeaderJobSeeker";
import SupportTeamList from "../../../Components/SupportTeamList";
import { getAllSupportTeamMembers } from "../../../Services/LeaderSupportTeamMember";
import JobSeekersList from "../../../Components/JobSeekersList";
import {
  groupLeaderRecentJobsPerPage,
  groupLeaderRecentJSPerPage,
} from "../../../Utilities/Helper";
import { getAllJobs } from "../../../Services/AllJobsService";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import moment from "moment";
import { toast } from "react-toastify";
import { getUserDetailsAPI } from "../../../Utilities/APIHelper";
import { get } from "../../../Services/AxiosService";

export default function GroupLeaderDashboard() {
  const [jobSeekers, setJobSeekers] = useState([]);
  const [supportTeamMemebers, setSupportTeamMemebers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [showSpinnerForJS, setShowSpinnerForJS] = useState(true);
  const [showSpinnerForSTM, setShowSpinnerForSTM] = useState(true);
  const [showSpinnerForJobs, setShowSpinnerForJobs] = useState(true);
  const [name, setName] = useState("");

  // const [NewJobs, setNewJobs] = useState([]);

  // const [SupportTeam, setSupportTeam] = useState([]);

  //getting recent activity jobs list

  useEffect(() => {
    async function jobSeeksersListData() {
      getAllJobSeekers("recordperpage=" + groupLeaderRecentJSPerPage)
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobSeekers(result.jobSeekers);
          setShowSpinnerForJS(false);
        })
        .catch((err) => {
          console.log("JobSeeker: ", err);
          setShowSpinnerForJS(false);
        });
    }
    jobSeeksersListData();
  }, []);

  useEffect(() => {
    async function jobsData() {
      const filter = "recordperpage=" + groupLeaderRecentJobsPerPage;
      getAllJobs(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobs(result.jobs);
          setShowSpinnerForJobs(false);
        })
        .catch((err) => {
          console.log("Jobs: ", err);
          setShowSpinnerForJobs(false);
        });
    }
    jobsData();
  }, []);

  // getting Sponsership list
  useEffect(() => {
    async function supportTeamMemberListData() {
      const filter = "recordperpage=" + groupLeaderRecentJSPerPage;
      getAllSupportTeamMembers(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setSupportTeamMemebers(result.supportTeamMembers);
          setShowSpinnerForSTM(false);
        })
        .catch((err) => {
          console.log("Sponsorship: ", err);
          setShowSpinnerForSTM(false);
        });
    }
    supportTeamMemberListData();
  }, []);

  useEffect(() => {
    async function getName() {
      get(getUserDetailsAPI)
        .then((res) => {
          // console.log(res.data.response);
          let response = res.data.response;
          setName(response.firstName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getName();
  }, []);

  return (
    <React.Fragment>
      <div className="container p-0">
        <h1 className="page-title">
          Hello {name}{" "}
          <small>(Group leader)</small>
        </h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={6}>
              {/* <div className="dashboard-box resume dashboard-col-bg">
              <h2 className="page-sub-title">New Job Posting</h2>              
              <NewJobsPostingList posts={NewJobs.slice(0, 3)}/>  
              <Link to={`${process.env.PUBLIC_URL}/groupleader/groupleaderdashboard`} className="btn btn-warning btn-xs white mt-3">
              <span class="icon icon-design mr-2"></span> view all job posting
              </Link>
            </div> */}

              <div className="dashboard-box resume dashboard-col-bg sponser-dashboard-col hide-from-dashboard job-seeker-dashboard-list">
                <h2 className="page-sub-title">Job Seekers</h2>
                <JobSeekersList
                  jobSeekersList={jobSeekers}
                  showSpinner={showSpinnerForJS}
                />
                <Link
                  className="btn btn-warning btn-xs white mt-3"
                  to={`${process.env.PUBLIC_URL}/groupleader/jobseekers`}
                >
                  <span className="icon icon-job-seeker mr-2"> </span> View all
                  job seekers
                </Link>
              </div>

              <div className="dashboard-box resume dashboard-col-bg sponser-dashboard-col hide-from-dashboard mt-5 support-team-dashboard-list">
                <h2 className="page-sub-title">Support Team Members</h2>
                <SupportTeamList
                  supportTeamMembers={supportTeamMemebers}
                  showSpinner={showSpinnerForSTM}
                />
                <Link
                  className="btn btn-warning btn-xs white mt-3"
                  to={`${process.env.PUBLIC_URL}/groupleader/supportteammembers`}
                >
                  <span className="icon icon-support-members mr-2"></span> View
                  all Support Team Members{" "}
                </Link>
              </div>
            </Col>

            <Col md={6}>
              <div className="dashboard-box resume dashboard-col-bg sponser-dashboard-col hide-from-dashboard">
                <h2 className="page-sub-title">New Job Posting</h2>
                <ul className="resume-list job-list">
                  {showSpinnerForJobs && <SpinnerLoader />}
                  {jobs.length === 0 && !showSpinnerForJobs && (
                    <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                  )}
                  {jobs.map((item, index) => (
                    <li key={item.jobId}>
                      <div className="resume-details">
                        <Link
                          to={`${process.env.PUBLIC_URL}/groupleader/groupleaderjobdetails/${item.jobId}`}
                        >
                          <h4>{item.positionTitle}</h4>
                        </Link>
                        <h5>{item.companyName}</h5>
                        <div className="date-location">
                          Posted :{" "}
                          <span className="text">
                            {/* {new Date(item.jobStartDate).toDateString()} */}
                            {moment(item.jobStartDate).format("MMMM DD, Y")}
                          </span>
                        </div>
                        <div className="date-location">
                          Location :{" "}
                          <span className="text">
                            {item.city === null || item.city === ""
                              ? "-"
                              : item.city + ", " + item.stateName === null ||
                                item.stateName === ""
                                ? "-"
                                : item.stateName}
                          </span>
                          {item.jobDescription !== null && (
                            <Fragment>
                              {item.jobDescription.length <= 135 && (
                                <p className="discription">
                                  {item.jobDescription}
                                </p>
                              )}
                              {item.jobDescription.length > 135 && (
                                <p className="discription">
                                  {item.jobDescription.substr(0, 134) + "..."}
                                </p>
                              )}
                            </Fragment>
                          )}
                          <div>
                            <Link
                              to={`${process.env.PUBLIC_URL}/groupleader/groupleaderjobdetails/${item.jobId}`}
                              className="btn-link"
                            >
                              Read more.
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <Link
                  className="btn btn-warning btn-xs white mt-3"
                  to={`${process.env.PUBLIC_URL}/groupleader/groupleaderjobs`}
                >
                  <span className="icon icon-support-members mr-2"></span> View
                  all jobs{" "}
                </Link>
              </div>
            </Col>

            {/* <Col md={6}>
              <div className="dashboard-box resume dashboard-col-bg sponser-dashboard-col hide-from-dashboard">
                <h2 className="page-sub-title">Support Team Members</h2>
                <SupportTeamList
                  supportTeamMembers={supportTeamMemebers}
                  showSpinner={showSpinnerForSTM}
                />
                <Link
                  className="btn btn-warning btn-xs white mt-3"
                  to={`${process.env.PUBLIC_URL}/groupleader/supportteammembers`}
                >
                  <span className="icon icon-support-members mr-2"></span> view
                  all support team members{" "}
                </Link>
              </div>
            </Col> */}

            {/* 
          <Col md={7}>
            <div className="dashboard-box activity dashboard-col-bg">
              <h2 className="page-sub-title">Job Seekers</h2>
              <JobSekkersList jobs={JobSeekers} />          
              <Link className="btn btn-warning btn-xs white mt-3"  to={`${process.env.PUBLIC_URL}/activitieslist`}>
                <FaRegListAlt className="mr-2" /> view more
              </Link>
            </div>
            <div className="dashboard-box activity dashboard-col-bg mt-5">
              <h2 className="page-sub-title">Messages</h2>
              <MessagesList messages={Messages} />
              <Link to={`${process.env.PUBLIC_URL}/messages`} className="btn btn-warning btn-xs white mt-3">
                <FaRegListAlt className="mr-2" /> view all messages
              </Link>
            </div>
          </Col> */}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

// export default Dashboard;

import React, { useState, Component, Fragment } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import FormInput from "./Shared/FormInput";
import Buttons from "./Shared/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Validators } from "../Utilities/Validator";
import Dropdown from "./Shared/Dropdown";
import { Link } from "react-router-dom";
import Select from "react-select";

// const options = [
//   { label: "Automobile", value: 1 },
//   { label: "Finance", value: 2 },
//   { label: "Healthcare", value: 3 },
// ];

class Search extends Component {
  state = {
    position: "",
    city: "",
    fromDate: null,
    toDate: null,
    state: "",
    company: "",
    industryid: null,
    jobtypeid: null,
    groupid: "",
    // this.props.groupId
    isjobapplied: null,
  };

  // DatePicker settings

  constructor(props) {
    super(props);
    this.minDate = new moment(props.minDate);
    this.maxDate = new moment(props.maxDate);
    this.state = {
      fromDate: props.minDate,
      toDate: props.maxDate,
      // // category dropdown
      // categories: [
      //   {
      //     options: [
      //       { label: "Sort", value: 1 },
      //       { label: "Location", value: 2 },
      //       { label: "Date", value: 3 },
      //     ]
      //   },
      // ],
      // category dropdown
      //  industry: [
      //   {
      //     options: [
      //       { label: "Industry", value: 1 },
      //       { label: "Finance", value: 2 },
      //       { label: "Healthcare", value: 3 },
      //     ]
      //   },
      // ],
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleDateChange(date) {
    this.setState({
      fromDate: date,
    });
  }

  handleEndDateChange(date) {
    this.setState({
      toDate: date,
    });
  }

  handelindustrychange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handeljobtypchange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleGroupChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  // validation check

  validate = (keyword, location) => {
    let result = true;
    if (Validators.isNull(keyword) || Validators.isUndefined(keyword)) {
      result = false;
    }

    if (Validators.isNull(location)) {
      result = false;
    }
    return result;
  };

  jobApplieChange(e, key) {
    if (e.target.checked) {
      this.setState({ [key]: true });
    } else {
      this.setState({ [key]: null });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let searchParam = "";
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key)) {
        if (
          this.state[key] !== "" &&
          this.state[key] !== null &&
          this.state[key] !== undefined
        ) {
          if (key === "fromDate" || key === "toDate") {
            var selectedDate = new Date(this.state[key]);
            var newDate = moment(selectedDate).format("YYYY/MM/DD");
            searchParam += `&${key}=${newDate}`;
          } else {
            searchParam += `&${key}=${this.state[key]}`;
          }
        }
      }
    }
    this.props.searchParam(searchParam);
    // console.log(searchParam);
  };

  resetSearchFilter() {
    this.setState({
      position: "",
      city: "",
      fromDate: null,
      toDate: null,
      state: "",
      company: "",
      industryid: this.props.industryId,
      jobtypeid: "",
      groupid: "",
      // this.props.groupId,
      isjobapplied: false,
    });
    document.getElementById("chkJobApplied").checked = false;
    this.props.searchParam("");
  }

  render() {
    const {
      position,
      city,
      fromDate,
      toDate,
      state,
      company,
      industryid = this.props.industryId,
      jobtypeid,
      groupid,
      // = this.props.groupId
    } = this.state;
    const { history } = this.props;
    return (
      <div className="search mb-4">
        <Form noValidate>
          <Row>
            {/* <Col>
             
             {this.state.categories.map(cat => (
                <Dropdown options={cat.options} title={cat.name} />
              ))}
            
             </Col> */}
            <Col md={3}>
              <FormInput
                value={position}
                type="text"
                placeholder="Position"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("position")}
              />
            </Col>

            <Col md={3}>
              <FormInput
                value={company}
                type="text"
                placeholder="Company Name"
                onChange={this.handleChange("company")}
              />
            </Col>
            <Col md={3}>
              <div className="form-group">
                <DatePicker
                  selected={this.state.fromDate}
                  onChange={this.handleDateChange}
                  // minDate={this.minDate}
                  value={this.props.fromDate}
                  placeholderText="Job Post Start Date"
                  className="inputclass form-control"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <DatePicker
                  selected={this.state.toDate}
                  onChange={this.handleEndDateChange}
                  // minDate={this.maxDate}
                  value={this.props.toDate}
                  placeholderText="Job Post End Date"
                  className="inputclass form-control"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
              </div>
            </Col>
            <Col md={3}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  className="inputclass form-control"
                  value={industryid}
                  onChange={this.handelindustrychange("industryid")}
                >
                  {/* <option value="">Select Industry</option> */}
                  <option value="0">All Industries</option>
                  {this.props.industriesList &&
                    this.props.industriesList.map((item) => (
                      <option key={item.industryId} value={item.industryId}>
                        {item.industryName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="jobPostingType">
                <Form.Control
                  onChange={this.handeljobtypchange("jobtypeid")}
                  as="select"
                  className="inputclass form-control"
                  value={jobtypeid}
                >
                  <option value="">Select Job-Poster Type</option>
                  {this.props.jobTypesList &&
                    this.props.jobTypesList.map((item) => (
                      <option key={item.jobTypeId} value={item.jobTypeId}>
                        {item.jobTypeName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <FormInput
                value={city}
                type="text"
                placeholder="City"
                onChange={this.handleChange("city")}
              />
            </Col>
            <Col md={3}>
              <FormInput
                value={state}
                type="text"
                placeholder="State"
                onChange={this.handleChange("state")}
              />
            </Col>

            {/* <Col>
            <div className="form-group calender-icon">
                  <DatePicker
                    selected={this.state.postDate}
                    onChange={this.handleDateChange}
                    minDate={this.date}
                    value={this.props.postDate}
                    placeholderText="Date & Time"
                    className="inputclass form-control"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  <i className="icon far fa-calendar"></i>
                </div>
            </Col> */}
            <Col
              md={3}
              className="d-flex align-items-center applied-job-fliter-box"
            >
              <div className="custom-control custom-checkbox">
                <input
                  id="chkJobApplied"
                  onClick={(e) => this.jobApplieChange(e, "isjobapplied")}
                  class="custom-control-input"
                  type="checkbox"
                />
                <label htmlFor="chkJobApplied" class="custom-control-label">
                  Jobs I Applied For
                </label>
              </div>
            </Col>
            <Col md={3} className="dd">
              <Form.Group controlId="dd">
                <Form.Control
                  as="select"
                  //defaultValue={this.props.groupId}
                  value={groupid}
                  className="inputclass form-control"
                  onChange={this.handleGroupChange("groupid")}
                >
                  <option value="0">All Groups</option>
                  {this.props.groupList &&
                    this.props.groupList.map((item, index) => (
                      <option key={index} value={item.groupId}>
                        {item.groupName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Buttons
                variant="warning"
                title="Search"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleSubmit}
              ></Buttons>
              <span style={{ marginLeft: 18 }}></span>
              <Buttons
                variant="warning"
                title="Reset"
                size="xs"
                color="white"
                onClick={() => {
                  this.resetSearchFilter();
                }}
              ></Buttons>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(Search);

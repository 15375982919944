import { API_URL } from "../Utilities/Helper";
import { get, post } from "./AxiosService";

export function getMessagesList() {
  return fetch(
    "https://my-json-server.typicode.com/bitsnitinpatil/demo/Messages"
  ).then((data) => data.json());
}

// export function getRecentMessagesForSTM(filter) {
//   return fetch(
//     `${API_URL}JobSeeker/GetRecenetJobSeekerMessageDashBorad${filter}`,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getRecentMessagesForSTM(filter) {
  return get(`${API_URL}JobSeeker/GetRecenetJobSeekerMessageDashBorad${filter}`
  ).then((res) => res.data);
}

// export function getRecentMessagesForEmployer(filter) {
//   return fetch(
//     `${API_URL}Employer/GetRecenetEmployerAndJobSeekerMessageDashBorad${filter}`,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getRecentMessagesForEmployer(filter) {
  return get(`${API_URL}Employer/GetRecenetEmployerAndJobSeekerMessageDashBorad${filter}`
  ).then((res) => res.data);
}

// export function getRecentMessagesForJobSeeker(filter) {
//   return fetch(
//     `${API_URL}JobSeeker/GetRecenetMessageDashBoradForJobSeeker${filter}`,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getRecentMessagesForJobSeeker(filter) {
  return get(`${API_URL}JobSeeker/GetRecenetMessageDashBoradForJobSeeker${filter}`
  ).then((res) => res.data);
}

// Employer Message Service

// export function getUserInboxMessages(filter) {
//   return fetch(`${API_URL}user/inboxmessages?${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getUserInboxMessages(filter) {
  return get(`${API_URL}user/inboxmessages?${filter}`).then((res) => res.data);
}

// export function getUserSentMessages(filter) {
//   return fetch(`${API_URL}user/sentmessages?${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getUserSentMessages(filter) {
  return get(`${API_URL}user/sentmessages?${filter}`).then((res) => res.data);
}

// export function getUserTrashMessages(filter) {
//   return fetch(`${API_URL}user/trashmessages?${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getUserTrashMessages(filter) {
  return get(`${API_URL}user/trashmessages?${filter}`).then((res) => res.data);
}

// export function getUserFlaggedMessages(filter) {
//   return fetch(`${API_URL}user/flaggedmessages?${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getUserFlaggedMessages(filter) {
  return get(`${API_URL}user/flaggedmessages?${filter}`).then((res) => res.data);
}

// export function sendUserMessages(data) {
//   return fetch(`${API_URL}user/sendmessage`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function sendUserMessages(data) {
  return post(`${API_URL}user/sendmessage`, data).then((res) => res.data);
}

// export function addremoveUserFlagMessages(data) {
//   return fetch(`${API_URL}user/addremoveflagmessage`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function addremoveUserFlagMessages(data) {
  return post(`${API_URL}user/addremoveflagmessage`, data).then((res) => res.data);
}

// export function deleteUserMessages(data) {
//   return fetch(`${API_URL}user/DeleteMessage`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function deleteUserMessages(data) {
  return post(`${API_URL}user/DeleteMessage`, data).then((res) => res.data);
}

// export function restoreUserMessages(data) {
//   return fetch(`${API_URL}user/restoremessage`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function restoreUserMessages(data) {
  return post(`${API_URL}user/restoremessage`, data).then((res) => res.data);
}
import React, { useState, useEffect, Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FaRegListAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import AllActivities from "../../../Components/AllActivities";
import SponsershipList from "../../../Components/SponsershipList";
import MessagesList from "../../../Components/MessagesList";
import { getRecentMessagesForSTM } from "../../../Services/MessagesService";
import { getSponsershipList } from "../../../Services/SponsershipService";
import getMessagesList from "../../../Services/MessagesService";
import getAllActivities from "../../../Services/AllActivities";
import {
  recentActivityPerPage,
  supportRecentSponsorshipsPerPage,
  recentMessagesPerPage,
} from "../../../Utilities/Helper";
import { toast } from "react-toastify";
import { get } from "../../../Services/AxiosService";
import { getUserDetailsAPI } from "../../../Utilities/APIHelper";

export default function SupportDashboard() {
  const [RecentActivities, setActivities] = useState([]);
  const [sponsership, setSponsership] = useState([]);
  const [Messages, setMessages] = useState([]);
  const [showSpinnerForActivities, setShowSpinnerForActivities] =
    useState(true);
  const [showSpinnerForSponsorships, setShowSpinnerForSponsorships] =
    useState(true);
  const [showSpinnerForMessages, setShowSpinnerForMessages] = useState(true);
  const [name, setName] = useState("");

  // getting jobs list list

  useEffect(() => {
    function activitiesData() {
      getAllActivities("recordperpage=" + recentActivityPerPage)
        .then((res) => {
          const result = JSON.parse(res.response);
          const data = result.userActivity;
          setActivities(data);
          setShowSpinnerForActivities(false);
        })
        .catch((err) => {
          console.log("Support: ", err);
          setShowSpinnerForActivities(false);
        });
    }
    activitiesData();
  }, []);

  // getting Sponsership list
  useEffect(() => {
    function sponsershipData() {
      const filter =
        "recordperpage=" + supportRecentSponsorshipsPerPage + "&IsPending=true";
      getSponsershipList(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setSponsership(result.jobSeekers);
          setShowSpinnerForSponsorships(false);
        })
        .catch((err) => {
          setShowSpinnerForSponsorships(false);
          console.log("Sponsorship: ", err);
        });
    }
    sponsershipData();
  }, []);

  // getting message list
  useEffect(() => {
    function messagesData() {
      getRecentMessagesForSTM("?recordperpage=" + recentMessagesPerPage)
        .then((res) => {
          const result = JSON.parse(res.response);
          var resultArray = [];
          result.forEach((element) => {
            var obj = {};
            obj.name = element.fullName;
            obj.text = element.jobSeekerSponshirShipMsg;
            obj.date = element.createdDate;
            obj.profilePhoto = element.profilePhoto;
            resultArray.push(obj);
          });
          setMessages(resultArray);
          setShowSpinnerForMessages(false);
        })
        .catch((err) => {
          console.log("Message: ", err);
          setShowSpinnerForMessages(false);
        });
    }
    messagesData();
  }, []);

  useEffect(() => {
    async function getName() {
      get(getUserDetailsAPI)
        .then((res) => {
          // console.log(res.data.response);
          let response = res.data.response;
          setName(response.firstName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getName();
  }, []);

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Hello {name} <small>(Support team)</small>
      </h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={7}>
            <div className="dashboard-box activity dashboard-col-bg">
              <h2 className="page-sub-title">Recent Activity</h2>
              <AllActivities
                activities={RecentActivities}
                showSpinner={showSpinnerForActivities}
              />
              <Link
                className="btn btn-warning btn-xs white mt-3"
                to={`${process.env.PUBLIC_URL}/support/activitieslist`}
              >
                <span className="icon icon-list_alt mr-3"></span> View more
              </Link>
            </div>

            <div className="dashboard-box activity dashboard-col-bg mt-5">
              <h2 className="page-sub-title">Messages</h2>
              <MessagesList
                messages={Messages}
                showSpinner={showSpinnerForMessages}
              />
              <Link
                to={`${process.env.PUBLIC_URL}/support/messages`}
                className="btn btn-warning btn-xs white mt-3"
              >
                <span className="icon icon-chat_bubble_outline mr-3"></span>{" "}
                View all messages
              </Link>
            </div>
          </Col>
          <Col md={5}>
            <div className="dashboard-box resume dashboard-col-bg sponser-dashboard-col sponsership-dashboard-list">
              <h2 className="page-sub-title">New Sponsorship Requests</h2>
              <SponsershipList
                sponsers={sponsership}
                showSpinner={showSpinnerForSponsorships}
              />
              <Link
                to={`${process.env.PUBLIC_URL}/support/sponsorship`}
                className="btn btn-warning btn-xs white mt-3"
              >
                <span className="icon-people_outline mr-3"></span> View all
                sponsorships
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

// export default Dashboard;

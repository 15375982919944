import React, { useState, useEffect } from "react";
import { Col, Row, Form, Modal, Button, Badge } from "react-bootstrap";
import ListPagination from "../Components/Pagination";
import FormInput from "../Components/Shared/FormInput";
import Buttons from "../Components/Shared/Button";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getNotificationsList, getNotificationsUnreadCount, readNotification } from "../Services/NotificationService";
import SpinnerLoader from "./SpinnerLoader";
import { getData } from "../Utilities/StorageHelper";

export default function Notifications() {

    const [notification, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
    const [refreshTable, setRefreshTable] = useState(true);
    const [showSpinner, setShowSpinner] = useState(true);
    const [totalRecord, setTotalRecord] = useState("");
    const [unreadCount, setUnreadCount] = useState("");


    useEffect(() => {
        let isLoggedIn = getData("token");
        if (isLoggedIn) {
            let filter = "?recordperpage=" + 10 + currentPage.filter;
            getNotificationsList(filter)
                .then((res) => {
                    // console.log(res.notificationModels);
                    setShowSpinner(false);
                    setNotifications(res.notificationModels);
                    setTotalRecord(res.totalRecord);
                    getUnreadCount();
                })
                .catch((err) => {
                    setShowSpinner(false);
                    console.log(err);
                });
        }
    }, [refreshTable]);

    const pageChangeHandler = (page) => {
        if (currentPage.value !== page) {
            setCurrentPage((prev) => ({ ...prev, filter: `&page=${page}` }));
            setRefreshTable((prev) => !prev);
            setShowSpinner(true);
        }
    };

    const refreshTableHandler = () => {
        setRefreshTable((prev) => !prev);
        setShowSpinner(true);
    };

    const markAsRead = (id) => {
        // console.log(id);
        // let data = JSON.stringify({
        //     userId: id,
        // });
        let data = id;
        readNotification(data)
            .then((res) => {
                // if (res.status === 200)
                refreshTableHandler();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getUnreadCount = () => {
        let isLoggedIn = getData("token");
        if (isLoggedIn) {
            getNotificationsUnreadCount()
                .then((res) => {
                    setUnreadCount(res.totalUnreadCount);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div className="container p-0">
            <h1 className="page-title">
                Notifications
                <Badge
                    pill
                    bg="danger"
                    className="bg-warning text-white px-3 ml-3 font-weight-normal"
                >
                    {unreadCount}
                </Badge>
            </h1>
            <div className="dashboard-wrapper">
                <Row>
                    <Col md={12}>
                        <div className="dashboard-box dashboard-col-bg sponsership-page">

                            <div className="d-flex">
                                <ul className="job-list sponsership-list">
                                    {/* <p>Jobs</p> */}
                                    {showSpinner && <SpinnerLoader />}
                                    {notification.length === 0 && !showSpinner && (
                                        <h5 style={{ textAlign: "center" }}>No Recent Notifications!</h5>
                                    )}
                                    {notification.map((item, index) => (
                                        <li key={index} onClick={() => {
                                            if (!item.isSeen)
                                                markAsRead(item.userId);
                                        }}
                                            style={{ backgroundColor: item.isSeen ? "white" : "#F3F9FD" }}
                                            className="align-items-center">
                                            <small style={{ fontSize: "13px" }} className="title">
                                                {item.message}
                                            </small>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="d-flex">
                                {notification.length != 0 && <ListPagination
                                    recordPerPage={10}
                                    TotalRecord={totalRecord}
                                    className="mt-3"
                                    onPageChange={pageChangeHandler}
                                />}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div >
    );
}

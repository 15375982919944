import React, { useState, Component } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import FormInput from "./Shared/FormInput";
import Buttons from "./Shared/Button";
import moment from "moment";

import { Validators } from "../Utilities/Validator";
import Dropdown from "./Shared/Dropdown";
import Select from "react-select";

const options = [
    { label: "Automobile", value: 1 },
    { label: "Finance", value: 2 },
    { label: "Healthcare", value: 3 },
];

class SearchSeekersNetwork extends Component {
    state = {
        keyword: "",
        title: "",
        CompanyName: "",
        City: "",
        State: "",
        IndustryId: "",
        groupId: "",
        postDate: "",
        selectedOption: "",
    };

    // DatePicker settings

    constructor(props) {
        super(props);
        this.date = new moment(props.date);
        this.state = {
            postDate: props.date,
            // category dropdown
            //   categories: [
            //     {
            //       options: [
            //         { label: "Sort", value: 1 },
            //         { label: "Location", value: 2 },
            //         { label: "Date", value: 3 },
            //       ],
            //     },
            //   ],
            //   // category dropdown
            //   industry: [
            //     {
            //       options: [
            //         { label: "Category", value: 1 },
            //         { label: "Category", value: 2 },
            //         { label: "Category", value: 3 },
            //       ],
            //     },
            //   ],
        };
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleChange = (key) => (value) => {
        // console.log("handlechange", key, value);
        this.setState({ [key]: value });
    };

    handleDateChange(date) {
        this.setState({
            postDate: date,
        });
    }

    handelindustrychange = (key) => (e) => {
        //console.log(key, e.target.value);
        this.setState({ [key]: e.target.value });
    };

    handelgroupchange = (key) => (e) => {
        //console.log(key, e.target.value);
        this.setState({ [key]: e.target.value });
    };
    // validation check

    validate = (keyword, location) => {
        let result = true;
        if (Validators.isNull(keyword) || Validators.isUndefined(keyword)) {
            result = false;
        }

        if (Validators.isNull(location)) {
            result = false;
        }
        return result;
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        let searchParam = "";
        for (var key in this.state) {
            if (this.state.hasOwnProperty(key)) {
                if (
                    this.state[key] !== "" &&
                    this.state[key] !== null &&
                    this.state[key] !== undefined
                ) {
                    //   if (key === "fromDate" || key === "toDate") {
                    //     var selectedDate = new Date(this.state[key]);
                    //     var newDate = new Date(
                    //       selectedDate.setDate(selectedDate.getDate() + 1)
                    //     ).toISOString();
                    //     searchParam += `&${key}=${newDate}`;
                    //   } else {
                    searchParam += `&${key}=${this.state[key]}`;
                    //   }
                }
            }
        }
        // console.log(searchParam);
        this.props.searchParam(searchParam);
    };

    resetSearchFilter() {
        this.setState({
            title: "",
            State: "",
            City: "",
            CompanyName: "",
            IndustryId: "",
            groupId: "",
        });
        this.props.searchParam("");
    }

    render() {
        const {
            keyword,
            title,
            CompanyName,
            City,
            State,
            postDate,
            IndustryId,
            groupId,
            location,
        } = this.state;
        const { history } = this.props;

        return (
            <div className="search mb-4">
                <Form noValidate>
                    <Row>
                        <Col md={3}>
                            <FormInput
                                value={title}
                                type="text"
                                placeholder="Position"
                                onChange={this.handleChange("title")}
                            />
                        </Col>
                        <Col md={3}>
                            <FormInput
                                value={CompanyName}
                                type="text"
                                placeholder="Company name"
                                onChange={this.handleChange("CompanyName")}
                            />
                        </Col>
                        <Col md={3}>
                            <div className="form-group">
                            <Form.Control
                                value={IndustryId}
                                as="select"
                                className="inputclass form-control"
                                onChange={this.handelindustrychange("IndustryId")}
                            >
                                {" "}
                                <option value="0">Select Industry</option>
                                {this.props.industriesList &&
                                    this.props.industriesList.map((item) => (
                                        <option key={item.industryId} value={item.industryId}>
                                            {item.industryName}
                                        </option>
                                    ))}
                            </Form.Control>
                            </div>
                            {/* <Select placeholder="Industry" options={options} isMulti className="selectd" /> */}
                        </Col>
                        <Col>
                        <div className="form-group">
                            <Form.Control
                                value={groupId}
                                as="select"
                                className="inputclass form-control"
                                onChange={this.handelgroupchange("groupId")}
                            >
                                {" "}
                                <option value="">Select Group</option>
                                {this.props.groupsList &&
                                    this.props.groupsList.map((item) => (
                                        <option key={item.groupId} value={item.groupId}>
                                            {item.groupName}
                                        </option>
                                    ))}
                            </Form.Control>
                            </div>
                        </Col>
                        <Col md={3}>
                            <FormInput
                              value={State}
                              type="text"
                              placeholder="State"
                              onChange={this.handleChange("State")}
                            />
                        </Col>
                        <Col md={3}>
                            <FormInput
                                value={City}
                                type="text"
                                placeholder="City"
                                onChange={this.handleChange("City")}
                            />
                        </Col>
                        <Col className="col-auto">
                            <Buttons
                                variant="warning"
                                title="Search"
                                type="submit"
                                size="xs"
                                color="white"
                                onClick={this.handleSubmit}
                            ></Buttons>
                            <span style={{ marginLeft: 18 }}></span>
                            <Buttons
                                variant="warning"
                                title="Reset"
                                size="xs"
                                color="white"
                                onClick={(e) => this.resetSearchFilter(e)}
                            ></Buttons>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}
export default withRouter(SearchSeekersNetwork);

import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import SpinnerLoader from "./SpinnerLoader";
import { ActionType, Roles } from "../Utilities/Helper";
import moment from "moment";
import { getData } from "../Utilities/StorageHelper";

export default function AllActivities({ activities = [], showSpinner }) {
  let msgIndex = 0;
  return (
    <ul className="job-list">
      {showSpinner && <SpinnerLoader />}
      {activities.length === 0 && !showSpinner && (
        <h5 style={{ textAlign: "center" }}>No Data Found</h5>
      )}
      {activities.map((activity, index) => (
        <li key={index}>
          <div className={`icon icon-post`}></div>
          <div className="job-details">
            {activity.actionTypeName === ActionType.AppliedJob && (
              <h5>You applied for this job</h5>
            )}
            {activity.actionTypeName !== ActionType.AppliedJob && (
              <h5>{activity.actionTypeName}</h5>
            )}
            {activity.actionTypeName === ActionType.PostedJob && (
              <Link
                to={`${process.env.PUBLIC_URL}/employer/employerjobdetails/${activity.entityId}`}
              >
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.DraftJob && (
              <Link
                to={`${process.env.PUBLIC_URL}/employer/employerjobdetails/${activity.entityId}`}
              >
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.SavedResume && (
              <Link
                to={`${process.env.PUBLIC_URL}/employer/resumedetails/${activity.entityId}`}
              >
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.SentMessage && (
              <Fragment>
                {getData("role") === Roles.jobSeeker && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/jobseeker/messages${
                      "?" + msgIndex++ + "?s"
                    }`}
                  >
                    <h4>{activity.activity}</h4>
                  </Link>
                )}
                {getData("role") === Roles.stm && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/support/messages${
                      "?" + msgIndex++ + "?s"
                    }`}
                  >
                    <h4>{activity.activity}</h4>
                  </Link>
                )}
                {getData("role") === Roles.employer && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/employer/messages${
                      "?" + msgIndex++ + "?s"
                    }`}
                  >
                    <h4>{activity.activity}</h4>
                  </Link>
                )}
              </Fragment>
            )}
            {activity.actionTypeName === ActionType.ResumeReceived && (
              <Link
                to={`${process.env.PUBLIC_URL}/employer/employerjobdetails/${activity.entityId}`}
              >
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.AppliedJob && (
              <Link
                to={`${process.env.PUBLIC_URL}/jobseeker/jobdetails/${activity.entityId}`}
              >
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.NetworkingRequest && (
              <Link to={`${process.env.PUBLIC_URL}/network`}>
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === "Accepted Networking Request" && (
              <Link to={`${process.env.PUBLIC_URL}/supportConnections`}>
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.SponsorshipRequest && (
              <Link to={`${process.env.PUBLIC_URL}/jobseeker/sponsorship`}>
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.SponsorshipNoteAdded && (
              <Link
                to={`${process.env.PUBLIC_URL}/support/sponsorshipdetails/${activity.entityId}`}
              >
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.AcceptedRequest && (
              <Link
                to={`${process.env.PUBLIC_URL}/support/sponsorshipdetails/${activity.entityId}`}
              >
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.InActive && (
              <Link to={`${process.env.PUBLIC_URL}/admin/manageusers`}>
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.Active && (
              <Link to={`${process.env.PUBLIC_URL}/admin/manageusers`}>
                <h4>{activity.activity}</h4>
              </Link>
            )}
            {activity.actionTypeName === ActionType.ResumePublished && (
              <Link
                to={`${process.env.PUBLIC_URL}/jobseeker/resumedetails/${activity.entityId}`}
              >
                <h4>{activity.activity}</h4>
              </Link>
            )}
          </div>
          <div className="job-post-date ml-auto">
            {moment(
              new Date(activity.createdDate.toString() + "Z").toString()
            ).format("MM.DD.Y")}
          </div>
        </li>
      ))}
    </ul>
  );
}

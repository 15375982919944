import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormInput from "../../../Components/Shared/FormInput";
import { getSupportTeamMember } from "../../../Services/LeaderSupportTeamMember";
import { getUserDetails } from "../../../Services/GetFields";
import { toast } from "react-toastify";
import profile from "../../../assests/img/profile.png";
import { Roles } from "../../../Utilities/Helper";
import { getData } from "../../../Utilities/StorageHelper";

const SupportTeamMembersDetails = (props) => {
  const [supportTeamMember, setSupportTeamMember] = useState({});
  const supportTeamMemberId = props.match.params.supportTeamMemberId;

  useEffect(() => {
    async function getSupportTeamMemberDetails() {
      getUserDetails()
        .then((rspUserDetails) => {
          const userDetails = rspUserDetails.response;
          getSupportTeamMember(supportTeamMemberId)
            .then((res) => {
              const result = res.response;
              if (result.groupId !== userDetails.groupId) return;
              setSupportTeamMember(result);
            })
            .catch((err) => {
              console.log("Support Details: ", err);
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    getSupportTeamMemberDetails();
  }, []);

  const handleChange = (value) => {};

  return (
    <div className="container p-0">
      <h1 className="page-title">Support Team Members Details</h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            {supportTeamMember && (
              <div className="dashboard-box dashboard-col-bg float-left pb-5 w-100">
                {getData("role") === Roles.groupLeader && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/groupleader/supportteammembers`}
                    className="page-sub-title-with-back"
                  >
                    <AiOutlineArrowLeft />
                    Back to support team members
                  </Link>
                )}
                {getData("role") === Roles.admin && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/admin/manageusers`}
                    className="page-sub-title-with-back"
                  >
                    <AiOutlineArrowLeft />
                    Back to manage users
                  </Link>
                )}
                <div className="user-details d-flex align-items-center">
                  <div className="user-avatar">
                    {supportTeamMember.profilePhoto !== null ? (
                      <img
                        src={`https://data.jin-storage.us-sjo1.upcloudobjects.com/ProfilePhotos/${supportTeamMember.profilePhoto}`}
                      />
                    ) : (
                      <img src={profile} />
                    )}
                  </div>
                  <div className="user-name">
                    {supportTeamMember.firstName +
                      " " +
                      supportTeamMember.lastName}
                  </div>
                </div>
                <div className="users-wrap users-bg">
                  <div className="users-box left">
                    <Form>
                      <FormInput
                        value={supportTeamMember.email}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Email"
                        ic
                        onChange={handleChange}
                      />
                      <FormInput
                        value={supportTeamMember.phoneNumber}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Phone"
                        onChange={handleChange}
                      />
                      <FormInput
                        value={supportTeamMember.companyName}
                        type="text"
                        placeholder="Not available"
                        required="required"
                        label="Company Name"
                        onChange={handleChange}
                      />
                    </Form>
                  </div>
                  {/* <div className="users-box right">
                  <h6 className="sub-title mb-4">Documents</h6>
                  <Buttons
                      variant="light"
                      title="view resume"
                      icon="icon icon-documents mr-2"
                      type="button"
                      size="xs"
                      color="white"
                      marginLeft="ml-0"
                    ></Buttons>
                  <br/>
                  <br/>
                    <Buttons
                      variant="light"
                      title="view cover letter"
                      icon="icon icon-documents mr-2"
                      type="button"
                      size="xs"
                      color="white"
                      marginLeft="ml-0"
                    ></Buttons>
                  
                  </div> */}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SupportTeamMembersDetails;

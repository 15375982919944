import { Spinner } from "react-bootstrap";

const SpinnerLoader = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" role="status"></Spinner>
    </div>
  );
};

export default SpinnerLoader;

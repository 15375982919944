import { React } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const toolBoxItems = [
  {
    title: "Save the current demo",
    text: "Use this to save the current demo allowing you to use it again later.",
    path: "/admin/demotools/save/0",
  },
  {
    title: "Restore a saved demo",
    text: "Use this to restore a saved demo exactly as it was when it was saved.",
    path: "/admin/demotools/restore/0",
  },
  {
    title: "Delete a saved demo",
    text: "Use this to remove a saved demo. This will not affect the current demo but will permanently remove a saved demo.",
    path: "/admin/demotools/remove/0",
  },
  {
    title: "Create a new demo",
    text: `Use this to create a new demo. This will allow you to supply a new group name, location, and Industry.
          You can also use a saved demo as a starting point.`,
    path: "/admin/demotools/create/0",
  },
  {
    title: "Create a slideshow presentation (Upcoming)",
    text: "Use this to generate a new slideshow presentation for the current demo database setup.",
    path: "/admin/demotools/presentation/0",
  },

  {
    title: "Change demo settings (Upcoming)",
    text: "Want to switch the demo from automated to manual?",
    //path: "/admin/demotools/settings",
  },
];

const AdminDemoSetup = () => {
  const history = useHistory();

  const ToolBoxSelectItem = ({ title, text, path }) => {
    return (
      <div className="dashboard-box dashboard-col-bg mb-4">
        <h2 class="sub-form-title d-flex">{title}</h2>
        <Row>
          <Col md={12}>
            <p style={{ minHeight: "145px" }}>{text}</p>
          </Col>
          <Col md={12}>
            <button
              onClick={() => history.push(path)}
              disabled={path === undefined}
              type="button"
              class="btn btn-primary btn btn-primary"
            >
              Select
            </button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Container>
      <h1 className="page-title">I want to...</h1>
      <div className="dashboard-wrapper">
        <Row className="justify-content-md-center">
          {toolBoxItems.map((item, index) => (
            <Col xs={12} sm={8} md={4} lg={4}>
              <ToolBoxSelectItem
                key={index}
                title={item.title}
                text={item.text}
                path={item.path}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default AdminDemoSetup;

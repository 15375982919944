import { API_URL } from "../Utilities/Helper";
import { get } from "./AxiosService";

const Url = API_URL;

// export default function getAllActivities(filter) {
//   return fetch(`${Url}user/activities?` + filter, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export default function getAllActivities(filter) {
  return get(`${Url}user/activities?` + filter).then((res) => res.data);
}
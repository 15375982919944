import { API_URL } from "../Utilities/Helper";
import { get, put } from "./AxiosService";

// export function getReportAbuseList(filter) {
//   return fetch(`${API_URL}JinAdminMangeUser/GetListOfAllUsers?` + filter, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function getReportAbuseList(filter) {
  return get(`${API_URL}JinAdminMangeUser/GetListOfAllUsers?` + filter).then(
    (res) => res.data
  );
}

// export function getUserTypes() {
//   return fetch(`${API_URL}JinAdminMangeUser/GetUserTypes`, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function getUserTypes() {
  return get(`${API_URL}JinAdminMangeUser/GetUserTypes`).then(
    (res) => res.data
  );
}

// export function userActiveAndInactive(data) {
//   return fetch(`${API_URL}JinAdminMangeUser/UserInActiveOrActive`, {
//     method: "PUT",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function userActiveAndInactive(data) {
  return put(`${API_URL}JinAdminMangeUser/UserInActiveOrActive`, data).then(
    (res) => res.data
  );
}

// export function getEmployer(employerId) {
//   return fetch(`${API_URL}user/employer?EmployerId=` + employerId, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getEmployer(employerId) {
  return get(`${API_URL}user/employer?EmployerId=` + employerId).then(
    (res) => res.data
  );
}

// export function getGroupLeader(groupleaderId) {
//   return fetch(`${API_URL}user/groupleader?GroupLeaderId=` + groupleaderId, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getGroupLeader(groupleaderId) {
  return get(`${API_URL}user/groupleader?GroupLeaderId=` + groupleaderId).then(
    (res) => res.data
  );
}

// export function getGroupsWithJSCount(filter) {
//   return fetch(
//     `${API_URL}JinAdminMangeUser/GetGroupDetailsForJobSeeker?${filter}`,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getGroupsWithJSCount(filter) {
  return get(
    `${API_URL}JinAdminMangeUser/GetGroupDetailsForJobSeeker?${filter}`,
  ).then((res) => res.data);
}

// export function getGroupsWithSTMCount(filter) {
//   return fetch(`${API_URL}JinAdminMangeUser/GetGroupDetailsForSTM?${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getGroupsWithSTMCount(filter) {
  return get(
    `${API_URL}JinAdminMangeUser/GetGroupDetailsForSTM?${filter}`
  ).then((res) => res.data);
}

import { API_URL } from "../Utilities/Helper";
import { get, post, put } from "./AxiosService";

// export function getSponsershipList(filter) {
//   return fetch(`${API_URL}JobSeeker/GetSponsirShipList?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getSponsershipList(filter) {
  return get(`${API_URL}JobSeeker/GetSponsirShipList?` + filter).then((res) => res.data);
}

// export function removeSponsershipRequest(data) {
//   return fetch(`${API_URL}JobSeeker/RemoveJobSeeker`, {
//     method: "PUT",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function removeSponsershipRequest(data) {
  return put(`${API_URL}JobSeeker/RemoveJobSeeker`, data).then((res) => res.data);
}

// export function approveDeclineSponsershipRequest(data) {
//   return fetch(`${API_URL}JobSeeker/ApproveJobSeeker_DeclineJobSeeker`, {
//     method: "POST",
//     body: JSON.stringify(data),
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//   }).then((data) => data.json());
// }

export function approveDeclineSponsershipRequest(data) {
  return post(`${API_URL}JobSeeker/ApproveJobSeeker_DeclineJobSeeker`, data
  ).then((res) => res.data);
}

// export function getSponsershipDetails(jobSeekerId) {
//   return fetch(
//     `${API_URL}JobSeeker/GetDetailsJobSeeker?JobSeekerSponsershipRequestId=` +
//     jobSeekerId,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getSponsershipDetails(jobSeekerId) {
  return get(
    `${API_URL}JobSeeker/GetDetailsJobSeeker?JobSeekerSponsershipRequestId=` +
    jobSeekerId).then((res) => res.data);
}

// export function updateNotification(data) {
//   return fetch(`${API_URL}JobSeeker/NotiFicationJobSeeker`, {
//     method: "POST",
//     body: JSON.stringify(data),
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//   }).then((data) => data.json());
// }

export function updateNotification(data) {
  return post(`${API_URL}JobSeeker/NotiFicationJobSeeker`, data
  ).then((res) => res.data);
}

// export function saveNotes(data) {
//   return fetch(`${API_URL}JobSeeker/SaveNote`, {
//     method: "PUT",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function saveNotes(data) {
  return put(`${API_URL}JobSeeker/SaveNote`, data).then((res) => res.data);
}

// export function getSponsershipDetailRecentMessages(data) {
//   return fetch(`${API_URL}JobSeeker/GetRecentMsg${data}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getSponsershipDetailRecentMessages(data) {
  return get(`${API_URL}JobSeeker/GetRecentMsg${data}`).then((res) => res.data);
}

//For Adding New Sponsorship Request
// export function addSponsorshipRequest(data) {
//   return fetch(`${API_URL}jobseeker/CreateUpdateJobSeekerSponsershipsRequest`, {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then((data) => data.json());
// }

export function addSponsorshipRequest(data) {
  return post(`${API_URL}jobseeker/CreateUpdateJobSeekerSponsershipsRequest`, data
  ).then((res) => res.data);
}

// export function getJobSeekerSponsorshipRequest(filter) {
//   return fetch(`${API_URL}JobSeeker/GetJobSeekerConnection?${filter}`, {
//     method: "GET",
//     headers: {
//       Authorization: "Bearer " + getData("token"),
//     },
//   }).then((data) => data.json());
// }

export function getJobSeekerSponsorshipRequest(filter) {
  return get(`${API_URL}JobSeeker/GetJobSeekerConnection?${filter}`
  ).then((res) => res.data);
}

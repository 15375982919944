import React from "react";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

export const ContentBox = ({
  headerText,
  nextText,
  onNext,
  disableNext,
  children,
  showLoader,
  rootPage,
  hideNext,
}) => {
  const history = useHistory();
  const { step } = useParams();

  if (!rootPage) {
    rootPage = "create/";
  }

  return (
    <Row className="justify-content-md-center">
      <Col sm={12} md={6} className="mt-0">
        <div className="dashboard-box dashboard-col-bg mb-4">
          <h2 className="sub-form-title mb-6 d-flex">{headerText}</h2>
          <Row>
            <Col md={12}>{children}</Col>
          </Row>
          <Row>
            <Col xs={2} className="mt-4 mr-2">
              <Button
                type="button"
                className="btn btn-default"
                disabled={step <= 0}
                onClick={() => {
                  history.push(
                    process.env.PUBLIC_URL +
                      "/admin/demotools/" +
                      rootPage +
                      (parseInt(step) - 1)
                  );
                }}
              >
                Back
              </Button>
            </Col>
            {hideNext ? null : (
              <Col xs={2} className="mt-4 ml-2">
                <Button
                  type="button"
                  className="btn btn-success"
                  disabled={true === showLoader || true === disableNext}
                  onClick={() => {
                    onNext();
                  }}
                >
                  {showLoader ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    nextText
                  )}
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

import { applyMiddleware, createStore, compose } from "redux";

import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer } from "./Reducers/rootReducer";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import logger from "redux-logger";
// import { PURGE_STATE } from "../constants/index";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = (state, action) => {
  // if (action.type === PURGE_STATE) {
  //     Object.keys(state).forEach(key => {
  //         storage.removeItem(`persist:root`);
  //     });
  //     state = undefined;
  // }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, logger));

const store = createStore(persistedReducer, undefined, enhancer);
const persistor = persistStore(store);

export { persistor };
export default store;

// import { createStore } from "redux";

// const dispathFunction = (state = { items: [], totalRecord: 0 }, action) => {
//     if (action.type === "jobs") {
//         return { items: action.items, totalRecord: action.totalRecord };
//       }
//       if (action.type === "clear") {
//         return { items: [], totalRecord: 0 };
//       }
//   return state;
// };

// const store = createStore(dispathFunction);

// export default store;

import axios from "axios";
import { API_URL } from "../Utilities/Helper";
import { get, post, put } from "./AxiosService";

export function ForgotPassword(data) {
  var options = {
    method: "POST",
    headers: { "content-type": "application/json; charset=UTF-8" },
    body: data,
  };
  return fetch(`${API_URL}Account/ForgotPassword`, options).then((res) => res.json());
}

export function ResetPassword(data) {
  var options = {
    method: "POST",
    headers: { "content-type": "application/json; charset=UTF-8" },
    body: data,
  };
  return fetch(`${API_URL}Account/ResetPassword`, options).then((res) => res.json());
}

// export function ChangeUserPassword(data) {
//   var options = {
//     method: "POST",
//     headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
//     body: data,
//   };
//   return fetch(`${API_URL}Account/ChangePassword`, options).then((res) => res.json());
// }

export function ChangeUserPassword(data) {
  // var options = {
  //   method: "POST",
  //   headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
  //   body: data,
  // };
  return post(`${API_URL}Account/ChangePassword`, data).then((res) => res.data);
}

// export function updateAccountInformation(data, api) {
//   var options = {
//     method: "POST",
//     headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
//     body: data,
//   };
//   return fetch(api, options).then((res) => res.json());
// }

export function updateAccountInformation(data, api) {
  // var options = {
  //   method: "POST",
  //   headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
  //   body: data,
  // };
  return post(api, data).then((res) => res.data);
}

// export function getUserDetails() {
//   return fetch(API_URL + "user/userdetails", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function getUserDetails() {
  return get(API_URL + "user/userdetails").then((res) => res.data);
}

// export function getUserGroups() {
//   return fetch(API_URL + "user/groupdetails", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function getUserGroups() {
  return get(API_URL + "user/groupdetails").then((res) => res.data);
}

// export function getJob(id) {
//   return fetch(API_URL + "Job/job/" + id, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function getJob(JobId) {
  return get(`${API_URL}job/job/` + JobId).then((res) => res.data);
}

// export function getJobResumes(jobId) {
//   return fetch(API_URL + "job/jobresumes/" + jobId, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function getJobResumes(jobId) {
  return get(API_URL + "job/jobresumes/" + jobId).then((res) => res.data);
}

// export function fillCloseJob(jobId) {
//   return fetch(API_URL + "job/filledclosedjob/" + jobId, {
//     method: "PUT",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function fillCloseJob(jobId) {
  return put(API_URL + "job/filledclosedjob/" + jobId).then((res) => res.data);
}

// export function getJobs(filter) {
//   return fetch(API_URL + "job/jobs?" + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function getJobs(filter) {
  return get(API_URL + "job/jobs?" + filter).then((res) => res.data);
}

// export function getPublicResumes(filter) {
//   return fetch(API_URL + "JINTemplate/GetPublicResumeCardsList" + filter,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }).then((res) => res.json());
// }

export function getPublicResumes(filter) {
  return get(API_URL + "JINTemplate/GetPublicResumeCardsList" + filter).then((res) => JSON.parse(res.data.response));
}

export function getPrivateSavedResumes(filter) {
  return get(API_URL + "Profile/GetPrivateSavedResumeList" + filter).then((res) => JSON.parse(res.data.response));
}
// export function getPrivateSavedResumes(filter) {
//   return fetch(API_URL + "Profile/GetPrivateSavedResumeList" + filter,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }).then((res) => res.json());
// }

// export function getPrivateResumes(filter) {
//   return fetch(API_URL + "Profile/GetPrivateResumeList" + filter,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }).then((res) => res.json());
// }

export function getPrivateResumes(filter) {
  return get(API_URL + "Profile/GetPrivateResumeList" + filter).then((res) => JSON.parse(res.data.response));
}

// export function searchPublicResumes(searchParams) {
//   return fetch(API_URL + "JINTemplate/SearchResumes" + searchParams,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }).then((res) => res.json());
// }

export function searchPublicResumes(searchParams) {
  return get(API_URL + "JINTemplate/SearchResumes" + searchParams).then((res) => res.data);
}

// export function getDraftJobsCount() {
//   return fetch(API_URL + "job/draftjobcount", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
//   }).then((res) => res.json());
// }

export function getDraftJobsCount() {
  return get(API_URL + "job/draftjobcount").then((res) => res.data);
}

// export function sendNotInterested(data) {
//   return fetch(API_URL + "job/notinterested", {
//     method: "POST",
//     headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
//     body: data,
//   }).then((res) => res.json());
// }

export function sendNotInterested(data) {
  return post(API_URL + "job/notinterested", data).then((res) => res.data);
}

// export function getEmployerPicture() {
//   return fetch(API_URL + "Employer/GetEmployerProfilePhoto", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
//   }).then((res) => res.json());
// }

export function getEmployerPicture() {
  return get(API_URL + "Employer/GetEmployerProfilePhoto").then((res) => res.data);
}

// export function setEmployerPicture(data) {
//   return fetch(API_URL + "Employer/UploadEmployerProfilePhotoUsingIFormFile", {
//     method: "POST",
//     headers: { Authorization: "Bearer " + getData("token"), "Accept": "*/*" },
//     body: data
//   }).then((res) => res.json());
// }

export function setEmployerPicture(data) {
  return post(API_URL + "Employer/UploadEmployerProfilePhotoUsingIFormFile", data)
    .then((res) => res.data);
}

// export function deleteEmployerPicture() {
//   return fetch(API_URL + "Employer/DeleteEmployerProfilePhoto", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function deleteEmployerPicture() {
  return get(API_URL + "Employer/DeleteEmployerProfilePhoto").then((res) => res.data);
}

// export function getJSPicture() {
//   return fetch(API_URL + "JobSeeker/GetJobSeekerProfilePhoto", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
//   }).then((res) => res.json());
// }

export function getJSPicture() {
  return get(API_URL + "JobSeeker/GetJobSeekerProfilePhoto").then((res) => res.data);
}

// export function setJSPicture(data) {
//   return fetch(API_URL + "JobSeeker/UploadJobSeekerProfilePhotoUsingIFormFile", {
//     method: "POST",
//     headers: { Authorization: "Bearer " + getData("token"), "Accept": "*/*" },
//     body: data
//   }).then((res) => res.json());
// }

export function setJSPicture(data) {
  return post(API_URL + "JobSeeker/UploadJobSeekerProfilePhotoUsingIFormFile", data
  ).then((res) => res.data);
}

// export function deleteJSPicture() {
//   return fetch(API_URL + "JobSeeker/DeleteJobSeekerProfilePhoto", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function deleteJSPicture() {
  return get(API_URL + "JobSeeker/DeleteJobSeekerProfilePhoto").then((res) => res.data);
}

// export function getSTMPicture() {
//   return fetch(API_URL + "SupportTeamMember/GetSupportTeamMemberProfilePhoto", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
//   }).then((res) => res.json());
// }

export function getSTMPicture() {
  return get(API_URL + "SupportTeamMember/GetSupportTeamMemberProfilePhoto"
  ).then((res) => res.data);
}

// export function setSTMPicture(data) {
//   return fetch(API_URL + "SupportTeamMember/UploadSupportTeamMemberProfilePhotoUsingIFormFile", {
//     method: "POST",
//     headers: { Authorization: "Bearer " + getData("token"), "Accept": "*/*" },
//     body: data
//   }).then((res) => res.json());
// }

export function setSTMPicture(data) {
  return post(API_URL + "SupportTeamMember/UploadSupportTeamMemberProfilePhotoUsingIFormFile", data).then((res) => res.json());
}

// export function deleteSTMPicture() {
//   return fetch(API_URL + "SupportTeamMember/DeleteSupportTeamMemberProfilePhoto", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function deleteSTMPicture() {
  return get(API_URL + "SupportTeamMember/DeleteSupportTeamMemberProfilePhoto"
  ).then((res) => res.data);
}

// export function getAGLPicture() {
//   return fetch(API_URL + "AffiliateGroupLeader/GetAffiliateGroupLeaderProfilePhoto", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token"), "content-type": "application/json; charset=UTF-8" },
//   }).then((res) => res.json());
// }

export function getAGLPicture() {
  return get(API_URL + "AffiliateGroupLeader/GetAffiliateGroupLeaderProfilePhoto"
  ).then((res) => res.data);
}

// export function setAGLPicture(data) {
//   return fetch(API_URL + "AffiliateGroupLeader/UploadAffiliateGroupLeaderProfilePhotoUsingIFormFile", {
//     method: "POST",
//     headers: { Authorization: "Bearer " + getData("token"), "Accept": "*/*" },
//     body: data
//   }).then((res) => res.json());
// }

export function setAGLPicture(data) {
  return post(API_URL + "AffiliateGroupLeader/UploadAffiliateGroupLeaderProfilePhotoUsingIFormFile", data
  ).then((res) => res.data);
}

// export function deleteAGLPicture() {
//   return fetch(API_URL + "AffiliateGroupLeader/DeleteAffiliateGroupLeaderProfilePhoto", {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((res) => res.json());
// }

export function deleteAGLPicture() {
  return get(API_URL + "AffiliateGroupLeader/DeleteAffiliateGroupLeaderProfilePhoto").then((res) => res.data);
}

// export function getPicture() {
//   return fetch(`${API_URL}user/userprofile`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getPicture() {
  return get(`${API_URL}user/userprofile`).then((res) => res.data);
}

export function getCountry() {
  return fetch(`${API_URL}data/countries`).then((data) => data.json());
}

export function getStateByCountry(countryId) {
  return fetch(`${API_URL}data/statesbycountry/` + countryId).then((data) =>
    data.json()
  );
}

export function getIndustry() {
  return fetch(`${API_URL}data/industries`).then((data) => data.json());
}

// export function getGroups() {
//   return fetch(`${API_URL}data/groups`).then((data) => data.json());
// }

export function getGroups() {
  return get(`${API_URL}data/groups`).then((res) => res.data);
}

// export function getJobStatus() {
//   return fetch(`${API_URL}data/jobstatuses`).then((data) => data.json());
// }

export function getJobStatus() {
  return get(`${API_URL}data/jobstatuses`).then((res) => res.data);
}

// export function getJobTypes() {
//   return fetch(`${API_URL}data/jobtypes`).then((data) => data.json());
// }
export function getJobTypes() {
  return get(`${API_URL}data/jobtypes`).then((res) => res.data);
}

// export function getEmployerDetails(userName) {
//   return fetch(`${API_URL}Employer/GetEmployer?UserName=` + userName).then(
//     (data) => data.json()
//   );
// }

export function getEmployerDetails(userName) {
  return get(`${API_URL}Employer/GetEmployer?UserName=` + userName).then(
    (res) => res.data);
}

// export function getJobseekerDetails(userName) {
//   return fetch(`${API_URL}Jobseeker/GetJobseeker?UserName=` + userName).then(
//     (data) => data.json()
//   );
// }

export function getJobseekerDetails(userName) {
  return get(`${API_URL}Jobseeker/GetJobseeker?UserName=` + userName).then(
    (res) => res.data);
}

// export function getSupportTeamDetails(userName) {
//   return fetch(
//     `${API_URL}SupportTeam/GetSupportTeam?UserName=` + userName
//   ).then((data) => data.json());
// }

export function getSupportTeamDetails(userName) {
  return get(`${API_URL}SupportTeam/GetSupportTeam?UserName=` + userName).then((res) => res.data);
}

// export function getGroupLeaderDetails(userName) {
//   return fetch(
//     `${API_URL}GroupLeader/GetGroupLeader?UserName=` + userName
//   ).then((data) => data.json());
// }

export function getGroupLeaderDetails(userName) {
  return get(`${API_URL}GroupLeader/GetGroupLeader?UserName=` + userName).then((res) => res.data);
}

// export function getGroupCategoriesByGroup(groupId) {
//   return fetch(`${API_URL}data/groupcategoriesbygroup/` + groupId).then(
//     (data) => data.json()
//   );
// }

export function getGroupCategoriesByGroup(groupId) {
  return get(`${API_URL}data/groupcategoriesbygroup/` + groupId).then(
    (res) => res.data);
}
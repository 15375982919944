import { useState } from "react";
import { NavLink } from "react-router-dom";
import { jobseekerStmCount } from "../Utilities/Helper";

export default function SeekerMenu({ show, setToggleFalse }) {
  const [linkDisabled, setlinkDisabled] = useState(true);

  const handleClick = (e) => {
    if (linkDisabled) e.preventDefault();
  };

  return (
    <ul>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/seeker/dashboard`}
        >
          <span className="icon icon-wysiwyg"></span>
          Dashboard
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        {show ?
          <NavLink
            activeClassName="is-active"
            to={`${process.env.PUBLIC_URL}/network`}
          >
            <span className="icon icon-people_outline"></span> Network
          </NavLink>
          :
          <NavLink
            activeClassName="is-active"
            to={`${process.env.PUBLIC_URL}/network`}
            onClick={handleClick}
            style={{ color: "#c7c7c7" }}
          >
            <span className="icon icon-people_outline disabled"></span> Network
          </NavLink>
        }
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/jobseeker/jobs`}
        >
          <span className="icon icon-manage_search"></span> Jobs
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/jobseeker/myresumes`}
        >
          <span className="icon icon-file_present"></span> My Resume
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/jobseeker/messages`}
        >
          <span className="icon icon-chat_bubble_outline"></span> Messages
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        {show ?
          <NavLink
            activeClassName="is-active"
            to={`${process.env.PUBLIC_URL}/SeekersConnections`}
          >
            <span className="icon icon-group-add"></span> Connections
          </NavLink>
          :
          <NavLink
            activeClassName="is-active"
            to={`${process.env.PUBLIC_URL}/SeekersConnections`}
            onClick={handleClick}
            style={{ color: "#c7c7c7" }}
          >
            <span className="icon icon-group-add"></span> Connections
          </NavLink>}
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/jobseeker/sponsorship`}
        >
          <span className="icon icon-people_outline"></span> Sponsorships
        </NavLink>
      </li>
    </ul>
  );
}

// export default SeekerMenu;

import { API_URL } from "../Utilities/Helper";
import { get } from "./AxiosService";

// export function getJobSeekerConnections(filter) {
//   return fetch(`${API_URL}JobSeeker/GetJobSeekerConnectionPageList?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeekerConnections(filter) {
  return get(`${API_URL}JobSeeker/GetJobSeekerConnectionPageList?` + filter
  ).then((res) => res.data);
}

// export function getConnectionResponse(jobSeekerConnectionId) {
//   return fetch(
//     `${API_URL}JobSeeker/ViewConnectionResponse?JobSeekerConnectionId=` +
//     jobSeekerConnectionId,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   ).then((data) => data.json());
// }

export function getConnectionResponse(jobSeekerConnectionId) {
  return get(
    `${API_URL}JobSeeker/ViewConnectionResponse?JobSeekerConnectionId=` +
    jobSeekerConnectionId).then((res) => res.data);
}
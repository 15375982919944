import React, { useState, useEffect } from "react";
import { Col, Row, Form, Badge } from "react-bootstrap";

import ListPagination from "../../../Components/Pagination";

import GroupLeaderJobsList from "../../../Components/GroupLeaderJobsList";
import { getAllJobs } from "../../../Services/AllJobsService";
import { getIndustry } from "../../../Services/GetFields";
import {
  groupLeaderJobsPerPage,
  sortParamsForJob,
} from "../../../Utilities/Helper";
import SearchJob from "../../../Components/SearchJob";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { globalSearchAction } from "../../../Redux/GlobalSearch/Action";

export default function GroupLeaderJobs() {
  //Using Redux
  const store = useSelector((res) => res.GlobalSearchReducer.jobs);

  const [jobs, setJobs] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [currentPage, setCurrentPage] = useState({ value: "0", filter: "" });
  const [searchParams, setSearchParams] = useState("");
  const [totalJobs, setTotalJobs] = useState();
  const [refreshTable, setRefreshTable] = useState(true);
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [showPagination, setShowPagination] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function allJobs() {
      setShowPagination(true);
      const filter =
        "recordperpage=" +
        groupLeaderJobsPerPage +
        currentPage.filter +
        searchParams +
        sort.filter;
      getAllJobs(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobs(result.jobs);
          setTotalJobs(result.totalRecord);
          setShowSpinner(false);
        })
        .catch((err) => {
          console.log("Jobs: ", err);
          setShowSpinner(false);
        });
    }
    if (store === null) {
      allJobs();
    } else {
      setShowSpinner(true);
      setJobs(store.items);
      setTotalJobs(store.totalRecord);
      setShowSpinner(false);
    }
  }, [refreshTable, store]);

  useEffect(() => {
    async function allIndustries() {
      getIndustry()
        .then((res) => {
          const result = JSON.parse(res.response);
          setIndustries(result);
        })
        .catch((err) => {
          console.log("Industry: ", err);
        });
    }
    allIndustries();
  }, []);

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      refreshTableHandler(page);
    }
  };

  const refreshTableHandler = (page = 0) => {
    if (store !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store.recordperpage,
          page: currentPage.value,
          type: store.type,
          SerachText: store.SerachText,
          GlobalSearchTypeId: store.GlobalSearchTypeId,
          searchTypeText: store.searchTypeText,
        })
      );
      setShowSpinner(true);
    } else {
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const onSerchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  const sortingChangeHandler = (e) => {
    var val = e.target.value;
    setSort((prev) => ({ ...prev, filter: `&sort=${val}` }));
    refreshTableHandler();
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Jobs{" "}
        <Badge
          pill
          bg="danger"
          className="bg-warning text-white px-3 ml-3 font-weight-normal"
        >
          {totalJobs}
        </Badge>
      </h1>
      <div className="dashboard-wrapper resumes-page">
        {/* <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg mb-4 saved-resume  hide-btns hide-heart-icon">
              <h2 className="page-sub-title">Drafts</h2>
             
              <EmployerJobsList jobslist={AllJobs}/>
              <ListPagination/>
            </div>
          </Col>
          </Row>  */}
        <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg hide-draft">
              <div className="search">
                <SearchJob
                  industriesList={industries}
                  searchParam={onSerchParamHandler}
                />
              </div>

              <div className="sort">
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="w-25"
                >
                  {" "}
                  <label>
                    <span>Sort By:</span>
                  </label>
                  <Form.Control
                    as="select"
                    className="inputclass form-control"
                    onChange={sortingChangeHandler}
                  >
                    {sortParamsForJob.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              {showSpinner && <SpinnerLoader />}
              {jobs.length === 0 && !showSpinner && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              <GroupLeaderJobsList jobList={jobs} />
              {jobs.length !== 0 && showPagination && (
                <ListPagination
                  recordPerPage={groupLeaderJobsPerPage}
                  TotalRecord={totalJobs}
                  className="mt-3"
                  onPageChange={pageChangeHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default Jobs;

// jobs

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_USER":
      const users = state.users.concat(action.payload);
      return { ...state, users };
    default:
      return state;
  }
};

// postJob
export const postJobReducer = (state = {}, action) => {
  // console.log(action,"action")
  // console.log(state,"state");
  switch (action.type) {
    case "POST_JOB":
      const postJobDetails = state.postJobDetails.concat(action.payload);
      return { ...state, postJobDetails };
    default:
      return state;
  }
};

const regex = {
  email: new RegExp(
    "^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
  ),
  number: new RegExp("^[0-9]+$"),
};

export class Validators {
  static isNull(value, message) {
    if (value === "" || value === " ") {
      return true;
    } else {
      return false;
    }
  }

  static isName(value, message) {
    var reg = /^[ a-zA-Z_'"-]+$/;
    if (!reg.test(value)) {
      return { error: true, message: "Enter valid " + message };
    } else {
      return { error: false };
    }
  }

  static isSingleName(value, message) {
    var reg = /^[a-zA-Z_'"-]+$/;
    if (!reg.test(value)) {
      return { error: true, message: "Enter valid " + message };
    } else {
      return { error: false };
    }
  }

  static isEmail(value, message) {
    var reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!reg.test(value)) {
      return { error: true, message: "Enter valid " + message };
    } else {
      return { error: false };
    }
  }

  static isPhone(value, message) {
    // var reg = /^[0-9]{11}$/;
    var reg = /^(\d{10}|\d{11})$/;
    if (!reg.test(value)) {
      return { error: true, message: "Enter valid " + message };
    } else {
      return { error: false };
    }
  }

  static isUndefined(value, message) {
    if (
      value === "" ||
      value === undefined ||
      value === null ||
      value === "undefined"
    ) {
      return true;
    } else {
      return false;
    }
  }

  static email(value, message) {
    if (value) {
      const result = regex.email.test(value);
      if (!result) return { error: true, message };
    }
    return false;
  }

  static required(value, message) {
    if (!value || !value.toString().trim().length) {
      return { error: true, message };
    }
    return false;
  }

  static number(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.number.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }
  static formatPhoneNumber = (value) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
    // we need to return the value with no formatting if its less then four digits this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 1) return `+(${phoneNumber})`;
    // if phoneNumberLength is greater than 1 and less the 4 we start to return the formatted number
    if (phoneNumberLength < 7) {
      return `+(${phoneNumber.slice(0, 1)}) ${phoneNumber.slice(1)}`;
    }
    // finally, if the phoneNumberLength is greater then seven, we add the last bit of formatting and return it.
    return `+(${phoneNumber.slice(0, 1)}) ${phoneNumber.slice(
      1,
      4
    )}-${phoneNumber.slice(4, 11)}`;
  };
}

export const validateInput = (validators, value) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }
  }
  return false;
};

export const validateCharacterAndSpace = (value, message) => {
  var reg = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
  if (!reg.test(value)) {
    return { error: true, message: "Enter valid " + message };
  } else {
    return { error: false };
  }
};

export const validateCharacterSpaceAndDigit = (value, message) => {
  var reg = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
  if (!reg.test(value)) {
    return { error: true, message: "Enter valid " + message };
  } else {
    return { error: false };
  }
};

export const validatePosition = (value, message) => {
  var reg = /^[ A-Za-z0-9.#&+_'"-]*$/;
  if (!reg.test(value)) {
    return { error: true, message: "Enter valid " + message };
  } else {
    return { error: false };
  }
};

export const validatePayrate = (value, message) => {
  var reg = /^[ A-Za-z0-9,/$£-]*$/;
  if (!reg.test(value)) {
    return { error: true, message: "Enter valid " + message };
  } else {
    return { error: false };
  }
};

export const validateCompany = (value, message) => {
  var reg = /^[ A-Za-z0-9,.@&_'"*-]*$/;
  if (!reg.test(value)) {
    return { error: true, message: "Enter valid " + message };
  } else {
    return { error: false };
  }
};

export const validateCity = (value, message) => {
  var reg = /^[ A-Za-z0-9,.&_'"-]*$/;
  if (!reg.test(value)) {
    return { error: true, message: "Enter valid " + message };
  } else {
    return { error: false };
  }
};

export const validateURL = (value, message) => {
  var reg =
    /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
  if (!reg.test(value)) {
    return { error: true, message: "Enter valid " + message };
  } else {
    return { error: false };
  }
};

export const validateCharacterAndDigit = (value, message) => {
  var reg = /^[A-Za-z0-9]*[A-Za-z0-9][A-Za-z0-9]*\s*$/;
  if (!reg.test(value)) {
    return { error: true, message: "Enter valid " + message };
  } else {
    return { error: false };
  }
};

export const required = (value, message) => {
  if (!value || !value.toString().trim().length) {
    return { error: true, message: message + " is required" };
  }
  return { error: false };
};

export const isImage = (filename) => {
  var parts = filename.split('.');
  var ext = parts[parts.length - 1];
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
    case 'jpeg':
      //etc
      return true;
  }
  return false;
};

// export const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;
export const passwordRegex = /^(?=.*[0-9])(?=.*[~!@#$%^&*)(_+:[}="`\-\{\]\\\|\;\'\,\<\.\>\/\?])(?=.*[a-z])(?=.*[A-Z])[~!@#$%^&*)(+:[}="`\w\-\{\]\\\|\;\'\,\<\.\>\/\?]{8,}$/;
import React, { useState, useEffect, Component, Fragment } from "react";
import { Col, Row, Form, Badge } from "react-bootstrap";
import { FaRegListAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import ReportAbuseList from "../../../Components/ReportAbuseList";
import {
  addReportAbuseComment,
  getReportAbuseList,
} from "../../../Services/AbuseReport";
import ListPagination from "../../../Components/Pagination";
import {
  groupLeaderAbuseReportsPerPage,
  Roles,
} from "../../../Utilities/Helper";
import { toast } from "react-toastify";
import { getUserTypes } from "../../../Services/AdminManageUsersServices";
import Buttons from "../../../Components/Shared/Button";
import AbuseReportSearch from "../../../Components/AbuseReportSearch";

export default function AdminReportAbuse() {
  const [reportAbuse, setReportAbuse] = useState([]);
  const [totalReportAbuse, setTotalReportAbuse] = useState();
  const [currentPage, setCurrentPage] = useState({ value: "", filter: "" });
  const [showSpinnerAbuseReport, setShowSpinnerAbuseReport] = useState(true);
  const [refreshTable, setRefreshTable] = useState(true);

  const [usersTypes, setUsersTypes] = useState([]);
  const [searchParams, setSearchParams] = useState("");

  useEffect(() => {
    function getAllUserTypes() {
      getUserTypes()
        .then((res) => {
          setUsersTypes(res.result);
        })
        .catch((err) => {
          console.log(err);
          // toast.error("something went wrong");
        });
    }
    getAllUserTypes();
  }, []);

  useEffect(() => {
    const filter =
      "recordperpage=" +
      groupLeaderAbuseReportsPerPage +
      currentPage.filter +
      searchParams;

    async function reportAbuseData() {
      getReportAbuseList(filter)
        .then((res) => {
          var result = JSON.parse(res.response);
          setReportAbuse(result.abuseReports);
          setTotalReportAbuse(result.totalRecord);
          setShowSpinnerAbuseReport(false);
        })
        .catch((err) => {
          console.log("Repost Abuse List: ", err);
          setShowSpinnerAbuseReport(false);
        });
    }
    reportAbuseData();
  }, [refreshTable]);

  const refreshTableHandler = () => {
    setRefreshTable((prev) => !prev);
    setShowSpinnerAbuseReport(true);
  };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ ...prev, filter: `&page=${page}` }));
      refreshTableHandler();
    }
  };

  const addCommentHandler = (data) => {
    addReportAbuseComment(data)
      .then((res) => {
        refreshTableHandler();
        toast.success(res.response, { toastId: "custom_toast" });
      })
      .catch((err) => {
        console.log("Add Comment: ", err);
        // console.log(err);
      });
  };

  const onSerchParamHandler = (params) => {
    setSearchParams(params);
    setCurrentPage((prev) => ({ ...prev, filter: "" }));
    refreshTableHandler();
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Report Abuse{" "}
        <Badge
          pill
          bg="danger"
          className="bg-warning text-white px-3 ml-3 font-weight-normal"
        >
          {totalReportAbuse}
        </Badge>
      </h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg sponsership-page">
              <div className="search">
                <AbuseReportSearch
                  //groupId={jobSeekerGroupId}
                  userTypeList={usersTypes}
                  searchParam={onSerchParamHandler}
                />
              </div>
              <ReportAbuseList
                reports={reportAbuse}
                showSpinner={showSpinnerAbuseReport}
                addComment={addCommentHandler}
              />
              {reportAbuse.length !== 0 && (
                <ListPagination
                  recordPerPage={groupLeaderAbuseReportsPerPage}
                  TotalRecord={totalReportAbuse}
                  className="mt-3"
                  onPageChange={pageChangeHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

import React, { useState, Component, Fragment } from "react";
import { Col, Row, Form, Badge } from "react-bootstrap";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import {
  required,
  validateCharacterAndDigit,
  validateCharacterAndSpace,
  validateCharacterSpaceAndDigit,
  validateCity,
  validateCompany,
  validatePosition,
  Validators,
} from "../../../Utilities/Validator";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FaTimesCircle } from "react-icons/fa";
import {
  AddOrUpdateJINTemplate,
  getJINTemplateDetails,
  getProfanitiesWords,
} from "../../../Services/ResumeService";
import {
  getCountry,
  getIndustry,
  getStateByCountry,
  getUserDetails,
} from "../../../Services/GetFields";
import Select from "react-select";
import { toast } from "react-toastify";
import { ProfanitiesWordKeysToReplace } from "../../../Utilities/Helper";

export default class JINTemplatePrivate extends Component {
  state = {
    paramId: null,
    jinTemplateId: "",
    name: "",
    email: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    stateName: "",
    country: "",
    countryName: "",
    stateList: [],
    countryList: [],
    zip: "",
    jobTitle: "",
    industryList: [],
    coreCompetencity: "",
    coreCompetencies: [],
    industry: "",
    careerObjective: "",
    coverLetter: "",
    resumeTitle: "",
    isPublished: null,
    // Start Education
    stateForEdu: "",
    cityForEdu: "",
    stateNameForEdu: "",
    stateListForEdu: [],
    countryListForEdu: [],
    countryForEdu: "",
    countryNameForEdu: "",
    BadgestateNameForEdu: "",
    education: [],
    course: "",
    specialization: "",
    passingYear: "",
    marks: "",
    university: "",
    // End

    // Start Profession
    stateForProfess: "",
    countryNameForProfess: "",
    organization: "",
    cityForProfess: "",
    designation: "",
    startDate: null,
    endDate: null,
    jobProfile: "",
    stateNameForProfess: "",
    BadgestateNameForProfess: "",
    stateListForProfess: [],
    countryListForProfess: [],
    countryForProfess: "",
    professionalExperiences: [],
    noticePeriod: "",
    // End
    //Start Addition Skill
    additionalSkills: [],
    additionalSkillName: "",
    // End
    // Start
    awardsAndHonors: [],
    awardAndHonor: "",
    //End
    showStateNameField: false,
    showStateNameFieldForProfess: false,
    showStateNameFieldForEdu: false,
    jinTemplatesIndustries: [],

    // Validation

    nameError: { error: null, message: "" },
    address1Error: { error: null, message: "" },
    address2Error: { error: null, message: "" },
    emailError: { error: null, message: "" },
    phoneError: { error: null, message: "" },
    resumeTitleError: { error: null, message: "" },
    countryError: { error: null, message: "" },
    stateError: { error: null, message: "" },
    stateNameError: { error: null, message: "" },
    cityError: { error: null, message: "" },
    zipError: { error: null, message: "" },
    jobTitleError: { error: null, message: "" },
    industryError: { error: null, message: "" },
    coreCompetencityError: { error: null, message: "" },
    organizationError: { error: null, message: "" },
    countryProfessError: { error: null, message: "" },
    stateProfessError: { error: null, message: "" },
    stateNameProfessError: { error: null, message: "" },
    cityProfessError: { error: null, message: "" },
    designationError: { error: null, message: "" },
    startDateError: { error: null, message: "" },
    endDateError: { error: null, message: "" },
    noticePeriodError: { error: null, message: "" },
    additionalSkillNameError: { error: null, message: "" },
    awardAndHonorError: { error: null, message: "" },
    courseError: { error: null, message: "" },
    specializationError: { error: null, message: "" },
    passingYearError: { error: null, message: "" },
    universityError: { error: null, message: "" },
    marksError: { error: null, message: "" },
    countryEduError: { error: null, message: "" },
    stateEduError: { error: null, message: "" },
    stateNameEduError: { error: null, message: "" },
    cityEduError: { error: null, message: "" },
    jobProfileError: { error: null, message: "" },
    eduStartDate: null,
    eduEndDate: null,
    eduStartDateError: { error: null, message: "" },
    eduEndDateError: { error: null, message: "" },
    careerObjectiveError: { error: null, message: "" },
    coverLetterError: { error: null, message: "" },
    disablePublish: false,
    badgeEducation: [],
    badgeProfessionalExperiences: [],

    //update Education
    eduUpateIndex: null,
    showEduUpadateOption: false,

    //update Profession
    showProfessionUpadateOption: false,
    professionUpateIndex: null,

    //update Core Compentesis
    compentesisUpateIndex: null,
    showCompentesisUpadateOption: false,

    //update Additional Skills
    skillsUpateIndex: null,
    showSkillsUpadateOption: false,

    //update Awards and Honors
    AwardsHonorsUpateIndex: null,
    showAwardsHonorsUpadateOption: false,

    ProfanitiesWords: null,
  };

  // DatePicker settings

  required = (value, message) => {
    if (!value || !value.toString().trim().length) {
      return true;
    }
    return false;
  };

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
    this.eduInput = React.createRef();
    this.eduTextInput = this.eduTextInput.bind(this);
    this.minDate = new moment(props.minDate);
    this.maxDate = new moment(props.maxDate);
    this.state = {
      paramId: null,
      startDate: props.minDate,
      endDate: null,
      eduStartDate: props.minDate,
      eduEndDate: null,
      selectedValue: "Nothing selected",
      jinTemplateId: "",
      education: [],
      coreCompetencies: [],
      professionalExperiences: [],
      awardsAndHonors: [],
      additionalSkills: [],
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleEduDateChange = this.handleEduDateChange.bind(this);
    this.handleEduEndDateChange = this.handleEduEndDateChange.bind(this);
  }

  focusTextInput() {
    this.textInput.current.setFocus();
  }

  eduTextInput() {
    this.eduInput.current.setFocus();
  }

  handleDateChange(date) {
    this.setState({
      startDate: date,
      endDate: null,
      startDateError: { error: false, message: "" },
    });
  }

  handleEndDateChange(date) {
    this.setState({
      endDate: date,
      endDateError: { error: false, message: "" },
    });
  }

  handleEduDateChange(date) {
    this.setState({
      eduStartDate: date,
      eduEndDate: null,
      eduStartDateError: { error: false, message: "" },
    });
  }

  handleEduEndDateChange(date) {
    this.setState({
      eduEndDate: date,
      eduEndDateError: { error: false, message: "" },
    });
  }

  validateContent(value) {
    const ProfanitiesWords = this.state.ProfanitiesWords;
    let data;
    if (value != null) {
      data = value.toLowerCase();
      ProfanitiesWordKeysToReplace.forEach((item) => {
        data = data.replaceAll(item, "");
      });
      data = data.replace(/\s/g, "~");
      let list = data.split("~");
      if (list.some((w) => ProfanitiesWords.includes(w))) {
        return true;
      }
    }
    return false;
  }

  handleOnBlur = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "name") {
      if (this.required(value)) {
        this.setState({
          nameError: { error: true, message: "Name is required" },
        });
        return;
      }
      const error = Validators.isName(value);
      if (error.error) {
        this.setState({
          nameError: { error: true, message: "Name is invalid" },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          nameError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ nameError: { error: false, message: "" } });
    } else if (key === "email") {
      if (this.required(value)) {
        this.setState({
          emailError: { error: true, message: "Email is required" },
        });
        return;
      }
      const error = Validators.isEmail(value);
      if (error.error) {
        this.setState({
          emailError: { error: true, message: "Email is invalid" },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          emailError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ emailError: { error: false, message: "" } });
    } else if (key === "phone") {
      //  if (this.required(value)) {
      //   this.setState({
      //     phoneError: { error: true, message: "Phone is required" },
      //   });
      //   return;
      // }
      const error = Validators.isPhone(value);
      if (error.error && !this.required(value)) {
        this.setState({
          phoneError: { error: true, message: "Phone number is invalid" },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          phoneError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ phoneError: { error: false, message: "" } });
    } else if (key === "address1") {
      if (this.required(value)) {
        this.setState({
          address1Error: { error: true, message: "Address line 1 is required" },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          address1Error: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ address1Error: { error: false, message: "" } });
    } else if (key === "address2") {
      if (this.validateContent(value)) {
        this.setState({
          address2Error: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ address2Error: { error: false, message: "" } });
      // if (this.required(value)) {
      //   this.setState({
      //     address2Error: { error: true, message: "Address line 2 is required" },
      //   });
      //   return;
      // }
      // this.setState({ address2Error: { error: false, message: "" } });
    } else if (key === "stateName") {
      if (this.required(value)) {
        this.setState({
          stateNameError: { error: true, message: "Name is required" },
        });
        return;
      }
      const error = validateCharacterAndSpace(value);
      if (error.error) {
        this.setState({
          stateNameError: { error: true, message: "State name is invalid" },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          stateNameError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ stateNameError: { error: false, message: "" } });
    } else if (key === "city") {
      if (this.required(value)) {
        this.setState({
          cityError: { error: true, message: "City is required" },
        });
        return;
      }
      const error = validateCity(value);
      if (error.error) {
        this.setState({
          cityError: { error: true, message: "City is invalid" },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          cityError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ cityError: { error: false, message: "" } });
    } else if (key === "zip") {
      // if (this.required(value)) {
      //   this.setState({
      //     zipError: { error: true, message: "ZipCode is required" },
      //   });
      //   return;
      // }
      const error = validateCharacterAndDigit(value);
      if (error.error && !this.required(value)) {
        this.setState({
          zipError: { error: true, message: "ZipCode is invalid" },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          zipError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ zipError: { error: false, message: "" } });
    } else if (key === "jobTitle") {
      if (this.required(value)) {
        this.setState({
          jobTitleError: {
            error: true,
            message: "Professional title is required",
          },
        });
        return;
      }
      const error = validatePosition(value);
      if (error.error) {
        this.setState({
          jobTitleError: {
            error: true,
            message: "Professional title is invalid",
          },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          jobTitleError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ jobTitleError: { error: false, message: "" } });
    } else if (key === "resumeTitle") {
      if (this.required(value)) {
        this.setState({
          resumeTitleError: {
            error: true,
            message: "Resume title is required",
          },
        });
        return;
      }
      const error = validateCharacterSpaceAndDigit(value);
      if (error.error) {
        this.setState({
          resumeTitleError: { error: true, message: "Resume title is invalid" },
        });
        return;
      }
      this.setState({ resumeTitleError: { error: false, message: "" } });
    } else if (key === "noticePeriod") {
      const error = validateCharacterSpaceAndDigit(value);
      if (error.error && !this.required(value)) {
        this.setState({
          noticePeriodError: {
            error: true,
            message: "Notice period is invalid",
          },
        });
        return;
      }
      this.setState({ noticePeriodError: { error: false, message: "" } });
    } else if (key == "careerObjective") {
      if (this.required(value)) {
        this.setState({
          careerObjectiveError: {
            error: true,
            message: "Career Objective is required",
          },
        });
        return;
      }
      if (this.validateContent(value)) {
        this.setState({
          careerObjectiveError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ careerObjectiveError: { error: false, message: "" } });
    } else if (key === "coverLetter") {
      if (this.validateContent(value)) {
        this.setState({
          coverLetterError: { error: true, message: "Disallowed Content" },
        });
        return;
      }
      this.setState({ coverLetterError: { error: false, message: "" } });
    } else if (key == "organization") {
      if (this.validateContent(value)) {
        this.setState({
          organizationError: { error: true, message: "Disallowed Content" },
        });
      } else {
        this.setState({
          organizationError: { error: false, message: "" },
        });
      }
    } else if (key == "stateNameForProfess") {
      const stateError = validateCharacterAndSpace(value);
      if (stateError.error && !this.required(value)) {
        this.setState({
          stateNameProfessError: {
            error: true,
            message: "State name is invalid",
          },
        });
      } else {
        if (this.validateContent(value)) {
          this.setState({
            stateNameProfessError: {
              error: true,
              message: "Disallowed Content",
            },
          });
        } else {
          this.setState({
            stateNameProfessError: { error: false, message: "" },
          });
        }
      }
    } else if (key == "cityForProfess") {
      const errorCity = validateCity(value);
      if (errorCity.error && !this.required(value)) {
        this.setState({
          cityProfessError: { error: true, message: "City is invalid" },
        });
      } else {
        if (this.validateContent(value)) {
          this.setState({
            cityProfessError: { error: true, message: "Disallowed Content" },
          });
        } else {
          this.setState({
            cityProfessError: { error: false, message: "" },
          });
        }
      }
    } else if (key == "designation") {
      const errorDesignation = validatePosition(this.state.designation);
      if (errorDesignation.error && !this.required(value)) {
        this.setState({
          designationError: { error: true, message: "Position is invalid" },
        });
      } else {
        if (this.validateContent(value)) {
          this.setState({
            designationError: { error: true, message: "Disallowed Content" },
          });
        } else {
          this.setState({
            designationError: { error: false, message: "" },
          });
        }
      }
    } else if (key == "jobProfile") {
      if (this.validateContent(value)) {
        this.setState({
          jobProfileError: { error: true, message: "Disallowed Content" },
        });
      } else {
        this.setState({
          jobProfileError: { error: false, message: "" },
        });
      }
    }
    ///Education
    else if (key == "university") {
      const errorUniversity = validateCity(value);
      if (errorUniversity.error && !this.required(value)) {
        this.setState({
          universityError: {
            error: true,
            message: "University/School is invalid",
          },
        });
      } else {
        if (this.validateContent(value)) {
          this.setState({
            universityError: { error: true, message: "Disallowed Content" },
          });
        } else {
          this.setState({
            universityError: { error: false, message: "" },
          });
        }
      }
    } else if (key == "course") {
      if (this.validateContent(value)) {
        this.setState({
          courseError: { error: true, message: "Disallowed Content" },
        });
      } else {
        this.setState({
          courseError: { error: false, message: "" },
        });
      }
    } else if (key == "stateNameForEdu") {
      console.log("check");
      const stateError = validateCharacterAndSpace(value);
      if (stateError.error && !this.required(value)) {
        this.setState({
          stateNameEduError: {
            error: true,
            message: "State name is invalid",
          },
        });
      } else {
        if (this.validateContent(value)) {
          this.setState({
            stateNameEduError: { error: true, message: "Disallowed Content" },
          });
        } else {
          this.setState({
            stateNameEduError: { error: false, message: "" },
          });
        }
      }
    } else if (key == "cityForEdu") {
      const errorCity = validateCity(value);
      if (errorCity.error && !this.required(value)) {
        this.setState({
          cityEduError: { error: true, message: "City is invalid" },
        });
      } else {
        if (this.validateContent(value)) {
          this.setState({
            cityEduError: { error: true, message: "Disallowed Content" },
          });
        } else {
          this.setState({
            cityEduError: { error: false, message: "" },
          });
        }
      }
    } else if (key == "specialization") {
      if (this.validateContent(value)) {
        this.setState({
          specializationError: { error: true, message: "Disallowed Content" },
        });
      } else {
        this.setState({
          specializationError: { error: false, message: "" },
        });
      }
    } else if (key == "additionalSkillName") {
      if (this.validateContent(this.state.additionalSkillName)) {
        this.setState({
          additionalSkillNameError: {
            error: true,
            message: "Disallowed Content",
          },
        });
      } else {
        this.setState({
          additionalSkillNameError: {
            error: false,
            message: "",
          },
        });
      }
    } else if (key == "awardAndHonor") {
      if (this.validateContent(this.state.awardAndHonor)) {
        this.setState({
          awardAndHonorError: { error: true, message: "Disallowed Content" },
        });
      } else {
        this.setState({
          awardAndHonorError: {
            error: false,
            message: "",
          },
        });
      }
    } else if (key == "coreCompetencity") {
      if (this.validateContent(this.state.coreCompetencity)) {
        this.setState({
          coreCompetencityError: { error: true, message: "Disallowed Content" },
        });
      } else {
        this.setState({ coreCompetencityError: { error: false, message: "" } });
      }
    }
  };

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  getCountryList() {
    getCountry()
      .then((res) => {
        const result = JSON.parse(res.response);
        this.setState({ countryList: result });
        this.setState({ countryListForEdu: result });
        this.setState({ countryListForProfess: result });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  countryChange(e) {
    const val = e.target.value;
    if (val === "0") {
      this.setState({
        countryError: {
          error: true,
          message: "Country is required",
        },
        stateList: [],
        country: val,
      });
      return;
    }
    this.setState({
      stateList: [],
      country: val,
      state: null,
      stateName: "",
      countryError: { error: false, message: "" },
    });
    var index = e.nativeEvent.target.selectedIndex;
    const countryText = e.nativeEvent.target[index].text;
    this.setState({ countryName: countryText });
    if (countryText.toLowerCase() === "other") {
      this.setState({
        stateName: "",
        stateError: { error: false, message: "" },
        stateNameError: { error: null, message: "" },
        showStateNameField: true,
      });
    } else {
      this.setState({
        showStateNameField: false,
        stateError: { error: null, message: "" },
        stateNameError: { error: false, message: "" },
      });

      getStateByCountry(val)
        .then((res) => {
          const result = JSON.parse(res.response);
          this.setState({ stateList: result });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  stateChange(e) {
    const val = e.target.value;
    this.setState({ state: val });
    if (val === "0") {
      this.setState({
        stateError: {
          error: true,
          message:
            this.state.country == 2
              ? "Province is required"
              : "State is required",
        },
        state: val,
      });
      return;
    }
    this.setState({
      stateError: { error: false, message: "" },
    });
  }

  stateChangesForProfess(e) {
    const val = e.target.value;
    if (val == "0") {
      this.setState({
        stateProfessError: {
          error: true,
          message: `${
            this.state.countryNameForProfess == "US" ? "State" : "Province"
          } is required`,
        },
      });
    } else {
      this.setState({ stateProfessError: { error: false, message: "" } });
    }
    var index = e.nativeEvent.target.selectedIndex;
    const StateText = e.nativeEvent.target[index].text;
    this.setState({
      stateForProfess: val,
      BadgestateNameForProfess: StateText,
    });
  }

  stateChangesForEdu(e) {
    const val = e.target.value;
    if (val == "0") {
      this.setState({
        stateEduError: {
          error: true,
          message: `${
            this.state.countryNameForEdu == "US" ? "State" : "Province"
          } is required`,
        },
      });
    } else {
      this.setState({ stateEduError: { error: false, message: "" } });
    }
    var index = e.nativeEvent.target.selectedIndex;
    const StateText = e.nativeEvent.target[index].text;
    this.setState({
      stateForEdu: val,
      BadgestateNameForEdu: StateText,
    });
  }

  countryChangesForEdu(e) {
    const val = e.target.value;
    if (val == 0) {
      this.setState({
        countryEduError: { error: false, message: "Country is required" },
      });
    } else {
      this.setState({ countryEduError: { error: false, message: "" } });
    }
    this.setState({ stateEduError: { error: false, message: "" } });
    this.setState({ stateNameEduError: { error: false, message: "" } });

    var index = e.nativeEvent.target.selectedIndex;
    const countryText = e.nativeEvent.target[index].text;
    this.setState({
      stateListForEdu: [],
      countryForEdu: val,
      stateForEdu: null,
      stateNameForEdu: "",
      countryNameForEdu: countryText,
    });
    if (countryText.toLowerCase() === "other") {
      this.setState({ stateNameForEdu: "", showStateNameFieldForEdu: true });
    } else {
      this.setState({ showStateNameFieldForEdu: false });
      getStateByCountry(val)
        .then((res) => {
          const result = JSON.parse(res.response);
          this.setState({ stateListForEdu: result });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  countryChangesProfess(e) {
    const val = e.target.value;
    if (val == 0) {
      this.setState({
        countryProfessError: { error: false, message: "Country is required" },
      });
    } else {
      this.setState({ countryProfessError: { error: false, message: "" } });
    }

    this.setState({ stateProfessError: { error: false, message: "" } });
    this.setState({ stateNameProfessError: { error: false, message: "" } });

    var index = e.nativeEvent.target.selectedIndex;
    const countryText = e.nativeEvent.target[index].text;
    this.setState({
      stateListForProfess: [],
      countryForProfess: val,
      stateForProfess: null,
      countryNameForProfess: countryText,
      stateNameForProfess: "",
    });
    if (countryText.toLowerCase() === "other") {
      this.setState({
        stateNameForProfess: "",
        showStateNameFieldForProfess: true,
      });
    } else {
      this.setState({ showStateNameFieldForProfess: false });
      getStateByCountry(val)
        .then((res) => {
          const result = JSON.parse(res.response);
          this.setState({ stateListForProfess: result });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // handleIndustryChangeBlur() {
  //   if (
  //     this.state.jinTemplatesIndustries === undefined ||
  //     this.state.jinTemplatesIndustries.length === 0 ||
  //     this.state.jinTemplatesIndustries === null
  //   ) {
  //     this.setState({
  //       industryError: { error: true, message: "Industry is required" },
  //     });
  //     return;
  //   }
  //   this.setState({
  //     industryError: { error: false, message: "" },
  //   });
  // }
  getResumeDetails(jinTemplateId) {
    getJINTemplateDetails(jinTemplateId)
      .then((res) => {
        const result = JSON.parse(res.response);
        //  console.log(result.jobSeekerId);
        let industryarr = result.jinTemplatesIndustries;
        let array = [];
        for (let i = 0; i < industryarr.length; i++) {
          array.push({
            industryId: industryarr[i].industryId,
            industryName: industryarr[i].industryName,
          });
        }
        this.setState({
          jinTemplateId: this.state.paramId,
          name: result.userName,
          jobTitle: result.jobTitle,
          city: result.city,
          phone: result.phoneNumber,
          address1: result.addressLine1,
          address2: result.addressLine2,
          zip: result.zipCode,
          careerObjective: result.careerObjective,
          coverLetter: result.coverLetter,
          //resumeTitle: result.templateName,
          //noticePeriod: result.noticePeriod,
          stateName: result.stateName,
          countryName: result.countryName,
          state: result.stateId,
          country: result.countryId,
          email: result.email,
          jobSeekerId: result.jobSeekerId,
          coreCompetencies: result.coreCompetencies,
          professionalExperiences: result.professionalExperiences,
          education: result.education,
          additionalSkills: result.additionalSkills,
          awardsAndHonors: result.awardsAndHonors,
          isPublished: this.state.paramId !== null ? result.isPublished : false,
          jinTemplatesIndustries: array,
        });

        getStateByCountry(result.countryId)
          .then((res) => {
            const resultState = JSON.parse(res.response);

            if (result.countryName.toLowerCase() === "other") {
              this.setState({
                showStateNameField: true,
                stateName: result.stateName,
                stateId: result.stateId,
              });
            } else {
              this.setState({
                stateList: resultState,
                state: result.stateId,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getUserInfo() {
    getUserDetails()
      .then((res) => {
        const result = res.response;

        if (result.jinTemplatePublicPrivateId !== null) {
          this.getResumeDetails(result.jinTemplatePublicPrivateId);
        } else {
          this.setState({
            name: result.firstName + " " + result.lastName,
            city: result.city,
            zip: result.zipCode,
            phone: result.phoneNumber,
            address1: result.addressLine1,
            address2: result.addressLine2,
            stateName: result.stateName,
            state: result.stateId,
            country: result.countryId,
            email: result.email,
          });
        }
        getStateByCountry(result.countryId)
          .then((res) => {
            const resultState = JSON.parse(res.response);

            if (result.countryName.toLowerCase() === "other") {
              this.setState({
                showStateNameField: true,
                stateName: result.stateName,
                stateId: result.stateId,
              });
            } else {
              this.setState({
                stateList: resultState,
                state: result.stateId,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  }

  handleIndustryChange = (event) => {
    let array = [];
    for (let i = 0; i < event.length; i++) {
      array.push({
        industryId: event[i].value,
        jinTemplateId: this.state.jinTemplateId,
        industryName: event[i].label,
      });
    }
    this.setState({ jinTemplatesIndustries: array });
  };

  getProfanitiesWordsForValidate() {
    getProfanitiesWords()
      .then((res) => {
        this.setState({ ProfanitiesWords: res.data.response });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getCountryList();
    this.getProfanitiesWordsForValidate();
    const param = this.props.match.params.jinTemplateId;

    if (param !== undefined) {
      this.setState({ paramId: param });
      this.getResumeDetails(param);
    } else {
      this.setState({ paramId: null });
      this.getUserInfo();
    }
    getIndustry()
      .then((res) => {
        const result = JSON.parse(res.response);
        let industryArray = [];
        for (let i = 0; i < result.length; i++) {
          industryArray.push({
            label: result[i].industryName,
            value: result[i].industryId,
          });
        }
        this.setState({ industryList: industryArray });
      })
      .catch((err) => {
        console.log("Industry:", err);
      });
  }

  addCoreCompetecies() {
    var array = this.state.coreCompetencies || [];
    if (this.required(this.state.coreCompetencity)) {
      this.setState({
        coreCompetencityError: {
          error: true,
          message: "This field is required",
        },
      });
      return;
    }
    if (this.validateContent(this.state.coreCompetencity)) {
      this.setState({
        coreCompetencityError: { error: true, message: "Disallowed Content" },
      });
      return;
    }
    if (this.state.showCompentesisUpadateOption) {
      array[this.state.compentesisUpateIndex].coreCompentencyName =
        this.state.coreCompetencity;
      this.setState({
        showCompentesisUpadateOption: false,
        compentesisUpateIndex: null,
      });
    } else {
      array.push({
        coreCompentencyName: this.state.coreCompetencity,
      });
    }

    this.setState({
      coreCompetencies: array,
      coreCompetencity: "",
      coreCompetencityError: { error: false, message: "" },
    });
  }

  removeCoreCompetecies(index) {
    var array = this.state.coreCompetencies;
    array.splice(index, 1);
    this.setState({ coreCompetencies: array });
    this.cancelCoreCompeteciesUpdate();
  }

  editCoreCompetecies(index) {
    var core = this.state.coreCompetencies[index];

    this.setState({
      compentesisUpateIndex: index,
      showCompentesisUpadateOption: true,
      coreCompetencity: core.coreCompentencyName,
    });
  }

  cancelCoreCompeteciesUpdate() {
    this.setState({
      coreCompetencity: "",
      showCompentesisUpadateOption: false,
      compentesisUpateIndex: null,
      coreCompetencityError: { error: false, message: "" },
    });
  }

  updateCoreCompetecies() {
    this.addCoreCompetecies();
  }

  addProfessionalExperience() {
    var array = this.state.professionalExperiences || [];

    var Error = false;
    if (this.required(this.state.organization)) {
      this.setState({
        organizationError: { error: true, message: "Organization is required" },
      });
      Error = true;
    } else {
      if (this.validateContent(this.state.organization)) {
        this.setState({
          organizationError: { error: true, message: "Disallowed Content" },
        });
        Error = true;
      } else {
        this.setState({
          organizationError: { error: false, message: "" },
        });
      }
    }

    if (this.required(this.state.designation)) {
      this.setState({
        designationError: { error: true, message: "Position is required" },
      });
      Error = true;
    } else {
      const errorDesignation = validatePosition(this.state.designation);
      if (errorDesignation.error && !this.required(this.state.designation)) {
        this.setState({
          designationError: { error: true, message: "Position is invalid" },
        });
        Error = true;
      } else {
        if (this.validateContent(this.state.designation)) {
          this.setState({
            designationError: { error: true, message: "Disallowed Content" },
          });
          Error = true;
        } else {
          this.setState({
            designationError: { error: false, message: "" },
          });
        }
      }
    }

    if (this.required(this.state.cityForProfess)) {
      this.setState({
        cityProfessError: { error: true, message: "City is required" },
      });
      Error = true;
    } else {
      const errorCity = validateCity(this.state.cityForProfess);
      if (errorCity.error && !this.required(this.state.cityForProfess)) {
        this.setState({
          cityProfessError: { error: true, message: "City is invalid" },
        });
        Error = true;
      } else {
        if (this.validateContent(this.state.cityForProfess)) {
          this.setState({
            cityProfessError: { error: true, message: "Disallowed Content" },
          });
          Error = true;
        } else {
          this.setState({
            cityProfessError: { error: false, message: "" },
          });
        }
      }
    }

    if (
      this.state.countryForProfess === "0" ||
      this.state.countryForProfess == undefined
    ) {
      this.setState({
        countryProfessError: { error: true, message: "Country is required" },
      });
      Error = true;
    } else {
      this.setState({ countryProfessError: { error: false, message: "" } });
    }

    if (this.state.countryNameForProfess !== "Other") {
      this.setState({ stateNameProfessError: { error: false, message: "" } });
      if (
        this.state.stateForProfess === "0" ||
        this.state.stateForProfess == undefined
      ) {
        this.setState({
          stateProfessError: {
            error: true,
            message:
              this.state.countryForProfess == 2
                ? "Province is required"
                : "State is required",
          },
        });
        Error = true;
      } else {
        this.setState({ stateProfessError: { error: false, message: "" } });
      }
    }

    if (this.state.countryNameForProfess === "Other") {
      this.setState({ stateProfessError: { error: false, message: "" } });
      if (this.required(this.state.stateNameForProfess)) {
        this.setState({
          stateNameProfessError: {
            error: true,
            message: "State name is required",
          },
        });
        Error = true;
      } else {
        const stateError = validateCharacterAndSpace(
          this.state.stateNameForProfess
        );
        if (
          stateError.error &&
          !this.required(this.state.stateNameForProfess)
        ) {
          this.setState({
            stateNameProfessError: {
              error: true,
              message: "State name is invalid",
            },
          });
          Error = true;
        } else {
          if (this.validateContent(this.state.stateNameForProfess)) {
            this.setState({
              stateNameProfessError: {
                error: true,
                message: "Disallowed Content",
              },
            });
            Error = true;
          } else {
            this.setState({
              stateNameProfessError: { error: false, message: "" },
            });
          }
        }
      }
    }

    var tempstart,
      tempend = null;
    if (this.state.startDate === undefined || this.state.startDate === null) {
      this.setState({
        startDateError: { error: true, message: "Start date is required" },
      });
      Error = true;
    } else {
      this.setState({ startDateError: { error: false, message: "" } });
      // var selectedDate = new Date(this.state.startDate);
      tempstart = moment(this.state.startDate).format("YYYY/MM/DD");
    }

    if (this.state.endDate === undefined || this.state.endDate === null) {
      this.setState({ endDateError: { error: false, message: "" } });
    } else {
      this.setState({ endDateError: { error: false, message: "" } });
      //var selectedDate = new Date(this.state.endDate);
      tempend = moment(this.state.endDate).format("YYYY/MM/DD");
    }

    if (this.validateContent(this.state.jobProfile)) {
      this.setState({
        jobProfileError: { error: true, message: "Disallowed Content" },
      });
      Error = true;
    } else {
      this.setState({
        jobProfileError: { error: false, message: "" },
      });
    }
    if (Error) {
      return true;
    }

    if (this.state.showProfessionUpadateOption === true) {
      array[this.state.professionUpateIndex].organization =
        this.state.organization;
      array[this.state.professionUpateIndex].specialization =
        this.state.specialization;
      array[this.state.professionUpateIndex].stateName =
        this.state.countryNameForProfess !== "Other"
          ? this.state.BadgestateNameForProfess
          : this.state.stateNameForProfess;
      array[this.state.professionUpateIndex].countryId =
        this.state.countryForProfess;
      array[this.state.professionUpateIndex].countryName =
        this.state.countryNameForProfess;
      array[this.state.professionUpateIndex].stateId =
        this.state.stateForProfess;
      array[this.state.professionUpateIndex].designation =
        this.state.designation;
      array[this.state.professionUpateIndex].startDate = tempstart;
      array[this.state.professionUpateIndex].endDate = tempend;
      array[this.state.professionUpateIndex].jobProfile = this.state.jobProfile;
      array[this.state.professionUpateIndex].tempStartDate =
        this.state.startDate;
      array[this.state.professionUpateIndex].tempEndDate = this.state.endDate;
      array[this.state.professionUpateIndex].city = this.state.cityForProfess;
      this.setState({
        showProfessionUpadateOption: false,
        professionUpateIndex: null,
      });
    } else {
      array.push({
        organization: this.state.organization,
        city: this.state.cityForProfess,
        stateName:
          this.state.countryNameForProfess === "Other"
            ? this.state.stateNameForProfess
            : this.state.BadgestateNameForProfess,
        countryId: this.state.countryForProfess,
        countryName: this.state.countryNameForProfess,
        stateId: this.state.stateForProfess,
        designation: this.state.designation,
        startDate: tempstart,
        endDate: tempend,
        jobProfile: this.state.jobProfile,
        tempStartDate: this.state.startDate,
        tempEndDate: this.state.endDate,
      });
    }

    this.setState({
      professionalExperiences: array,
      organization: "",
      designation: "",
      cityForProfess: "",
      countryForProfess: "0",
      stateNameForProfess: "",
      stateForProfess: "0",
      jobProfile: "",
      startDate: undefined,
      endDate: null,
      BadgestateNameForProfess: "",
    });
  }

  removeProfessionalExperience(index) {
    var array = this.state.professionalExperiences;
    array.splice(index, 1);
    this.setState({ professionalExperiences: array });
    this.cancelProfessionalExperienceUpdate();
  }

  editProfessionalExperience(index) {
    var profess = this.state.professionalExperiences[index];
    if (profess.countryName.toLowerCase() === "other") {
      this.setState({
        showStateNameFieldForProfess: true,
        stateForProfess: null,
        stateNameForProfess: profess.stateName,
      });
    } else {
      getStateByCountry(profess.countryId)
        .then((res) => {
          const result = JSON.parse(res.response);
          this.setState({
            showStateNameFieldForProfess: false,
            stateListForProfess: result,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // profess.BadgestateNameForProfess,
    this.setState({
      professionUpateIndex: index,
      organization: profess.organization,
      cityForProfess: profess.city,
      stateNameForProfess: profess.stateName,
      BadgestateNameForProfess: profess.stateName,
      countryForProfess: profess.countryId,
      countryNameForProfess: profess.countryName,
      stateForProfess: profess.stateId,
      designation: profess.designation,
      jobProfile: profess.jobProfile,
      startDate: profess.tempStartDate
        ? profess.tempStartDate
        : new Date(profess.startDate),
      endDate: profess.tempEndDate
        ? profess.tempEndDate
        : profess.endDate !== null
        ? new Date(profess.endDate)
        : null,
      showProfessionUpadateOption: true,
    });
  }

  cancelProfessionalExperienceUpdate() {
    this.setState({
      organization: "",
      designation: "",
      cityForProfess: "",
      countryForProfess: "0",
      stateNameForProfess: "",
      stateForProfess: "0",
      jobProfile: "",
      startDate: undefined,
      endDate: undefined,
      BadgestateNameForProfess: "",
      showProfessionUpadateOption: false,
      professionUpateIndex: null,
    });
  }

  clearAddProfessionalExperience() {
    this.setState({
      organization: "",
      designation: "",
      cityForProfess: "",
      countryForProfess: "0",
      stateNameForProfess: "",
      stateForProfess: "0",
      jobProfile: "",
      startDate: undefined,
      endDate: undefined,
      BadgestateNameForProfess: "",
      showProfessionUpadateOption: false,
      professionUpateIndex: null,
      organizationError: { error: null, message: "" },
      countryProfessError: { error: null, message: "" },
      stateProfessError: { error: null, message: "" },
      stateNameProfessError: { error: null, message: "" },
      cityProfessError: { error: null, message: "" },
      designationError: { error: null, message: "" },
      startDateError: { error: null, message: "" },
      endDateError: { error: null, message: "" },
      jobProfileError: { error: null, message: "" },
    });
  }

  updateProfessionalExperience() {
    this.addProfessionalExperience();
  }

  addEducation() {
    var array = this.state.education || [];
    var Error = false;
    if (this.required(this.state.course)) {
      this.setState({
        courseError: { error: true, message: "Degree is required" },
      });
      Error = true;
    } else {
      // const errorOrganization = validateCompany(this.state.course);
      // if (errorOrganization.error && !this.required(this.state.course)) {
      //   this.setState({
      //     courseError: {
      //       error: true,
      //       message: "Course is invalid",
      //     },
      //   });
      //   Error = true;
      // } else {
      if (this.validateContent(this.state.course)) {
        this.setState({
          courseError: { error: true, message: "Disallowed Content" },
        });
        Error = true;
      } else {
        this.setState({
          courseError: { error: false, message: "" },
        });
      }
      // }
    }

    if (this.required(this.state.specialization)) {
      this.setState({
        specializationError: {
          error: true,
          message: "Major is required",
        },
      });
      Error = true;
    } else {
      // const errorSpecialization = validateCharacterAndSpace(
      //   this.state.specialization
      // );
      // if (
      //   errorSpecialization.error &&
      //   !this.required(this.state.specialization)
      // ) {
      //   this.setState({
      //     specializationError: {
      //       error: true,
      //       message: "Specialization is invalid",
      //     },
      //   });
      //   Error = true;
      // } else {
      if (this.validateContent(this.state.specialization)) {
        this.setState({
          specializationError: { error: true, message: "Disallowed Content" },
        });
        Error = true;
      } else {
        this.setState({
          specializationError: { error: false, message: "" },
        });
      }
      // }
    }

    if (this.required(this.state.university)) {
      this.setState({
        universityError: {
          error: true,
          message: "University/School is required",
        },
      });
      Error = true;
    } else {
      const errorUniversity = validateCity(this.state.university);
      if (errorUniversity.error && !this.required(this.state.university)) {
        this.setState({
          universityError: {
            error: true,
            message: "University/School is invalid",
          },
        });
        Error = true;
      } else {
        if (this.validateContent(this.state.university)) {
          this.setState({
            universityError: { error: true, message: "Disallowed Content" },
          });
          Error = true;
        } else {
          this.setState({
            universityError: { error: false, message: "" },
          });
        }
      }
    }

    if (
      this.state.countryForEdu === "0" ||
      this.state.countryForEdu == undefined
    ) {
      this.setState({
        countryEduError: { error: true, message: "Country is required" },
      });
      Error = true;
    } else {
      this.setState({ countryEduError: { error: false, message: "" } });
    }

    if (this.state.countryNameForEdu !== "Other") {
      this.setState({
        stateNameEduError: { error: false, message: "" },
      });

      if (
        this.state.stateForEdu === "0" ||
        this.state.stateForEdu == undefined
      ) {
        this.setState({
          stateEduError: {
            error: true,
            message:
              this.state.countryForEdu == 2
                ? "Province is required"
                : "State is required",
          },
        });
        Error = true;
      } else {
        this.setState({ stateEduError: { error: false, message: "" } });
      }
    }

    if (this.state.countryNameForEdu === "Other") {
      this.setState({
        stateEduError: { error: false, message: "" },
      });
      if (this.required(this.state.stateNameForEdu)) {
        this.setState({
          stateNameEduError: {
            error: true,
            message: "State name is required",
          },
        });
        Error = true;
      } else {
        const stateError = validateCharacterAndSpace(
          this.state.stateNameForEdu
        );
        if (stateError.error && !this.required(this.state.stateNameForEdu)) {
          this.setState({
            stateNameEduError: {
              error: true,
              message: "State name is invalid",
            },
          });
          Error = true;
        } else {
          if (this.validateContent(this.state.stateNameForEdu)) {
            this.setState({
              stateNameEduError: { error: true, message: "Disallowed Content" },
            });
            Error = true;
          } else {
            this.setState({
              stateNameEduError: { error: false, message: "" },
            });
          }
        }
      }
    }

    // if (this.required(this.state.passingYear)) {
    //   this.setState({
    //     passingYearError: { error: true, message: "Passing year is required" },
    //   });
    //   Error = true;
    // } else {
    //   const error = validateCharacterSpaceAndDigit(this.state.passingYear);
    //   if (error.error && !this.required(this.state.passingYear)) {
    //     this.setState({
    //       passingYearError: {
    //         error: true,
    //         message: "Passing year is invalid",
    //       },
    //     });
    //     Error = true;
    //   } else {
    //     this.setState({ passingYearError: { error: false, message: "" } });
    //   }
    // }

    let tempstart,
      tempend = null;
    if (
      this.state.eduStartDate === undefined ||
      this.state.eduStartDate === null
    ) {
      this.setState({
        eduStartDateError: { error: true, message: "Start date is required" },
      });
      Error = true;
    } else {
      this.setState({ eduStartDateError: { error: false, message: "" } });
      //var selectedDate = new Date(this.state.eduStartDate);
      tempstart = moment(this.state.eduStartDate).format("YYYY/MM/DD");
    }

    if (this.state.eduEndDate === undefined || this.state.eduEndDate === null) {
      this.setState({
        eduEndDateError: { error: true, message: "End date is required" },
      });
      Error = true;
    } else {
      this.setState({
        eduEndDateError: { error: false, message: "" },
      });
      //var selectedDate = new Date(this.state.eduEndDate);
      tempend = moment(this.state.eduEndDate).format("YYYY/MM/DD");
    }

    // if (this.required(this.state.marks)) {
    //   this.setState({
    //     marksError: { error: true, message: "Marks/Grade is required" },
    //   });
    //   Error = true;
    // } else {
    //   this.setState({
    //     marksError: { error: false, message: "" },
    //   });
    // }

    if (this.required(this.state.cityForEdu)) {
      this.setState({
        cityEduError: { error: true, message: "City is required" },
      });
      Error = true;
    } else {
      const errorCity = validateCity(this.state.cityForEdu);
      if (errorCity.error && !this.required(this.state.cityForEdu)) {
        this.setState({
          cityEduError: { error: true, message: "City is invalid" },
        });
        Error = true;
      } else {
        if (this.validateContent(this.state.cityForEdu)) {
          this.setState({
            cityEduError: { error: true, message: "Disallowed Content" },
          });
          Error = true;
        } else {
          this.setState({
            cityEduError: { error: false, message: "" },
          });
        }
      }
    }
    if (Error) {
      return true;
    }

    if (this.state.showEduUpadateOption === true) {
      array[this.state.eduUpateIndex].courseName = this.state.course;
      array[this.state.eduUpateIndex].specialization =
        this.state.specialization;
      array[this.state.eduUpateIndex].stateName =
        this.state.countryNameForEdu !== "Other"
          ? this.state.BadgestateNameForEdu
          : this.state.stateNameForEdu;
      array[this.state.eduUpateIndex].countryId = this.state.countryForEdu;
      array[this.state.eduUpateIndex].countryName =
        this.state.countryNameForEdu;
      array[this.state.eduUpateIndex].stateId = this.state.stateForEdu;
      array[this.state.eduUpateIndex].university = this.state.university;
      array[this.state.eduUpateIndex].startDate = tempstart;
      array[this.state.eduUpateIndex].endDate = tempend;
      array[this.state.eduUpateIndex].tempStartDate = this.state.eduStartDate;
      array[this.state.eduUpateIndex].tempEndDate = this.state.eduEndDate;
      array[this.state.eduUpateIndex].city = this.state.cityForEdu;
      this.setState({ showEduUpadateOption: false, eduUpateIndex: null });
    } else {
      array.push({
        courseName: this.state.course,
        specialization: this.state.specialization,
        stateName:
          this.state.countryNameForEdu !== "Other"
            ? this.state.BadgestateNameForEdu
            : this.state.stateNameForEdu,
        countryId: this.state.countryForEdu,
        countryName: this.state.countryNameForEdu,
        stateId: this.state.stateForEdu,
        university: this.state.university,
        startDate: tempstart,
        endDate: tempend,
        //passingYear: this.state.passingYear,
        //marks: this.state.marks,
        city: this.state.cityForEdu,
        tempStartDate: this.state.eduStartDate,
        tempEndDate: this.state.eduEndDate,
      });
    }

    this.setState({
      education: array,

      course: "",
      specialization: "",
      cityForEdu: "",
      countryForEdu: "0",
      stateNameForEdu: "",
      stateForEdu: "0",
      //passingYear: "",
      university: "",
      eduStartDate: undefined,
      eduEndDate: undefined,
      marks: "",
      BadgestateNameForEdu: "",
    });
  }

  removeEducations(index) {
    var array = this.state.education;
    array.splice(index, 1);
    this.setState({ education: array });
    this.cancelEduUpdate();
  }

  editEducations(index) {
    var education = this.state.education[index];
    if (education.countryName.toLowerCase() === "other") {
      this.setState({
        showStateNameFieldForEdu: true,
        stateForEdu: null,
        stateNameForEdu: education.stateName,
      });
    } else {
      getStateByCountry(education.countryId)
        .then((res) => {
          const result = JSON.parse(res.response);
          this.setState({
            showStateNameFieldForEdu: false,
            stateListForEdu: result,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.setState({
      eduUpateIndex: index,
      stateNameForEdu: education.stateName,
      BadgestateNameForEdu: education.stateName,
      stateForEdu: education.stateId,
      cityForEdu: education.city,
      countryForEdu: education.countryId,
      countryNameForEdu: education.countryName,
      course: education.courseName,
      specialization: education.specialization,
      university: education.university,
      eduStartDate: education.tempStartDate
        ? education.tempStartDate
        : new Date(education.startDate),
      eduEndDate: education.tempEndDate
        ? education.tempEndDate
        : education.endDate !== null
        ? new Date(education.endDate)
        : null,
      showEduUpadateOption: true,
    });
  }

  cancelEduUpdate() {
    this.setState({
      course: "",
      specialization: "",
      cityForEdu: "",
      countryForEdu: "0",
      stateNameForEdu: "",
      stateForEdu: "0",
      university: "",
      eduStartDate: undefined,
      eduEndDate: undefined,
      BadgestateNameForEdu: "",
      showEduUpadateOption: false,
      eduUpateIndex: null,
    });
  }

  clearAddEducation() {
    this.setState({
      course: "",
      specialization: "",
      cityForEdu: "",
      countryForEdu: "0",
      stateNameForEdu: "",
      stateForEdu: "0",
      university: "",
      eduStartDate: undefined,
      eduEndDate: undefined,
      BadgestateNameForEdu: "",
      showEduUpadateOption: false,
      eduUpateIndex: null,
      courseError: { error: null, message: "" },
      specializationError: { error: null, message: "" },
      passingYearError: { error: null, message: "" },
      universityError: { error: null, message: "" },
      marksError: { error: null, message: "" },
      countryEduError: { error: null, message: "" },
      stateEduError: { error: null, message: "" },
      stateNameEduError: { error: null, message: "" },
      cityEduError: { error: null, message: "" },
      careerObjectiveError: { error: null, message: "" },
      jobProfileError: { error: null, message: "" },
      eduStartDate: null,
      eduEndDate: null,
      eduStartDateError: { error: null, message: "" },
      eduEndDateError: { error: null, message: "" },
    });
  }

  updateEducation() {
    this.addEducation();
  }

  addAdditionalSkills() {
    var array = this.state.additionalSkills || [];

    if (this.required(this.state.additionalSkillName)) {
      this.setState({
        additionalSkillNameError: {
          error: true,
          message: "This field is required",
        },
      });
      return;
    }
    if (this.validateContent(this.state.additionalSkillName)) {
      this.setState({
        additionalSkillNameError: {
          error: true,
          message: "Disallowed Content",
        },
      });
      return;
    }
    if (this.state.showSkillsUpadateOption) {
      array[this.state.skillsUpateIndex].additionalSkillName =
        this.state.additionalSkillName;
      this.setState({ showSkillsUpadateOption: false, skillsUpateIndex: null });
    } else {
      array.push({
        additionalSkillName: this.state.additionalSkillName,
        jinTemplateId: this.state.jinTemplateId,
      });
    }

    this.setState({
      additionalSkills: array,
      additionalSkillName: "",
      additionalSkillNameError: {
        error: false,
        message: "",
      },
    });
  }

  removeAdditionalSkills(index) {
    var array = this.state.additionalSkills;
    array.splice(index, 1);
    this.setState({ additionalSkills: array });
    this.cancelAdditionalSkillsUpdate();
  }

  editAdditionalSkills(index) {
    var skill = this.state.additionalSkills[index];

    this.setState({
      skillsUpateIndex: index,
      showSkillsUpadateOption: true,
      additionalSkillName: skill.additionalSkillName,
    });
  }

  cancelAdditionalSkillsUpdate() {
    this.setState({
      additionalSkillName: "",
      showSkillsUpadateOption: false,
      skillsUpateIndex: null,
      additionalSkillNameError: {
        error: false,
        message: "",
      },
    });
  }

  updateAdditionalSkills() {
    this.addAdditionalSkills();
  }

  addAwardsAndHonors() {
    var array = this.state.awardsAndHonors || [];

    if (this.required(this.state.awardAndHonor)) {
      this.setState({
        awardAndHonorError: {
          error: true,
          message: "This field is required",
        },
      });
      return;
    }
    if (this.validateContent(this.state.awardAndHonor)) {
      this.setState({
        awardAndHonorError: { error: true, message: "Disallowed Content" },
      });
      return;
    }
    if (this.state.showAwardsHonorsUpadateOption) {
      array[this.state.AwardsHonorsUpateIndex].awardAndHonor =
        this.state.awardAndHonor;
      this.setState({
        showAwardsHonorsUpadateOption: false,
        AwardsHonorsUpateIndex: null,
      });
    } else {
      array.push({
        awardAndHonor: this.state.awardAndHonor,
        jinTemplateId: this.state.jinTemplateId,
      });
    }

    this.setState({
      awardsAndHonors: array,
      awardAndHonor: "",
      awardAndHonorError: {
        error: false,
        message: "",
      },
    });
  }

  removeAwardsAndHonors(index) {
    var array = this.state.awardsAndHonors;
    array.splice(index, 1);
    this.setState({ awardsAndHonors: array });
    this.cancelAwardsAndHonorsUpdate();
  }

  editAwardsAndHonors(index) {
    var award = this.state.awardsAndHonors[index];

    this.setState({
      AwardsHonorsUpateIndex: index,
      showAwardsHonorsUpadateOption: true,
      awardAndHonor: award.awardAndHonor,
    });
  }

  cancelAwardsAndHonorsUpdate() {
    this.setState({
      awardAndHonor: "",
      showAwardsHonorsUpadateOption: false,
      AwardsHonorsUpateIndex: null,
      awardAndHonorError: {
        error: false,
        message: "",
      },
    });
  }

  updateAwardsAndHonors() {
    this.addAwardsAndHonors();
  }

  AddMultipleDataAuto() {
    var Error = false;

    if (!this.required(this.state.coreCompetencity)) {
      var array = this.state.coreCompetencies || [];
      if (this.validateContent(this.state.coreCompetencity)) {
        this.setState({
          coreCompetencityError: { error: true, message: "Disallowed Content" },
        });
        Error = true;
      } else {
        array.push({
          coreCompentencyName: this.state.coreCompetencity,
        });
        this.setState({ coreCompetencies: array, coreCompetencity: "" });
      }
    }

    if (!this.required(this.state.awardAndHonor)) {
      var array = this.state.awardsAndHonors || [];
      if (this.validateContent(this.state.awardAndHonor)) {
        this.setState({
          awardAndHonorError: { error: true, message: "Disallowed Content" },
        });
        Error = true;
      } else {
        array.push({
          awardAndHonor: this.state.awardAndHonor,
        });
        this.setState({ awardsAndHonors: array, awardAndHonor: "" });
      }
    }

    if (!this.required(this.state.additionalSkillName)) {
      var array = this.state.additionalSkills || [];
      if (this.validateContent(this.state.additionalSkillName)) {
        this.setState({
          additionalSkillNameError: {
            error: true,
            message: "Disallowed Content",
          },
        });
        Error = true;
      } else {
        array.push({
          additionalSkillName: this.state.additionalSkillName,
        });
        this.setState({ addAdditionalSkills: array, additionalSkillName: "" });
      }
    }

    if (
      this.required(this.state.organization) &&
      this.required(this.state.designation) &&
      this.required(this.state.cityForProfess) &&
      (this.state.countryForProfess === "0" ||
        this.state.countryForProfess == undefined) &&
      (this.state.startDate === undefined || this.state.startDate === null) &&
      this.required(this.state.stateNameForProfess) &&
      (this.state.stateForProfess === "0" ||
        this.state.stateForProfess == undefined)
    ) {
    } else {
      if (this.addProfessionalExperience()) {
        Error = true;
      }
    }

    if (
      this.required(this.state.course) &&
      this.required(this.state.specialization) &&
      this.required(this.state.university) &&
      this.required(this.state.cityForEdu) &&
      (this.state.countryForEdu === "0" ||
        this.state.countryForEdu == undefined) &&
      (this.state.eduStartDate === undefined ||
        this.state.eduEndDate === null) &&
      this.required(this.state.stateNameForEdu) &&
      (this.state.stateForEdu === "0" || this.state.stateForEdu == undefined)
    ) {
    } else {
      if (this.addEducation()) {
        Error = true;
      }
    }
    return Error;
  }

  validateOnButtonClick(status, isPublish) {
    if (
      this.state.countryName !== "Other" &&
      this.state.stateError.error === null
    ) {
      this.setState({
        stateError: {
          error: true,
          message:
            this.state.country == 2
              ? "Province is required"
              : "State is required",
        },
      });
    }

    if (
      this.state.countryName === "Other" &&
      this.state.stateNameError.error === null
    ) {
      this.setState({
        stateNameError: { error: true, message: "State name is required" },
      });
    }

    var isValid = this.AddMultipleDataAuto();

    if (
      isValid ||
      this.state.nameError.error === true ||
      this.state.emailError.error === true ||
      this.state.phoneError.error === true ||
      this.state.address1Error.error === true ||
      this.state.address2Error.error === true ||
      this.state.zipError.error === true ||
      this.state.countryError.error === true ||
      this.state.cityError.error === true ||
      this.state.stateError.error === true ||
      this.state.stateNameError.error === true ||
      this.state.jobTitleError.error === true ||
      this.state.stateNameError.error === null ||
      this.state.stateError.error === null ||
      this.state.careerObjectiveError.error === true ||
      this.state.coverLetterError.error === true
    ) {
      return;
    }

    this.setState({ disablePublish: true });
    const data = {
      //jobSeekerId: this.state.jobSeekerId,
      JINTemplateId: this.state.jinTemplateId,
      UserName: this.state.name ? this.state.name.trim() : this.state.names,
      City: this.state.city ? this.state.city.trim() : this.state.city,
      StateName: this.state.stateName
        ? this.state.stateName.trim()
        : this.state.stateName,
      StateId: this.state.state,
      CountryId: this.state.country,
      AddressLine1: this.state.address1
        ? this.state.address1.trim()
        : this.state.address1,
      AddressLine2: this.state.address2
        ? this.state.address2.trim()
        : this.state.address2,
      ZipCode: this.state.zip ? this.state.zip.trim() : this.state.zip,
      Email: this.state.email ? this.state.email.trim() : this.state.email,
      PhoneNumber: this.state.phone
        ? this.state.phone.trim()
        : this.state.phone,
      CareerObjective: this.state.careerObjective
        ? this.state.careerObjective.trim()
        : this.state.careerObjective,
      CoverLetter: this.state.coverLetter
        ? this.state.coverLetter.trim()
        : this.state.coverLetter,
      IsPublished: status,
      CoreCompetencies: this.state.coreCompetencies,
      ProfessionalExperiences: this.state.professionalExperiences,
      Education: this.state.education,
      AdditionalSkills: this.state.additionalSkills,
      AwardsAndHonors: this.state.awardsAndHonors,
      JINTemplatesIndustries: this.state.jinTemplatesIndustries,
      //TemplateName: this.state.resumeTitle,
      JobTitle: this.state.jobTitle
        ? this.state.jobTitle.trim()
        : this.state.jobTitle,
      // NoticePeriod: this.state.noticePeriod,
      PublishStatus: isPublish,
      IsPrivate: true,
    };
    AddOrUpdateJINTemplate(data)
      .then((res) => {
        const result = res.response;
        toast.success(result);
        this.setState({ disablePublish: false });
        this.props.history.push("/jobseeker/myresumes");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  SetFocusAndBlurOnFields() {
    document.querySelectorAll(".validate").forEach((item) => {
      item.focus();
      item.blur();
    });
  }

  addResume(status, isPublish) {
    this.SetFocusAndBlurOnFields();
    this.setState({ ...this.state }, () => {
      this.validateOnButtonClick(status, isPublish);
    });
  }

  render() {
    const {
      name,
      email,
      phone,
      address1,
      address2,
      city,
      state,
      stateName,
      country,
      stateList = [],
      countryList = [],
      zip,
      jobTitle,
      industryList = [],
      coreCompetencity,
      coreCompetencies = [],
      industry,
      careerObjective,
      coverLetter,
      resumeTitle,
      isPublished = false,
      jobSeekerId,
      // Start Education
      stateForEdu,
      cityForEdu,
      stateNameForEdu,
      stateListForEdu = [],
      countryListForEdu = [],
      countryForEdu,
      countryNameForEdu,
      education = [],
      course,
      specialization,
      passingYear,
      marks,
      university,
      // End

      // Start
      stateForProfess,
      organization,
      cityForProfess,
      designation,
      startDate,
      endDate,
      jobProfile,
      stateNameForProfess,
      stateListForProfess = [],
      countryListForProfess = [],
      countryForProfess,
      professionalExperiences = [],
      noticePeriod,
      // End

      //
      additionalSkills = [],
      additionalSkillName,
      // End
      // Start
      awardsAndHonors = [],
      awardAndHonor,
      // End
      showStateNameField,
      showStateNameFieldForProfess,
      showStateNameFieldForEdu,
      jinTemplatesIndustries = [],
      nameError = { message: "" },
      emailError = { message: "" },
      phoneError = { message: "" },
      address1Error = { message: "" },
      address2Error = { message: "" },
      countryError = { message: "" },
      stateError = { message: "" },
      stateNameError = { message: "" },
      cityError = { message: "" },
      jobTitleError = { message: "" },
      zipError = { message: "" },
      industryError = { message: "" },
      resumeTitleError = { message: "" },
      // careerObjectiveError= { message: "" },
      coreCompetencityError = { message: "" },
      organizationError = { message: "" },
      countryProfessError = { message: "" },
      stateProfessError = { message: "" },
      stateNameProfessError = { message: "" },
      cityProfessError = { message: "" },
      designationError = { message: "" },
      startDateError = { message: "" },
      endDateError = { message: "" },
      noticePeriodError = { message: "" },
      additionalSkillNameError = { message: "" },
      awardAndHonorError = { message: "" },
      courseError = { message: "" },
      specializationError = { message: "" },
      passingYearError = { message: "" },
      universityError = { message: "" },
      marksError = { message: "" },
      countryEduError = { message: "" },
      stateEduError = { message: "" },
      stateNameEduError = { message: "" },
      cityEduError = { message: "" },
      jobProfileError = { message: "" },
      coverLetterError = { message: "" },
      eduStartDate,
      eduEndDate,
      eduStartDateError = { message: "" },
      eduEndDateError = { message: "" },
      careerObjectiveError = { message: "" },
      disablePublish = false,
      badgeEducation = [],
      badgeProfessionalExperiences = [],
      carrerObjectiveError = { message: "" },
      showEduUpadateOption = false,
      showProfessionUpadateOption = false,
      showCompentesisUpadateOption = false,
      showSkillsUpadateOption = false,
      showAwardsHonorsUpadateOption = false,
    } = this.state;

    let checkval = [];
    for (let i = 0; i < jinTemplatesIndustries.length; i++) {
      checkval.push(jinTemplatesIndustries[i].industryId);
    }

    return (
      <div className="container p-0 post-job-container">
        <h1 className="page-title d-flex align-items-center">Resume</h1>
        <div className="dashboard-wrapper jin-resumes">
          <Form noValidate>
            <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg">
                  <Row>
                    <Col>
                      <FormInput
                        className="validate"
                        value={name}
                        type="text"
                        placeholder="Name *"
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: "Name is required",
                        //   },
                        // ]}
                        onBlur={this.handleOnBlur("name")}
                        onChange={this.handleChange("name")}
                        inputerror={nameError.message}
                        maxLength="100"
                      />
                    </Col>
                    <Col>
                      <FormInput
                        value={email}
                        className="validate"
                        type="text"
                        maxLength="100"
                        placeholder="Email *"
                        onBlur={this.handleOnBlur("email")}
                        onChange={this.handleChange("email")}
                        inputerror={emailError.message}
                        email="100"
                      />
                    </Col>
                    <Col>
                      <FormInput
                        value={phone}
                        className="validate"
                        type="text"
                        placeholder="Phone"
                        onChange={this.handleChange("phone")}
                        onBlur={this.handleOnBlur("phone")}
                        inputerror={phoneError.message}
                        minLength="10"
                        maxLength="11"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormInput
                        value={address1}
                        className="validate"
                        type="text"
                        placeholder="Address line 1 *"
                        onChange={this.handleChange("address1")}
                        onBlur={this.handleOnBlur("address1")}
                        maxLength="150"
                        inputerror={address1Error.message}
                      />
                    </Col>
                    <Col>
                      <FormInput
                        value={address2}
                        type="text"
                        className="validate"
                        placeholder="Address line 2"
                        maxLength="150"
                        inputerror={address2Error.message}
                        onChange={this.handleChange("address2")}
                        onBlur={this.handleOnBlur("address2")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          value={country}
                          as="select"
                          className="inputclass validate"
                          onChange={(e) => this.countryChange(e)}
                          onBlur={(e) => this.countryChange(e)}
                        >
                          <option value="0">Country *</option>
                          {countryList.map((item) => (
                            <option key={item.countryId} value={item.countryId}>
                              {item.countryName}
                            </option>
                          ))}
                        </Form.Control>
                        <span style={{ color: "#dc3545", fontSize: "13px" }}>
                          {countryError.message}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col>
                      {showStateNameField && (
                        <FormInput
                          value={stateName}
                          type="text"
                          className="validate"
                          placeholder="Country Name *"
                          required="required"
                          onChange={this.handleChange("stateName")}
                          onBlur={this.handleOnBlur("stateName")}
                          inputerror={stateNameError.message}
                        />
                      )}

                      {!showStateNameField && (
                        <Form.Group>
                          <Form.Control
                            as="select"
                            value={state}
                            className="inputclass validate"
                            onChange={(e) => this.stateChange(e)}
                            onBlur={(e) => this.stateChange(e)}
                          >
                            <option value="0">
                              {country == 2
                                ? "Select Province *"
                                : "Select State *"}
                            </option>
                            {stateList.map((item) => (
                              <option key={item.stateId} value={item.stateId}>
                                {item.stateName}
                              </option>
                            ))}
                          </Form.Control>
                          <span style={{ color: "#dc3545", fontSize: "13px" }}>
                            {stateError.message}
                          </span>
                        </Form.Group>
                      )}
                    </Col>
                    <Col>
                      <FormInput
                        value={city}
                        type="text"
                        placeholder="City *"
                        required="required"
                        className="validate"
                        inputerror={cityError.message}
                        maxLength="150"
                        onChange={this.handleChange("city")}
                        onBlur={this.handleOnBlur("city")}
                      />
                    </Col>
                    <Col>
                      <FormInput
                        value={zip}
                        type="text"
                        placeholder="Zip Code"
                        className="validate"
                        inputerror={zipError.message}
                        maxLength="6"
                        onChange={this.handleChange("zip")}
                        onBlur={this.handleOnBlur("zip")}
                      />
                    </Col>
                  </Row>
                  <Row></Row>
                  <h3>Resume Information</h3>
                  <Row>
                    {/* <Col md="12">
                      <FormInput
                        value={resumeTitle}
                        type="text"
                        placeholder="Resume Title"
                        className="validate"
                        required="required"
                        rows="5"
                        maxLength="30"
                        inputerror={resumeTitleError.message}
                        onBlur={this.handleOnBlur("resumeTitle")}
                        onChange={this.handleChange("resumeTitle")}
                      />
                    </Col> */}
                    <Col>
                      <Select
                        placeholder="Job Category"
                        options={industryList}
                        value={industryList.filter((obj) =>
                          checkval.includes(obj.value)
                        )}
                        onChange={this.handleIndustryChange}
                        //  onBlur={(e) => this.handleIndustryChangeBlur(e)}
                        isMulti
                        className="selectd"
                      />
                    </Col>
                    <Col>
                      <FormInput
                        value={jobTitle}
                        type="text"
                        className="validate"
                        placeholder="Professional Title *"
                        inputerror={jobTitleError.message}
                        maxLength="30"
                        onChange={this.handleChange("jobTitle")}
                        onBlur={this.handleOnBlur("jobTitle")}
                      />
                    </Col>
                  </Row>

                  <h3>Cover Letter</h3>
                  <Row>
                    <Col md="12">
                      <FormInput
                        type="textarea"
                        value={coverLetter}
                        className="validate"
                        inputerror={coverLetterError.message}
                        placeholder="Cover Letter"
                        rows="5"
                        maxLength="1000"
                        onChange={this.handleChange("coverLetter")}
                        onBlur={this.handleOnBlur("coverLetter")}
                      />
                    </Col>
                  </Row>

                  <h3>Career Objective</h3>
                  <Row>
                    <Col md="12">
                      <FormInput
                        type="textarea"
                        value={careerObjective}
                        placeholder="Career Objective *"
                        rows="5"
                        className="validate"
                        inputerror={careerObjectiveError.message}
                        maxLength="500"
                        onChange={this.handleChange("careerObjective")}
                        onBlur={this.handleOnBlur("careerObjective")}
                      />
                    </Col>
                  </Row>

                  <h3>Core Competencies</h3>
                  <Row>
                    {coreCompetencies.length !== null && (
                      <Col className="mb-3">
                        {coreCompetencies.map((item, index) => (
                          <Badge pill variant="light" key={index}>
                            {item.coreCompentencyName}
                            <div className="d-inline-block ml-3">
                              <span
                                title="Remove"
                                style={{ cursor: "pointer" }}
                                className="close-b btn-b"
                                onClick={(e) =>
                                  this.removeCoreCompetecies(index)
                                }
                              >
                                <i className="far fa-times-circle"></i>
                              </span>

                              <span
                                title="Edit"
                                style={{ cursor: "pointer" }}
                                className="edit-b btn-b"
                                onClick={(e) => this.editCoreCompetecies(index)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </span>
                            </div>
                          </Badge>
                        ))}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col className="input-add-btn">
                      <FormInput
                        type="text"
                        value={coreCompetencity}
                        maxLength="500"
                        placeholder="Core Competencies"
                        inputerror={coreCompetencityError.message}
                        onBlur={this.handleOnBlur("coreCompetencity")}
                        onChange={this.handleChange("coreCompetencity")}
                      />
                      {!showCompentesisUpadateOption && (
                        <Buttons
                          variant="outline-secondary"
                          title="Add"
                          type="button"
                          size="xs"
                          onClick={(e) => this.addCoreCompetecies(e)}
                        ></Buttons>
                      )}
                      {showCompentesisUpadateOption && (
                        <Fragment>
                          <Buttons
                            variant="outline-secondary"
                            title="Cancel"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) => this.cancelCoreCompeteciesUpdate(e)}
                          ></Buttons>
                          <Buttons
                            variant="outline-secondary"
                            title="Update"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) => this.updateCoreCompetecies(e)}
                          ></Buttons>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                  <h3>Professional Experience</h3>
                  {professionalExperiences.map((item, index) => (
                    <Fragment key={index}>
                      <Row>
                        <Col className="mb-3">
                          {/* <Badge pill variant="light">
           Professional Experience : 5 year's
           <Buttons
             variant="link"
             icon="far fa-times-circle"
           ></Buttons>
         </Badge> */}
                          <Badge pill variant="light">
                            Organization : {item.organization}
                            {/* <Buttons
             variant="link"
             icon="far fa-times-circle"
           ></Buttons> */}
                          </Badge>
                          <Badge pill variant="light">
                            Country : {item.countryName}
                            {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                          </Badge>
                          <Badge pill variant="light">
                            State : {item.stateName}
                            {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                          </Badge>
                          <Badge pill variant="light">
                            City : {item.city}
                            {/* <Buttons
             variant="link"
             icon="far fa-times-circle"
           ></Buttons> */}
                          </Badge>
                          <Badge pill variant="light">
                            Position : {item.designation}
                            {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                          </Badge>
                          {!item.tempStartDate && (
                            <Badge pill variant="light">
                              Start Date :{" "}
                              {moment(item.startDate).format("MMM DD, Y")}
                              {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                            </Badge>
                          )}
                          {item.tempStartDate && (
                            <Badge pill variant="light">
                              Start Date :{" "}
                              {moment(item.tempStartDate).format("MMM DD, Y")}
                              {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                            </Badge>
                          )}
                          {!item.tempEndDate && (
                            <Badge pill variant="light">
                              End Date :{" "}
                              {item.endDate === null ||
                              item.endDate === undefined ||
                              item.endDate === ""
                                ? "Present"
                                : moment(item.endDate).format("MMM DD, Y")}
                              {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                            </Badge>
                          )}
                          {item.tempEndDate && (
                            <Badge pill variant="light">
                              End Date :{" "}
                              {item.tempEndDate === null ||
                              item.tempEndDate === undefined ||
                              item.tempEndDate === ""
                                ? "-"
                                : moment(item.tempEndDate).format("MMM DD, Y")}
                              {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                            </Badge>
                          )}
                          <Badge pill variant="light">
                            Describe Job Profile :{" "}
                            {item.jobProfile === null ||
                            item.jobProfile === undefined ||
                            item.jobProfile === ""
                              ? "-"
                              : item.jobProfile}
                          </Badge>
                          <div className="d-inline-block">
                            <span
                              title="Remove"
                              style={{ cursor: "pointer" }}
                              className="close-b btn-b"
                              onClick={() =>
                                this.removeProfessionalExperience(index)
                              }
                            >
                              <i className="far fa-times-circle"></i>
                            </span>

                            <span
                              title="Edit"
                              style={{ cursor: "pointer" }}
                              className="edit-b btn-b"
                              onClick={() =>
                                this.editProfessionalExperience(index)
                              }
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </span>
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col className="mb-3">
                          
                        </Col>
                      </Row> */}
                    </Fragment>
                  ))}

                  <div className="repeat-box mt-2">
                    <Row>
                      <Col>
                        <FormInput
                          value={organization}
                          type="text"
                          placeholder="Organization *"
                          inputerror={organizationError.message}
                          maxLength="100"
                          onChange={this.handleChange("organization")}
                          onBlur={this.handleOnBlur("organization")}
                        />
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            value={countryForProfess}
                            as="select"
                            className="inputclass"
                            onChange={(e) => this.countryChangesProfess(e)}
                          >
                            <option value="0">Country *</option>
                            {countryListForProfess.map((item) => (
                              <option
                                key={item.countryId}
                                value={item.countryId}
                              >
                                {item.countryName}
                              </option>
                            ))}
                          </Form.Control>
                          <span style={{ color: "#dc3545", fontSize: "13px" }}>
                            {countryProfessError.message}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col>
                        {showStateNameFieldForProfess && (
                          <FormInput
                            value={stateNameForProfess}
                            type="text"
                            placeholder="Country Name *"
                            maxLength="50"
                            inputerror={stateNameProfessError.message}
                            onChange={this.handleChange("stateNameForProfess")}
                            onBlur={this.handleOnBlur("stateNameForProfess")}
                          />
                        )}

                        {!showStateNameFieldForProfess && (
                          <Form.Group>
                            <Form.Control
                              as="select"
                              value={stateForProfess}
                              className="inputclass"
                              onChange={(e) => this.stateChangesForProfess(e)}
                            >
                              <option value="0">
                                {countryForProfess == 2
                                  ? "Select Province *"
                                  : "Select State *"}
                              </option>
                              {stateListForProfess.map((item) => (
                                <option key={item.stateId} value={item.stateId}>
                                  {item.stateName}
                                </option>
                              ))}
                            </Form.Control>
                            <span
                              style={{ color: "#dc3545", fontSize: "13px" }}
                            >
                              {stateProfessError.message}
                            </span>
                          </Form.Group>
                        )}
                      </Col>
                      <Col>
                        <FormInput
                          value={cityForProfess}
                          type="text"
                          placeholder="City *"
                          maxLength="50"
                          inputerror={cityProfessError.message}
                          onBlur={this.handleOnBlur("cityForProfess")}
                          onChange={this.handleChange("cityForProfess")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormInput
                          value={designation}
                          type="text"
                          placeholder="Position *"
                          maxLength="50"
                          inputerror={designationError.message}
                          onChange={this.handleChange("designation")}
                          onBlur={this.handleOnBlur("designation")}
                        />
                      </Col>
                      <Col>
                        <DatePicker
                          selected={startDate}
                          onChange={this.handleDateChange}
                          value={startDate}
                          placeholderText="Start Date *"
                          className="inputclass form-control"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          shouldCloseOnSelect={true}
                          onCalendarClose={() => {
                            if (this.state.startDate) this.focusTextInput();
                          }}
                        />
                        <span style={{ color: "#dc3545", fontSize: "13px" }}>
                          {startDateError.message}
                        </span>
                      </Col>
                      <Col>
                        <DatePicker
                          selected={endDate}
                          onChange={this.handleEndDateChange}
                          minDate={startDate}
                          value={endDate}
                          placeholderText="Present"
                          className="inputclass form-control"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          ref={this.textInput}
                        />
                        <span style={{ color: "#dc3545", fontSize: "13px" }}>
                          {endDateError.message}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormInput
                          value={jobProfile}
                          type="textarea"
                          placeholder="Describe Job Profile"
                          rows="5"
                          maxLength="500"
                          inputerror={jobProfileError.message}
                          onChange={this.handleChange("jobProfile")}
                          onBlur={this.handleOnBlur("jobProfile")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {!showProfessionUpadateOption && (
                        <Col className="mt-2 text-right">
                          <Buttons
                            variant="outline-secondary"
                            title="Clear"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) =>
                              this.clearAddProfessionalExperience(e)
                            }
                          ></Buttons>
                          <Buttons
                            variant="outline-secondary"
                            title="Add"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) => this.addProfessionalExperience(e)}
                          ></Buttons>
                        </Col>
                      )}
                      {showProfessionUpadateOption && (
                        <Col className="mt-2 text-right">
                          <Buttons
                            variant="outline-secondary"
                            title="Cancel"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) =>
                              this.cancelProfessionalExperienceUpdate(e)
                            }
                          ></Buttons>
                          <Buttons
                            variant="outline-secondary"
                            title="Update"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) =>
                              this.updateProfessionalExperience(e)
                            }
                          ></Buttons>
                        </Col>
                      )}
                    </Row>

                    {/* <Row>
                      <Col>
                        <FormInput
                          value={noticePeriod}
                          className="validate"
                          type="text"
                          placeholder="Notice Period"
                          maxLength="10"
                          inputerror={noticePeriodError.message}
                          onChange={this.handleChange("noticePeriod")}
                          onBlur={this.handleOnBlur("noticePeriod")}
                        />
                      </Col>
                    </Row> */}
                  </div>

                  <h3>Education</h3>

                  {education.map((item, index) => (
                    <Row key={index}>
                      <Col className="mb-3">
                        <Badge pill variant="light">
                          University/School: {item.university}
                          {/* <Buttons
                           variant="link"
                           icon="far fa-times-circle"
                         ></Buttons> */}
                        </Badge>
                        <Badge pill variant="light">
                          Country: {item.countryName}
                          {/* <Buttons
                           variant="link"
                           icon="far fa-times-circle"
                         ></Buttons> */}
                        </Badge>
                        <Badge pill variant="light">
                          State: {item.stateName}
                          {/* <Buttons
                           variant="link"
                           icon="far fa-times-circle"
                         ></Buttons> */}
                        </Badge>
                        <Badge pill variant="light">
                          City: {item.city}
                          {/* <Buttons
                           variant="link"
                           icon="far fa-times-circle"
                         ></Buttons> */}
                        </Badge>
                        <Badge pill variant="light">
                          Major: {item.specialization}
                          {/* <Buttons
                           variant="link"
                           icon="far fa-times-circle"
                         ></Buttons> */}
                        </Badge>
                        <Badge pill variant="light">
                          Degree: {item.courseName}
                          {/* <Buttons
                           variant="link"
                           icon="far fa-times-circle"
                         ></Buttons> */}
                        </Badge>
                        {!item.tempStartDate && (
                          <Badge pill variant="light">
                            Start Date :{" "}
                            {moment(item.startDate).format("MMM DD, Y")}
                            {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                          </Badge>
                        )}
                        {item.tempStartDate && (
                          <Badge pill variant="light">
                            Start Date :{" "}
                            {moment(item.tempStartDate).format("MMM DD, Y")}
                            {/* <Buttons
                              variant="link"
                              icon="far fa-times-circle"
                            ></Buttons> */}
                          </Badge>
                        )}
                        {!item.tempEndDate && (
                          <Badge pill variant="light">
                            End Date :{" "}
                            {item.endDate === null ||
                            item.endDate === undefined ||
                            item.endDate === ""
                              ? "-"
                              : moment(item.endDate).format("MMM DD, Y")}
                          </Badge>
                        )}
                        {item.tempEndDate && (
                          <Badge pill variant="light">
                            End Date :{" "}
                            {item.tempEndDate === null ||
                            item.tempEndDate === undefined ||
                            item.tempEndDate === ""
                              ? "-"
                              : moment(item.tempEndDate).format("MMM DD, Y")}
                          </Badge>
                        )}
                        <div className="d-inline-block">
                          <span
                            title="Remove"
                            style={{ cursor: "pointer" }}
                            className="close-b btn-b"
                            onClick={() => this.removeEducations(index)}
                          >
                            <i className="far fa-times-circle"></i>
                          </span>

                          <span
                            title="Edit"
                            style={{ cursor: "pointer" }}
                            className="edit-b btn-b"
                            onClick={() => this.editEducations(index)}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ))}
                  {
                    <div className="repeat-box">
                      <Row>
                        <Col>
                          <FormInput
                            value={university}
                            type="text"
                            placeholder="University/School *"
                            inputerror={universityError.message}
                            maxLength="60"
                            onBlur={this.handleOnBlur("university")}
                            onChange={this.handleChange("university")}
                          />
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Control
                              value={countryForEdu}
                              as="select"
                              className="inputclass"
                              onChange={(e) => this.countryChangesForEdu(e)}
                            >
                              <option value="0">Country *</option>
                              {countryListForEdu.map((item) => (
                                <option
                                  key={item.countryId}
                                  value={item.countryId}
                                >
                                  {item.countryName}
                                </option>
                              ))}
                            </Form.Control>
                            <span
                              style={{ color: "#dc3545", fontSize: "13px" }}
                            >
                              {countryEduError.message}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col>
                          {showStateNameFieldForEdu && (
                            <FormInput
                              value={stateNameForEdu}
                              type="text"
                              placeholder="Country Name *"
                              inputerror={stateNameEduError.message}
                              maxLength="50"
                              onChange={this.handleChange("stateNameForEdu")}
                              onBlur={this.handleOnBlur("stateNameForEdu")}
                            />
                          )}

                          {!showStateNameFieldForEdu && (
                            <Form.Group>
                              <Form.Control
                                as="select"
                                value={stateForEdu}
                                className="inputclass"
                                onChange={(e) => this.stateChangesForEdu(e)}
                              >
                                <option value="0">
                                  {countryForEdu == 2
                                    ? "Select Province *"
                                    : "Select State *"}
                                </option>
                                {stateListForEdu.map((item) => (
                                  <option
                                    key={item.stateId}
                                    value={item.stateId}
                                  >
                                    {item.stateName}
                                  </option>
                                ))}
                              </Form.Control>{" "}
                              <span
                                style={{ color: "#dc3545", fontSize: "13px" }}
                              >
                                {stateEduError.message}
                              </span>
                            </Form.Group>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormInput
                            value={cityForEdu}
                            type="text"
                            placeholder="City *"
                            inputerror={cityEduError.message}
                            maxLength="50"
                            onBlur={this.handleOnBlur("cityForEdu")}
                            onChange={this.handleChange("cityForEdu")}
                          />
                        </Col>
                        <Col>
                          <FormInput
                            value={specialization}
                            type="text"
                            placeholder="Major *"
                            inputerror={specializationError.message}
                            maxLength="50"
                            onBlur={this.handleOnBlur("specialization")}
                            onChange={this.handleChange("specialization")}
                          />
                        </Col>
                        <Col>
                          <FormInput
                            value={course}
                            type="text"
                            placeholder="Degree *"
                            inputerror={courseError.message}
                            maxLength="50"
                            onChange={this.handleChange("course")}
                            onBlur={this.handleOnBlur("course")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col>
                          <FormInput
                            value={passingYear}
                            type="text"
                            placeholder="Passing Year"
                            inputerror={passingYearError.message}
                            maxLength="20"
                            onBlur={this.handleOnBlur("passingYear")}
                            onChange={this.handleChange("passingYear")}
                          />
                        </Col> */}

                        <Col>
                          <DatePicker
                            selected={eduStartDate}
                            onChange={this.handleEduDateChange}
                            value={eduStartDate}
                            placeholderText="Start Date *"
                            className="inputclass form-control"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            shouldCloseOnSelect={true}
                            onCalendarClose={() => {
                              if (this.state.startDate) this.eduTextInput();
                            }}
                          />
                          <span style={{ color: "#dc3545", fontSize: "13px" }}>
                            {eduStartDateError.message}
                          </span>
                        </Col>
                        <Col>
                          <DatePicker
                            selected={eduEndDate}
                            onChange={this.handleEduEndDateChange}
                            minDate={eduStartDate}
                            value={eduEndDate}
                            placeholderText="End Date *"
                            className="inputclass form-control"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            ref={this.eduInput}
                          />
                          <span style={{ color: "#dc3545", fontSize: "13px" }}>
                            {eduEndDateError.message}
                          </span>
                        </Col>
                        {/* <Col>
                          <FormInput
                            value={marks}
                            type="text"
                            placeholder="Marks/Grade *"
                            inputerror={marksError.message}
                            maxLength="10"
                            onBlur={this.handleOnBlur("marks")}
                            onChange={this.handleChange("marks")}
                          />
                        </Col> */}
                      </Row>
                      <Row>
                        {!showEduUpadateOption && (
                          <Col className="mt-2 text-right">
                            <Buttons
                              variant="outline-secondary"
                              title="Clear"
                              type="button"
                              size="xs"
                              marginLeft="ml-2"
                              onClick={(e) => this.clearAddEducation(e)}
                            ></Buttons>
                            <Buttons
                              variant="outline-secondary"
                              title="Add"
                              type="button"
                              size="xs"
                              marginLeft="ml-2"
                              onClick={(e) => this.addEducation(e)}
                            ></Buttons>
                          </Col>
                        )}
                        {showEduUpadateOption && (
                          <Col className="mt-2 text-right">
                            <Buttons
                              variant="outline-secondary"
                              title="Cancel"
                              type="button"
                              size="xs"
                              marginLeft="ml-2"
                              onClick={(e) => this.cancelEduUpdate(e)}
                            ></Buttons>
                            <Buttons
                              variant="outline-secondary"
                              title="Update"
                              type="button"
                              size="xs"
                              marginLeft="ml-2"
                              onClick={(e) => this.updateEducation(e)}
                            ></Buttons>
                          </Col>
                        )}
                      </Row>
                    </div>
                  }
                  <h3>Additional Skills</h3>
                  <Row>
                    <Col className="mb-3">
                      {additionalSkills.map((item, index) => (
                        <Badge key={index} pill variant="light">
                          {item.additionalSkillName}
                          <div className="d-inline-block ml-3">
                            <span
                              title="Remove"
                              style={{ cursor: "pointer" }}
                              className="close-b btn-b"
                              onClick={(e) =>
                                this.removeAdditionalSkills(index)
                              }
                            >
                              <i className="far fa-times-circle"></i>
                            </span>

                            <span
                              title="Edit"
                              style={{ cursor: "pointer" }}
                              className="edit-b btn-b"
                              onClick={(e) => this.editAdditionalSkills(index)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </span>
                          </div>
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="input-add-btn">
                      <FormInput
                        value={additionalSkillName}
                        type="text"
                        placeholder="Additional Skills"
                        maxLength="100"
                        inputerror={additionalSkillNameError.message}
                        onChange={this.handleChange("additionalSkillName")}
                        onBlur={this.handleOnBlur("additionalSkillName")}
                      />
                      {!showSkillsUpadateOption && (
                        <Buttons
                          variant="outline-secondary"
                          title="Add"
                          type="button"
                          size="xs"
                          onClick={(e) => this.addAdditionalSkills(e)}
                        ></Buttons>
                      )}
                      {showSkillsUpadateOption && (
                        <Fragment>
                          <Buttons
                            variant="outline-secondary"
                            title="Cancel"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) =>
                              this.cancelAdditionalSkillsUpdate(e)
                            }
                          ></Buttons>
                          <Buttons
                            variant="outline-secondary"
                            title="Update"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) => this.updateAdditionalSkills(e)}
                          ></Buttons>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                  <h3>Awards and Honors</h3>
                  <Row>
                    <Col className="mb-3">
                      {awardsAndHonors.map((item, index) => (
                        <Badge pill variant="light" key={index}>
                          {item.awardAndHonor}
                          <div className="d-inline-block ml-3">
                            <span
                              title="Remove"
                              style={{ cursor: "pointer" }}
                              className="close-b btn-b"
                              onClick={(e) => this.removeAwardsAndHonors(index)}
                            >
                              <i className="far fa-times-circle"></i>
                            </span>

                            <span
                              title="Edit"
                              style={{ cursor: "pointer" }}
                              className="edit-b btn-b"
                              onClick={(e) => this.editAwardsAndHonors(index)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </span>
                          </div>
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="input-add-btn">
                      <FormInput
                        value={awardAndHonor}
                        type="text"
                        placeholder="Awards and Honors"
                        maxLength="500"
                        inputerror={awardAndHonorError.message}
                        onChange={this.handleChange("awardAndHonor")}
                        onBlur={this.handleOnBlur("awardAndHonor")}
                      />

                      {/* <Col md={4} className="text-right"> */}

                      {!showAwardsHonorsUpadateOption && (
                        <Buttons
                          variant="outline-secondary"
                          title="Add"
                          type="button"
                          size="xs"
                          onClick={(e) => this.addAwardsAndHonors(e)}
                        ></Buttons>
                      )}
                      {showAwardsHonorsUpadateOption && (
                        <Fragment>
                          <Buttons
                            variant="outline-secondary"
                            title="Cancel"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) => this.cancelAwardsAndHonorsUpdate(e)}
                          ></Buttons>
                          <Buttons
                            variant="outline-secondary"
                            title="Update"
                            type="button"
                            size="xs"
                            marginLeft="ml-2"
                            onClick={(e) => this.updateAwardsAndHonors(e)}
                          ></Buttons>
                        </Fragment>
                      )}
                    </Col>
                    {/* </Col> */}
                  </Row>
                  <hr />
                  <Row>
                    <Col className="mt-4">
                      {isPublished === true && (
                        <Buttons
                          variant="warning"
                          title="UnPublish"
                          type="button"
                          size="xs"
                          onClick={(e) => this.addResume(false, "unpublish")}
                        ></Buttons>
                      )}
                      {isPublished !== true && (
                        <Buttons
                          variant="warning"
                          title="Publish"
                          type="button"
                          disabled={disablePublish}
                          size="xs"
                          onClick={(e) => this.addResume(true, "publish")}
                        ></Buttons>
                      )}

                      {/* <Buttons variant="warning" title="Save" type="submit" size="xs" color="white" onClick={this.handleSubmit}></Buttons> */}
                      {/* <Link
                        className="btn btn-secondary btn-sm ml-3"
                        to={`${process.env.PUBLIC_URL}/resumeupload`}
                      >
                        save
                      </Link> */}
                      <Buttons
                        variant="secondary ml-3"
                        title={isPublished ? "Save" : "Save for later"}
                        disabled={disablePublish}
                        type="button"
                        size="xs"
                        onClick={(e) => this.addResume(isPublished, "save")}
                      ></Buttons>
                      <Buttons
                        variant="light"
                        title="Cancel"
                        type="button"
                        size="xs"
                        marginLeft="ml-3"
                        onClick={() => {
                          this.props.history.push("/jobseeker/myresumes");
                        }}
                      ></Buttons>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

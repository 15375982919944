import React, { useState, Component } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import Routes from "../Routes/Routes";

export default function Content({ isOpen, toggle, imagetoggle, setToggleFalse }) {
  return (
    <div className={isOpen ? 'main is-open' : 'main'} onClick={setToggleFalse}>
      {/* <Route exact path="/" component={Dashboard} />
        <Route path="/about" component={About} /> */}
      <Routes imagetoggle={imagetoggle}> </Routes>
    </div>
  );
}

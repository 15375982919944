import { NavLink } from "react-router-dom";
import { CgUserList } from "react-icons/cg";
import { FiUsers } from "react-icons/fi";
import { RiBuilding2Line } from "react-icons/ri";

export default function GroupLeaderMenu({ setToggleFalse }) {
  return (
    <ul>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/groupleader/groupleaderdashboard`}
        >
          <span className="icon icon-wysiwyg"></span>
          Dashboard
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/groupleader/jobseekers`}
        >
          <span className="icon icon-job-seeker"> </span> Sponsored Job Seekers
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/groupleader/supportteammembers`}
        >
          <span className="icon icon-support-members"></span> Support Team
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/groupleader/organization`}
        >
          {/* <span className="icon icon-support-members"></span>  */}
          <RiBuilding2Line className="custom-icon-size" />  Organization
        </NavLink>
      </li>
      <li onClick={setToggleFalse}>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/groupleader/reportabuse`}
        >
          <span className="icon icon-documents"></span> Report Abuse
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          activeClassName="is-active"
          to={`${process.env.PUBLIC_URL}/groupleader/supportjobs`}>
           <span className="icon icon-manage_search"></span> Jobs 
          </NavLink>
      </li> */}
    </ul>
  );
}

// export default SeekerMenu;

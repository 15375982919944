import { API_URL } from "../Utilities/Helper";
import { get } from "./AxiosService";

export default function getJobSeekersList() {
  return fetch(
    "https://my-json-server.typicode.com/sagarkhese1990/demo/JobSeekers"
  ).then((data) => data.json());
}

// export function getJobSeekerForSTM() {
//   return fetch(`${API_URL}jobseeker/GetJobSeekersForSTMJobFilter`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeekerForSTM() {
  return get(`${API_URL}jobseeker/GetJobSeekersForSTMJobFilter`).then(
    (res) => res.data
  );
}

// export function getJobSeekerNetworkList(filter) {
//   return fetch(`${API_URL}jobseeker/GetJobSeekerNetworkList?${filter}`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeekerNetworkList(filter) {
  return get(`${API_URL}jobseeker/GetJobSeekerNetworkList?${filter}`).then(
    (res) => res.data
  );
}

// export function getJobSeekerGroups() {
//   return fetch(`${API_URL}user/groupdetails`, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeekerGroups() {
  return get(`${API_URL}user/groupdetails`).then((res) => res.data);
}

// export function sendNetworkRequest(SupportTeamMemberId, JobSeekerResumeId, JobSeekerPublicResumeId) {
//   return fetch(`${API_URL}jobseeker/SendNetworkRequest?SupportTeamMemberId=` +
//     SupportTeamMemberId + "&JobSeekerResumeId=" + JobSeekerResumeId
//     + "&JobSeekerPublicResumeId=" + JobSeekerPublicResumeId,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }).then((data) => data.json());
// }

export function sendNetworkRequest(
  SupportTeamMemberId,
  JobSeekerResumeId,
  JobSeekerPublicResumeId,
  currentDate
) {
  return get(
    `${API_URL}jobseeker/SendNetworkRequest?SupportTeamMemberId=` +
      SupportTeamMemberId +
      "&JobSeekerResumeId=" +
      JobSeekerResumeId +
      "&JobSeekerPublicResumeId=" +
      JobSeekerPublicResumeId +
      "&CurrentDate=" +
      currentDate
  ).then((res) => res.data);
}

export function getJobSeekerResumes(jobSeekerId) {
  return get(`${API_URL}job/jobseekerresumes?JobSeekerId=${jobSeekerId}`).then(
    (res) => res.data
  );
}

import axios from 'axios';
import RefreshTokenService from '../Services/RefreshTokenService';
import { getData } from './StorageHelper';

const token = () => {
    let data = getData("token");
    let parseData = data;
    return parseData;
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

instance.defaults.headers.common['Authorization'] = "Bearer " + token();
instance.interceptors.request.use(request => {

    let data = getData("token");
    let parseData = data;
    const token = parseData;
    if (token) {
        request.headers["Authorization"] = 'Bearer ' + token;
    }

    return request;
}, error => {
    return Promise.reject(error);
});

const interceptor = instance.interceptors.response.use(response => {
    return response;
}, error => {
    const originalRequest = error.config;
    console.log("error", error.response);

    if (error.response) {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }
        else if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return RefreshTokenService(originalRequest, instance);
        }
    }
    /* 
    * When response code is 401, try to refresh the token.
    * Eject the interceptor so it doesn't loop in case
    * token refresh causes the 401 response
    */
    axios.interceptors.response.eject(interceptor);
});

export default instance;
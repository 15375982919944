export const saveByteArray = (reportName, filename, byte) => {
  var pdf = "application/pdf";
  var doc = "application/msword";
  var docx =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  let setType = "";
  let ext = filename.split(".")[1];
  if (ext.toLowerCase() === "pdf") {
    setType = pdf;
  }
  if (ext.toLowerCase() === "doc") {
    setType = doc;
  }
  if (ext.toLowerCase() === "docx") {
    setType = docx;
  }
  var blob = new Blob([byte], { type: setType });
  var link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
};

export const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import {
  getDemoDatabaseBackups,
  putRemoveSavedDemo,
} from "../../../Services/DemoServices";
import { ConformationModal } from "../../../Components/ConformationModal";
import { toast } from "react-toastify";

export const AdminDemoRemove = () => {
  const history = useHistory();

  const [showLoader, setShowLoader] = useState(false);
  const [savedDemos, setSavedDemos] = useState([]);
  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);
  const [restoreSavedDemoId, setRestoreSavedDemoId] = useState(0);

  useEffect(() => {
    getDemoDatabaseBackups()
      .then((res) => {
        var result = JSON.parse(res.response);
        result.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        setSavedDemos(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const removeDemo = () => {
    setShowOverwriteWarning(false);

    putRemoveSavedDemo({ id: restoreSavedDemoId })
      .then((_) => {
        toast.success("Removed saved demo.");
        setTimeout(() => history.push("/admin/demotools"), 3000);
      })
      .catch((err) => {
        toast.error("Unable to remove saved demo.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const handleUserClickedRemove = () => {
    setShowLoader(true);
    setShowOverwriteWarning(true);
  };

  return (
    <>
      <Container>
        <h1 className="page-title">Delete A Saved Demo</h1>
        <div className="dashboard-wrapper">
          <Row className="justify-content-md-center">
            <Col sm={12} md={6} className="mt-0">
              <div className="dashboard-box dashboard-col-bg mb-4">
                <h2 className="sub-form-title mb-6 d-flex">Delete Demo</h2>
                <p className="m-0">
                  Removing a saved demo will permanently delete it. This action
                  cannot be undone.
                </p>

                <p className="mt-4 mb-0 font-weight-bold">
                  Select a saved demo to remove:
                </p>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="dd">
                      <Form.Control
                        as="select"
                        name="restore-saved-demo"
                        value={restoreSavedDemoId}
                        disabled={true === showLoader}
                        onChange={(e) =>
                          setRestoreSavedDemoId(parseInt(e.target.value))
                        }
                        className="inputclass form-control"
                      >
                        <option value="0">Select A Saved Demo</option>
                        {savedDemos.map((item, index) => (
                          <option key={"sd-" + index} value={item.demoResetId}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-4">
                    <Button
                      type="button"
                      className="btn btn-danger"
                      disabled={restoreSavedDemoId <= 0 || true === showLoader}
                      onClick={() => {
                        handleUserClickedRemove();
                      }}
                    >
                      {showLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <ConformationModal
        show={showOverwriteWarning}
        onHide={() => setShowOverwriteWarning(false)}
        onPrimary={() => removeDemo()}
        onSecondary={() => {
          setShowOverwriteWarning(false);
          setShowLoader(false);
        }}
        showSecondary={true}
        primaryText="Continue"
        secondaryText="Cancel"
        title="Removed Demo?"
        bodyText="If you continue, the saved demo will be removed and you cannot restore it later. Do you want to continue?"
      />
    </>
  );
};

import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Form, Button, Modal, Badge } from "react-bootstrap";
import { FaSearch, FaTrashAlt, FaTimes } from "react-icons/fa";
import {
  AiOutlineUpload,
  AiFillPlusCircle,
  AiOutlinePlus,
  AiOutlineDelete,
} from "react-icons/ai";

import ListPagination from "../../../Components/Pagination";
import Buttons from "../../../Components/Shared/Button";
import { Link } from "react-router-dom";
import {
  deletePrivateResume,
  DownloadByteArray,
  getJobSeekerPrivateResumeList,
  getJobSeekerPublicResumeList,
  uploadPrivateResume,
} from "../../../Services/ResumeService";
import { toast } from "react-toastify";
import { getUserDetails } from "../../../Services/GetFields";
import moment from "moment";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import {
  jobSeekerResumesPerPage,
  fileResumeTitle,
} from "../../../Utilities/Helper";
import {
  base64ToArrayBuffer,
  saveByteArray,
} from "../../../Utilities/DownloadFile";

export default function ResumeUpload() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFileData(null);
    setFileError({ error: null, message: "" });
    setShow(true);
  };
  const [userDetails, setUserDetails] = useState({});
  const [showAddJIN, setShowAddJIN] = useState(false);
  const [publicResume, setPublicResume] = useState([]);
  const [privateResumes, setPrivateResumes] = useState([]);
  const [totalResumePublic, setTotalResumePublic] = useState(0);
  const [totalResumePrivate, setTotalResumePrivate] = useState(0);
  const [showSpinnerForPublic, setShowSpinnerForPublic] = useState(true);
  const [showSpinnerForPrivate, setShowSpinnerForPrivate] = useState(true);
  const [currentPageForPrivate, setCurrentPageForPrivate] = useState({
    value: "",
    filter: "",
  });
  const [currentPageForPublic, setCurrentPageForPublic] = useState({
    value: "",
    filter: "",
  });
  const [refreshTablePublic, setRefreshTablePublic] = useState(true);
  const [refreshTablePrivate, setRefreshTablePrivate] = useState(true);
  const [fileName, setFileName] = useState({
    JobSeekerResumeId: null,
    ResumePath: null,
    TemplateId: null,
  });
  const [showConfirmModal, setshowConfirmModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [FileData, setFileData] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [fileError, setFileError] = useState({ error: null, message: "" });
  const [showScanning, setShowScanning] = useState(false);
  const openConfimationMoadal = (id, type, fileName) => {
    if (type === "JIN") {
      setFileName({
        JobSeekerResumeId: 0,
        ResumePath: null,
        TemplateId: id,
      });
    } else {
      setFileName({
        JobSeekerResumeId: id,
        ResumePath: fileName,
        TemplateId: null,
      });
    }
    setshowConfirmModal(true);
    setDisableButton(false);
  };

  const onCloseModalHandler = () => {
    setFileName(null);
    setshowConfirmModal(false);
  };

  useEffect(() => {
    const getUserInfo = () => {
      getUserDetails()
        .then((res) => {
          const result = res.response;
          setUserDetails(result);
        })
        .catch((err) => console.log("User Details:", err));
    };
    getUserInfo();
  }, []);

  useEffect(() => {
    const getPrivateResumeList = () => {
      const filter =
        "?PageSize=" + jobSeekerResumesPerPage + currentPageForPrivate.filter;
      getJobSeekerPrivateResumeList(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setPrivateResumes(result.privateResumesList);
          setTotalResumePrivate(result.jobSeekerPrivateResumesTotalCount);
          setShowSpinnerForPrivate(false);
          // setHideSpinnerForPublic(true);
          //if (result.publicResume === null) setShowAddJIN(true);
        })
        .catch((err) => {
          setShowSpinnerForPrivate(false);
          console.log("Private Resume List:", err);
        });
    };
    getPrivateResumeList();
  }, [refreshTablePrivate]);

  useEffect(() => {
    const getPublicResumeList = () => {
      const filter =
        "?recordperpage=" +
        jobSeekerResumesPerPage +
        currentPageForPublic.filter;
      getJobSeekerPublicResumeList(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setPublicResume(result.publicResume);
          setTotalResumePublic(result.jobSeekerPublicResumesTotalCount);
          setShowSpinnerForPublic(false);
        })
        .catch((err) => {
          setShowSpinnerForPublic(false);
          console.log("Public Resume List:", err);
        });
    };
    getPublicResumeList();
  }, [refreshTablePublic]);

  const pageChangeHandlerPublic = (page) => {
    if (currentPageForPublic.value !== page) {
      setCurrentPageForPublic((prev) => ({
        value: page,
        filter: `&PageNumber=${page}`,
      }));
      refreshTableHandlerPublic();
    }
  };

  const pageChangeHandlerPrivate = (page) => {
    if (currentPageForPrivate.value !== page) {
      setCurrentPageForPrivate((prev) => ({
        value: page,
        filter: `&PageNumber=${page}`,
      }));
      refreshTableHandlerPrivate();
    }
  };

  const refreshTableHandlerPublic = () => {
    setRefreshTablePublic((prev) => !prev);
    setShowSpinnerForPublic(true);
  };

  const refreshTableHandlerPrivate = () => {
    setRefreshTablePrivate((prev) => !prev);
    setShowSpinnerForPrivate(true);
  };

  const DeletePrivateResumeHandler = () => {
    deletePrivateResume(fileName)
      .then((res) => {
        toast.success(res.response);

        if (fileName.TemplateId != null) {
          refreshTableHandlerPublic();
          refreshTableHandlerPrivate();
          setCurrentPageForPublic((prev) => ({ ...prev, filter: "" }));
        } else {
          refreshTableHandlerPrivate();
          setCurrentPageForPrivate((prev) => ({ ...prev, filter: "" }));
        }
        //setHideSpinnerForPublic(false);
      })
      .catch((err) => {
        console.log(err);
      });
    onCloseModalHandler();
  };

  // function readFile(file) {
  //   return new Promise((resolve, reject) => {
  //     // Create file reader
  //     let reader = new FileReader();

  //     // Register event listeners
  //     reader.addEventListener("loadend", (e) => resolve(e.target.result));
  //     reader.addEventListener("error", reject);

  //     // Read file
  //     reader.readAsArrayBuffer(file);
  //   });
  // }

  // function bin2String(array) {
  //   var result = "";
  //   for (var i = 0; i < array.length; i++) {
  //     result += String.fromCharCode(parseInt(array[i], 2));
  //   }
  //   return result;
  // }

  // async function getAsByteArray(file) {
  //   return new Uint8Array(await readFile(file));
  // }

  // var fileData;
  // const checkFileContent = (fileData) => {
  //   // var fr = new FileReader();
  //   // fr.onload = function () {
  //   //fileData = fr.result;
  //   // ReadPDF_File(fileData);
  //   var bots = ["Testing", "good", "find"];
  //   var isBot = false;
  //   isBot = bots.some(function (word) {
  //     return fileData.indexOf(word) !== -1;
  //   });
  //   // console.log(fileData);
  //   if (isBot) alert("Found");
  //   return isBot;
  //   // };
  //   //fr.readAsText(filelocation);
  // };
  // function string2Bin(str) {
  //   var result = [];
  //   for (var i = 0; i < str.length; i++) {
  //     result.push(str.charCodeAt(i).toString(2));
  //   }
  //   return result;
  // }
  // async function loadFile(file) {
  //   let text = await new Response(file).text();
  //   // if (text.toLowerCase().("Hello aniket".toLowerCase())) {
  //   //   return true;
  //   // }
  //   // return false;
  //   var bots = ["bad", "good", "find"];
  //   var isBot = false;
  //   isBot = bots.some(function (word) {
  //     return text.indexOf(word) !== -1;
  //   });
  //   console.log(isBot, text);
  //   return isBot;
  // }

  const onFileChange = async (e) => {
    setFileData(e.target.files[0]);
    if (e.target.files.length !== 0) {
      var file = e.target.files[0];
      var type = file.name.split(".");
      if (type.length < 1) {
      } else {
        type = type[type.length - 1];
      }
      if (type === "pdf" || type === "doc" || type === "docx") {
        if (file.size === 0) {
          setFileError({ error: true, message: "The file is empty" });
          return;
        }
        var filesize = file.size;
        var mb = (filesize / (1024 * 1024)).toFixed();
        if (mb > 3) {
          setFileError({
            error: true,
            message: "The file size must be less than or equal to 3 mb",
          });
          return;
        }
        setFileError({ error: false, message: "" });
        setFileData(file);
      } else {
        setFileError({ error: true, message: "Only pdf and doc are allowed" });
      }
    } else {
      setFileError({ error: true, message: "Please select the file" });
    }
  };

  const uploadResume = () => {
    if (fileError.error === null || fileError.error === true) {
      setFileError({
        error: true,
        message:
          fileError.message !== ""
            ? fileError.message
            : "Please select the file",
      });
      return;
    }
    let formData = new FormData();
    formData.append("file", FileData);
    formData.append("jobTitle", jobTitle);
    setShowScanning(true);
    setDisableButton(true);
    uploadPrivateResume(formData)
      .then((res) => {
        const result = res;
        setShow(false);
        setDisableButton(false);
        setFileData(null);
        if (result.isSuccess) {
          refreshTableHandlerPrivate();
          toast.success(result.response);
        } else {
          toast.error(result.response);
        }
        setShowScanning(false);
        handleClose();
      })
      .catch((err) => {
        setDisableButton(false);
        handleClose();
        setShowScanning(false);
        //toast.error(err.response.data.response);
        console.log("File Upload: ", err);
      });
  };

  const downloadFile = (filename, resumeName) => {
    DownloadByteArray(filename)
      .then((res) => {
        const result = JSON.parse(res.response);
        var sampleArr = base64ToArrayBuffer(result.fileContentInBytes);
        saveByteArray(resumeName, result.fileName, sampleArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">Resume </h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box resume dashboard-col-bg mb-4 upload-resume-btns-mobile">
              <h2 className="page-sub-title d-flex">
                Public Resume
                <Col className="text-right">
                  {/* {showAddJIN && ( */}
                  <Link
                    style={{ float: "right" }}
                    to={`${process.env.PUBLIC_URL}/jobseeker/jintemplate`}
                    className="btn btn-warning btn-xs white upload-icon ml-3"
                  >
                    <AiOutlineUpload className="mr-2" />
                    Upload Public Resume (no contact info)
                    {/* <span className="fa fa-plus"></span> */}
                  </Link>
                  {/* )} */}
                </Col>
              </h2>
              {/* {publicResume !== null && ( */}
              {showSpinnerForPublic && <SpinnerLoader />}
              {publicResume.length === 0 && !showSpinnerForPublic && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              {publicResume.map((item, index) => (
                <div className="upload-cv-box mb-3" key={index}>
                  <Button
                    variant="secondary"
                    size="sm"
                    className="fav-icon ml-2 btn-delete float-right"
                    onClick={() =>
                      openConfimationMoadal(item.jinTemplateId, "JIN")
                    }
                  >
                    <FaTrashAlt />
                  </Button>
                  <Link
                    className="btn btn-secondary fav-icon ml-auto btn-delete float-right"
                    to={`${process.env.PUBLIC_URL}/jobseeker/jintemplate/${item.jinTemplateId}`}
                  >
                    <span className="icon icon-post"></span>
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/jobseeker/resumedetails/${item.jinTemplateId}`}
                  >
                    <h4>Public Resume: {item.jobTitle}</h4>
                  </Link>
                  <label>
                    Uploaded:
                    <span style={{ marginLeft: "5px" }}>
                      {moment(
                        new Date(item.createdDate.toString() + "Z").toString()
                      ).format("MMMM D, Y")}
                    </span>
                  </label>
                </div>
              ))}
              {publicResume.length !== 0 && (
                <ListPagination
                  recordPerPage={jobSeekerResumesPerPage}
                  TotalRecord={totalResumePublic}
                  className="mt-3"
                  onPageChange={pageChangeHandlerPublic}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>

      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box resume dashboard-col-bg mb-4 upload-resume-btns-mobile">
              <h2 className="page-sub-title d-flex">
                Private Resume{" "}
                <Badge
                  pill
                  bg="danger"
                  className="bg-warning text-white px-3 ml-3 font-weight-normal mb-5"
                >
                  {totalResumePrivate}
                </Badge>
                <Col className="text-right">
                  {userDetails.isSponsered === true && (
                    <Button
                      style={{ float: "right" }}
                      className="btn btn-warning btn-xs white upload-icon ml-3"
                      onClick={handleShow}
                    >
                      <AiOutlineUpload className="mr-2" />
                      Upload Private Resume (Doc or PDF)
                    </Button>
                  )}
                  <Link
                    to={`${process.env.PUBLIC_URL}/jobseeker/jintemplateprivate`}
                    style={{ float: "right" }}
                    className="btn btn-warning btn-xs white upload-icon"
                  >
                    <AiOutlineUpload className="mr-2" />
                    Upload Private Resume (JIN)
                  </Link>
                </Col>
              </h2>
              {showSpinnerForPrivate && <SpinnerLoader />}
              {privateResumes.length === 0 && !showSpinnerForPrivate && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              {privateResumes.map((item, index) => (
                <div key={index} className="upload-cv-box mb-3">
                  {item.templateId === null && (
                    <Fragment>
                      {" "}
                      <Button
                        variant="secondary"
                        size="sm"
                        className="fav-icon ml-auto btn-delete float-right"
                        onClick={() =>
                          openConfimationMoadal(
                            item.jobSeekerResumeId,
                            "Private",
                            item.resumePath
                          )
                        }
                      >
                        <FaTrashAlt />
                      </Button>
                      <h4
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          downloadFile(item.resumePath, item.resumePath)
                        }
                        title="click for download"
                      >
                        Private Resume:{" "}
                        {fileResumeTitle(item.resumePath, item.resumeName)}
                      </h4>
                    </Fragment>
                  )}
                  {item.templateId !== null && (
                    <Fragment>
                      {" "}
                      <Button
                        variant="secondary"
                        size="sm"
                        className="fav-icon ml-2 btn-delete float-right"
                        onClick={() =>
                          openConfimationMoadal(item.templateId, "JIN")
                        }
                      >
                        <FaTrashAlt />
                      </Button>
                      <Link
                        className="btn btn-secondary fav-icon ml-auto btn-delete float-right"
                        to={`${process.env.PUBLIC_URL}/jobseeker/jintemplateprivate/${item.templateId}`}
                      >
                        <span className="icon icon-post"></span>
                      </Link>
                      <h4>
                        <Link
                          to={`${process.env.PUBLIC_URL}/jobseeker/resumedetails/${item.templateId}`}
                          style={{ cursor: "pointer" }}
                        >
                          Private Resume: {item.templateName}
                        </Link>
                      </h4>
                    </Fragment>
                  )}
                  <label>
                    Uploaded:
                    <span style={{ marginLeft: "5px" }}>
                      {moment(
                        new Date(item.createdDate.toString() + "Z").toString()
                      ).format("MMMM D, Y")}
                    </span>
                  </label>
                </div>
              ))}
              {privateResumes.length !== 0 && (
                <ListPagination
                  recordPerPage={jobSeekerResumesPerPage}
                  TotalRecord={totalResumePrivate}
                  className="mt-3"
                  onPageChange={pageChangeHandlerPrivate}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row className="d-none">
          <Col md={12}>
            <div className="dashboard-box resume dashboard-col-bg">
              <h2 className="page-sub-title">Resume notes</h2>
              <div className="resume-notes mb-3">
                <Button
                  variant="secondary"
                  size="sm"
                  className="fav-icon ml-auto btn-delete float-right"
                >
                  <i className="far fa-trash-alt"></i>
                </Button>
                <p>
                  Jennifer, you may want to include your skills after your
                  experience. Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat.
                </p>
                <label>
                  Posted: <span>February 13, 2021</span>
                </label>
              </div>
              <div className="resume-notes mb-3">
                <Button
                  variant="secondary"
                  size="sm"
                  className="fav-icon ml-auto btn-delete float-right"
                >
                  <i className="far fa-trash-alt"></i>
                </Button>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <label>
                  Posted: <span>February 13, 2021</span>
                </label>
              </div>
              <div className="resume-notes mb-3">
                <Button
                  variant="secondary"
                  size="sm"
                  className="fav-icon ml-auto btn-delete float-right"
                >
                  <i className="far fa-trash-alt"></i>
                </Button>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <label>
                  Posted: <span>February 13, 2021</span>
                </label>
              </div>
              <ListPagination />
            </div>
          </Col>
        </Row>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="custome-modal"
        >
          <div className="message-container custome-msg-container">
            <div className="message-header-box">
              <h4>Upload Private Resumefdsa</h4>
              <div className="messages-modal-actions">
                <Button className="btn" onClick={handleClose}>
                  <FaTimes />
                </Button>
              </div>
            </div>
            <div className="message-body">
              <form>
                {/* <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Resume title"
                    className="inputclass "
                  />
                </Form.Group> */}
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                    className="validate"
                    type="file"
                    onChange={onFileChange}
                  />
                  <span style={{ color: "red" }}>{fileError.message}</span>
                </Form.Group>

                <Form.Control
                  type="text"
                  placeholder="Professional Title*"
                  className="inputclass"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </form>
            </div>
            <div className="message-footer">
              <Buttons
                variant="warning"
                title="Upload Private Resume"
                type="submit"
                size="xs"
                disabled={disableButton}
                color="white"
                onClick={uploadResume}
              ></Buttons>
              {showScanning && (
                <span style={{ color: "green", fontWeight: "400" }}>
                  Scanning document...
                </span>
              )}
            </div>
          </div>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          show={showConfirmModal}
          onHide={onCloseModalHandler}
          centered
          className="confirm-modal"
        >
          <div className="message-container custome-msg-container">
            <div className="message-header-box">
              <h4>Confirm</h4>
              <div className="messages-modal-actions">
                <Button className="btn" onClick={onCloseModalHandler}>
                  <FaTimes />
                </Button>
              </div>
            </div>
            <div className="message-body">
              Are you sure you want to delete the resume?
            </div>
            <div className="message-footer pt-0">
              <Buttons
                variant="primary"
                title="Ok"
                type="submit"
                size="xs"
                color="white"
                onClick={() => {
                  DeletePrivateResumeHandler();
                }}
              ></Buttons>
              <Buttons
                variant="secondary"
                title="Cancel"
                type="submit"
                size="xs"
                color="white"
                onClick={() => {
                  onCloseModalHandler();
                }}
              ></Buttons>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
// export default ResumeUpload;

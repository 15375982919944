import React, { useState, useEffect, Component, Fragment } from "react";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { getJob } from "../../../Services/AllJobsService";
import { toast } from "react-toastify";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import {
  fillCloseJob,
  getJobResumes,
  sendNotInterested,
  getUserDetails,
} from "../../../Services/GetFields";
import { DownloadByteArray } from "../../../Services/ResumeService";
import {
  base64ToArrayBuffer,
  saveByteArray,
} from "../../../Utilities/DownloadFile";
import MessageLimit from "../../../Components/Shared/MessageLimit";
import { _delete } from "../../../Services/AxiosService";
import { API_URL, declineMessage } from "../../../Utilities/Helper";
import { getData } from "../../../Utilities/StorageHelper";

const EmployerJobDetails = (props) => {
  const jobId = props.match.params.jobId;
  const [job, setJob] = useState({});
  const [resume, setResume] = useState();
  const [modalToggle, setModalToggle] = useState(false);
  const [message, setMessage] = useState("");
  const [jobTypeId, setJobTypeId] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [selectedResume, setSelectedResume] = useState("");
  const [selectedResumeJobSeeker, setSelectedResumeJobSeeker] = useState("");
  const [totalLength, setTotalLength] = useState(500);
  const [currentLength, setCurrentLength] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  useEffect(() => {
    function getJobDetails() {
      getJob(jobId)
        .then((res) => {
          const response = res.response;
          if (response === "") {
            setJob(null);
          } else {
            const result = JSON.parse(res.response);
            // console.log("Test:", result, "--", res);
            setJob(result);
            setJobTypeId(result.jobTypeId);
          }
        })
        .catch((err) => {
          console.log("Test:", err);
          toast.error(err.response, { toastId: "custom_toast" });
        });
    }
    getJobDetails();

    function getResumes() {
      getJobResumes(jobId)
        .then((res) => {
          const result = JSON.parse(res.response);
          setResume(result);
        })
        .catch((err) => {
          toast.error(err.response, { toastId: "custom_toast" });
        });
    }
    getResumes();

    function getUser() {
      getUserDetails()
        .then((rspUserDetails) => {
          let userDetails = rspUserDetails.response;
          setUserEmail(userDetails.email);
        })
        .catch((err) => {
          toast.error(err.response, { toastId: "custom_toast" });
        });
    }
    getUser();
  }, []);

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
    show: false,
    id: null,
  });

  const openDeleteConfirmModal = (id) => {
    setShowDeleteConfirmModal({
      show: true,
      id: id,
    });
  };

  const closeDeleteConfirmModal = () => {
    setShowDeleteConfirmModal({
      show: false,
      id: null,
    });
  };

  const setRefreshAfterDelete = () => {
    window.location.href = `${process.env.PUBLIC_URL}/#/jobslist`;
  };

  const deleteJobHandler = (id) => {
    const headers = {
      Authorization: "Bearer " + getData("token"),
      "content-type": "application/json",
    };
    const toastId = "custom_toast";

    // axios.
    _delete(
      API_URL + `job/deletejob/` + id
      // , {
      //     headers: headers,
      //   }
    )
      .then((response) => {
        // console.log(response, response.message, "response success");
        if (response.status === 200) {
          setTimeout(setRefreshAfterDelete, 6000);
          toast.success(response.data.response, { toastId: toastId });
        }
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(error.response);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        //  console.log(error.config);
      });
  };

  const handleFillCloseJob = (id) => {
    fillCloseJob(id)
      .then((res) => {
        // console.log(res, "response success");
        toast.success(res.response);
        setTimeout(setRefreshAfterDelete, 6000);
      })
      .catch((err) => {
        toast.error(err.response, { toastId: "custom_toast" });
      });
  };

  const openModal = () => {
    setMessageError(false);
    setModalToggle(!modalToggle);
    setMessage("");
    setCurrentLength(0);
  };

  const sendMessage = (e) => {
    if (e === "") {
      setMessageError(true);
    }
    setCurrentLength(e.length);
    if (e.length > 500) {
      return;
    }
    setMessage(e);
  };

  const downloadFile = (filename, resumeName) => {
    DownloadByteArray(filename)
      .then((res) => {
        const result = JSON.parse(res.response);
        var sampleArr = base64ToArrayBuffer(result.fileContentInBytes);
        saveByteArray(resumeName, result.fileName, sampleArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setRefresh = () => {
    window.location.reload();
  };

  const selectResumeId = (selectedResume, resumeJobseeker) => {
    setSelectedResume(selectedResume);
    setSelectedResumeJobSeeker(resumeJobseeker);
  };

  const sendNotInterestedMessage = () => {
    if (message === "") {
      setMessageError(true);
    } else {
      setMessageError(false);
    }
    // if (message !== "") {
    const data =
      // JSON.stringify(
      {
        JobApplicationId: selectedResume,
        JobId: job.jobId,
        Message: message,
        JobSeekerId: selectedResumeJobSeeker,
      };
    // );
    sendNotInterested(data).then((res) => {
      // console.log(res.response, res);
      if (res.response) {
        // console.log(res.response);
        toast.success(res.response);
        setModalToggle(!modalToggle);
        setTimeout(setRefresh, 6000);
      } else {
        toast.error("Something went wrong.");
      }
    });
    // }
  };

  const declineApplication = (selectedResume, resumeJobseeker) => {
    const data =
      // JSON.stringify(
      {
        JobApplicationId: selectedResume,
        JobId: job.jobId,
        Message: null,
        JobSeekerId: resumeJobseeker,
      };
    // );
    // console.log(data);
    sendNotInterested(data).then((res) => {
      if (res.response) {
        // console.log(res.response);
        toast.success(res.response);
        setTimeout(setRefresh, 6000);
      } else {
        toast.error("Something went wrong.");
      }
    });
  };

  let val1 = "";
  let val2 = "";
  let link = "";
  if (job !== null) {
    if (job.jobPostingLink) {
      let text = job.jobPostingLink;
      val1 = text.includes("http");
      val2 = text.includes("https");
      // console.log(val1);
      // console.log(val2);

      if (val1 === true) {
        link = text.replace("http://", "");
      } else if (val2 === true) {
        link = text.replace("https://", "");
      } else {
        link = "https://" + job.jobPostingLink;
      }
      // console.log(link);
    }
  }
  // resume && resume.map((item, index) => { console.log(item, index); });

  return (
    <div className="container p-0">
      <h1 className="page-title">Job Details</h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg pb-5">
              <Link
                to={`${process.env.PUBLIC_URL}/jobslist`}
                className="page-sub-title-with-back"
              >
                <AiOutlineArrowLeft />
                Back to jobs
              </Link>
              {job === null && (
                <div className="users-wrap users-bg job-details-wrap btns-width d-block">
                  <h6 style={{ color: "red", textAlign: "center" }}>
                    This job has been deleted.
                  </h6>
                </div>
              )}
              {job !== null && Object.keys(job).length !== 0 && (
                <Fragment>
                  <div className="users-wrap users-bg job-details-wrap btns-width d-block">
                    <div className="users-box left w-100 d-flex">
                      <div>
                        <h3 className="job-title">{job.positionTitle}</h3>
                        {/* jobPostingLink  Show job posting link   float-left*/}
                        <div className="company-name">
                          {job.companyName === null || job.companyName === ""
                            ? "-"
                            : job.companyName}
                        </div>
                      </div>

                      <div className="ml-auto d-flex align-items-start">
                        {job.contactEmail === userEmail && (
                          <>
                            <Link
                              to={`${process.env.PUBLIC_URL}/editjob/${job.jobId}`}
                              className="text-decoration-none"
                            >
                              <Buttons
                                variant="primary"
                                title="Edit"
                                type="submit"
                                size="xs"
                                color="white"
                                marginLeft="ml-3"
                                icon="icon icon-post mr-2"
                              ></Buttons>
                            </Link>
                            <Buttons
                              variant="danger"
                              title="Delete"
                              type="button"
                              size="xs"
                              color="white"
                              marginLeft="ml-3"
                              icon="icon icon-trash mr-2"
                              onClick={() => openDeleteConfirmModal(job.jobId)}
                            ></Buttons>
                            {jobTypeId === 1 && (
                              <Buttons
                                variant="light"
                                title="Filled/Closed"
                                type="button"
                                size="xs"
                                color="white"
                                marginLeft="ml-3"
                                icon="icon far fa-times-circle mr-2"
                                onClick={() => handleFillCloseJob(job.jobId)}
                              ></Buttons>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="detail-view mt-3">
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Job post start date:</span>{" "}
                            {new Date(job.jobStartDate).toDateString()}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Job post end date:</span>{" "}
                            {new Date(job.jobEndDate).toDateString()}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Contact Name:</span>
                            {job.contactName === null || job.contactName === ""
                              ? "-"
                              : job.contactName}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Contact Email:</span>
                            {job.contactEmail === null ||
                            job.contactEmail === ""
                              ? "-"
                              : job.contactEmail}
                          </div>
                        </Col>

                        <Col>
                          <div className="post-date">
                            <span>Telephone:</span>
                            {job.contactPhone === null ||
                            job.contactPhone === ""
                              ? "-"
                              : job.contactPhone}
                          </div>
                        </Col>
                        <Col>
                          <div className="location">
                            <span>Address:</span>{" "}
                            {`${job.addressLine1}, ${
                              job.addressLine2 !== null
                                ? job.addressLine2 + ", "
                                : ""
                            }                                         
                          ${job.city !== null ? job.city + ", " : "-"} ${
                              job.stateName !== null
                                ? job.stateName + ", "
                                : "-"
                            }
                         ${job.countryName !== null ? job.countryName : "-"}`}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Industry:</span>
                            {job.industryName === null ||
                            job.industryName === ""
                              ? "-"
                              : job.industryName}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Compensation:</span>
                            {job.payRate === null || job.payRate === ""
                              ? "-"
                              : job.payRate}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Job Length:</span>
                            {job.jobLength === null || job.jobLength === ""
                              ? "-"
                              : job.jobLength}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Travel Required:</span>{" "}
                            {job.travelRequired === true && "Yes"}
                            {job.travelRequired === false && "No"}
                            {job.travelRequired === null && "-"}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Telecommute:</span>
                            {job.telecommute === true && "Yes"}
                            {job.telecommute === false && "No"}
                            {job.telecommute === null && "-"}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Position Responsibilites:</span>{" "}
                            {job.positionResponsibilities === null ||
                            job.positionResponsibilities === ""
                              ? "-"
                              : (
                                <span style={{ wordBreak: "break-word", color: "#2660a5" }}>{job.positionResponsibilities}</span>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Minimum Qualification:</span>{" "}
                            {job.minimumQualification === null ||
                            job.minimumQualification === ""
                              ? "-"
                              : (
                                <span style={{ wordBreak: "break-word", color: "#2660a5"}}>{job.minimumQualification}</span>
                              )}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Preferred Qualification:</span>{" "}
                            {job.preferredQualification === null ||
                            job.preferredQualification === ""
                              ? "-"
                              : (
                                <span style={{ wordBreak: "break-word", color: "#2660a5"}}>{job.preferredQualification}</span>
                              )}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Employment policy:</span>
                            {job.employmentPolicy === null ||
                            job.employmentPolicy === ""
                              ? "-"
                              : job.employmentPolicy}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {jobTypeId === 3 && (
                          <Col>
                            <div className="post-date">
                              <span>Job post link:</span>
                              {job.jobPostingLink === null ||
                              job.jobPostingLink === "" ? (
                                "-"
                              ) : (
                                <a
                                  className="post-date"
                                  href={link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {job.jobPostingLink}
                                </a>
                              )}
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                  <div className="job-description">
                    <h3>Description</h3>
                    <p>
                      {job.jobDescription === null || job.jobDescription === ""
                        ? "-"
                        : job.jobDescription}
                    </p>
                  </div>

                  {jobTypeId !== 3 && (
                    <ul className="job-list sponsership-list applied-list">
                      {resume && resume.length !== 0 && (
                        <li className="headings mb-2">
                          <div className="name">Name</div>
                          <div className="email">Resume</div>
                          <div className="ml-auto">&nbsp;</div>
                        </li>
                      )}
                      {resume &&
                        job.contactEmail === userEmail &&
                        resume.length !== 0 &&
                        resume.map((item, index) => (
                          <li className="align-items-center mb-2">
                            <div className="name">{item.jobSeekerName}</div>
                            <div className="email">
                              {item.templateId !== 0 && (
                                <Link
                                  to={`${process.env.PUBLIC_URL}/employer/resumedetails/${item.templateId}`}
                                >
                                  {item.templateName}
                                </Link>
                              )}
                              {item.templateId === null && (
                                <>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      downloadFile(
                                        item.resumePath,
                                        item.resumePath
                                      )
                                    }
                                  >
                                    {item.resumeName}
                                  </span>
                                  {/* <label>{"Private Resume"}</label> */}
                                </>
                              )}
                            </div>
                            {jobTypeId === 1 && (
                              <div className="ml-auto">
                                <Button
                                  className="btn-request btn btn-warning btn-sm"
                                  onClick={() => {
                                    declineApplication(
                                      item.jobApplicationId,
                                      item.jobSeekerId
                                    );
                                  }}
                                >
                                  Decline Application
                                </Button>
                              </div>
                            )}
                            {jobTypeId === 1 && (
                              <div className="ml-auto">
                                <Button
                                  className="btn-request btn btn-primary btn-sm ml-3"
                                  onClick={() => {
                                    openModal();
                                    selectResumeId(
                                      item.jobApplicationId,
                                      item.jobSeekerId
                                    );
                                  }}
                                >
                                  {"Send Message & Decline"}
                                </Button>
                              </div>
                            )}
                          </li>
                        ))}
                    </ul>
                  )}
                  {/* {resume && resume.length === 0 && (
                <ul className="job-list sponsership-list applied-list">
                  <li className="headings mb-2">
                    <div className="name">Name</div>
                    <div className="email">Resume</div>
                    <div className="company ml-auto">&nbsp;</div>
                  </li>
                </ul>
              )} */}
                  {resume && resume.length === 0 && (
                    <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                  )}
                </Fragment>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={showDeleteConfirmModal.show}
        onHide={closeDeleteConfirmModal}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={closeDeleteConfirmModal}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            Are you sure you want to delete the job?
          </div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="Ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                deleteJobHandler(showDeleteConfirmModal.id);
                closeDeleteConfirmModal();
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="Cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                closeDeleteConfirmModal();
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>
      <Modal show={modalToggle} onHide={openModal} className="respond-modal">
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormInput
            value={message}
            maxLength={"500"}
            type="textarea"
            placeholder="Your message goes here"
            onChange={(e) => {
              sendMessage(e);
            }}
          />
          <MessageLimit currentLength={message} totalLength={totalLength} />
          {messageError && <p style={{ color: "red" }}>Message is required</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            show={modalToggle}
            onHide={openModal}
            onClick={sendNotInterestedMessage}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmployerJobDetails;

import React, { useState, Component, Fragment } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import FormInput from "./Shared/FormInput";
import Buttons from "./Shared/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Validators } from "../Utilities/Validator";
import Dropdown from "./Shared/Dropdown";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Roles } from "../Utilities/Helper";

import AdminManageUsers from "../Modules/Views/Admin/AdminManageUsers";

// const options = [
//   { label: "Automobile", value: 1 },
//   { label: "Finance", value: 2 },
//   { label: "Healthcare", value: 3 },
// ];

class AdminUserSearch extends Component {
  state = {
    // position: "",
    // city: "",
    // fromDate: null,
    // toDate: null,
    // state: "",
    // company: "",
    // industryid: null,
    // jobtypeid: null,
    // groupid: "",
    // this.props.groupId
    // isjobapplied: null,
    userTypeid: "",
    name: "",
    isActive: "",
    groupid: "",
  };

  // DatePicker settings

  constructor(props) {
    super(props);
    this.minDate = new moment(props.minDate);
    this.maxDate = new moment(props.maxDate);
    //this.state = {
    //  fromDate: props.minDate,
    //  toDate: props.maxDate,
    // // category dropdown
    // categories: [
    //   {
    //     options: [
    //       { label: "Sort", value: 1 },
    //       { label: "Location", value: 2 },
    //       { label: "Date", value: 3 },
    //     ]
    //   },
    // ],
    // category dropdown
    //  industry: [
    //   {
    //     options: [
    //       { label: "Industry", value: 1 },
    //       { label: "Finance", value: 2 },
    //       { label: "Healthcare", value: 3 },
    //     ]
    //   },
    // ],
    //  };
    //this.handleDateChange = this.handleDateChange.bind(this);
    //this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  // handleDateChange(date) {
  //   this.setState({
  //     fromDate: date,
  //   });
  // }

  // handleEndDateChange(date) {
  //   this.setState({
  //     toDate: date,
  //   });
  // }

  // handelindustrychange = (key) => (e) => {
  //   this.setState({ [key]: e.target.value });
  // };

  handleUserTypeChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleGroupChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };
  // handleGroupChange = (key) => (e) => {
  //   this.setState({ [key]: e.target.value });
  // };

  // validation check

  handleSubmit = async (event) => {
    event.preventDefault();
    let searchParam = "";
    for (var key in this.state) {
      if (this.state.hasOwnProperty(key)) {
        if (
          this.state[key] !== "" &&
          this.state[key] !== null &&
          this.state[key] !== undefined
        ) {
          searchParam += `&${key}=${this.state[key]}`;
        }
      }
    }
    this.props.searchParam(searchParam);
    // console.log(searchParam);
  };

  resetSearchFilter() {
    this.setState({
      // position: "",
      // city: "",
      // fromDate: null,
      // toDate: null,
      // state: "",
      // company: "",
      // industryid: "",
      // jobtypeid: "",
      // groupid: "",
      userTypeid: "",
      isActive: "",
      name: "",
      groupid: "",
    });
    this.props.searchParam("");
  }
  exportData() {
    this.props.exportUsersList();
  }

  render() {
    const {
      // position,
      // city,
      // fromDate,
      // toDate,
      // state,
      // company,
      // industryid,
      // jobtypeid,
      // groupid,
      userTypeid,
      name,
      isActive,
      groupid,
      // = this.props.groupId
    } = this.state;
    const { history } = this.props;
    return (
      <div className="search mb-4">
        <Form noValidate>
          <Row>
            <Col md={4}>
              <FormInput
                value={name}
                type="text"
                placeholder="Name"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("name")}
              />
            </Col>
            <Col md={4}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  className="inputclass form-control"
                  value={userTypeid}
                  onChange={this.handleUserTypeChange("userTypeid")}
                >
                  <option value="">Select User Type</option>
                  {this.props.userTypeList &&
                    this.props.userTypeList.map((item) => (
                      <Fragment key={item.userTypeId}>
                        {item.userType !== Roles.admin && (
                          <option value={item.userTypeId}>
                            {item.userType}
                          </option>
                        )}
                      </Fragment>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="dd">
                <Form.Control
                  as="select"
                  value={this.state.groupid}
                  className="inputclass form-control"
                  onChange={this.handleGroupChange("groupid")}
                >
                  <option value="0">Select Group</option>
                  {this.props.groupsList &&
                    this.props.groupsList.map((item) => (
                      <option key={item.groupId} value={item.groupId}>
                        {item.groupName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  className="inputclass form-control"
                  value={isActive}
                  onChange={this.handleUserTypeChange("isActive")}
                >
                  <option value="">Select Status</option>
                  <option value="true">Active</option>
                  <option value="false">InActive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            {/* <Col>
             
             {this.state.categories.map(cat => (
                <Dropdown options={cat.options} title={cat.name} />
              ))}
            
             </Col> */}
            {/* <Col md={3}>
              <FormInput
                value={position}
                type="text"
                placeholder="Position"
                // icon="fas fa-search"
                // withIcon="with-icon"
                onChange={this.handleChange("position")}
              />
            </Col>

            <Col md={3}>
              <FormInput
                value={company}
                type="text"
                placeholder="Company Name"
                onChange={this.handleChange("company")}
              />
            </Col>
            <Col md={3}>
              <div className="form-group">
                <DatePicker
                  selected={this.state.fromDate}
                  onChange={this.handleDateChange}
                  minDate={this.minDate}
                  value={this.props.fromDate}
                  placeholderText="Job Posted From Date"
                  className="inputclass form-control"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <DatePicker
                  selected={this.state.toDate}
                  onChange={this.handleEndDateChange}
                  minDate={this.maxDate}
                  value={this.props.toDate}
                  placeholderText="Job Posted End Date"
                  className="inputclass form-control"
                />
              </div>
            </Col>
            <Col md={3}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  as="select"
                  className="inputclass form-control"
                  value={industryid}
                  onChange={this.handelindustrychange("industryid")}
                >
                  <option value="">Select Industry</option>
                  {this.props.industriesList &&
                    this.props.industriesList.map((item) => (
                      <option key={item.industryId} value={item.industryId}>
                        {item.industryName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="3" className="jobPostingType">
              <Form.Group controlId="jobPostingType">
                <Form.Control
                  onChange={this.handeljobtypchange("jobtypeid")}
                  as="select"
                  className="inputclass form-control"
                  value={jobtypeid}
                >
                  <option value="">Select Job Type</option>
                  {this.props.jobTypesList &&
                    this.props.jobTypesList.map((item) => (
                      <option key={item.jobTypeId} value={item.jobTypeId}>
                        {item.jobTypeName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <FormInput
                value={city}
                type="text"
                placeholder="City"
                onChange={this.handleChange("city")}
              />
            </Col>
            <Col md={3}>
              <FormInput
                value={state}
                type="text"
                placeholder="State"
                onChange={this.handleChange("state")}
              />
            </Col> */}

            {/* <Col>
            <div className="form-group calender-icon">
                  <DatePicker
                    selected={this.state.postDate}
                    onChange={this.handleDateChange}
                    minDate={this.date}
                    value={this.props.postDate}
                    placeholderText="Date & Time"
                    className="inputclass form-control"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  <i className="icon far fa-calendar"></i>
                </div>
            </Col> */}
            {/* <Col md={3} className="dd">
              <Form.Group controlId="dd">
                <Form.Control
                  as="select"
                  //defaultValue={this.props.groupId}
                  value={groupid}
                  className="inputclass form-control"
                  onChange={this.handleGroupChange("groupid")}
                >
                  <option value="0">All Groups</option>
                  {this.props.groupList &&
                    this.props.groupList.map((item, index) => (
                      <option key={index} value={item.groupId}>
                        {item.groupName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col> */}
            <Col className="col-auto" md={12}>
              <Buttons
                variant="warning"
                title="Search"
                type="submit"
                size="xs"
                color="white"
                onClick={this.handleSubmit}
              ></Buttons>
              <span style={{ marginLeft: 18 }}></span>
              <Buttons
                variant="warning"
                title="Reset"
                size="xs"
                color="white"
                onClick={() => {
                  this.resetSearchFilter();
                }}
              ></Buttons>
              <span style={{ marginLeft: 18 }}></span>

              <button
                type="button"
                className="btn btn-primary btn-sm float-right ml-1"
                onClick={this.exportData.bind(this)}
              >
                Export to Excel
              </button>
              <span style={{ marginLeft: 18 }}></span>
              <button
                onClick={this.props.exportToPdf}
                type="button"
                className="btn btn-primary btn-sm float-right"
              >
                Export to PDF
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(AdminUserSearch);

import React, { useState, useEffect, Component, useRef } from "react";
import { Col, Row, Form, Alert, Modal, Button, Badge } from "react-bootstrap";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import Buttons from "../../../Components/Shared/Button";
import ListPagination from "../../../Components/Pagination";
import { groupCategoryPerPage } from "../../../Utilities/Helper";
import {
  getAllGroupCategory,
  addGroupCategory,
  updateGroupCategory,
  getGroupCategory,
  deleteGroupCategory,
} from "../../../Services/Organization";
import {
  required,
  validateCharacterSpaceAndDigit,
} from "../../../Utilities/Validator";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import { toast } from "react-toastify";

export default function Organization() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    clearForm();
    setShow(true);
  };

  const [groupCategory, setGroupCategory] = useState([]);
  const [totalgroupCategory, setTotalgroupCategory] = useState();

  const [currentPage, setCurrentPage] = useState("");
  const [refreshTable, setRefreshTable] = useState(true);
  const [showPagination, setShowPagination] = useState(true);
  //const [showt, setShowt] = useState(false);

  const [form, setForm] = useState({ groupCategoryName: "", error: "" });
  const [categoryTitle, setCategoryTitle] = useState("Add Sub Category");
  const [updateForm, setUpdateForm] = useState({ Show: false, Id: null });

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
    show: false,
    id: null,
  });
  const closeDeleteConfirmModal = () => {
    setShowDeleteConfirmModal({
      show: false,
      id: null,
    });
  };

  const [showSpinnerForOrganization, setShowSpinnerForOrganization] =
    useState(true);

  const [showAlertSuccess, setShowAlertSuccess] = useState({
    show: false,
    message: "",
  });
  const [showAlertError, setShowAlertError] = useState({
    show: false,
    message: "",
  });

  //   const handleClose1 = () => setShowt(false);
  const hideAlerts = () => {
    setTimeout(() => {
      setShowAlertSuccess({ show: false, message: "" });
      setShowAlertError({ show: false, message: "" });
    }, 5000);
  };

  useEffect(() => {
    async function allGroupCategory() {
      setShowPagination(true);
      const filter = "recordperpage=" + groupCategoryPerPage + currentPage;
      getAllGroupCategory(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroupCategory(result.groupCategory);
          setTotalgroupCategory(result.totalRecord);
          setShowSpinnerForOrganization(false);
        })
        .catch((err) => {
          console.log("Group Category: ", err);
          setShowSpinnerForOrganization(false);
        });
    }
    allGroupCategory();
  }, [refreshTable]);

  const pageChangeHandler = (page) => {
    setCurrentPage(`&page=${page}`);
    setRefreshTable((prev) => !prev);
  };

  const groupCategoryChange = (e) => {
    setForm((prev) => {
      return { groupCategoryName: e.target.value, error: "" };
    });
  };

  const refreshTableHandler = () => {
    setRefreshTable((prev) => !prev);
    setShowSpinnerForOrganization(true);
  };

  const validateForm = () => {
    var FormErrorRequired = required(
      form.groupCategoryName,
      "Sub Category Name"
    );

    var FormErrorRegex = validateCharacterSpaceAndDigit(
      form.groupCategoryName,
      "Sub Category Name"
    );

    if (FormErrorRequired.error || FormErrorRegex.error) {
      setForm((prev) => {
        return {
          ...prev,
          error: FormErrorRequired.error
            ? FormErrorRequired.message
            : FormErrorRegex.message,
        };
      });
      return true;
    }
  };

  const clearForm = () => {
    setShow(false);
    setForm((prev) => {
      return { groupCategoryName: "", error: "" };
    });
    setCategoryTitle("Add Sub Category");
    setUpdateForm((prev) => {
      return { Show: false, Id: null };
    });
  };

  const addGroupCategoryHandler = () => {
    if (validateForm()) return;

    var data = { GroupCategoryName: form.groupCategoryName };
    //service
    addGroupCategory(data)
      .then((res) => {
        var result = JSON.parse(res.response);
        if (result.isSuccess === true) {
          clearForm();
          setShowAlertSuccess({ show: true, message: result.message });
          hideAlerts();
          refreshTableHandler();
        } else {
          setForm((prev) => {
            return { ...prev, error: result.message };
          });
        }
      })
      .catch((err) => {
        console.log("Add Sub Group: ", err);
      });
  };

  const editGroupCategoryHandler = (id) => {
    getGroupCategory(id)
      .then((res) => {
        var data = JSON.parse(res.response);
        setForm((prev) => {
          return { ...prev, groupCategoryName: data.groupCategoryName };
        });
        setCategoryTitle("Update Sub Category");
        setUpdateForm((prev) => {
          return { Show: true, Id: id };
        });
        setShow(true);
      })
      .catch((err) => {
        console.log("Edit Sub Group: ", err);
      });
  };

  const openDeletConfirmModal = (id) => {
    setShowDeleteConfirmModal({
      show: true,
      id: id,
    });
  };

  const deleteGroupCategoryHandler = (id) => {
    deleteGroupCategory(id)
      .then((res) => {
        var result = JSON.parse(res.response);
        if (result.isSuccess === true) {
          setShowAlertError({ show: true, message: result.message });
          hideAlerts();
          refreshTableHandler();
        } else {
          setShowAlertError({ show: true, message: result.message });
          hideAlerts();
        }
        setShowPagination(false);
      })
      .catch((err) => {
        console.log("Delete Sub Group: ", err);
      });
  };

  const updateGroupCategoryHandler = () => {
    if (validateForm()) return;

    var data = {
      GroupCategoryName: form.groupCategoryName,
      GroupCategoryId: updateForm.Id,
    };
    updateGroupCategory(data)
      .then((res) => {
        var result = JSON.parse(res.response);
        if (result.isSuccess === true) {
          clearForm();
          setShowAlertSuccess({ show: true, message: result.message });
          hideAlerts();
          refreshTableHandler();
        } else {
          setForm((prev) => {
            return { ...prev, error: result.message };
          });
        }
      })
      .catch((err) => {
        console.log("Update Sub Group: ", err);
      });
  };

  return (
    <React.Fragment>
      <Alert
        className="confirm-alert"
        variant="success"
        show={showAlertSuccess.show}
        onClose={() => setShowAlertSuccess({ show: false, message: "" })}
        dismissible
      >
        <p className="text-center mb-0 py-3">{showAlertSuccess.message}</p>
      </Alert>

      <Alert
        className="confirm-alert"
        variant="danger"
        show={showAlertError.show}
        onClose={() => setShowAlertError({ show: false, message: "" })}
        dismissible
      >
        <p className="text-center mb-0 py-3">{showAlertError.message}</p>
      </Alert>
      <div className="container p-0">
        <h1 className="page-title">
          Sub Category{" "}
          <Badge
            pill
            bg="danger"
            className="bg-warning text-white px-3 ml-3 font-weight-normal"
          >
            {totalgroupCategory}
          </Badge>
        </h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={12}>
              <div className="dashboard-box dashboard-col-bg sponsership-page">
                <h2 className="page-sub-title d-flex mb-0">
                  &nbsp;
                  <Button
                    type="button"
                    className="btn btn-warning btn-xs white ml-auto"
                    onClick={handleShow}
                  >
                    {" "}
                    Add Sub Category
                  </Button>
                </h2>

                <ul className="job-list support-team-list job-seekers-list mobile-list">
                  <li className="headings">
                    <div className="w-50">Name </div>
                    <div className="w-50"> </div>
                  </li>
                  {showSpinnerForOrganization && <SpinnerLoader />}
                  {groupCategory.length === 0 &&
                    !showSpinnerForOrganization && (
                      <h5 style={{ textAlign: "center" }}>No Data Found</h5>
                    )}
                  {groupCategory.map((item) => (
                    <li
                      className="align-items-center" 
                      key={item.groupCategoryId}
                    >
                      <div className="mobile-coulmn w-100">
                        <span className="mobile-column-name text-nowrap">Name</span>
                        <span className="w-100">{item.groupCategoryName}</span>
                      </div>
                       
                      <div className="w-50 seeker-action-btns text-right status small-action-btn">
                        <Button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() =>
                            editGroupCategoryHandler(item.groupCategoryId)
                          }
                        >
                          <span className="icon-edit mr-2"></span>Edit
                        </Button>
                        <Button
                          type="button"
                          className="ml-2 btn btn-danger btn-sm"
                          onClick={() =>
                            openDeletConfirmModal(item.groupCategoryId)
                          }
                        >
                          <span className="icon-trash mr-2"></span>Delete
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
                {groupCategory.length !== 0 && showPagination && (
                  <ListPagination
                    recordPerPage={groupCategoryPerPage}
                    TotalRecord={totalgroupCategory}
                    className="mt-3"
                    onPageChange={pageChangeHandler}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* Add Group Category Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custome-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>{categoryTitle}</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleClose}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            <form>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Sub Category Name"
                  className="inputclass "
                  onChange={groupCategoryChange}
                  value={form.groupCategoryName}
                  maxLength={100}
                />
                {form.error !== "" && (
                  <Form.Label style={{ color: "red" }}>{form.error}</Form.Label>
                )}
              </Form.Group>
            </form>
          </div>
          <div className="message-footer">
            {!updateForm.Show && (
              <Button
                variant="warning"
                title="Save"
                type="button"
                size="xs"
                color="white"
                onClick={addGroupCategoryHandler}
              >
                Save
              </Button>
            )}
            {updateForm.Show && (
              <Button
                variant="warning"
                title="Save"
                type="button"
                size="xs"
                color="white"
                onClick={updateGroupCategoryHandler}
              >
                update
              </Button>
            )}
          </div>
        </div>
      </Modal>

      {/* Delete Group Category Confirm Modal */}
      <Modal
        show={showDeleteConfirmModal.show}
        onHide={closeDeleteConfirmModal}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={closeDeleteConfirmModal}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            Are you sure you want to delete sub category?
          </div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="Ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                deleteGroupCategoryHandler(showDeleteConfirmModal.id);
                closeDeleteConfirmModal();
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="Cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                closeDeleteConfirmModal();
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

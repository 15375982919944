import { Fragment } from "react";
import { Button } from "react-bootstrap";
import {
  FaTrash,
  FaFlag,
  FaLongArrowAltRight,
  FaReply,
  FaReplyAll,
  FaRedo,
  FaRedoAlt,
} from "react-icons/fa";
export default function InboxMessagesActions({
  message,
  replay,
  flag,
  trash,
  restore,
  messageType,
  isFlagged,
}) {
  const onReplyHandler = () => {
    replay(message.senderId, message.userType);
  };
  const onFlagHandler = () => {
    flag({
      MessageId: message.messageId,
      UserType: message.userType,
      MessageType: message.messageType,
    });
  };
  const onDeleteHandler = () => {
    trash({
      MessageId: message.messageId,
      UserType: message.userType,
      MessageType: message.messageType,
    });
  };

  const onRestoreHandler = () => {
    restore({
      MessageId: message.messageId,
      UserType: message.userType,
      MessageType: message.messageType,
    });
  };

  return (
    <div className="email-actions text-right">
      {messageType === "Trash" && (
        <Button
          variant="secondary"
          title="Restore"
          size="sm"
          onClick={onRestoreHandler}
        >
          <FaRedoAlt />
        </Button>
      )}
      {messageType !== "Trash" && (
        <Fragment>
          {" "}
          {message.fullName != "JobInfoNetwork " && (
            <Button
              variant="secondary"
              size="sm"
              onClick={onReplyHandler}
              title="Replay"
            >
              <FaReply />
            </Button>
          )}
          <Button
            variant="secondary"
            size="sm"
            onClick={onFlagHandler}
            title="Flag"
            style={
              message.flagged || message.senderFlagged || isFlagged
                ? { backgroundColor: "#012e51", color: "#fff" }
                : {}
            }
          >
            <FaFlag />
          </Button>
          <Button
            variant="secondary"
            size="sm"
            onClick={onDeleteHandler}
            title="Trash"
          >
            <FaTrash />
          </Button>
        </Fragment>
      )}
    </div>
  );
}
{
  /* <Button variant="secondary" size="sm">
        <FaReplyAll />
      </Button>   
      <Button variant="secondary" size="sm">
        <FaLongArrowAltRight />
      </Button>    */
}
// export default InboxMessagesActions;

import React, { useState, useEffect, Component } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { Validators } from "../../../Utilities/Validator";

export default class ConnectionDetails extends React.Component {
  state = {
    email: "",
    phone: "",
    name: "",
  };

  constructor(props) {  
        super(props);  
        this.state = { isHidden: true }
    }

    toggleHidden () {
      this.setState({ isHidden: !this.state.isHidden })
    }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { email, phone, name } = this.state;
    const { isHidden } = this.state

    return (
      <div className="container p-0">
        <h1 className="page-title">Connections Details</h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={12}>
              <div className="dashboard-box dashboard-col-bg float-left pb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/supportConnections`}
                  className="page-sub-title-with-back"
                >
                  <AiOutlineArrowLeft />Back to connections
                </Link>

                <div className="user-details d-flex align-items-center">
                  <div className="user-avatar">
                    <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" />
                  </div>
                  <div className="user-name">Jessica Adams</div>
                  <div className="actions-buttons ml-auto">
                  <Buttons
                      variant="success"
                      title="Accept"
                      icon="icon far fa-check-circle mr-2"
                      type="submit"
                      size="xs"
                      color="white"
                      marginLeft="ml-3"
                    ></Buttons>
                     <Buttons
                      variant="danger"
                      title="Reject"
                      icon="icon far fa-times-circle mr-2" 
                      type="button"
                      size="xs"
                      color="white"
                      marginLeft="ml-3"
                    ></Buttons>
                    {/* <Buttons
                      variant="light"
                      title="remove contact"
                      icon="icon icon-trash mr-2"
                      type="button"
                      size="xs"
                      color="white"
                      marginLeft="ml-3"
                    ></Buttons>

                   
                    */}
                  </div>
                </div>
                <div className="users-wrap users-bg">
                  <div className="users-box left">
                    <Form>
                    <FormInput
                        value={name}
                        type="text"
                        placeholder="starbucks"
                        required="required"
                        label="name"
                        icon="fas fa-pen"
                        withIcon="with-icon"
                        onChange={this.handleChange("name")}
                      />
                      <FormInput
                        value={email}
                        type="text"
                        placeholder="jess.adams@gmail.com"
                        required="required"
                        label="email"
                        icon="fas fa-pen"
                        withIcon="with-icon"
                        onChange={this.handleChange("email")}
                      />
                      <FormInput
                        value={phone}
                        type="text"
                        placeholder="203.555.7384"
                        required="required"
                        label="phone"
                        icon="fas fa-pen"
                        withIcon="with-icon"
                        onChange={this.handleChange("phone")}
                      />
                     
                    </Form>
                  </div>
                  <div className="users-box right">
                    <div className="note note-bg">
                      <div className="note-heading">
                        <h4>Notes</h4>
                        <i className="fas fa-pen"></i>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                      </p>
                    </div>
                  </div>
                </div>
               

         
                <div className="recent-communication mt-5">
                  <h3>Recent communication</h3>
                  <ul className="job-list">
                    <li>
                      <div className="message-box other">
                        <div className="avatar">
                          <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" />
                        </div>
                        <div className="message-details">
                          <h4>Stephen Jackson</h4>
                          <h4>new job posting</h4>
                          <p className="mb-0">
                            Jennifer, I'm letting you know that a new job that I
                            feel woluld be a great fit has been posted. You
                            can..
                            <a href="">READ MORE</a>
                          </p>
                        </div>
                        <div className="job-post-date ml-auto">05.05.2021</div>
                      </div>
                    </li>
                    <li>
                      <div className="message-box me">
                        <div className="avatar">
                          <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" />
                        </div>
                        <div className="message-details">
                          <h4>me</h4>
                          <h4>new job posting</h4>
                          <p className="mb-0">
                            Jennifer, I'm letting you know that a new job that I
                            feel woluld be a great fit has been posted. You
                            can..
                            <a href="">READ MORE</a>
                          </p>
                        </div>
                        <div className="job-post-date ml-auto">05.05.2021</div>
                      </div>
                    </li>
                    <li>
                      <div className="message-box other">
                        <div className="avatar">
                          <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" />
                        </div>
                        <div className="message-details">
                          <h4>Stephen Jackson</h4>
                          <h4>new job posting</h4>
                          <p className="mb-0">
                            Jennifer, I'm letting you know that a new job that I
                            feel woluld be a great fit has been posted. You
                            can..
                            <a href="">READ MORE</a>
                          </p>
                        </div>
                        <div className="job-post-date ml-auto">05.05.2021</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}


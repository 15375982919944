import React, { useState, useEffect, Fragment } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllJobSeekers } from "../../../Services/LeaderJobSeeker";
import SupportTeamList from "../../../Components/SupportTeamList";
import { getAllSupportTeamMembers } from "../../../Services/LeaderSupportTeamMember";
import JobSeekersList from "../../../Components/JobSeekersList";
import {
  groupLeaderRecentJobsPerPage,
  groupLeaderRecentJSPerPage,
  recentActivityPerPage,
} from "../../../Utilities/Helper";
import { getAllJobs } from "../../../Services/AllJobsService";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import moment from "moment";
import { toast } from "react-toastify";
import getAllActivities from "../../../Services/AllActivities";
import AllActivities from "../../../Components/AllActivities";
import {
  getGroupsWithJSCount,
  getGroupsWithSTMCount,
} from "../../../Services/AdminManageUsersServices";
import GroupListing from "../../../Components/GroupListing";
import { getUserDetailsAPI } from "../../../Utilities/APIHelper";
import { get } from "../../../Services/AxiosService";

export default function AdminDashboard() {
  const [jobSeekers, setJobSeekers] = useState([]);
  const [supportTeamMemebers, setSupportTeamMemebers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [showSpinnerForJS, setShowSpinnerForJS] = useState(true);
  const [showSpinnerForSTM, setShowSpinnerForSTM] = useState(true);
  const [showSpinnerForJobs, setShowSpinnerForJobs] = useState(true);
  const [totalSTMCount, setTotalSTMCount] = useState();
  const [totalJSCount, setTotalJSCount] = useState();
  // const [NewJobs, setNewJobs] = useState([]);
  const [RecentActivities, setActivities] = useState([]);
  const [showSpinnerForActivities, setShowSpineerForActivities] =
    useState(true);
  const [name, setName] = useState("");

  // const [SupportTeam, setSupportTeam] = useState([]);

  //getting recent activity jobs list

  useEffect(() => {
    async function activitiesData() {
      getAllActivities("recordperpage=" + recentActivityPerPage)
        .then((res) => {
          const result = JSON.parse(res.response);
          const data = result.userActivity;
          setActivities(data);
          setShowSpineerForActivities(false);
        })
        .catch((err) => {
          console.log("Activity: ", err);
          setShowSpineerForActivities(false);
        });
    }
    activitiesData();
  }, []);

  useEffect(() => {
    async function jobSeeksersListData() {
      getGroupsWithJSCount("recordperpage=" + 5)
        .then((res) => {
          const result = res;
          setJobSeekers(result.groupModels);
          setShowSpinnerForJS(false);
          setTotalJSCount(result.jobSeekerTotalCount);
        })
        .catch((err) => {
          console.log("JobSeeker: ", err);
          setShowSpinnerForJS(false);
        });
    }
    jobSeeksersListData();
  }, []);

  // getting Sponsership list
  useEffect(() => {
    async function supportTeamMemberListData() {
      const filter = "recordperpage=" + 5;
      getGroupsWithSTMCount(filter)
        .then((res) => {
          const result = res;
          setSupportTeamMemebers(result.groupModels);
          setTotalSTMCount(result.totalSTMCount);
          setShowSpinnerForSTM(false);
        })
        .catch((err) => {
          console.log("Sponsorship: ", err);
          setShowSpinnerForSTM(false);
        });
    }
    supportTeamMemberListData();
  }, []);

  useEffect(() => {
    async function getName() {
      get(getUserDetailsAPI)
        .then((res) => {
          // console.log(res.data.response);
          let response = res.data.response;
          setName(response.firstName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getName();
  }, []);

  return (
    <React.Fragment>
      <div className="container p-0">
        <h1 className="page-title">
          Hello {name}{" "}
          <small>(Admininstrator)</small>
        </h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={6}>
              <div className="dashboard-box activity dashboard-col-bg">
                <h2 className="page-sub-title">Recent Activity</h2>
                <AllActivities
                  activities={RecentActivities}
                  showSpinner={showSpinnerForActivities}
                />
                <Link
                  className="btn btn-warning btn-xs white mt-3"
                  to={`${process.env.PUBLIC_URL}/admin/activitieslist`}
                >
                  <span className="icon icon-list_alt mr-3"></span> View more
                </Link>
              </div>

              <div className="dashboard-box resume dashboard-col-bg mt-5 sponser-dashboard-col hide-from-dashboard support-team-dashboard-list admin-dashboard-jobseeker">
                <h2 className="page-sub-title">
                  Job Seekers{" "}
                  <Badge
                    pill
                    bg="danger"
                    className="bg-warning text-white px-3 ml-3 font-weight-normal"
                  >
                    {totalJSCount}
                  </Badge>
                </h2>
                <GroupListing
                  groups={jobSeekers}
                  showSpinner={showSpinnerForJS}
                />
                <Link
                  className="btn btn-warning btn-xs white mt-3"
                  to={`${process.env.PUBLIC_URL}/admin/jobseekers`}
                >
                  <span className="icon icon-job-seeker mr-2"> </span> View all
                  job seekers
                </Link>
              </div>
            </Col>

            <Col md={6}>
              <div className="dashboard-box resume dashboard-col-bg sponser-dashboard-col hide-from-dashboard support-team-dashboard-list admin-dashboard-jobseeker">
                <h2 className="page-sub-title">
                  Support Team Members
                  <Badge
                    pill
                    bg="danger"
                    className="bg-warning text-white px-3 ml-3 font-weight-normal"
                  >
                    {totalSTMCount}
                  </Badge>
                </h2>
                <GroupListing
                  groups={supportTeamMemebers}
                  showSpinner={showSpinnerForSTM}
                />
                <Link
                  className="btn btn-warning btn-xs white mt-3"
                  to={`${process.env.PUBLIC_URL}/admin/supportteammembers`}
                >
                  <span className="icon icon-support-members mr-2"></span> View
                  all Support Team Members{" "}
                </Link>
              </div>
            </Col>

            {/* <Col md={6}>
              <div className="dashboard-box resume dashboard-col-bg sponser-dashboard-col hide-from-dashboard">
                <h2 className="page-sub-title">Support Team Members</h2>
                <SupportTeamList
                  supportTeamMembers={supportTeamMemebers}
                  showSpinner={showSpinnerForSTM}
                />
                <Link
                  className="btn btn-warning btn-xs white mt-3"
                  to={`${process.env.PUBLIC_URL}/groupleader/supportteammembers`}
                >
                  <span className="icon icon-support-members mr-2"></span> view
                  all support team members{" "}
                </Link>
              </div>
            </Col> */}

            {/* 
          <Col md={7}>
            <div className="dashboard-box activity dashboard-col-bg">
              <h2 className="page-sub-title">Job Seekers</h2>
              <JobSekkersList jobs={JobSeekers} />          
              <Link className="btn btn-warning btn-xs white mt-3"  to={`${process.env.PUBLIC_URL}/activitieslist`}>
                <FaRegListAlt className="mr-2" /> view more
              </Link>
            </div>
            <div className="dashboard-box activity dashboard-col-bg mt-5">
              <h2 className="page-sub-title">Messages</h2>
              <MessagesList messages={Messages} />
              <Link to={`${process.env.PUBLIC_URL}/messages`} className="btn btn-warning btn-xs white mt-3">
                <FaRegListAlt className="mr-2" /> view all messages
              </Link>
            </div>
          </Col> */}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

// export default Dashboard;

import instance from '../Utilities/AxiosHelper';

export const get = (url) => {
    return instance.get(url);
};

export const post = (url, payload) => {
    return instance.post(url, payload);
};

export const put = (url, payload) => {
    return instance.put(url, payload);
};

export const _delete = (url) => {
    return instance.delete(url);
};
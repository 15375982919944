import React, { useState, useEffect, Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FaRegListAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import AllActivities from "../../../Components/AllActivities";
import NewResumesList from "../../../Components/ResumesList";
import MessagesList from "../../../Components/MessagesList";
import {
  getResumesList,
  getPublicResumes,
  getPublicResumeListForEmpDashboard,
} from "../../../Services/ResumeService";
import { getRecentMessagesForEmployer } from "../../../Services/MessagesService";
import getAllActivities from "../../../Services/AllActivities";
import {
  recentActivityPerPage,
  recentMessagesPerPage,
  recentPublicResumePerPage,
} from "../../../Utilities/Helper";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import { get } from "../../../Services/AxiosService";
import { getUserDetailsAPI } from "../../../Utilities/APIHelper";

export default function Dashboard() {
  const [RecentActivities, setActivities] = useState([]);
  const [NewResumes, setNewResumes] = useState([]);
  const [Messages, setMessages] = useState([]);

  // getting jobs list list
  const [showSpinnerForActivities, setShowSpineerForActivities] =
    useState(true);
  const [showSpinnerForMessages, setShowSpineerForMessages] = useState(true);

  const [refresh, setRefresh] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [name, setName] = useState("");

  useEffect(() => {
    async function activitiesData() {
      getAllActivities("recordperpage=" + recentActivityPerPage)
        .then((res) => {
          const result = JSON.parse(res.response);
          const data = result.userActivity;
          setActivities(data);
          setShowSpineerForActivities(false);
        })
        .catch((err) => {
          console.log("Activity: ", err);
          setShowSpineerForActivities(false);
        });
    }
    activitiesData();
  }, [refresh]);

  // getting resume list
  useEffect(() => {
    async function resumesData() {
      getPublicResumeListForEmpDashboard(
        `?recordperpage=${recentPublicResumePerPage}`
      )
        .then((res) => {
          const result = res;
          setNewResumes(result.jinTemplatesModelList);
          setShowSpinner(false);
        })
        .catch((err) => {
          console.log("Resume: ", err);
          setShowSpinner(false);
        });
    }
    resumesData();
  }, [refresh]);

  useEffect(() => {
    async function getName() {
      get(getUserDetailsAPI)
        .then((res) => {
          // console.log(res.data.response);
          let response = res.data.response;
          setName(response.firstName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getName();
  }, [refresh]);

  const refreshTable = () => {
    setShowSpinner(true);
    setRefresh((prev) => !prev);
  };

  // getting message list
  useEffect(() => {
    async function messagesData() {
      getRecentMessagesForEmployer("?recordperpage=" + recentMessagesPerPage)
        .then((res) => {
          var result = JSON.parse(res.response);
          var resultArray = [];
          result.forEach((element) => {
            var obj = {};
            obj.name = element.fullName;
            obj.text = element.jobSeekerSponshirShipMsg;
            obj.date = element.createdDate;
            obj.profilePhoto = element.profilePhoto;
            resultArray.push(obj);
          });
          setMessages(resultArray);
          setShowSpineerForMessages(false);
        })
        .catch((err) => {
          console.log("Message: ", err);
          setShowSpineerForMessages(false);
        });
    }
    messagesData();
  }, []);

  return (
    <div className="container p-0">
      <h1 className="page-title">
        Hello {name} <small>(Employer)</small>
      </h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={7}>
            <div className="dashboard-box activity dashboard-col-bg">
              <h2 className="page-sub-title">Recent Activity</h2>
              <AllActivities
                activities={RecentActivities}
                showSpinner={showSpinnerForActivities}
              />
              <Link
                className="btn btn-warning btn-xs white mt-3"
                to={`${process.env.PUBLIC_URL}/employer/activitieslist`}
              >
                <span className="icon icon-list_alt mr-3"></span> View more
              </Link>
            </div>

            <div className="dashboard-box activity dashboard-col-bg mt-5">
              <h2 className="page-sub-title">Messages</h2>
              <MessagesList
                messages={Messages}
                showSpinner={showSpinnerForMessages}
              />
              <Link
                to={`${process.env.PUBLIC_URL}/employer/messages`}
                className="btn btn-warning btn-xs white mt-3"
              >
                <span className="icon icon-chat_bubble_outline mr-3"></span>{" "}
                View all messages
              </Link>
            </div>
          </Col>
          <Col md={5}>
            <div className="dashboard-box resume dashboard-col-bg">
              <h2 className="page-sub-title">New Public Resumes</h2>
              {showSpinner && <SpinnerLoader />}
              {NewResumes.length === 0 && !showSpinner && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              <NewResumesList
                resumes={NewResumes}
                saveResumeChange={refreshTable}
                showSpinner={showSpinner}
              />
              <Link
                to={`${process.env.PUBLIC_URL}/resumes`}
                className="btn btn-warning btn-xs white mt-3"
              >
                <span className="icon icon-file_present mr-3"></span> View all
                Public resumes
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

// export default Dashboard;

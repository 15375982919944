import React, { useState, useEffect, Component, Fragment } from "react";
import { Col, Row, Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Buttons from "../../../Components/Shared/Button";
import {
  getJINTemplateDetails,
  saveResume,
  unsaveResume,
} from "../../../Services/ResumeService";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserDetails } from "../../../Services/GetFields";
import FormInput from "../../../Components/Shared/FormInput";
import { Roles } from "../../../Utilities/Helper";
import MessageLimit from "../../../Components/Shared/MessageLimit";
import { sendUserMessages } from "../../../Services/MessagesService";
import { getData } from "../../../Utilities/StorageHelper";

export default class ResumeDetails extends React.Component {
  state = {
    resumeDetails: {},
    coreCompetencies: [],
    professionalExperiences: [],
    education: [],
    additionalSkills: [],
    awardsAndHonors: [],
    role: "",
    id: "",
    jinId: "",
    modalToggle: false,
    message: "",
    email: "",
    jobSeekerId: "",
    showSave: true,
    messageError: false,
    totalLength: 500,
    currentLength: 0,
    jinTemplateId: "",
    resumeName: "",
    jobSeekerUsername: "",
  };

  componentDidMount() {
    let user = getData("role");
    this.setState({ role: user });
    // console.log(user);

    getUserDetails()
      .then((res) => {
        //console.log(res.response);
        let response = res.response;
        this.setState({ id: response.id });
      })
      .catch((err) => {
        console.log(err);
      });

    var jinTemplateId = this.props.match.params.jinTemplateId;
    // console.log(jinTemplateId);
    // this.setState({ jinId: jobSeekerJINTemplateId });

    getJINTemplateDetails(jinTemplateId)
      .then((res) => {
        const result = JSON.parse(res.response);
        // console.log(result);
        this.setState({
          resumeDetails: result,
          jinId: result.jobSeekerJINTemplateId,
          email: result.email,
          jobSeekerId: result.jobSeekerId,
          showSave: result.isSaved,
          jinTemplateId: result.jinTemplateId,
          resumeName: result.jobTitle,
          jobSeekerUsername: result.userName,
        });
        this.setState({ coreCompetencies: result.coreCompetencies });
        this.setState({
          professionalExperiences: result.professionalExperiences,
        });
        this.setState({ education: result.education });
        this.setState({
          professionalExperiences: result.professionalExperiences,
        });
        this.setState({ additionalSkills: result.additionalSkills });
        this.setState({ awardsAndHonors: result.awardsAndHonors });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  saveResume = () => {
    // console.log(this.state.jinId, this.state.resumeDetails.isSaved);
    const toastId = "custom_toast";

    if (this.state.jinId === null) {
      return;
    }
    if (this.state.resumeDetails.isSaved === true) {
      unsaveResume(this.state.jinId)
        .then((res) => {
          const result = res;
          toast.success("Resume removed from saved list", { toastId: toastId });
          setTimeout(this.setRefresh, 4000);
        })
        .catch((err) => {
          console.log("UnSave Resume:", err);
        });
    } else {
      saveResume(
        this.state.jinId,
        this.state.resumeName,
        this.state.jinTemplateId
      )
        .then((res) => {
          toast.success("Resume saved successfully", { toastId: toastId });
          setTimeout(this.setRefresh, 4000);
        })
        .catch((err) => {
          console.log("Save Resume:", err);
        });
    }
  };

  setRefresh = () => {
    window.location.reload();
  };

  openModal = () => {
    this.setState({
      modalToggle: !this.state.modalToggle,
      message: "",
      messageError: false,
      currentLength: 0,
    });
  };

  sendMessage = (e) => {
    if (e === "") {
      this.setState({
        message: e,
        messageError: true,
        currentLength: e.length,
      });
    } else {
      if (e.length > 500) {
        return;
      }
      this.setState({ message: e, currentLength: e.length });
    }
  };

  sendRequestToContact = () => {
    if (this.state.message === "") {
      this.setState({ messageError: true });
    } else {
      this.setState({ messageError: false });
    }
    if (this.state.message !== "") {
      this.setState({ modalToggle: false });
      var data = {
        SenderId: this.state.jobSeekerId,
        Message: this.state.message,
        userType: Roles.jobSeeker,
        IsRequestToContact: true,
        FullName: this.state.jobSeekerUsername,
      };
      // console.log(data);
      sendUserMessages(data)
        .then((res) => {
          toast.success(res.response);

          this.setState({ modalToggle: false });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    var {
      resumeDetails,
      coreCompetencies = [],
      professionalExperiences = [],
      education = [],
      additionalSkills = [],
      awardsAndHonors = [],
      role,
      modalToggle,
      message,
      email,
      messageError,
      currentLength,
      totalLength,
    } = this.state;
    return (
      <div className="container p-0">
        <h1 className="page-title">Resume Details</h1>
        <div className="dashboard-wrapper">
          <Row>
            <Col md={12}>
              <div className="dashboard-box dashboard-col-bg  pb-5">
                {role !== Roles.employer && role !== Roles.jobSeeker && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/supportConnections`}
                    className="page-sub-title-with-back"
                  >
                    <AiOutlineArrowLeft /> Back
                  </Link>
                )}
                {role === Roles.employer && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/resumes`}
                    className="page-sub-title-with-back"
                  >
                    <AiOutlineArrowLeft /> Back to resumes
                  </Link>
                )}
                {role === Roles.jobSeeker && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/jobseeker/myresumes`}
                    className="page-sub-title-with-back"
                  >
                    <AiOutlineArrowLeft /> Back to resumes
                  </Link>
                )}
                <div className="users-wrap users-bg job-details-wrap d-block pt-4">
                  {role === "Employer" && (
                    <div className="text-right w-100 view-resume-details-btns">
                      {/* <Link
                      to={`${process.env.PUBLIC_URL}/messages`}
                      className="text-decoration-none"
                    > */}
                      <Buttons
                        variant="primary"
                        title="Contact JobSeeker"
                        // type="submit"
                        size="xs"
                        color="white"
                        marginLeft="ml-md-3"
                        icon="icon fa fa-paper-plane mr-2"
                        className="pd-btn"
                        onClick={this.openModal}
                      ></Buttons>
                      {/* </Link> */}

                      {role === Roles.employer && !this.state.showSave && (
                        <Buttons
                          variant="warning"
                          title="Save Resume"
                          // type="submit"
                          size="xs"
                          color="white"
                          marginLeft="ml-md-3 ml-md-2"
                          icon="icon-favorite-outline mr-2"
                          onClick={() => {
                            //this.setState({ showSave: true });
                            this.saveResume();
                          }}
                        ></Buttons>
                      )}
                      {role === Roles.employer && this.state.showSave && (
                        <Buttons
                          variant="warning"
                          title="Unsave Resume"
                          // type="submit"
                          size="xs"
                          color="white"
                          marginLeft="ml-md-3"
                          icon="icon-favorite-outline mr-2"
                          onClick={() => {
                            //this.setState({ showSave: true });
                            this.saveResume();
                          }}
                        ></Buttons>
                      )}
                      {/* <Buttons
                        variant="secondary"
                        title="Remove"
                        type="button"
                        size="xs"
                        color="white"
                        marginLeft="ml-3"
                        icon="icon icon-trash mr-2"
                      ></Buttons> */}
                    </div>
                  )}
                  {role === Roles.jobSeeker && (
                    <div className="text-right w-100 view-resume-details-btns">
                      {resumeDetails.isPrivate === true && (
                        <Link
                          to={`${process.env.PUBLIC_URL}/jobseeker/jintemplateprivate/${resumeDetails.jinTemplateId}`}
                          className="text-decoration-none"
                        >
                          <Buttons
                            variant="primary"
                            title="Edit"
                            type="submit"
                            size="xs"
                            color="white"
                            marginLeft="ml-3"
                            icon="icon icon-post mr-2"
                          ></Buttons>
                        </Link>
                      )}
                      {resumeDetails.isPrivate !== true && (
                        <Link
                          to={`${process.env.PUBLIC_URL}/jobseeker/jintemplate/${resumeDetails.jinTemplateId}`}
                          className="text-decoration-none"
                        >
                          <Buttons
                            variant="primary"
                            title="Edit"
                            type="submit"
                            size="xs"
                            color="white"
                            marginLeft="ml-3"
                            icon="icon icon-post mr-2"
                          ></Buttons>
                        </Link>
                      )}
                    </div>
                  )}
                  <div className="detail-view mt-3 resume-content">
                    {!resumeDetails.isPrivate && (
                      <h2>
                        {" "}
                        {resumeDetails.userName
                          ? resumeDetails.userName.split(" ")[0] +
                            " " +
                            resumeDetails.userName.split(" ")[1][0] +
                            "."
                          : ""}
                      </h2>
                    )}
                    {resumeDetails.isPrivate && (
                      <h2>
                        {" "}
                        {resumeDetails.userName
                          ? resumeDetails.userName.split(" ")[0] +
                            " " +
                            resumeDetails.userName.split(" ")[1]
                          : ""}
                      </h2>
                    )}
                    {resumeDetails.isPrivate && (
                      <p>
                        {" "}
                        {`${
                          resumeDetails.addressLine1 !== null &&
                          resumeDetails.addressLine1 !== ""
                            ? resumeDetails.addressLine1 + ", "
                            : ""
                        }${
                          resumeDetails.addressLine2 !== null &&
                          resumeDetails.addressLine2 !== ""
                            ? resumeDetails.addressLine2 + ", "
                            : ""
                        }${resumeDetails.city}, ${resumeDetails.stateName}${
                          resumeDetails.zipCode !== null &&
                          resumeDetails.zipCode !== ""
                            ? ", " + resumeDetails.zipCode
                            : ""
                        }, ${resumeDetails.countryName}`}
                        <span className="dot d-none d-md-inline-block">.</span>
                        <div className="d-md-none"></div>
                        <span className="r-title">Email: </span>
                        <span className="r-text">
                          <a href={`mailto:${resumeDetails.email}`}>
                            {resumeDetails.email}
                          </a>
                        </span>
                        <span className="dot d-none d-md-inline-block">.</span>
                        <div className="d-md-none"></div>
                        <span className="r-title">Telephone: </span>
                        <span className="r-text">
                          {resumeDetails.phoneNumber}
                        </span>
                      </p>
                    )}
                    <hr />
                    <h3>Professional Title</h3>
                    <p className="mb-4">{resumeDetails.jobTitle}</p>
                    {resumeDetails.isPrivate && (
                      <Fragment>
                        {" "}
                        <hr />
                        <h3>Cover Letter</h3>
                        <div className="mb-4 message-discription">
                          <textarea
                            style={{ overflowY: "scroll", display: "block" }}
                            data-gramm="false"
                            data-gramm_editor="false"
                            data-enable-grammarly="false"
                            spellcheck="false"
                            rows={15}
                            value={resumeDetails.coverLetter}
                            className="pre-textarea-resume"
                          ></textarea>
                        </div>
                      </Fragment>
                    )}
                    <hr />
                    <h3>Career Objective</h3>
                    <p className="mb-4">{resumeDetails.careerObjective}</p>
                    <hr />
                    <h3>Core Competencies</h3>
                    <ul className="points-2">
                      {coreCompetencies.map((item, index) => (
                        <li key={index}>{item.coreCompentencyName}</li>
                      ))}
                      {coreCompetencies === null && <li>-</li>}
                    </ul>
                    <hr />
                    <div>
                      <h3>Professional Experience</h3>
                      {professionalExperiences.map((item, index) => (
                        <Fragment key={index}>
                          <div className="company-text">
                            {`${item.organization}, ${item.city}, ${item.stateName}, ${item.countryName}`}
                          </div>
                          <div className="exp-text">
                            {`${item.designation}, ${moment(
                              item.startDate
                            ).format("MMM DD, Y")} - ${
                              item.endDate !== null
                                ? moment(item.endDate).format("MMM DD, Y")
                                : "Present"
                            }`}
                          </div>
                          {item.jobProfile !== null &&
                            item.jobProfile.trim() !== "" && (
                              <ul className="d-block  points">
                                <li>{item.jobProfile}</li>
                              </ul>
                            )}
                        </Fragment>
                      ))}
                    </div>
                    <div>
                      {/* <div>
                        <h3>Notice Period</h3>
                        {resumeDetails.noticePeriod !== null &&
                          resumeDetails.noticePeriod !== "" && (
                            <ul className="d-block points">
                              <li>{resumeDetails.noticePeriod}</li>
                            </ul>
                          )}
                      </div> */}
                      <hr />
                      <h3>Education</h3>
                      {education.map((item, index) => (
                        <Fragment key={index}>
                          <div className="company-text">
                            {`${item.university}, ${item.city}, ${item.stateName}, ${item.countryName}`}
                          </div>
                          <div className="exp-text">
                            {`Degree: ${item.courseName}, Major: ${
                              item.specialization
                            }, ${moment(item.startDate).format(
                              "MMM DD, Y"
                            )} - ${
                              item.endDate !== null
                                ? moment(item.endDate).format("MMM DD, Y")
                                : "Present"
                            }`}
                          </div>
                          {/* <div className="mark-and-grade">
                            Marks/Grade: {`${item.marks} `}
                          </div> */}
                        </Fragment>
                      ))}
                    </div>
                    <hr />
                    <div>
                      <h3>Additional Skills</h3>
                      <ul className="d-block points">
                        {additionalSkills.map((item, index) => (
                          <li key={index}>{item.additionalSkillName}</li>
                        ))}
                      </ul>
                    </div>
                    <hr />
                    <div>
                      <h3>Awards & Honors</h3>
                      <ul className="d-block points">
                        {awardsAndHonors.map((item, index) => (
                          <li key={index}>{item.awardAndHonor}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {role === "Employer" && (
          <Modal
            show={modalToggle}
            onHide={this.openModal}
            className="respond-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <FormInput
                value={email}
                type="text"
                placeholder="Job Seeker Email"
                onChange={() => {}}
              /> */}
              <FormInput
                value={message}
                maxLength={"500"}
                type="textarea"
                placeholder="Your message goes here"
                onChange={(e) => {
                  this.sendMessage(e);
                }}
              />
              <MessageLimit currentLength={message} totalLength={totalLength} />
              {messageError && (
                <p style={{ color: "red" }}>Message is required</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                show={modalToggle}
                onHide={this.openModal}
                onClick={this.sendRequestToContact}
              >
                Send
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}

import React, { useState, useEffect, Component, Fragment } from "react";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { getJob } from "../../../Services/AllJobsService";
import { toast } from "react-toastify";

const SupportJobDetails = (props) => {
  // state = {
  //   isOpen: false,
  // };
  // openModal = () => this.setState({ isOpen: true });
  // closeModal = () => this.setState({ isOpen: false });

  const jobId = props.match.params.jobId;
  const [job, setJob] = useState(null);

  useEffect(() => {
    function getJobDetails() {
      getJob(jobId)
        .then((res) => {
          const result = JSON.parse(res.response);
          setJob(result);
        })
        .catch((err) => {
          console.log("Job: ", err);
        });
    }
    getJobDetails();
  }, []);

  return (
    <div className="container p-0">
      <h1 className="page-title">Job Details</h1>
      <div className="dashboard-wrapper">
        <Row>
          <Col md={12}>
            <div className="dashboard-box dashboard-col-bg pb-5">
              <Link
                to={`${process.env.PUBLIC_URL}/support/jobs`}
                className="page-sub-title-with-back"
              >
                <AiOutlineArrowLeft />
                Back to jobs
              </Link>
              {job !== null && (
                <Fragment>
                  {" "}
                  <div className="users-wrap users-bg job-details-wrap btns-width d-block">
                    <div className="users-box left w-100 d-flex">
                      <div>
                        <h3 className="job-title">{job.positionTitle}</h3>
                        {/* jobPostingLink  Show job posting link   float-left*/}
                        <div className="company-name">
                          {job.companyName === null || job.companyName === ""
                            ? "-"
                            : job.companyName}
                        </div>
                      </div>
                    </div>
                    <div className="detail-view mt-3">
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Start Date:</span>{" "}
                            {new Date(job.jobStartDate).toDateString()}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>End Date:</span>{" "}
                            {new Date(job.jobEndDate).toDateString()}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Contact Name:</span>
                            {job.contactName === null || job.contactName === ""
                              ? "-"
                              : job.contactName}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Contact Email:</span>
                            {job.contactEmail === null ||
                            job.contactEmail === ""
                              ? "-"
                              : job.contactEmail}
                          </div>
                        </Col>

                        <Col>
                          <div className="post-date">
                            <span>Telephone:</span>
                            {job.contactPhone === null ||
                            job.contactPhone === ""
                              ? "-"
                              : job.contactPhone}
                          </div>
                        </Col>
                        <Col>
                          <div className="location">
                            <span>Address:</span>{" "}
                            {`${job.addressLine1}, ${
                              job.addressLine2 !== null
                                ? job.addressLine2 + ", "
                                : ""
                            }                                                 
                          ${job.city !== null ? job.city + ", " : "-"} ${
                              job.stateName !== null
                                ? job.stateName + ", "
                                : "-"
                            }
                         ${job.countryName !== null ? job.countryName : "-"}`}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Industry:</span>
                            {job.industryName === null ||
                            job.industryName === ""
                              ? "-"
                              : job.industryName}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Compensation:</span>
                            {job.payRate === null || job.payRate === ""
                              ? "-"
                              : job.payRate}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Job Length:</span>
                            {job.jobLength === null || job.jobLength === ""
                              ? "-"
                              : job.jobLength}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Travel Required:</span>{" "}
                            {job.travelRequired === true && "Yes"}
                            {job.travelRequired === false && "No"}
                            {job.travelRequired === null && "-"}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Telecommute:</span>
                            {job.telecommute === true && "Yes"}
                            {job.telecommute === false && "No"}
                            {job.telecommute === null && "-"}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Position Responsibilites:</span>{" "}
                            {job.positionResponsibilities === null ||
                            job.positionResponsibilities === ""
                              ? "-"
                              : (
                                <span style={{ wordBreak: "break-word", color: "#2660a5"}}>{job.positionResponsibilities}</span>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="post-date">
                            <span>Minimum Qualification:</span>{" "}
                            {job.minimumQualification === null ||
                            job.minimumQualification === ""
                              ? "-"
                              : (
                                <span style={{ wordBreak: "break-word", color: "#2660a5"}}>{job.minimumQualification}</span>
                              )}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Preferred Qualification:</span>{" "}
                            {job.preferredQualification === null ||
                            job.preferredQualification === ""
                              ? "-"
                              : (
                                <span style={{ wordBreak: "break-word", color: "#2660a5"}}>{job.preferredQualification}</span>
                              )}
                          </div>
                        </Col>
                        <Col>
                          <div className="post-date">
                            <span>Employment policy:</span>
                            {job.employmentPolicy === null ||
                            job.employmentPolicy === ""
                              ? "-"
                              : job.employmentPolicy}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {job.jobPostingLink !== null && (
                    <div className="job-description">
                      <h3>Job Link</h3>
                      <p>
                        {job.jobPostingLink === "" ? "-" : job.jobPostingLink}
                      </p>
                    </div>
                  )}
                  <div className="job-description">
                    <h3>Description</h3>
                    <p>
                      {job.jobDescription === null || job.jobDescription === ""
                        ? "-"
                        : job.jobDescription}
                    </p>
                  </div>
                </Fragment>
              )}{" "}
            </div>
          </Col>
        </Row>
      </div>
      {/* <Modal
          show={this.state.isOpen}
          onHide={this.closeModal}
          className="respond-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormInput
              value=""
              type="textarea"
              placeholder="Your message goes here"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              show={this.state.isOpen}
              onHide={this.closeModal}
            >
              send
            </Button>
          </Modal.Footer>
        </Modal> */}
    </div>
  );
};

export default SupportJobDetails;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import FormInput from "../../../Components/Shared/FormInput";
import { Spinner } from "react-bootstrap";
import {
  putSetupDemoPresentationText,
  putSetupDemoPresentationVideo,
  getSupportTeamMemberDetails,
} from "../../../Services/DemoServices";
import {
  getDemoDatabaseBackups,
  putRestoreSavedDemoDatabase,
} from "../../../Services/DemoServices";
import { putBackupDemoDatabase } from "../../../Services/DemoServices";
import { getJobSeekerResumes } from "../../../Services/JobSeekersService";
import { toast } from "react-toastify";
import { ContentBox } from "./ContentBox";
import { getGroups, getIndustry } from "../../../Services/GetFields";
import { getReportAbuseList } from "../../../Services/AdminManageUsersServices";

const BASE_TOOL_URL = process.env.PUBLIC_URL + "/admin/demotools/presentation/";
const DATABASE_SAVE_NAME = "Auto Save (Slides)";

export const AdminDemoSlideshow = () => {
  const history = useHistory();
  const { step } = useParams();

  const [showLoader, setShowLoader] = useState(false);
  const [demoDetails, setDemoDetails] = useState({
    org_name: "",
    org_member_noun: "Client", // client, member, student, employee
    industry: "Finance",
    email_1: "J11",
    user_name_1: "Benjamin Thompson",
    email_2: "S55",
    position_to_network_name: "Project Coordinator",
    position_to_network_index: "5",
    resume_title: "Assistant Project Manager",
    notify_when_ready_email: "itsahit@comcast.net",
  });

  const [savedDemos, setSavedDemos] = useState([]);
  const [presentation_id, setPresentationId] = useState("");
  const [groupsList, setGroupsList] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [jobSeekers, setJobSeekers] = useState([]);
  const [supportTeamMembers, setSupportTeamMembers] = useState([]);

  const [jobSeekerResumes, setJobSeekerResumes] = useState([]);

  useEffect(() => {
    getDemoDatabaseBackups()
      .then((res) => {
        var result = JSON.parse(res.response);
        result.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        setSavedDemos(result);
      })
      .catch((err) => {
        console.log(err);
      });

    getGroups()
      .then((res) => {
        const result = JSON.parse(res.response);
        setGroupsList(result);
        if (result.length > 0) {
          setDemoDetails((old) => ({
            ...old,
            org_name: result[0].groupName,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getIndustry()
      .then((res) => {
        const result = JSON.parse(res.response);
        setIndustries(result);
      })
      .catch((err) => {
        console.log(err);
      });

    getReportAbuseList("recordperpage=99&userTYpeid=2")
      .then((res) => {
        const result = res;
        setJobSeekers(result.jinUsers);
        if (result.jinUsers.length > 0) {
          setDemoDetails((old) => ({
            ...old,
            email_1: result.jinUsers[0].email.replace("@jindemo.com", ""),
            user_name_1: result.jinUsers[0].fullName,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getReportAbuseList("recordperpage=101&userTYpeid=3")
      .then((res) => {
        const result = res;
        setSupportTeamMembers(result.jinUsers);
        if (result.jinUsers.length > 0) {
          setDemoDetails((old) => ({
            ...old,
            email_2: result.jinUsers[0].email.replace("@jindemo.com", ""),
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getJobSeekerResumes(42)
      .then((res) => {
        const results = JSON.parse(res.response);
        const jobTitles = results
          .map((item) => item.templateName)
          .filter((item) => item !== null);
        setJobSeekerResumes(jobTitles);
        setDemoDetails((old) => ({
          ...old,
          resume_title: jobTitles[0],
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      getSupportTeamMemberDetails("s1@jindemo.com")
        .then((res) => {
          const result = JSON.parse(res.response);
          const industryId = result.supportTeamMembersIndustries[0]?.industryId;
          setDemoDetails((old) => ({
            ...old,
            industry: industries.find((item) => item.industryId === industryId)
              ?.industryName,
            position_to_network_name: result.position,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
  }, []);

  const updateJobSeekerResumes = (jobSeekerId) => {
    getJobSeekerResumes(jobSeekerId)
      .then((res) => {
        const results = JSON.parse(res.response);
        const jobTitles = results
          .map((item) => item.templateName)
          .filter((item) => item !== null);
        setJobSeekerResumes(jobTitles);
        setDemoDetails((old) => ({
          ...old,
          resume_title: jobTitles[0],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSTMDetails = (userName) => {
    getSupportTeamMemberDetails(userName)
      .then((res) => {
        const result = JSON.parse(res.response);
        const industryId = result.supportTeamMembersIndustries[0]?.industryId;
        setDemoDetails((old) => ({
          ...old,
          industry: industries.find((item) => item.industryId === industryId)
            ?.industryName,
          position_to_network_name: result.position,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBackupDatabase = () => {
    setShowLoader(true);

    putBackupDemoDatabase({ name: DATABASE_SAVE_NAME })
      .then((res) => {
        toast.success("Demo site saved successfully.");
        getDemoDatabaseBackups()
          .then((res) => {
            var result = JSON.parse(res.response);
            result.sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            console.log(result);
            setSavedDemos(result);
          })
          .catch((err) => {
            console.log(err);
          });
        setShowLoader(false);
      })
      .catch((err) => {
        toast.error("Unable to save demo site.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const restoreDemo = () => {
    setShowLoader(true);
    const demoResetId = savedDemos.find(
      (demo) => demo.name === DATABASE_SAVE_NAME
    )?.demoResetId;
    if (!demoResetId) {
      toast.error("Unable to find the demo site to restore.");
      setTimeout(() => setShowLoader(false), 3000);
      return;
    }

    putRestoreSavedDemoDatabase({ id: demoResetId })
      .then((_) => {
        toast.success("Demo site restored successfully.");
        setTimeout(() => setShowLoader(false), 3000);
      })
      .catch((err) => {
        toast.error("Unable to restore demo site.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const handleUserClickedCreateSlides = () => {
    setShowLoader(true);

    putSetupDemoPresentationText({ ...demoDetails })
      .then((res) => {
        toast.success("Demo Slides Successfully Saved.");
        setPresentationId(res.presentation_id);
        setTimeout(() => {
          setShowLoader(false);
        }, 2000);
      })
      .catch((err) => {
        toast.error("Unable to create demo slideshow.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  // Note: this function will go away and be an all in one "Create Slides and Videos"
  // once the backend is updated to support it.
  const handleUserClickedCreateVideos = () => {
    setShowLoader(true);
    putSetupDemoPresentationVideo({
      ...demoDetails,
      presentation_id: presentation_id,
    })
      .then((res) => {
        toast.success("Demo Videos Successfully Saved.");
        console.log(res);
        setTimeout(() => {
          setShowLoader(false);
        }, 2000);
      })
      .catch((err) => {
        toast.error("Unable to create demo videos.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const handleUserClickedCreateSlidesAndVideos = () => {
    setShowLoader(true);

    putSetupDemoPresentationText({ ...demoDetails })
      .then((res) => {
        toast.success("Demo Slides Successfully Started.");
        setPresentationId(res.presentation_id);
        putSetupDemoPresentationVideo({
          ...demoDetails,
          presentation_id: res.presentation_id,
        })
          .then((res) => {
            toast.success("Demo Videos running...");
            console.log(res);
            setTimeout(() => {
              setShowLoader(false);
            }, 2000);
          })
          .catch((err) => {
            toast.error(
              "Unable to create demo videos. Please contact support."
            );
            setTimeout(() => setShowLoader(false), 3000);
          });
      })
      .catch((err) => {
        toast.error("Unable to create demo slideshow. Please contact support.");
        setTimeout(() => setShowLoader(false), 3000);
      });
  };

  const renderOrganizationDetails = () => {
    return (
      <ContentBox
        headerText="Select The Organization Details for the slide show"
        nextText="Next"
        disableNext={false}
        onNext={() => history.push(BASE_TOOL_URL + "1")}
      >
        <p className="mb-0 font-weight-bold">Select the organization:</p>
        <Row>
          <Col md={12}>
            {/* Dropdown group list */}
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="restore-saved-demo"
                value={demoDetails.org_name}
                disabled={true === showLoader}
                onChange={(e) =>
                  setDemoDetails({
                    ...demoDetails,
                    org_name: e.target.value,
                  })
                }
                className="inputclass form-control"
              >
                {/* <option value="0">Select A ...</option> */}
                {groupsList.map((item, index) => (
                  <option key={"sd-" + index} value={item.groupName}>
                    {item.groupName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <p className="m-0">
              <span className="font-weight-bold">Note: </span>
              Only organizations setup for this demo are available to select. To
              create a slideshow for a different organization, you'll need to
              first setup the demo database for them.
            </p>
          </Col>
        </Row>

        <p className="mb-0 mt-4 font-weight-bold">
          Noun for organization member:
        </p>
        <Row>
          <Col md={12}>
            {/* Dropdown group list */}
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="org-member-noun-saved-demo"
                value={demoDetails.org_member_noun}
                disabled={true === showLoader}
                onChange={(e) =>
                  setDemoDetails({
                    ...demoDetails,
                    org_member_noun: e.target.value,
                  })
                }
                className="inputclass form-control"
              >
                {/* <option value="0">Select A ...</option> */}
                {["Client", "Member"].map((item, index) => (
                  <option key={"omn-" + index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <p className="m-0">
              <span className="font-weight-bold">Examples: </span> <br />
              Employed Church of Jesus Christ of Latter-day Saints <b>
                member
              </b>{" "}
              reviews Job Seeker's resume. <br />
              vs <br />
              Employed Upwardly Global <b>client</b> reviews Job Seeker's
              resume. <br />
            </p>
          </Col>
        </Row>
      </ContentBox>
    );
  };

  const renderJobSeekerDetails = () => {
    return (
      <ContentBox
        headerText="Enter Job Seeker Details"
        nextText="Next"
        disableNext={false}
        onNext={() => history.push(BASE_TOOL_URL + "2")}
        rootPage={"presentation/"}
      >
        <p className="mb-0 font-weight-bold">Enter the Job Seeker:</p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="js-select-demo"
                value={demoDetails.email_1}
                disabled={true === showLoader}
                onChange={(e) => {
                  const js = jobSeekers.find(
                    (user) => user.email === e.target.value + "@jindemo.com"
                  );

                  if (!js) {
                    toast.error("Unable to find the job seeker.");
                    return;
                  }

                  setDemoDetails({
                    ...demoDetails,
                    email_1: e.target.value,
                    user_name_1: js.fullName,
                  });

                  updateJobSeekerResumes(js.userId);
                }}
                className="inputclass form-control"
              >
                {jobSeekers.map((item, index) => (
                  <option
                    key={"js-" + index}
                    value={item.email.replace("@jindemo.com", "")}
                  >
                    {item.email}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <p className="mt-4 mb-0 font-weight-bold">
          Enter the name of the Job Seeker:
        </p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <FormInput
                name="user_name_1"
                value={demoDetails.user_name_1}
                type="text"
                placeholder="Name"
                maxLength={50}
                required="required"
                onChange={(e) => {
                  if (showLoader) return;

                  setDemoDetails({
                    ...demoDetails,
                    user_name_1: e,
                  });
                }}
              />
            </Form.Group>
            <p className="m-0">
              <span className="font-weight-bold">Note: </span>
              This name was pulled from the database based on the Job Seeker's
              email selected above. However, you can change it if needed.
            </p>
          </Col>
        </Row>

        <p className="mt-4 mb-0 font-weight-bold">
          Enter the name of the Job Seeker's resume to send:
        </p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="js-select-resume-name"
                value={demoDetails.resume_title}
                disabled={true === showLoader}
                onChange={(e) => {
                  setDemoDetails({
                    ...demoDetails,
                    resume_title: e.target.value,
                  });
                }}
                className="inputclass form-control"
              >
                {jobSeekerResumes.map((item, index) => (
                  <option key={"jsr-" + index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <p className="m-0">
              <span className="font-weight-bold">Note: </span>
              This name was pulled from the database based on the Job Seeker's
              email selected above. If there is no value to select, it means the
              Job Seeker does not have a JIN formatted resume.
            </p>
          </Col>
        </Row>
      </ContentBox>
    );
  };

  const renderSupportTeamMemberDetails = () => {
    return (
      <ContentBox
        headerText="Enter Support Team Member to Network with"
        nextText="Next"
        disableNext={false}
        onNext={() => history.push(BASE_TOOL_URL + "3")}
        rootPage={"presentation/"}
      >
        <p className="mb-0 font-weight-bold">
          Enter the email of the Support Team Member:
        </p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="stm-select-demo"
                value={demoDetails.email_2}
                disabled={true === showLoader}
                onChange={(e) => {
                  setDemoDetails({
                    ...demoDetails,
                    email_2: e.target.value,
                  });

                  updateSTMDetails(e.target.value + "@jindemo.com");
                }}
                className="inputclass form-control"
              >
                {supportTeamMembers.map((item, index) => (
                  <option
                    key={"stm-" + index}
                    value={item.email.replace("@jindemo.com", "")}
                  >
                    {item.email}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <p className="mt-4 mb-0 font-weight-bold">Enter the industry:</p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <Form.Control
                as="select"
                name="industries-select-demo"
                value={demoDetails.industry}
                disabled={true === showLoader}
                onChange={(e) =>
                  setDemoDetails({
                    ...demoDetails,
                    industry: e.target.value,
                  })
                }
                className="inputclass form-control"
              >
                {industries.map((item, index) => (
                  <option key={"sd-" + index} value={item.industryName}>
                    {item.industryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <p className="m-0 mb-2">
              <span className="font-weight-bold">Note: </span>
              This is the industry that the job seeker will search for when
              networking. The selected value is updated when you pick a STM from
              above
            </p>
          </Col>
        </Row>
        <p className="mt-4 mb-0 font-weight-bold">
          Enter the Support Team Member's job title:
        </p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <FormInput
                name="position_to_network_name"
                value={demoDetails.position_to_network_name}
                type="text"
                placeholder="Position Name"
                maxLength={50}
                required="required"
                onChange={(e) => {
                  if (showLoader) return;

                  setDemoDetails({
                    ...demoDetails,
                    position_to_network_name: e,
                  });
                }}
              />
            </Form.Group>
            <p className="m-0 mb-2">
              <span className="font-weight-bold">Note: </span>
              This is the job title of the Support Team Member that was selected
              above and is pulled from the database.
            </p>
          </Col>
        </Row>

        <p className="mt-4 mb-0 font-weight-bold">
          Enter the position to network index:
        </p>
        <Row>
          <Col md={12}>
            <Form.Group controlId="dd">
              <FormInput
                name="position_to_network_index"
                value={demoDetails.position_to_network_index}
                type="text"
                placeholder="Position Index"
                maxLength={50}
                required="required"
                onChange={(e) => {
                  if (showLoader) return;

                  setDemoDetails({
                    ...demoDetails,
                    position_to_network_index: e,
                  });
                }}
              />
            </Form.Group>
            <p className="m-0">
              <span className="font-weight-bold">Note: </span>
              We are unable to pull this value from the database. <br />
              This represents the index of the position to network search
              results and needs to be set by hand for now.
            </p>
          </Col>
        </Row>
      </ContentBox>
    );
  };

  const renderConfirmDetails = () => {
    return (
      <ContentBox
        headerText="Ready to Create Slides and Videos?"
        hideNext={true}
        disableNext={true}
        rootPage={"presentation/"}
      >
        {/* <p className="m-0">
          <span className="font-weight-bold">Note: </span>
          Each step of creating the slide show is accessible here to allow for
          better testing and debugging. Once the feature is finalized we'll only
          have one button to generate the demo slide shows.
        </p> */}
        <p className="m-0">
          <span className="font-weight-bold">Note: </span>
          Make sure to create a backup of the database before creating the demo
          slides. This will allow you to restore the database so you can fallow
          the steps in the slides.
        </p>

        {/* <Col md={12} className="mt-4">
          <Button
            type="button"
            className="btn btn-success w-100"
            disabled={showLoader}
            onClick={() => {
              handleBackupDatabase();
            }}
          >
            {showLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "1) Create Database Backup"
            )}
          </Button>
        </Col>
        <Col md={12} className="mt-4">
          <Button
            type="button"
            className="btn btn-success w-100"
            disabled={showLoader}
            onClick={() => {
              handleUserClickedCreateSlides();
            }}
          >
            {showLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "2) Create Slides"
            )}
          </Button>
        </Col>
        {presentation_id !== "" && (
          <Col md={12} className="mt-4">
            <a
              href={"https://docs.google.com/presentation/d/" + presentation_id}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="button" className="btn btn-small btn-info w-100">
                2.b) View Slides
              </Button>
            </a>
          </Col>
        )}
        <Col md={12} className="mt-4">
          <Button
            type="button"
            className="btn btn-success w-100"
            disabled={showLoader || presentation_id === ""}
            onClick={() => {
              handleUserClickedCreateVideos();
            }}
          >
            {showLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "3) Create & Add Videos to Slides"
            )}
          </Button>
        </Col>
        <Col md={12} className="mt-4">
          <Button
            type="button"
            className="btn btn-success w-100"
            disabled={showLoader}
            onClick={() => {
              restoreDemo();
            }}
          >
            {showLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "4) Restore Backup"
            )}
          </Button>
        </Col> */}

        <Col md={12} className="mt-4">
          <Button
            type="button"
            className="btn btn-success w-100"
            disabled={showLoader || presentation_id !== ""}
            onClick={() => {
              handleUserClickedCreateSlidesAndVideos();
            }}
          >
            {showLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Create Slides and Videos"
            )}
          </Button>
        </Col>
        {presentation_id !== "" && (
          <Col md={12} className="mt-4">
            <a
              href={"https://docs.google.com/presentation/d/" + presentation_id}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="button" className="btn btn-small btn-info w-100">
                View Slides
              </Button>
            </a>
          </Col>
        )}
      </ContentBox>
    );
  };

  return (
    <>
      <Container>
        <h1 className="page-title">Create Demo Slideshow</h1>
        <div className="dashboard-wrapper">
          {step === "0" && renderOrganizationDetails()}
          {step === "1" && renderJobSeekerDetails()}
          {step === "2" && renderSupportTeamMemberDetails()}
          {step === "3" && renderConfirmDetails()}
        </div>
      </Container>

      {/* <ConformationModal
        show={showOverwriteWarning}
        onHide={() => setShowOverwriteWarning(false)}
        onPrimary={() => saveDemo()}
        onSecondary={() => {
          setShowOverwriteWarning(false);
          setShowLoader(false);
        }}
        showSecondary={true}
        primaryText="Overwrite Old Save"
        secondaryText="Cancel"
        title="Warning"
        bodyText="A demo with this name already exists. Do you want to overwrite it?"
      /> */}
    </>
  );
};

import { combineReducers } from "redux";

import { login } from "../Login/Reducer";
import { GlobalSearchReducer } from "../GlobalSearch/Reducer";
import { userReducer, postJobReducer } from "../Jobs/Reducer";
// import { postJobReducer } from './PostJobReducer'
export const reducer = combineReducers({
  userReducer,
  login,
  postJobReducer,
  GlobalSearchReducer,
});

import { API_URL } from "../Utilities/Helper";
import { get } from "./AxiosService";

const Url = API_URL;

// export function getAllJobSeekers(filter) {
//   return fetch(`${Url}user/jobseekersforgroupleader?` + filter, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getAllJobSeekers(filter) {
  return get(`${Url}user/jobseekersforgroupleader?` + filter).then((res) => res.data);
}

// export function getJobSeeker(jobSeekerId) {
//   return fetch(`${Url}user/jobseeker?JobSeekerId=` + jobSeekerId, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

export function getJobSeeker(jobSeekerId) {
  return get(`${Url}user/jobseeker?JobSeekerId=` + jobSeekerId).then((res) => res.data);
}

// export function getJobSeekerResumes(jobSeekerId) {
//   return fetch(`${Url}job/jobseekerresumes?JobSeekerId=` + jobSeekerId, {
//     method: "GET",
//     headers: { Authorization: "Bearer " + getData("token") },
//   }).then((data) => data.json());
// }

// export function updateJobSeekerStatus(data) {
//   return fetch(
//     `${Url}user/jobseekerisactivestatus?JobSeekerId=${data.JobSeekerId}&IsActive=${data.IsActive}`,
//     {
//       method: "GET",
//       headers: { Authorization: "Bearer " + getData("token") },
//     }
//   )
//     .then((data) => data.json())
//     .catch((err) => err.json());
// }

export function updateJobSeekerStatus(data) {
  return get(
    `${Url}user/jobseekerisactivestatus?JobSeekerId=${data.JobSeekerId}&IsActive=${data.IsActive}`
  ).then((res) => res.data);
}

// jobs
import axios from 'axios';

export const addUser = userObj => {
    return (dispatch) => {
        axios.post('https://jsonplaceholder.typicode.com/posts', userObj)
        .then(response => {
            dispatch({
                type: 'ADD_USER',
                payload: response.data
            }) 
        })
        .catch(error => {
            console.log(error);
        });
    }
}

// postJob
export const postJob = jobObj => {
    return (dispatch) => {
        axios.post('https://jsonplaceholder.typicode.com/posts', jobObj)
        .then(response => {
            dispatch({
                type: 'POST_JOB',
                payload: response.data
            }) 
        })
        .catch(error => {
            console.log(error);
        });
    }
}

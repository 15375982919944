import React, { useState, useEffect } from "react";
import { Col, Row, Alert, Badge } from "react-bootstrap";
// import { FaRegListAlt } from "react-icons/fa";
// import { Link } from "react-router-dom";
import SupportTeamList from "../../../Components/SupportTeamList";

//import alphabates from "../../../Components/AlphabatesSorting";
//import getSupportTeamList from "../../../Services/SupportTeamService";
import SearchSupportTeam from "../../../Components/SearchSupportTeam";
import {
  getAllSupportTeamMembers,
  updateSupportTeamMemberStatus,
  getGroupCategoriesByGroup,
} from "../../../Services/LeaderSupportTeamMember";

import { Helper, groupLeaderSTMPerPage } from "../../../Utilities/Helper";
import ListPagination from "../../../Components/Pagination";
import { toast } from "react-toastify";
import { globalSearchAction } from "../../../Redux/GlobalSearch/Action";
import { useDispatch, useSelector } from "react-redux";

import { generatePdf } from "../../../Utilities/Helper";

export default function SupportTeamMembers() {
  //Using Redux
  const store = useSelector((res) => res.GlobalSearchReducer.supports);

  const [supportTeamMemebers, setSupportTeamMemebers] = useState([]);
  const [totalSupportTeamMemebers, setTotalSupportTeamMemebers] = useState([]);
  const [activeSupportTeamMemebers, setActiveSupportTeamMemebers] = useState(0);
  const [groupCategoriesList, setGroupCategoriesList] = useState([]);
  //new

  const [currentPage, setCurrentPage] = useState({ value: "0", filter: "" });
  const [searchParams, setSearchParams] = useState("");
  // const [sort, setSort] = useState({ value: "", filter: "" });
  const [refreshTable, setRefreshTable] = useState(true);
  const [showPagination, setShowPagination] = useState(true);
  const [showSpinnerForSTM, setShowSpinnerForSTM] = useState(true);
  //export state
  const [exportData, setExportData] = useState([]);

  const [showAlertSuccess, setShowAlertSuccess] = useState({
    show: false,
    message: "",
  });
  const [showAlertError, setShowAlertError] = useState({
    show: false,
    message: "",
  });
  //new const for name
  const name = "SupportTeamMembersList.xlsx";

  const dispatch = useDispatch();

  const hideAlerts = () => {
    setTimeout(() => {
      setShowAlertSuccess({ show: false, message: "" });
      setShowAlertError({ show: false, message: "" });
    }, 5000);
  };

  useEffect(() => {
    async function supportTeamMemberListData() {
      setShowPagination(true);
      const filter =
        "recordperpage=" +
        groupLeaderSTMPerPage +
        currentPage.filter +
        searchParams;
      // sort.filter;
      getAllSupportTeamMembers(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          console.log("response", result); //
          setSupportTeamMemebers(result.supportTeamMembers);
          setTotalSupportTeamMemebers(result.totalRecord);
          setActiveSupportTeamMemebers(result.activeSupportTeamCount);
          setShowSpinnerForSTM(false);
        })
        .catch((err) => {
          console.log("Support Team List: ", err);
          setShowSpinnerForSTM(false);
        });
    }
    if (store === null) {
      supportTeamMemberListData();
    } else {
      setShowSpinnerForSTM(true);
      setSupportTeamMemebers(store.items);
      setTotalSupportTeamMemebers(store.totalRecord);
      setShowSpinnerForSTM(false);
    }
  }, [refreshTable, store]);

  useEffect(() => {
    function getAllGroupCategories() {
      getGroupCategoriesByGroup()
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroupCategoriesList(result);
        })
        .catch((err) => {
          console.log("Sub Group List: ", err);
        });
    }
    getAllGroupCategories();
  }, []);

  const refreshTableHandler = (page = 0) => {
    if (store !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store.recordperpage,
          page: currentPage.value,
          type: store.type,
          SerachText: store.SerachText,
          GlobalSearchTypeId: store.GlobalSearchTypeId,
          searchTypeText: store.searchTypeText,
        })
      );
      setShowSpinnerForSTM(true);
    } else {
      setShowSpinnerForSTM(true);
      setRefreshTable((prev) => !prev);
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const dataToExportAsPdf = (res) => {
    const userData = [];

    res.supportTeamMembers.forEach((element, index) => {
      let fullName = element.firstName + " " + element.lastName;
      let networkResponsesByRequests =
        element.supportTeamMemberResponseNumber +
        "/" +
        element.supportTeamMemberRequestNumber +
        " " +
        element.responseRequestPercentage +
        "%";
      userData.push([
        fullName,
        element.email,
        element.phone,
        networkResponsesByRequests,
        element.position,
        element.city,
        element.groupCategoryName,
        element.isActive ? "Active" : "Inactive",
      ]);
    });

    const pdfHeaders = [
      "Name",
      "Email",
      "Phone",
      "Responses / Requests",
      "Position",
      "City",
      "Category",
      "Status",
    ];

    generatePdf(
      pdfHeaders,
      userData,
      "SupportTeamMembersList.pdf",
      "Support Team Members List"
    );
  };

  const handleExportToPdf = () => {
    const filter = "recordperpage=" + totalSupportTeamMemebers + searchParams;
    setShowSpinnerForSTM(true);

    getAllSupportTeamMembers(filter)
      .then((res) => {
        const result = JSON.parse(res.response);
        dataToExportAsPdf(result);
        setShowSpinnerForSTM(false);
      })
      .catch((err) => {
        setShowSpinnerForSTM(false);
      });
  };

  // const sortingChangeHandler = (value) => {
  //   if (sort !== value) {
  //     setSort((prev) => ({ ...prev, filter: `&sort=${value}` }));
  //     setCurrentPage((prev) => ({ ...prev, filter: "" }));
  //     setRefreshTable((prev) => !prev);
  //   }
  // };

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      refreshTableHandler(page);
      setShowSpinnerForSTM(true);
    }
  };

  const changeStatusHandler = (data) => {
    const userdata = { SupportTeamMemberId: data.id, IsActive: !data.status };
    updateSupportTeamMemberStatus(userdata)
      .then((res) => {
        if (data.status == true) {
          setShowAlertError({ message: res.response, show: true });
        } else {
          setShowAlertSuccess({ message: res.response, show: true });
        }
        hideAlerts();
        refreshTableHandler(currentPage.value);
        setShowSpinnerForSTM(true);
      })
      .catch((err) => {
        console.log("Status Change: ", err);
      });
  };

  const onSerchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  //prepare data required for excel export
  const prepareData = (rawData) => {
    const supportTeamMembersData = rawData.supportTeamMembers;
    let data = [
      {
        Name: null,
        Email: null,
        Phone: null,
        "Network Responses/Requests": null,
        Position: null,
        Company: null,
        City: null,
        "Sub Category": null,
        Status: null,
      },
    ];
    if (supportTeamMembersData.length > 0) {
      console.log("support team", supportTeamMembersData);
      data = supportTeamMembersData.map((item) => {
        const active = "Active";
        const inActive = "InActive";
        return {
          Name: item.firstName + " " + item.lastName,
          Email: item.email,
          Phone: item.phone,
          "Network Responses/Requests":
            item.supportTeamMemberResponseNumber +
            "/" +
            item.supportTeamMemberRequestNumber +
            " " +
            (item.supportTeamMemberRequestNumber !== 0
              ? Math.floor(
                  parseFloat(
                    item.supportTeamMemberResponseNumber /
                      item.supportTeamMemberRequestNumber
                  ) * 100
                )
              : 0 + "%"),
          Position: item.position,
          Company: item.companyName,
          City: item.city,
          "Sub Category": item.groupCategoryName,
          Status: item.isActive ? active : inActive,
        };
      });
    }
    console.log("data", data);
    return data;
  };

  //export function
  const onExportHandler = async () => {
    setShowSpinnerForSTM(true);
    const filter = "recordperpage=" + totalSupportTeamMemebers + searchParams;
    await getAllSupportTeamMembers(filter)
      .then((res) => {
        if (res) {
          const result = JSON.parse(res.response);
          const newData = prepareData(result);

          console.log("newData", newData);

          Helper.excelGeneration(newData, name);
        }
      })
      .catch((err) => {
        console.log("Support Team List: ", err);
        setShowSpinnerForSTM(false);
      });
    setShowSpinnerForSTM(false);
  };

  return (
    <React.Fragment>
      <Alert
        className="confirm-alert"
        variant="success"
        show={showAlertSuccess.show}
        onClose={() => setShowAlertSuccess({ show: false, message: "" })}
        dismissible
      >
        <p className="text-center mb-0 py-3">{showAlertSuccess.message}</p>
      </Alert>
      {/* excel generation */}

      <Alert
        className="confirm-alert"
        variant="danger"
        show={showAlertError.show}
        onClose={() => setShowAlertError({ show: false, message: "" })}
        dismissible
      >
        <p className="text-center mb-0 py-3">{showAlertError.message}</p>
      </Alert>

      <div className="container p-0">
        <h1 className="page-title">
          Support Team Members{" "}
          <Badge
            pill
            bg="danger"
            className="bg-warning text-white px-3 ml-3 font-weight-normal"
          >
            {activeSupportTeamMemebers}
          </Badge>
        </h1>
        <div className="dashboard-wrapper support-team-members-list">
          <Row>
            <Col md={12}>
              <div className="dashboard-box dashboard-col-bg sponsership-page">
                <SearchSupportTeam
                  groupCategories={groupCategoriesList}
                  searchParam={onSerchParamHandler}
                  exportHandle={onExportHandler}
                  exportToPdf={handleExportToPdf}
                />
                <div className="support-member-list-wrapper">
                  <SupportTeamList
                    supportTeamMembers={supportTeamMemebers}
                    onChangeStatus={changeStatusHandler}
                    showSpinner={showSpinnerForSTM}
                  />
                  {/* <div className="alphabates">
                    {alphabates.map((alphabate, index) => (
                      <div
                        key={index}
                        onClick={() => sortingChangeHandler(alphabate)}
                      >
                        {alphabate}
                      </div>
                    ))}
                  </div> */}
                </div>
                <div className="d-flex">
                  {supportTeamMemebers.length !== 0 && showPagination && (
                    <ListPagination
                      recordPerPage={groupLeaderSTMPerPage}
                      TotalRecord={totalSupportTeamMemebers}
                      className="mt-3"
                      onPageChange={pageChangeHandler}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

import React, { useState } from "react";
import { HashRouter } from "react-router-dom";
import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";

import Content from "./Components/Content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((open) => !open);
  const [imageChange, setImageChange] = useState(false);
  const imagetoggle = () => setImageChange((open) => !open);

  const setToggleFalse = () => {
    setIsOpen(false);
  };

  return (
    <HashRouter>
      <Header
        isOpen={isOpen}
        toggle={toggle}
        imageChange={imageChange}
        imagetoggle={imagetoggle}
      />
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        imageChange={imageChange}
        imagetoggle={imagetoggle}
        setToggleFalse={setToggleFalse}
      />
      <Content
        isOpen={isOpen}
        toggle={toggle}
        imagetoggle={imagetoggle}
        setToggleFalse={setToggleFalse}
      />
      <ToastContainer limit={1} autoClose={8000} />
    </HashRouter>
  );
}

export default App;

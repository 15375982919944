import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { _delete } from "../Services/AxiosService";
import { API_URL } from "../Utilities/Helper";
import { getData } from "../Utilities/StorageHelper";
import Buttons from "./Shared/Button";

export default function EmployerJobsList({ groupName, jobslist = [], refreshPage }) {
  // console.log(jobslist);
  const [showAlertSuccess, setShowAlertSuccess] = useState({
    show: false,
    message: "",
  });
  const [showAlertError, setShowAlertError] = useState({
    show: false,
    message: "",
  });

  //   const handleClose1 = () => setShowt(false);
  const hideAlerts = () => {
    setTimeout(() => {
      setShowAlertSuccess({ show: false, message: "" });
      setShowAlertError({ show: false, message: "" });
    }, 5000);
  };

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
    show: false,
    id: null,
  });

  const openDeleteConfirmModal = (id) => {
    setShowDeleteConfirmModal({
      show: true,
      id: id,
    });
  };

  const closeDeleteConfirmModal = () => {
    setShowDeleteConfirmModal({
      show: false,
      id: null,
    });
  };

  const setRefresh = () => {
    window.location.reload();
  };

  const deleteJobHandler = (id) => {
    const headers = {
      Authorization: "Bearer " + getData("token"),
      "content-type": "application/json",
    };
    const toastId = "custom_toast";

    // axios.
    _delete(API_URL + `job/deletejob/` + id
      // , {
      //     headers: headers,
      //   }
    )
      .then((response) => {
        // console.log(response, response.message, "response success");
        if (response.status === 200) {
          // setTimeout(setRefresh, 7000);
          refreshPage();
          toast.success(response.data.response, { toastId: toastId });
          // window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(error.response);
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        //  console.log(error.config);
      });
  };

  return (
    <ul className="resume-list all-job-list">
      {jobslist.map((job, index) => (
        <li key={job.jobId}>
          <div className="resume-details">
            <Link
              to={
                `${process.env.PUBLIC_URL}/employer/employerjobdetails/` +
                job.jobId
              }
            >
              <h4>{job.positionTitle}</h4>
            </Link>
            <h5>{job.companyName}</h5>
            <div className="date-location">
              Industry : <span className="text">{" " + job.industryName}</span>
            </div>
            <div className="date-location">
              Posted :{" "}
              <span className="text">
                {" "}
                {/* {new Date(item.createdDate).toDateString()} */}
                {moment(job.jobStartDate).format("MMMM DD, Y")}
              </span>
            </div>
            <div className="date-location">
              Location :{" "}
              <span className="text">
                {job.city ? " " + job.city : "-"}
                {job.stateName ? ", " + job.stateName : "-"}
              </span>
            </div>
          </div>
          <Button
            variant="warning"
            size="sm"
            className="fav-icon ml-auto btn-like"
          >
            <i className="far fa-heart"></i>
          </Button>
          <div className="action-new-btns">
            <Link
              to={`${process.env.PUBLIC_URL}/editjob/${job.jobId}`}
              className="text-decoration-none"
            >
              <Button
                variant="warning"
                size="sm"
                className="fav-icon ml-auto mb-2"
              >
                <i className="icon icon-post"></i>
              </Button>
            </Link>
            <Button
              variant="light"
              size="sm"
              className="fav-icon ml-auto"
              onClick={() => openDeleteConfirmModal(job.jobId)}
            >
              <i className="icon icon-trash"></i>
            </Button>
          </div>
        </li>
      ))}
      <Modal
        show={showDeleteConfirmModal.show}
        onHide={closeDeleteConfirmModal}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={closeDeleteConfirmModal}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            Are you sure you want to delete the job?
          </div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="Ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                deleteJobHandler(showDeleteConfirmModal.id);
                closeDeleteConfirmModal();
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="Cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                closeDeleteConfirmModal();
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>
    </ul>
  );
}

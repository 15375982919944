// export default [
//     {
//       name: "😃 William",
//       location: "🏘️ Lagos",
//       car: "🚘 Honda"
//     },
//     {
//       name: "😃 Chris",
//       location: "🏘️ Moon",
//       car: "🚘 Tesla"
//     },
//     {
//       name: " 😃 Rose",
//       location: "🏘️ Venice",
//       car: "🚘 Pagani"
//     },
//     {
//       name: "😃 Mike",
//       location: "🏘️ Milan",
//       car: "🚘 Rolls Royce"
//     },
//     {
//       name: "😃 Liz",
//       location: "🏘️ Beirut",
//       car: "🚘 Mercedes"
//     }
//   ];

export default [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  // "#",
];

import moment from "moment";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function AllJobsList({
  groupName,
  jobslist = [],
  saveJobChange,
  title,
}) {
  //const [saveDisabled, setSaveDisabled] = useState(saveButtonStatus);
  const saveJobHandler = (jobId) => {
    saveJobChange(jobId);
  };

  return (
    <ul className="resume-list all-job-list">
      {jobslist.map((item, index) => (
        <li key={item.jobId}>
          <div className="resume-details">
            <Link
              to={`${process.env.PUBLIC_URL}/jobseeker/jobdetails/${item.jobId}`}
            >
              <h4>{item.positionTitle}</h4>
            </Link>
            <h5>{item.companyName}</h5>
            <div className="date-location">
              Industry : <span className="text">{" " + item.industryName}</span>
            </div>
            <div className="date-location">
              Posted :
              <span className="text">
                {" "}
                {moment(item.jobStartDate).format("MMMM DD, Y")}
              </span>
            </div>
            <div className="date-location">
              Location :
              <span className="text">
                {" "}
                {" " + item.city + ", " + item.stateName}
              </span>
            </div>
          </div>
          <Button
            variant="warning"
            size="sm"
            className="fav-icon ml-auto btn-like"
            title={title}
            onClick={() => saveJobHandler(item.jobId)}
          >
            <i className="far fa-heart"></i>
          </Button>
        </li>
      ))}
    </ul>
  );
}

import React, { useState, useEffect } from "react";
import { Col, Row, Form, Badge } from "react-bootstrap";

import ListPagination from "../../../Components/Pagination";
//import SavedJobsList from "../../../Components/SavedJobsList";

import AllJobsList from "../../../Components/AllJobsList";

import {
  getAllJobs,
  getAllSavedJobs,
  getJobSeekerGroupId,
  saveJob,
} from "../../../Services/AllJobsService";
//import getSavedJobsList from "../../../Services/SavedJobsService";
import Search from "../../../Components/Search";
import {
  getGroups,
  getIndustry,
  getJobTypes,
  getUserDetails,
} from "../../../Services/GetFields";
import {
  groupLeaderJobsPerPage,
  sortParamsForJobJS,
} from "../../../Utilities/Helper";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../Components/SpinnerLoader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { globalSearchAction } from "../../../Redux/GlobalSearch/Action";

export default function Jobs() {
  document.body.classList.add("applied-job-fliter");

  //Using Redux
  const store = useSelector((res) => res.GlobalSearchReducer.jobs);

  const [AllJobs, setAllJobsList] = useState([]);
  const [SavedJobs, setSavedJobs] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [jobSeekerGroupId, setJobSeekerGroupId] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSavedJobSpinner, setShowSavedJobSpinner] = useState(true);
  const [currentPage, setCurrentPage] = useState({ value: "0", filter: "" });
  const [currentPageSavedJob, setCurrentPageSavedJob] = useState({
    value: "",
    filter: "",
  });
  const [searchParams, setSearchParams] = useState("");
  const [totalJobs, setTotalJobs] = useState(0);
  const [industryId, setIndustryId] = useState(null);

  const [totalSavedJobs, setTotalSavedJobs] = useState();
  const [refreshTable, setRefreshTable] = useState(true);
  const [refreshTableSavedJob, setRefreshTableSavedJob] = useState(true);
  const [sort, setSort] = useState({ value: "", filter: "" });
  const [showPagination, setShowPagination] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // async function JobSeekerGroupId() {
    //   getJobSeekerGroupId()
    //     .then((res) => {
    //       const result = res;
    //       setJobSeekerGroupId(result.groupId);
    //     })
    //     .catch((err) => {
    //       console.log("JobSeeker Group Id: ", err);
    //     });
    // }
    async function allIndustries() {
      getIndustry()
        .then((res) => {
          const result = JSON.parse(res.response);
          setIndustries(result);
        })
        .catch((err) => {
          console.log("Industry List: ", err);
        });
    }
    async function UserDetails() {
      getUserDetails()
        .then((res) => {
          const result = res.response;
          if (result.industries.length > 0) {
            setIndustryId(result.industries[0].industryId);
          } else {
            setIndustryId(0);
          }
        })
        .catch((err) => {
          console.log("User Details: ", err);
        });
    }
    async function allGroups() {
      getGroups()
        .then((res) => {
          const result = JSON.parse(res.response);
          setGroups(result);
        })
        .catch((err) => {
          console.log("Group List: ", err);
        });
    }
    async function allJobTypes() {
      getJobTypes()
        .then((res) => {
          const result = JSON.parse(res.response);
          setJobTypes(result);
        })
        .catch((err) => {
          console.log("Job Types List: ", err);
        });
    }
    // JobSeekerGroupId();
    allJobTypes();
    allGroups();
    allIndustries();
    UserDetails();
  }, []);

  useEffect(() => {
    async function allSavedJobs() {
      const filter =
        "recordperpage=" + groupLeaderJobsPerPage + currentPageSavedJob.filter;
      getAllSavedJobs(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setSavedJobs(result.jobs);
          setTotalSavedJobs(result.totalRecord);
          setShowSavedJobSpinner(false);
        })
        .catch((err) => {
          // toast.error(err.response, { toastId: "custom_toast" });
          console.log("Saved Job List: ", err);
          setShowSavedJobSpinner(false);
        });
    }
    allSavedJobs();
  }, [refreshTableSavedJob]);

  useEffect(() => {
    async function allJobs() {
      setShowPagination(true);
      const filter =
        "recordperpage=" +
        groupLeaderJobsPerPage +
        currentPage.filter +
        sort.filter +
        searchParams;
      getAllJobs(filter)
        .then((res) => {
          const result = JSON.parse(res.response);
          setAllJobsList(result.jobs);
          setTotalJobs(result.totalRecord);
          setShowSpinner(false);
        })
        .catch((err) => {
          console.log("All Jobs List: ", err);
          //  toast.error(err.response, { toastId: "custom_toast" });
          setShowSpinner(false);
        });
    }

    if (store === null) {
      allJobs();
    } else {
      setShowSpinner(true);
      setAllJobsList(store.items);
      setTotalJobs(store.totalRecord);
      setShowSpinner(false);
    }
    // allJobs();
  }, [refreshTable, store]);

  const pageChangeHandler = (page) => {
    if (currentPage.value !== page) {
      setCurrentPage((prev) => ({ value: page, filter: `&page=${page}` }));
      refreshTableHandler(page);
    }
  };

  const pageChangeHandlerSavedJob = (page) => {
    if (currentPageSavedJob.value !== page) {
      setCurrentPageSavedJob((prev) => ({
        ...prev,
        filter: `&page=${page}`,
      }));
      refreshSavedTableHandler();
    }
  };

  const refreshTableHandler = (page = 0) => {
    if (store !== null && page !== 0) {
      dispatch(
        globalSearchAction({
          recordperpage: store.recordperpage,
          page: currentPage.value,
          type: store.type,
          SerachText: store.SerachText,
          GlobalSearchTypeId: store.GlobalSearchTypeId,
          searchTypeText: store.searchTypeText,
        })
      );
      setShowSpinner(true);
    } else {
      setRefreshTable((prev) => !prev);
      setShowSpinner(true);
      dispatch(
        globalSearchAction({
          type: "clear",
        })
      );
    }
  };

  const refreshSavedTableHandler = () => {
    setRefreshTableSavedJob((prev) => !prev);
    setShowSavedJobSpinner(true);
  };

  const onSerchParamHandler = (params) => {
    setShowPagination(false);
    setSearchParams(params);
    setCurrentPage((prev) => ({ value: "", filter: "" }));
    refreshTableHandler();
  };

  const sortingChangeHandler = (e) => {
    var val = e.target.value;
    setSort((prev) => ({ ...prev, filter: `&sort=${val}` }));
    refreshTableHandler();
  };

  const onSaveJobClick = (jobId) => {
    saveJob(jobId)
      .then((res) => {
        toast.success(res.response);
        refreshTableHandler(1);
        refreshSavedTableHandler();
      })
      .catch((err) => {
        console.log("Save Job: ", err);
      });
  };

  return (
    <div className="container p-0">
      <h1 className="page-title">Jobs</h1>
      <div className="dashboard-wrapper resumes-page">
        <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg mb-4 saved-resume">
              <h2 className="page-sub-title">
                Saved Jobs{" "}
                <Badge
                  pill
                  bg="danger"
                  className="bg-warning text-white px-3 ml-3 font-weight-normal"
                >
                  {totalSavedJobs}
                </Badge>
              </h2>
              {showSavedJobSpinner && <SpinnerLoader />}
              {SavedJobs.length === 0 && !showSavedJobSpinner && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              <AllJobsList
                jobslist={SavedJobs}
                saveJobChange={onSaveJobClick}
                title={"Remove from Saved Jobs"}
              />
              {SavedJobs.length !== 0 && (
                <ListPagination
                  recordPerPage={groupLeaderJobsPerPage}
                  TotalRecord={totalSavedJobs}
                  className="mt-3"
                  onPageChange={pageChangeHandlerSavedJob}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg">
              <h2 className="page-sub-title">
                Jobs{" "}
                <Badge
                  pill
                  bg="danger"
                  className="bg-warning text-white px-3 ml-3 font-weight-normal"
                >
                  {totalJobs}
                </Badge>
              </h2>
              <div className="search">
                <Search
                  //groupId={jobSeekerGroupId}
                  industriesList={industries}
                  industryId={industryId}
                  groupList={groups}
                  jobTypesList={jobTypes}
                  searchParam={onSerchParamHandler}
                />
              </div>
              <div className="sort">
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="w-25"
                >
                  <label>
                    <span>Sort By:</span>
                  </label>
                  <Form.Control
                    as="select"
                    className="inputclass form-control"
                    onChange={sortingChangeHandler}
                  >
                    {sortParamsForJobJS.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              {showSpinner && <SpinnerLoader />}
              {AllJobs.length === 0 && !showSpinner && (
                <h5 style={{ textAlign: "center" }}>No Data Found</h5>
              )}
              <AllJobsList
                jobslist={AllJobs}
                saveJobChange={onSaveJobClick}
                title={"Add to Saved Jobs"}
              />
              {AllJobs.length !== 0 && showPagination && (
                <ListPagination
                  recordPerPage={groupLeaderJobsPerPage}
                  TotalRecord={totalJobs}
                  className="mt-3"
                  onPageChange={pageChangeHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
// export default Jobs;

import React, { useState, Component } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormInput from "../../../Components/Shared/FormInput";
import Buttons from "../../../Components/Shared/Button";
import { passwordRegex, Validators } from "../../../Utilities/Validator";
import {
  getUserDetails,
  ChangeUserPassword,
} from "../../../Services/GetFields";
import { toast } from "react-toastify";
import { API_URL } from "../../../Utilities/Helper";

export default class ChangePassword extends Component {
  state = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
    regExError: false,
    userId: null,
    email: "",
    currentPasswordError: { message: "", error: false },
    passwordError: { message: "", error: false },
    confirmPasswordError: { message: "", error: false },
    passwordMatchError: false,
    oldPassword1Error: false,
    oldPassword2Error: false,
  };

  componentDidMount = () => {
    getUserDetails()
      .then((res) => {
        this.setState({ userId: res.response.id, email: res.response.email });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (key) => (value) => {
    // console.log(key, value);
    let regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;

    if (key === "currentPassword") {
      if (value === "" || value === undefined) {
        this.setState({
          currentPasswordError: {
            message: "Please provide current password",
            error: true,
          },
        });
      } else {
        this.setState({ currentPasswordError: { message: "", error: false } });
      }
    }
    if (key === "password") {
      if (value === "" || value === undefined) {
        this.setState({
          passwordError: {
            message: "Please provide new password",
            error: true,
          },
        });
      } else {
        if (this.state.currentPassword === value) {
          this.setState({
            passwordError: {
              message:
                "New Password cannot be the same as your Current Password.",
              error: true,
            },
          });
        } else {
          if (!passwordRegex.test(value)) {
            this.setState({
              passwordError: {
                message: "Please enter valid password",
                error: true,
              },
              regExError: true
            });
          } else {
            this.setState({ passwordError: { message: "", error: false }, regExError: false });
          }
        }
      }
    }
    if (key === "confirmPassword") {
      if (value === "" || value === undefined) {
        this.setState({
          confirmPasswordError: {
            message: "Please provide confirm password",
            error: true,
          },
        });
      } else {
        if (this.state.password !== value) {
          this.setState({
            confirmPasswordError: {
              message: "New password and confirm password does not match",
              error: true,
            },
          });
        } else {
          this.setState({
            confirmPasswordError: { message: "", error: false },
          });
        }
      }
    }

    this.setState({ [key]: value });
  };

  validate = (currentPassword, password, confirmPassword) => {
    let result = true;
    if (
      currentPassword === null ||
      currentPassword === undefined ||
      currentPassword === ""
    ) {
      result = false;
      this.setState({
        currentPasswordError: {
          message: "Please provide current password",
          error: true,
        },
      });
    }
    if (password === null || password === undefined || password === "") {
      result = false;
      this.setState({
        passwordError: { message: "Please provide new password", error: true },
      });
    }
    if (
      confirmPassword === null ||
      confirmPassword === undefined ||
      confirmPassword === ""
    ) {
      result = false;
      this.setState({
        confirmPasswordError: {
          message: "Please provide confirm password",
          error: true,
        },
      });
    }
    if (
      currentPassword === password &&
      currentPassword !== "" &&
      currentPassword !== undefined
    ) {
      result = false;
      this.setState({
        passwordError: {
          message: "New Password cannot be the same as your Current Password.",
          error: true,
        },
      });
    }
    if (password !== confirmPassword) {
      result = false;
      this.setState({
        confirmPasswordError: {
          message: "New password and confirm password does not match",
          error: true,
        },
      });
    }
    if (result === false) {
      return false;
    }
    return result;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { currentPassword, password, confirmPassword, regExError } =
      this.state;
    let value = this.validate(currentPassword, password, confirmPassword);
    if (value === true) {
      if (password === confirmPassword && regExError === false) {
        this.setState({
          passwordMatchError: false,
          oldPassword1Error: false,
          oldPassword2Error: false,
        });
        this.changePassword();
      } else {
        this.setState({ passwordMatchError: true });
      }
    } else {
      return;
    }
  };

  changePassword = () => {
    const data =
    // JSON.stringify(
    {
      OldPassword: this.state.currentPassword,
      Password: this.state.password,
      ConfirmPassword: this.state.confirmPassword,
      Email: this.state.email,
    };
    // );
    ChangeUserPassword(data).then((response) => {
      const toastId = "custom_toast";
      if (response.value.item1 === true) {
        toast.success("Password updated successfully.", { toastId: toastId });
        window.location.href = `${process.env.PUBLIC_URL}/#/dashboard`;
      } else {
        toast.error("Invalid Current Password.");
      }
    });
  };

  render() {
    const {
      currentPassword,
      password,
      confirmPassword,
      currentPasswordError,
      passwordError,
      confirmPasswordError,
      passwordMatchError,
      regExError,
      oldPassword1Error,
      oldPassword2Error,
    } = this.state;

    return (
      <div className="container p-0 post-job-container outline-form">
        <h1 className="page-title d-flex align-items-center">
          Change Password{" "}
        </h1>
        <div className="dashboard-wrapper resumes-page">
          <Form noValidate>
            <Row>
              <Col md={12}>
                <div className="dashboard-box activity dashboard-col-bg">
                  <Row>
                    <Col md={9} className="mx-auto">
                      <h5 className="sub-form-title">Password</h5>
                      <Row>
                        <Col md={6}>
                          <FormInput
                            value={currentPassword}
                            type="password"
                            placeholder="Current Password *"
                            required="required"
                            onChange={this.handleChange("currentPassword")}
                            maxLength={50}
                          />
                          {currentPasswordError.error && (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {currentPasswordError.message}
                            </p>
                          )}
                          <FormInput
                            value={password}
                            type="password"
                            placeholder="New Password *"
                            required="required"
                            onChange={this.handleChange("password")}
                            maxLength={50}
                          />
                          {passwordError.error && (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {passwordError.message}
                            </p>
                          )}
                          {/* {regExError && (
                            <p style={{ color: "red" }}>
                              Enter a valid Password
                            </p>
                          )} */}
                          <FormInput
                            value={confirmPassword}
                            type="password"
                            placeholder="Confirm New Password *"
                            required="required"
                            onChange={this.handleChange("confirmPassword")}
                            maxLength={50}
                          />
                          {confirmPasswordError.error && (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {confirmPasswordError.message}
                            </p>
                          )}
                        </Col>
                        <Col md={6}>
                          <p className="password-note">
                            Be a minimum of 8 characters
                            <br />
                            Include at least one uppercase letter (A-Z)
                            <br />
                            Include at least one lowercase letter (a-z)
                            <br />
                            One number (0-9) and symbol (@, #, $, %, etc.)
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={8} className="square-radius mt-4">
                          <Buttons
                            variant="warning"
                            title="Submit"
                            // type="submit"
                            size="xs"
                            color="white"
                            onClick={this.handleSubmit}
                          ></Buttons>
                          <span style={{ marginLeft: 18 }}></span>
                          <Buttons
                            variant="warning"
                            title="Cancel"
                            // type="submit"
                            size="xs"
                            color="white"
                            onClick={() => {
                              window.location.href = `${process.env.PUBLIC_URL}/#/home`;
                            }}
                          ></Buttons>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { validateInput } from "../../Utilities/Validator";

const FormInput = ({
  value,
  label,
  withIcon,
  icon,
  name,
  required,
  maxLength,
  minLength,
  placeholder,
  validators,
  type,
  onChange,
  inputerror,
  onBlur,
  className,
  style,
  rows,
}) => {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setError(validateInput(validators, value));
    onChange(value);
  };

  const handleOnBlur = (event) => {
    const { value } = event.target;
    setError(validateInput(validators, value));
    onBlur(value);
  };

  return (
    <div className={`form-group ${withIcon} ${required}`}>
      {label && <label htmlFor="input-field">{label}</label>}
      <span className={`fa-icon ${icon}`}></span>
      {onBlur !== undefined && (
        <>
          {" "}
          {type === "textarea" ? (
            <textarea
              style={style}
              className={`inputclass form-control ${className}`}
              placeholder={placeholder}
              value={value}
              maxLength={maxLength}
              rows={rows !== null || rows !== "" ? rows : 5}
              onChange={handleChange}
              onBlur={handleOnBlur}
            />
          ) : (
            <input
              style={style}
              type={type}
              value={value}
              maxLength={maxLength}
              minLength={minLength}
              className={`inputclass form-control ${className}`}
              placeholder={placeholder}
              required={required}
              onChange={handleChange}
              onBlur={handleOnBlur}
            />
          )}
        </>
      )}
      {onBlur === undefined && (
        <>
          {type === "textarea" ? (
            <textarea
              style={style}
              className="inputclass form-control"
              placeholder={placeholder}
              value={value}
              maxLength={maxLength}
              rows={5}
              onChange={handleChange}
            // onBlur={onBlur !== undefined ? "" : handleOnBlur}
            />
          ) : (
            <input
              type={type}
              style={style}
              value={value}
              maxLength={maxLength}
              minLength={minLength}
              className="inputclass form-control"
              placeholder={placeholder}
              required={required}
              onChange={handleChange}
            // onBlur={onBlur !== undefined ? "" : handleOnBlur}
            />
          )}
        </>
      )}
      {error && <div className="text-danger error">{error.message}</div>}
      {inputerror !== "" && (
        <div className="text-danger error">{inputerror}</div>
      )}
    </div>
  );
};

FormInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  withIcon: PropTypes.string,
  icon: PropTypes.string,
  required: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
};

FormInput.defaultProps = {
  value: "",
  label: "",
  withIcon: "",
  icon: "",
  required: "",
  placeholder: "",
  type: "text",
  validators: [],
};

export default FormInput;

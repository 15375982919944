import React from "react";
import { Modal } from "react-bootstrap";
import Buttons from "./Shared/Button";

export const ConformationModal = ({
  show,
  onHide,
  onPrimary,
  onSecondary,
  showSecondary,
  primaryText,
  secondaryText,
  title,
  bodyText,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      centered
      className="confirm-modal"
    >
      <div className="message-container custome-msg-container">
        <div
          className="message-header-box"
          style={{ backgroundColor: "#dc3545" }}
        >
          <h4>{title}</h4>
        </div>
        <div className="message-body">{bodyText}</div>
        <div className="message-footer pt-0">
          <Buttons
            variant="danger"
            title={primaryText ? primaryText : "Yes"}
            type="submit"
            size="xs"
            color="white"
            onClick={() => {
              if (onPrimary) onPrimary();
            }}
          ></Buttons>
          <Buttons
            visible={true === showSecondary}
            variant="secondary"
            title={secondaryText ? secondaryText : "No"}
            type="submit"
            size="xs"
            color="white"
            onClick={() => {
              if (onSecondary) onSecondary();
            }}
          ></Buttons>
        </div>
      </div>
    </Modal>
  );
};

import React, { useState, useEffect, Profiler } from "react";
import { Link } from "react-router-dom";
import profile from "../assests/img/profile.png";
import {
  Button,
  Modal,
  Form,
  Toast,
  Alert,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FaPaperPlane, FaTimes } from "react-icons/fa";
import Buttons from "./Shared/Button";
import SpinnerLoader from "./SpinnerLoader";
import { Roles } from "../Utilities/Helper";

export default function AdminUserListing({
  userList = [],
  onChangeStatus,
  showSpinner,
}) {
  const [showConfirmModal, setshowConfirmModal] = useState({
    title: "",
    show: false,
  });

  const [userData, setUserData] = useState({
    id: null,
    status: null,
    userTypeId: null,
    fullName: null,
  });
  const renderActiveTooltip = (props) => <Tooltip {...props}>Active</Tooltip>;

  const renderBlockTooltip = (props) => <Tooltip {...props}>Block</Tooltip>;

  const onOpenModalHandler = (id, status, userTypeId, fullName) => {
    setUserData({
      id: id,
      status: status,
      userTypeId: userTypeId,
      fullName: fullName,
    });
    if (status === true) {
      setshowConfirmModal({
        title: "Are you sure you want to block this user?",
        show: true,
      });
    } else {
      setshowConfirmModal({
        title: "Are you sure you want to activate this user?",
        show: true,
      });
    }
  };

  const onCloseModalHandler = () => {
    setshowConfirmModal({ title: "", show: false });
    setUserData({ id: null, status: null, userTypeId: null, fullName: null });
  };

  const onChangeStatusHandler = () => {
    onChangeStatus(userData);
    onCloseModalHandler();
  };

  return (
    <React.Fragment>
      <ul className="job-list support-team-list job-seekers-list mobile-list manage-admin-user-list">
        {/* <p>Jobs</p> */}
        <li className="headings">
          <div className="avatar border-0"></div>
          <div className="name2">Name </div>
          <div className="email2">Email </div>
          <div className="user-type">User Type </div>
          <div className="group2">Group Name</div>
          <div className="status2">Status </div>
          <div className="text-right action2">Action </div>
        </li>
        {showSpinner && <SpinnerLoader />}
        {userList.length === 0 && !showSpinner && (
          <h5 style={{ textAlign: "center" }}>No Data Found</h5>
        )}
        {userList.map((item, index) => (
          <li key={index} className="align-items-center">
            <div className="avatar">
              {item.profilePhoto !== null ? (
                <img
                  src={`https://data.jin-storage.us-sjo1.upcloudobjects.com/ProfilePhotos/${item.profilePhoto}`}
                />
              ) : (
                <img key={index} src={profile} />
              )}
              {/* src={}  */}
            </div>
            <div className="name2 mobile-coulmn">
              <span className="mobile-column-name">Name</span>
              <span className="mobile-column-content">
                {item.userType === Roles.jobSeeker && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/admin/jobseekersdetails/${item.userId}`}
                  >
                    {" "}
                    {item.fullName}
                    <span
                      className="job-post-date ml-auto hide-on-list pt-0"
                      style={{ color: "#7b7777" }}
                    >
                      {item.email}
                    </span>
                  </Link>
                )}
                {item.userType === Roles.stm && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/admin/supportteammembersdetails/${item.userId}`}
                  >
                    {" "}
                    {item.fullName}
                    <span
                      className="job-post-date ml-auto hide-on-list pt-0"
                      style={{ color: "#7b7777" }}
                    >
                      {item.email}
                    </span>
                  </Link>
                )}
                {item.userType === Roles.employer && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/admin/employerdetails/${item.userId}`}
                  >
                    {" "}
                    {item.fullName}
                    <span
                      className="job-post-date ml-auto hide-on-list pt-0"
                      style={{ color: "#7b7777" }}
                    >
                      {item.email}
                    </span>
                  </Link>
                )}
                {item.userType === Roles.groupLeader && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/admin/groupleaderdetails/${item.userId}`}
                  >
                    {" "}
                    {item.fullName}
                    <span
                      className="job-post-date ml-auto hide-on-list pt-0"
                      style={{ color: "#7b7777" }}
                    >
                      {item.email}
                    </span>
                  </Link>
                )}
              </span>
            </div>
            {/* <div className="job-post-date ml-auto hide-on-list pt-0">
              {new Date(item.createdDate)
                .toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
                .split("/")
                .join(".")}
            </div> */}
            <div className="email2 mobile-coulmn">
              <span className="mobile-column-name">Email</span>
              <span className="mobile-column-content">{item.email}</span>
            </div>
            <div className="user-type mobile-coulmn">
              <span className="mobile-column-name">User Type</span>
              <span className="mobile-column-content">{item.userType}</span>
            </div>
            <div
              className="group2 mobile-coulmn"
              title={item.groups.map((group, index) => group.groupName)}
            >
              <span className="mobile-column-name">Group Name</span>
              <span className="mobile-column-content">
                {item.groups.map((group, index) => (
                  <span key={index}>
                    {index > 1 && index < 3 && ",..."}
                    {index === 0 && group.groupName}
                    {index === 1 && ", " + group.groupName}
                  </span>
                ))}
                {item.groupName === null && item.groups.length === 0
                  ? "-"
                  : item.groupName}
              </span>
            </div>
            {/* <div className="phone2">{item.phone}</div> */}
            <div className="status2 mobile-coulmn">
              <span className="mobile-column-name">Status</span>
              <span className="mobile-column-content">
                {item.isActive ? "Active" : "InActive"}
              </span>
            </div>
            <div className="seeker-action-btns text-md-right action2">
              {item.isActive === false && (
                // {network.status}
                <OverlayTrigger placement="top" overlay={renderActiveTooltip}>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() =>
                      onOpenModalHandler(
                        item.userId,
                        item.isActive,
                        item.userTypeId,
                        item.fullName
                      )
                    }
                  >
                    <span className="icon-check mr-2"></span> Activate
                  </Button>
                </OverlayTrigger>
              )}

              {item.isActive === true && (
                <OverlayTrigger placement="top" overlay={renderBlockTooltip}>
                  <Button
                    variant="danger"
                    size="sm"
                    className="ml-md-2"
                    onClick={() =>
                      onOpenModalHandler(
                        item.userId,
                        item.isActive,
                        item.userTypeId,
                        item.fullName
                      )
                    }
                  >
                    <span className="icon-block mr-2"></span>Deactivate
                  </Button>
                </OverlayTrigger>
              )}
            </div>
          </li>
        ))}
      </ul>

      {/* Active confirm */}

      <Modal
        show={showConfirmModal.show}
        onHide={onCloseModalHandler}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={onCloseModalHandler}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">{showConfirmModal.title}</div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="Ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                onChangeStatusHandler();
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="Cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                onCloseModalHandler();
              }}
            ></Buttons>
          </div>
        </div>
      </Modal>

      {/* block confirm */}

      {/* <Modal
        show={showblock}
        onHide={handleCloseBlock}
        centered
        className="confirm-modal"
      >
        <div className="message-container custome-msg-container">
          <div className="message-header-box">
            <h4>Confirm</h4>
            <div className="messages-modal-actions">
              <Button className="btn" onClick={handleCloseBlock}>
                <FaTimes />
              </Button>
            </div>
          </div>
          <div className="message-body">
            {showConfirmModal.title}
          </div>
          <div className="message-footer pt-0">
            <Buttons
              variant="primary"
              title="ok"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                setShowb(true);
                setShowblock(false);
              }}
            ></Buttons>
            <Buttons
              variant="secondary"
              title="cancel"
              type="submit"
              size="xs"
              color="white"
              onClick={() => {
                setShowb(true);
                setShowblock(false);
              }}
            ></Buttons>
          </div>
        </div>
      </Modal> */}
    </React.Fragment>
  );
}

import { Link } from "react-router-dom";
import SpinnerLoader from "./SpinnerLoader";
import profile from "../assests/img/profile.png";

export default function SponsershipList({ sponsers = [], showSpinner }) {
  return (
    <ul className="job-list sponsership-list mobile-list">
      {/* <p>Jobs</p> */}
      <li className="headings">
        <div className="avatar border-0"></div>
        <div className="name">Name </div>
        <div className="email">Email </div>
        <div className="phone">Phone </div>
        <div className="company ml-auto">Status </div>
      </li>
      {showSpinner && <SpinnerLoader />}
      {sponsers.length === 0 && !showSpinner && (
        <h5 style={{ textAlign: "center" }}>No Data Found</h5>
      )}
      {sponsers.map((sponser, index) => (
        <Link
          key={index}
          to={`${process.env.PUBLIC_URL}/support/sponsorshipdetails/${sponser.jobSeekerSponsershipRequestId}`}
        >
          {/* to={
            sponser.statusName === "Pending" ||
            sponser.statusName === "Approved"
              ? `${process.env.PUBLIC_URL}/support/sponsorshipdetails/${sponser.jobSeekerId}`
              : "#"
          } */}
          <li key={index} className="align-items-center">
            <div className="avatar">
              {sponser.profilePhoto !== null ? (
                <img
                  src={`https://data.jin-storage.us-sjo1.upcloudobjects.com/ProfilePhotos/${sponser.profilePhoto}`}
                />
              ) : (
                <img key={index} src={profile} />
              )}
            </div>
            <div className="name mobile-coulmn"><span className="mobile-column-name">Name</span> <span className="mobile-column-content">{sponser.fullName}</span></div>
            <div className="email mobile-coulmn"><span className="mobile-column-name">Email</span> <span className="mobile-column-content"> {sponser.email}</span></div>
            <div className="phone mobile-coulmn"><span className="mobile-column-name">Phone</span> <span className="mobile-column-content">{sponser.phone}</span></div>
            <div className="company ml-auto mobile-coulmn"><span className="mobile-column-name">Company</span> <span className="mobile-column-content"> {sponser.statusName}</span></div>
          </li>
        </Link>
      ))}
    </ul>
  );
}

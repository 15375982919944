import React, { useState, Component, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  FaInbox,
  FaPaperPlane,
  FaFlag,
  FaEnvelopeOpen,
  FaTrashAlt,
} from "react-icons/fa";
import { Roles } from "../Utilities/Helper";
import { getData } from "../Utilities/StorageHelper";

export default function InboxMenu({ sendDataToParent, selectTab, markActive }) {
  const [active, setActive] = useState(markActive);

  useEffect(() => {
    document.getElementById("inboxTab").click();
  }, [selectTab]);

  return (
    <div className="inbox-menu">
      <ul>
        <li id="inboxTab">
          {getData("role") === Roles.employer && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/employer/messages`}
              onClick={() => {
                setActive(0);
                sendDataToParent(0);
              }}
              className={active === 0 ? "active" : ""}
            >
              <FaInbox />
              Inbox
            </NavLink>
          )}
          {getData("role") === Roles.jobSeeker && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/jobseeker/messages`}
              onClick={() => {
                setActive(0);
                sendDataToParent(0);
              }}
              className={active === 0 ? "active" : ""}
            >
              <FaInbox />
              Inbox
            </NavLink>
          )}
          {getData("role") === Roles.stm && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/support/messages`}
              onClick={() => {
                setActive(0);
                sendDataToParent(0);
              }}
              className={active === 0 ? "active" : ""}
            >
              <FaInbox />
              Inbox
            </NavLink>
          )}
        </li>
        <li>
          {getData("role") === Roles.employer && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/employer/messages`}
              onClick={() => {
                setActive(1);
                sendDataToParent(1);
              }}
              className={active === 1 ? "active" : ""}
            >
              <FaPaperPlane />
              Sent
            </NavLink>
          )}
          {getData("role") === Roles.jobSeeker && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/jobSeeker/messages`}
              onClick={() => {
                setActive(1);
                sendDataToParent(1);
              }}
              className={active === 1 ? "active" : ""}
            >
              <FaPaperPlane />
              Sent
            </NavLink>
          )}
          {getData("role") === Roles.stm && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/support/messages`}
              onClick={() => {
                setActive(1);
                sendDataToParent(1);
              }}
              className={active === 1 ? "active" : ""}
            >
              <FaPaperPlane />
              Sent
            </NavLink>
          )}
        </li>
        <li>
          {getData("role") === Roles.employer && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/employer/messages`}
              onClick={() => {
                setActive(2);
                sendDataToParent(2);
              }}
              className={active === 2 ? "active" : ""}
            >
              <FaFlag />
              Flagged
            </NavLink>
          )}
          {getData("role") === Roles.jobSeeker && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/jobSeeker/messages`}
              onClick={() => {
                setActive(2);
                sendDataToParent(2);
              }}
              className={active === 2 ? "active" : ""}
            >
              <FaFlag />
              Flagged
            </NavLink>
          )}
          {getData("role") === Roles.stm && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/support/messages`}
              onClick={() => {
                setActive(2);
                sendDataToParent(2);
              }}
              className={active === 2 ? "active" : ""}
            >
              <FaFlag />
              Flagged
            </NavLink>
          )}
        </li>
        {/* <li>
          <NavLink
            activeClassName="is-active" to={`${process.env.PUBLIC_URL}/send`} >
            <FaEnvelopeOpen />
            Draft
          </NavLink>
        </li> */}
        <li>
          {getData("role") === Roles.employer && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/employer/messages`}
              onClick={() => {
                setActive(3);
                sendDataToParent(3);
              }}
              className={active === 3 ? "active" : ""}
              //to={`${process.env.PUBLIC_URL}/send`}
            >
              <FaTrashAlt />
              Trash
            </NavLink>
          )}
          {getData("role") === Roles.jobSeeker && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/jobseeker/messages`}
              onClick={() => {
                setActive(3);
                sendDataToParent(3);
              }}
              className={active === 3 ? "active" : ""}
              //to={`${process.env.PUBLIC_URL}/send`}
            >
              <FaTrashAlt />
              Trash
            </NavLink>
          )}
          {getData("role") === Roles.stm && (
            <NavLink
              activeClassName="is-active"
              to={`${process.env.PUBLIC_URL}/support/messages`}
              onClick={() => {
                setActive(3);
                sendDataToParent(3);
              }}
              className={active === 3 ? "active" : ""}
              //to={`${process.env.PUBLIC_URL}/send`}
            >
              <FaTrashAlt />
              Trash
            </NavLink>
          )}
        </li>
      </ul>
    </div>
  );
}

// export default InboxMenu;

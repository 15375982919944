import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ListPagination from "../../../Components/Pagination";
import SavedJobsList from "../../../Components/SavedJobsList";

import AllJobsList from "../../../Components/AllJobsList";

import { getAllJobsList } from "../../../Services/AllJobsService";
import getSavedJobsList from "../../../Services/SavedJobsService";
import Search from "../../../Components/Search";

export default function AppliedJobs() {
  const [AllJobs, setAllJobsList] = useState([]);
  const [SavedJobs, setSavedJobs] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const res = await getAllJobsList();
        setAllJobsList(res);
      };
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const res = await getSavedJobsList();
        setSavedJobs(res);
      };
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="container p-0">
      <h1 className="page-title">Jobs Applied</h1>
      <div className="dashboard-wrapper resumes-page">
        <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg mb-4 saved-resume">
              <Link
                to={`${process.env.PUBLIC_URL}/jobs`}
                className="page-sub-title-with-back d-block"
              >
                <AiOutlineArrowLeft />
                Back to jobs
              </Link>

              {/* <h2 className="page-sub-title">Applied Jobs</h2> */}
              <AllJobsList jobslist={AllJobs} />
              <ListPagination />
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col md={12}>
            <div className="dashboard-box activity dashboard-col-bg">            
             <div className="search">
               <Search/>
             </div> 
             <div className="sort">
             <Form.Group controlId="exampleForm.ControlSelect1" className="w-25">
                <Form.Control as="select" className="inputclass form-control">
                  <option>Sort By</option>
                  <option>Newest First</option>
                  <option> Oldest First </option>
                  <option>Newest First Date</option>
                  <option> Oldest First Date</option>
                  <option>By Category</option>
                </Form.Control>
              </Form.Group>
               </div>             
              <AllJobsList jobslist={AllJobs} />
              <ListPagination/>
            </div>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}
// export default Jobs;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Button, Modal } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import SpinnerLoader from "./SpinnerLoader";
import moment from "moment";
import { getConnectionResponse } from "../Services/SeekersConnectionService";
import { toast } from "react-toastify";
import { connectionResponses, helpful, helpfulText } from "../Utilities/Helper";

export default function SeekersConnectionsList({
  connections = [],
  showSpinner,
}) {
  const [text, setText] = React.useState("");
  const [text1, setText1] = React.useState("");

  const [show, setShow] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [shortResponse, setShortResponse] = useState("");
  const [fullResponse, setFullResponse] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [status, setStatus] = React.useState(0); // 0: no show, 1: show yes, 2: show no.

  const [response, setResponse] = useState(false);
  const handleResponseClose = () => setResponse(false);
  const handleResponseShow = (id, ShortResponse, fullResponse) => {
    getConnectionResponse(id)
      .then((res) => {
        const result = JSON.parse(res.response);
        setResponseMessage(result.jobSeekerConnectionResponse);
        setShortResponse(ShortResponse);
        setFullResponse(fullResponse);
        setResponse(true);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("something went wrong");
      });
  };

  const radioHandler = (status) => {
    setStatus(status);
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleChangeText = (event) => {
    setText1(event.target.value);
  };

  return (
    <React.Fragment>
      <ul className="sponsership-list network-list mobile-list">
        <li className="headings">
          <div className="title">Requested</div>
          <div className="company-name">Group</div>
          <div className="company-name">Company</div>
          <div className="title">Position</div>
          <div className="company-name">Location</div>
          <div className="action ml-auto">Status</div>
          <div className="industry-category text-right">Response</div>
        </li>
        {showSpinner && <SpinnerLoader />}
        {connections.length === 0 && !showSpinner && (
          <h5 style={{ textAlign: "center" }}>No Data Found</h5>
        )}
        {connections.map((item, index) => (
          <li key={index} className="align-items-center">
            <div className="title mobile-coulmn">
              <span className="mobile-column-name">Requested</span>
              <span className="mobile-column-content">
                {moment(
                  new Date(
                    item.jobSeekerRequestedDate.toString() + "Z"
                  ).toString()
                ).format("MMM D, Y")}
              </span>
            </div>
            <div className="company-name mobile-coulmn">
              <span className="mobile-column-name">Group</span>
              <span className="mobile-column-content">{item.groupName}</span>
            </div>
            <div className="company-name mobile-coulmn">
              <span className="mobile-column-name">Company</span>
              <span className="mobile-column-content">
                {item.supportTeamMemberCompanyName}
              </span>
            </div>
            <div className="title mobile-coulmn">
              <span className="mobile-column-name">Position</span>
              <span className="mobile-column-content">
                {item.supportTeamMemberTitle}
              </span>
            </div>
            <div className="company-name mobile-coulmn">
              <span className="mobile-column-name">Location</span>
              <span className="mobile-column-content">
                {item.supportTeamMemberCity + ", " + item.stateName}
              </span>
            </div>
            <div className="action mobile-coulmn">
              <span className="mobile-column-name">Status</span>
              <span className="mobile-column-content">
                {item.jobSeekerConnectionResponseId === null && (
                  // {network.status}
                  <span>Pending</span>
                )}
                {
                  item.jobSeekerConnectionResponseId !== null && (
                    <span>Responded</span>
                  )
                  // <div>{network.status}</div>
                }
              </span>
            </div>
            <div className="industry-category text-right mobile-coulmn">
              <span className="mobile-column-name">Response</span>
              <span className="mobile-column-content">
                {item.jobSeekerConnectionResponseId === null && (
                  // {network.status}
                  <span>-</span>
                )}

                {
                  item.jobSeekerConnectionResponseId !== null && (
                    <Link
                      className="link"
                      onClick={(e) =>
                        handleResponseShow(
                          item.jobSeekerConnectionId,
                          item.shortResponse,
                          item.jobSeekerConnectionResponseId ==
                            connectionResponses.notAwareOfOpportunity
                            ? item.responseTitle
                            : null
                        )
                      }
                    >
                      {item.shortResponse}
                    </Link>
                  )
                  // <div>{network.status}</div>
                }
              </span>
            </div>
          </li>
        ))}
      </ul>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className="respond-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Support Team Member's Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 radio-list pl-0">
            <h5>
              Please have Job Seeker contact me at the following phone number.
              email address etc. I have specific suggestions I'd like to share
              with you
            </h5>
            <div className="note">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={response}
        size="lg"
        onHide={handleResponseClose}
        className="respond-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <h6 className="respons-heading">
              {fullResponse == null
                ? shortResponse === helpful
                  ? helpfulText
                  : shortResponse
                : fullResponse}{" "}
            </h6>
            <p className="respons-text">{responseMessage}</p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleResponseClose}>
            send
          </Button>
        </Modal.Footer> */}
      </Modal>
    </React.Fragment>
  );
}

// export default SeekersConnectionsList;

import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  addremoveUserFlagMessages,
  deleteUserMessages,
  restoreUserMessages,
  sendUserMessages,
} from "../Services/MessagesService";
import InboxMessagesActions from "./InboxMessageActions";
import Buttons from "./Shared/Button";
import MessageLimit from "./Shared/MessageLimit";
import profile from "../assests/img/profile.png";
import { readNotification } from "../Services/NotificationService";

export default function InboxMessage({
  message,
  messageType,
  refreshOnDelete,
  refreshMessageSend,
  refreshMessageRestore,
  refreshMessageFlag,
  refreshMessageTrash,
  refreshTableHandler,
  setPagination,
}) {
  // console.log(message, 'inboxmessage');
  const [messageData, setMessageData] = useState({
    SenderId: null,
    Message: "",
    UserType: null,
  });
  const [showTextBox, setShowTextBox] = useState(false);

  useEffect(() => {
    if (message === "") {
      setShowTextBox(false);
    }
  }, [message]);

  useEffect(() => {
    if (messageType == "Inbox" && message.isSeen == false) {
      // let data = JSON.stringify({
      //   userId: message.messageId,
      // });
      let data = message.messageId;
      readNotification(data)
        .then((res) => {
          // if (res.status === 200)
          refreshTableHandler();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [refreshOnDelete]);

  const replayHandler = (id, userType) => {
    setMessageData({ SenderId: id, Message: "", UserType: userType });
    setShowTextBox(true);
  };

  const onTextMessageChange = (e) => {
    if (e.target.value.length > 500) {
      return;
    }
    setMessageData((prev) => ({ ...prev, Message: e.target.value }));
  };

  const SendMessage = () => {
    if (messageData.Message.trim() === "") {
      return;
    }
    sendUserMessages(messageData)
      .then((res) => {
        refreshMessageSend();
        toast.success(res.response);
        setMessageData({ SenderId: null, Message: "", UserType: null });
        setShowTextBox(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const flagHandler = (data) => {
    addremoveUserFlagMessages(data)
      .then((res) => {
        refreshMessageFlag();
        toast.success(res.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const trashHandler = (data) => {
    deleteUserMessages(data)
      .then((res) => {
        refreshMessageTrash();
        toast.success(res.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const restoreHandler = (data) => {
    restoreUserMessages(data)
      .then((res) => {
        refreshMessageRestore();
        toast.success(res.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      {message.length !== 0 && (
        <InboxMessagesActions
          message={message}
          replay={replayHandler}
          flag={flagHandler}
          trash={trashHandler}
          restore={restoreHandler}
          messageType={messageType}
          isFlagged={messageType == "Flagged" ? true : false}
        />
      )}
      <div className="main-message">
        {message == "" && (
          <h6 style={{ textAlign: "center" }}>No Message Selected</h6>
        )}
        {message && message.length !== 0 && (
          <>
            <div className="main-msg-header mb-3">
              <span className="avatar">
                {/* JJ */}
                {message.profilePhoto !== null ? (
                  <img
                    src={`https://data.jin-storage.us-sjo1.upcloudobjects.com/ProfilePhotos/${message.profilePhoto}`}
                  />
                ) : (
                  <img key={message.messageId} src={profile} />
                )}
              </span>
              <h3>{message.fullName}</h3>
              <span className="msg-time text-right">
                {Date.parse(
                  moment(message.createdDate).format("Y-MM-DD").toString()
                ) < Date.parse(moment(Date.now()).format("Y-MM-DD").toString())
                  ? moment(
                    new Date(message.createdDate.toString() + "Z").toString()
                  ).format("llll")
                  : moment(
                    new Date(message.createdDate.toString() + "Z").toString()
                  ).format("LT")}
              </span>
            </div>
            {/* <div className="message-to  mb-3">
              To: <span>Me</span>
            </div> */}
            {/* <div className="message-subject  mb-3">New posting</div> */}
            <div className="message-discription">
              {/* <pre style={{ wordBreak: "break-word" }}>{message.message}</pre> */}
              <textarea
                rows={3}
                data-gramm="false"
                data-gramm_editor="false"
                data-enable-grammarly="false"
                spellcheck="false"
                value={message.message}
                className="pre-textarea"
              ></textarea>
              {/* <p>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
    </p> */}
            </div>
          </>
        )}
        {showTextBox && (
          <Fragment>
            {" "}
            <div className="mt-4">
              <textarea
                rows={"3"}
                value={messageData.Message}
                maxLength={500}
                className="form-control"
                onChange={(e) => {
                  onTextMessageChange(e);
                }}
              />
              <MessageLimit
                currentLength={messageData.Message}
                totalLength={500}
              />
            </div>
            <div className="mt-5 text-right">
              <Buttons
                variant="warning"
                title="Send message"
                icon="icon icon-chat_bubble_outline mr-2"
                type="button"
                size="xs"
                color="white"
                onClick={(e) => SendMessage(e)}
              ></Buttons>
            </div>
          </Fragment>
        )}
        {/* {message.length === 0 && (
          <>
            <div className="main-msg-header mb-3">
              <span className="abbr mr-3">JJ</span>
              <h3>Jeff Johnston</h3>
              <span className="msg-time text-right">9:32 am</span>
            </div>
            <div className="message-to  mb-3">
              To: <span>Jennifer Smith</span>
            </div>
            <div className="message-subject  mb-3">New posting</div>
            <div className="message-discription">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt.Neque porro quisquam est, qui dolorem ipsum quia dolor
                sit amet, consectetur, adipisci velit, sed quia non numquam eius
                modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                voluptatem.Ut enim ad minima veniam, quis nostrum exercitationem
                ullam corporis suscipit laboriosam, nisi ut aliquid ex ea
                commodi consequatur?Quis autem vel eum iure reprehenderit qui in
                ea voluptate velit esse quam nihil molestiae consequatur, vel
                illum qui dolorem eum fugiat quo voluptas nulla pariatur?
              </p>
            </div>
          </>
        )} */}
      </div>
    </Fragment>
  );
}
// export default InboxMessage;
